<template>
  <div class="margin-top-sm fs-exclude">
    <CompaniesSwitcher
      v-bind:currentId="companyId"
      v-on:company-switched="companySwitched"
      v-if="this.$store.getters.companies.length > 0"
    />
    <a
      href="https://help.avalara.com/z_AvaWeb/Customer_Learning/UKL_Internal/Jaime's_Sandbox/Tax_category_classification"
      class="helper flex align-items-center right"
    >
      <s-icon name="info-circle" class="text-sm margin-right-xs"></s-icon
      ><span class="text-xs-strong">Need help?</span>
    </a>
    <h1 class="margin-bottom-md">Create a classification profile</h1>
    <s-row v-show="errorMessageTop !== ''">
      <s-col>
        <s-alert nodismiss class="margin-top-sm" status="error">
          <div>
            {{ errorMessageTop }}
          </div>
        </s-alert>
      </s-col>
    </s-row>
    <s-dialog
      class="set-default-dialog"
      id="default_dialog"
      aria-labelledby="title"
      aria-modal="true"
      ref="set-default-profile"
      noscroll=""
    >
      <div slot="header" id="title">Set default profile</div>
      <div slot="body">
        Are you sure you want to set "{{ profileName }}" as the default profile?
        This will remove "{{ currentDefaultProfile }}" from the default profile.
      </div>
      <div slot="footer">
        <button class="secondary small" @click="closeDefaultDialog()">
          No
        </button>
        <button class="primary small" @click="createProfile()">Yes</button>
      </div>
    </s-dialog>
    <s-row>
      <s-col span="3">
        <label for="profile-name">Name</label>
        <input
          type="text"
          id="profile-name"
          v-model="profileName"
          aria-describedby="profile-error-message"
          :class="[isProfileNameInvalid ? 'error' : '']"
        />
        <div id="profile-error-message" class="input-msg">
          <s-icon name="alert-circle-filled" aria-hidden="true"></s-icon>Profile
          name can't be empty
        </div>
        <div>
          <input
            type="checkbox"
            id="set-default"
            name="set-default"
            :checked="isDefaultProfile"
            v-model="isDefaultProfile"
          />
          <label for="set-default"
            >Set this classification profile as default</label
          >
          <div class="tooltip-wrap info-icon">
            <s-icon
              class="margin-left-sm"
              name="help-circle-filled"
              role="img"
              aria-label="account alert"
            ></s-icon>
            <div class="tooltip-content pad-all-sm">
              The default job profile is displayed first in the list. You can't
              delete the default job profile.
            </div>
          </div>
        </div>
      </s-col>
    </s-row>
    <h3 class="margin-top-none margin-bottom-xs">
      Select categories
    </h3>
    <div class="category-select-intro margin-bottom-xs">
      Select at least one category or sub-category for the profile.
    </div>
    <s-row>
      <s-col>
        <BulkCategorySelection
          :categories="categories"
          :categoryPaths.sync="selectedCategoryPaths"
        />
      </s-col>
    </s-row>
    <s-row v-show="!loading" class="margin-bottom-lg"
      ><s-col>
        <button
          :disabled="dedupedCategoryPaths().length === 0"
          @click="saveProfile"
          class="primary"
        >
          Create
        </button>
        <button @click="cancel" class="ghost">Cancel</button>
      </s-col></s-row
    >
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Provide } from "vue-property-decorator";
import CompaniesSwitcher from "@/components/CompaniesSwitcher.vue";
import errorsUtil from "./../utils/errorMappings";
import _ from "lodash";
import BulkCategorySelection, {
  Category
} from "@/components/BulkCategorySelection.vue";

@Component({
  components: {
    BulkCategorySelection,
    CompaniesSwitcher
  }
})
export default class BulkProfileCreate extends Vue {
  @Provide("$api") $api: any;
  @Provide("$_") $_: any;

  @Prop() private companyId!: string;
  @Prop() private accountId!: string;

  categories: Category[] = [];
  selectedCategoryPaths: string[] = [];
  isDefaultProfile = false;
  profileName = "";
  currentDefaultProfile = "";
  isProfileNameInvalid = false;
  loading = false;
  private errorMessageTop = "";

  mounted() {
    this.$store.commit("startLoading");
    this.loading = true;
    this.$api.get("api/categories/all").then((result: any) => {
      this.categories = result.data;
      this.$api
        .get(`item-classification-api/v1/profiles/${this.companyId}/default`)
        .then((response) => {
          this.currentDefaultProfile = response.data.profileName;
          this.$store.commit("stopLoading");
          this.loading = false;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.currentDefaultProfile = "";
            this.$store.commit("recordError", "");
          } else {
            this.displayError(error);
          }
          this.$store.commit("stopLoading");
          this.loading = false;
        });
    });
  }

  private displayError(error) {
    const errorCode = error.response.data.code;
    if (_.has(errorsUtil.errorMessages, errorCode)) {
      this.$store.commit("recordError", "");
      this.errorMessageTop = errorsUtil.errorMessages[errorCode];
    }
  }

  companySwitched(event) {
    const params = Object.assign({}, this.$route.params, {
      companyId: event.companyId,
    });
    this.$router.push({ name: "bulk_profile_new", params: params });
  }

  private saveProfile() {
    if (this.profileName === "") {
      this.isProfileNameInvalid = true;
      return;
    }
    if (
      this.isDefaultProfile &&
      this.currentDefaultProfile &&
      this.currentDefaultProfile !== ""
    ) {
      this.openDefaultDialog();
    } else {
      this.createProfile();
    }
  }

  private openDefaultDialog() {
    (this.$refs["set-default-profile"] as any).showModal();
  }

  private createProfile() {
    this.closeDefaultDialog();
    this.$store.commit("startLoading");
    const categoryPaths = this.dedupedCategoryPaths();
    this.$api
      .post(`item-classification-api/v1/profiles/${this.companyId}`, {
        categories: categoryPaths,
        profileName: this.profileName,
      })
      .then((response) => {
        if (this.isDefaultProfile) {
          this.$api
            .put(
              `item-classification-api/v1/profiles/${this.companyId}/default`,
              null,
              { params: { profileId: response.data.profileId } }
            )
            .then((res) => {
              this.redirectToProfiles();
            });
        } else {
          this.redirectToProfiles();
        }
      })
      .catch((error) => {
        this.$store.commit("stopLoading");
        this.displayError(error);
      });
  }

  private redirectToProfiles() {
    this.$store.commit("stopLoading");
    this.$store.commit(
      "recordToast",
      "Classification profile successfully created!"
    );
    this.$router.replace({
      path: `/classification/${this.accountId}/${this.companyId}/profiles`,
    });
  }

  private cancel() {
    this.$router.push({
      path: `/classification/${this.accountId}/${this.companyId}/profiles`,
    });
  }

  private dedupedCategoryPaths() {
    const allPaths = this.selectedCategoryPaths;
    return this.selectedCategoryPaths.filter((path) => {
      return !allPaths
        .filter((p) => p !== path)
        .some((p) => p.startsWith(path) && p.length > path.length);
    });
  }

  private closeDefaultDialog() {
    (this.$refs["set-default-profile"] as any).close();
  }
}
</script>

<style scoped lang="scss">
a:visited {
  color: var(--color-blue-dark);
}
.category-select-intro {
  color: var(--color-gray-dark);
}
.tooltip-wrap {
  display: initial;
  line-height: normal;
}
.tooltip-wrap .tooltip-content {
  display: none;
  position: fixed;
  background-color: var(--color-gray-lightest);
  z-index: 1;
  max-width: 300px;
}
.tooltip-wrap:hover .tooltip-content {
  display: initial;
}
.info-icon {
  s-icon {
    color: var(--color-blue-dark);
  }
}
.category,
.subcategories {
  label {
    font-weight: 600;
  }
  s-icon {
    cursor: pointer;
  }
}
</style>
