<template>
  <div id="app" :class="[widget ? 'widget-mode' : '']">
    <script
      v-if="!$_.isEmpty(this.$store.state.analyticsScript)"
      type="application/javascript"
      async
      :src="this.$store.state.analyticsScript"
    />
    <aui-ie-banner></aui-ie-banner>
    <aui-header
      class="fs-mask"
      ref="header"
      productname="Tax Codes Search"
      homeurl="/"
      loginurl="/auth/login"
      :user="JSON.stringify(convertUserForHeader(user))"
    ></aui-header>
    <aui-supernav
      v-if="!widget"
      :navitems="JSON.stringify(navs)"
      :active="activeNavItem()"
    >
    </aui-supernav>

    <s-container ref="container" class="main-container">
      <s-row v-if="success !== ''">
        <s-col>
          <s-alert status="success">
            <div>
              {{ success }}
            </div>
          </s-alert>
        </s-col>
      </s-row>
      <s-row v-show="error !== ''">
        <s-col>
          <s-alert status="error">
            <div class="flex">
              {{ error }}
              <a
                v-if="error.startsWith('An unexpected error occurred.')"
                href="https://help.avalara.com/Contact_Avalara"
                class="margin-left-sm"
                >Support</a
              >
            </div>
          </s-alert>
        </s-col>
      </s-row>
      <router-view />
    </s-container>
    <hr class="margin-all-none" />
    <aui-footer
      termsurl="https://www.avalara.com/us/en/legal/site-terms.html"
    ></aui-footer>
    <s-toasts ref="toast"></s-toasts>
    <div class="loader" v-if="isLoading">
      <s-loader loading role="alert" aria-live="assertive"></s-loader>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Provide, Watch } from "vue-property-decorator";
import { FeatureLevel } from "@/common/privilege";

interface NavMenuItem {
  label: string;
  url: string;
  dataRef: string;
}

interface NavLink {
  label: string;
  url?: string;
  menuItems?: Array<NavMenuItem>;
  dataRef: string;
}

@Component({
  components: {},
})
export default class App extends Vue {
  @Provide("$api") $api: any;
  @Provide("$_") $_: any;

  @Watch("$store.state.error")
  focusErrorAlert() {
    if (this.error !== "") {
      (this.$refs.container as any).scrollIntoView();
    }
  }

  data() {
    return { drawer: false, publicPath: "/" };
  }

  get widget() {
    return this.$store.state.widget;
  }

  get isLoading() {
    return this.$store.state.loading;
  }

  get user() {
    return this.$store.state.user;
  }

  get avataxRole() {
    return this.$store.state.avataxRole;
  }

  get error() {
    return this.$store.state.error;
  }

  get success() {
    return this.$store.state.success;
  }

  get toast() {
    return this.$store.state.toast;
  }

  get navs() {
    const navLinks: Array<NavLink> = [
      {
        label: "Home",
        url: "/",
        dataRef: "aui-supernav.home",
      },
    ];
    const bulkLevel = this.$store.getters.featureLevel("item-classification");
    const herculeRole = this.$store.state.herculeRole;
    if (!this.$_.isEmpty(bulkLevel) && bulkLevel != "disabled") {
      const classificationsItem = {
        label: "Tax category classifications",
        url: `/classification/${this.$store.getters.accountID}/${this.$store.getters.companyID}/jobs`,
        dataRef: "aui-supernav.classification.classificationsList",
      };
      const profilesItem = {
        label: "Classification profiles",
        url: `/classification/${this.$store.getters.accountID}/${this.$store.getters.companyID}/profiles`,
        dataRef: "aui-supernav.classification.profilesList",
      };
      const schemasItem = {
        label: "Classification schemas",
        url: `/classification/${this.$store.getters.accountID}/${this.$store.getters.companyID}/schemas`,
        dataRef: "aui-supernav.classification.schemasList",
      };
      const bulkNavLink = {
        label: "Tax category classification",
        menuItems:
          bulkLevel != FeatureLevel.MtccLite || bulkLevel != FeatureLevel.AtccTrial ||
          herculeRole == "ClassificationInternalExpert"
            ? [classificationsItem, profilesItem, schemasItem]
            : [classificationsItem],
        dataRef: "aui-supernav.classification.menu-trigger",
      };
      navLinks.push(bulkNavLink);
    }
    if (
      !this.$_.isEmpty(this.$store.getters.featureLevel("taxability")) &&
      this.$store.getters.featureLevel("taxability") === "enabled"
    ) {
      navLinks.push({
        label: "Taxability Matrix",
        url: "/taxability",
        dataRef: "aui-supernav.taxability",
      });
    }
    return navLinks;
  }

  resolveHerculeRole(herculeRoles) {
    const rolesOrderedByAccessLevel = [
      "ClassificationInternalExpert",
      "ClassificationInternalRep",
      "ClassificationAdmin",
      "AuthenticatedUser",
      "Guest",
    ];
    for (const roleByAccessLevel of rolesOrderedByAccessLevel) {
      if (herculeRoles.indexOf(roleByAccessLevel) >= 0) {
        return roleByAccessLevel;
      }
    }
  }

  convertUserForHeader(user) {
    if (!user) {
      return user;
    }
    const { givenName, familyName, email, isLoggedIn } = user;
    const avataxAccountId = user.customerAvataxAccountId
      ? user.customerAvataxAccountId
      : user.avataxAccountId;
    const isTechSupport = user.herculeRoles
      ? user.herculeRoles.includes("SupportUser")
      : false;
    const switchAccountsUrl = isTechSupport ? "/support/switchaccount" : "";
    const headerUser = {
      avataxAccountId: avataxAccountId,
      givenName: givenName,
      familyName: familyName,
      email: email,
      isLoggedIn: isLoggedIn,
      isTechSupport: isTechSupport,
      switchAccountsUrl: switchAccountsUrl,
    };
    return headerUser;
  }

  activeNavItem() {
    const currentNavLabel = this.$router.currentRoute.meta?.navLabel;
    return (
      this.navs.find((navLink) => navLink.label === currentNavLabel)?.dataRef ||
      "aui-supernav.home"
    );
  }

  @Watch("$store.state.toast")
  triggerToast() {
    const toastData = {
      text: this.$store.state.toast,
      id: "toast",
      ariaLabel: this.$store.state.toast,
      status: "success",
    };
    (this.$refs["toast"] as any).createToast(toastData);
  }

  created() {
    this.$store.state.user = (window as any).user;
    this.$store.state.widget =
      this.$route.query.widget && this.$route.query.widget === "true";
    this.$store.commit("recordAssetsPath", (window as any).assetsPath);
    this.$store.commit(
      "recordAssetsPathGlobal",
      (window as any).assetsPathGlobal
    );

    if ((window as any).auiSession) {
      this.$store.commit(
        "recordSessionExpiry",
        (window as any).auiSession.accessTokenExpireTime
      );
    }
    if ((window as any).user && (window as any).user.herculeFeatures) {
      this.$store.commit(
        "recordHerculeFeatures",
        (window as any).user.herculeFeatures
      );
    }
    if ((window as any).user && (window as any).user.herculeRoles) {
      const roles = (window as any).user.herculeRoles;
      const resolvedRole = this.resolveHerculeRole(roles);
      this.$store.commit("recordHerculeRole", resolvedRole);
    }
    if (
      (window as any).userDetails &&
      (window as any).userDetails.companies &&
      (window as any).userDetails.companies.length > 0
    ) {
      this.$store.commit(
        "recordCompanies",
        (window as any).userDetails.companies
      );
    }
    if ((window as any).userDetails && (window as any).userDetails.avataxRole) {
      this.$store.commit(
        "recordAvataxRole",
        (window as any).userDetails.avataxRole
      );
    }
    if (
      (window as any).userDetails &&
      (window as any).userDetails.bulkCustomerId &&
      (window as any).userDetails.bulkCustomerId !== -1
    ) {
      this.$store.commit(
        "recordBulkCustomerId",
        (window as any).userDetails.bulkCustomerId
      );
    }
    if (
      (window as any).analyticsScript &&
      !this.$_.isEmpty((window as any).analyticsScript)
    ) {
      this.$store.commit(
        "recordAnalyticsScript",
        (window as any).analyticsScript
      );
    }
  }
}
</script>
<style lang="scss">
#app {
  font-family: "Source Sans Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  height: 100vh;
  width: 100vw;
  &.widget-mode {
    margin-top: 15px;
    & aui-footer,
    & aui-header {
      display: none;
    }
    & s-container {
      height: 100%;
      padding: 0;
      max-width: none;
    }
    & .margin-all-none {
      display: none;
    }
  }
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
div.card {
  border: thin solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  width: 100%;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  margin-right: auto !important;
  margin-left: auto !important;
  padding: 12px 16px;
}
.card .overline {
  font-size: 0.625rem !important;
  font-weight: 400;
  letter-spacing: 0.1666666667em !important;
  line-height: 1rem;
  text-transform: uppercase;
  font-family: "Source Sans Pro", sans-serif !important;
  margin-bottom: 16px;
}
.card .headline {
  font-size: 1rem !important;
  font-weight: 600;
  letter-spacing: normal !important;
}
.headline s-badge {
  background-color: var(--color-green-dark);
  border-color: var(--color-green-dark);
  height: 32px;
  padding: 0 12px;
  font-size: 14px;
  border-radius: 5px;
}
.overline s-badge {
  background: var(--color-red-medium);
  padding: 0 12px;
  font-size: 0.625rem;
  border-radius: 5px;
}
.card .subtitle {
  color: rgba(0, 0, 0, 0.6);
}
.card .display-1 {
  margin-bottom: 10px;
  font-size: 1.1rem;
  line-height: 1.2;
  letter-spacing: 0.0073529412em;
  font-weight: 600;
}
select {
  padding: 0rem 0.35rem;
}

.card .subtitle-1 {
  color: rgba(0, 0, 0, 0.87);
  white-space: normal;
  line-height: 1.2;
  font-weight: 400;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 0.85rem;
  letter-spacing: 0.009375em;
}
.card .subtitle-2 {
  color: rgba(0, 0, 0, 0.6);
  line-height: 1.2;
  margin-top: 2px !important;
  font-size: 0.875rem !important;
  font-weight: 500;
  letter-spacing: 0.0071428571em;
}
.subtitle-2 .text--primary {
  color: rgba(0, 0, 0, 0.87) !important;
}
h2,
h3 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.back-button {
  a,
  a:visited {
    text-decoration: none;
    width: 300px;
  }
  padding-bottom: 0;
  button {
    display: flex;
    align-items: center;
    s-icon {
      margin-right: 5px;
      height: 100%;
      margin-top: 13px;
    }
  }
}
.back-button {
  font-weight: 600;
  s-icon {
    margin-right: 5px;
  }
  margin-top: 15px;
}

[name="arrow-left-turn"]:before {
  content: "\21a9";
  display: block;
  transform: rotateX(180deg);
}
s-badge.taxcodes-badge {
  background-color: var(--color-blue-lighter);
  color: var(--color-grey-dark);
  border: 1px solid var(--color-blue-medium);
}
.main-container {
  padding-top: 10px;
  min-height: 625px;
}
.btn-use-this {
  float: right;
}
.main-container a {
  display: flex !important;
}
.loader {
  position: fixed;
  top: 40%;
  margin-left: 50%;
  left: 0;
  z-index: 1000;
}
.helper {
  cursor: pointer;
  color: var(--color-blue-dark);
  s-icon {
    color: var(--color-blue-dark);
  }
}
</style>
