var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('SearchBar'),_c('s-row',_vm._l((_vm.categories),function(c){return _c('s-col',{key:c.name,attrs:{"span":"3"}},[_c('router-link',{attrs:{"to":{
          name: 'search_category',
          query: { category: c.name, tab: 'decision_tree' },
        }}},[_c('h2',{staticClass:"category-heading"},[_vm._v(_vm._s(c.name))])]),_vm._l((c.subcategories),function(s){return _c('div',{key:s.name,staticClass:"mt-2"},[_c('router-link',{attrs:{"data-cy":s.name + '_subcategories',"to":{
            name: 'search_category',
            query: {
              category: c.name,
              subcategory: s.name,
              tab: 'decision_tree',
            },
          }}},[_c('div',{staticClass:"subcategory-heading"},[_vm._v(_vm._s(s.name))])])],1)})],2)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }