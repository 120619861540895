<template>
  <div v-show="!loading" class="margin-top-sm fs-exclude">
    <h1 class="margin-bottom-md">Edit classification profile</h1>
    <s-row v-show="errorMessageTop !== ''">
      <s-col>
        <s-alert nodismiss class="margin-top-sm" status="error">
          <div>
            {{ errorMessageTop }}
          </div>
        </s-alert>
      </s-col>
    </s-row>
    <s-row v-show="isProfileOutdated">
      <s-col>
        <s-alert class="margin-top-sm" status="warning" nodismiss="">
          <div>
            Please reselect the relevant categories and save the profile. This
            will ensure there is no impact to predictions using this profile.
          </div>
        </s-alert>
      </s-col>
    </s-row>
    <s-dialog
      class="set-default-dialog"
      id="default_dialog"
      aria-labelledby="title"
      aria-modal="true"
      ref="set-default-profile"
      noscroll=""
    >
      <div slot="header" id="title">Set default profile</div>
      <div slot="body">
        Are you sure you want to set "{{ profileName }}" as the default profile?
        This will remove "{{ currentDefaultProfile }}" from the default profile.
      </div>
      <div slot="footer">
        <button class="secondary small" @click="closeDefaultDialog()">
          No
        </button>
        <button class="primary small" @click="updateProfile()">Yes</button>
      </div>
    </s-dialog>
    <s-row>
      <s-col span="3">
        <label for="profile-name">Name</label>
        <input
          type="text"
          id="profile-name"
          v-model="profileName"
          aria-describedby="profile-error-message"
          :class="[isProfileNameInvalid ? 'error' : '']"
        />
        <div id="profile-error-message" class="input-msg">
          <s-icon name="alert-circle-filled" aria-hidden="true"></s-icon>Profile
          name can't be empty
        </div>
        <div>
          <input
            type="checkbox"
            id="set-default"
            name="set-default"
            :checked="isDefaultProfile"
            :disabled="isProfileAlreadyDefault"
            v-model="isDefaultProfile"
          />
          <label for="set-default"
            >Set this classification profile as default</label
          >
          <div class="tooltip-wrap info-icon">
            <s-icon
              class="margin-left-sm"
              name="help-circle-filled"
              role="img"
              aria-label="account alert"
            ></s-icon>
            <div class="tooltip-content pad-all-sm">
              The default job profile is displayed first in the list. You can't
              delete the default job profile.
            </div>
          </div>
        </div>
      </s-col>
    </s-row>
    <h3 class="margin-top-none margin-bottom-xs">
      Select categories
    </h3>
    <div class="category-select-intro margin-bottom-xs">
      Select at least one category or sub-category for the profile.
    </div>
    <s-row>
      <s-col>
        <BulkCategorySelection
          :categories="categories"
          :categoryPaths.sync="selectedCategoryPaths"
        />
      </s-col>
    </s-row>
    <s-row v-show="!loading" class="margin-bottom-lg"
      ><s-col>
        <button
          :disabled="dedupedCategoryPaths().length === 0"
          @click="saveProfile()"
          class="primary"
        >
          Save
        </button>
        <button @click="cancel" class="ghost">Cancel</button>
      </s-col></s-row
    >
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Provide, Watch } from "vue-property-decorator";
import CompaniesSwitcher from "@/components/CompaniesSwitcher.vue";
import errorsUtil from "./../utils/errorMappings";
import _ from "lodash";
import BulkCategorySelection, {
  Category
} from "@/components/BulkCategorySelection.vue";

interface Profile {
  accountId: string;
  companyId: string;
  categories: string[];
  createdBy: string;
  createdAt: string;
  deletedAt: string;
  isDefault: boolean;
  profileId: string;
  profileName: string;
  updatedBy: string;
  updatedAt: string;
  isOutdated: boolean;
}

@Component({
  components: {
    BulkCategorySelection,
    CompaniesSwitcher
  }
})
export default class BulkProfileUpdate extends Vue {
  @Provide("$api") $api: any;
  @Provide("$_") $_: any;

  @Prop() private companyId!: string;
  @Prop() private accountId!: string;
  @Prop() private profileId!: string;

  categories: Category[] = [];
  selectedCategoryPaths: string[] = [];
  isProfileAlreadyDefault = false;
  isDefaultProfile = false;
  profileName = "";
  isProfileOutdated = false;
  isProfileNameInvalid = false;
  loading = false;
  currentDefaultProfile = "";
  private errorMessageTop = "";

  mounted() {
    this.$store.commit("startLoading");
    this.loading = true;
    this.$api
      .get("api/categories/all")
      .then((result: any) => {
        this.categories = result.data;
        this.$api
          .get(
            `item-classification-api/v1/profiles/${this.companyId}/${this.profileId}`
          )
          .then((response) => {
            const profile: Profile = response.data;
            this.selectedCategoryPaths = this.expandedCategoryPaths(
              profile.categories
            );
            this.isDefaultProfile = profile.isDefault;
            this.isProfileAlreadyDefault = profile.isDefault;
            this.profileName = profile.profileName;
            this.isProfileOutdated = profile.isOutdated;
            this.$api
              .get(
                `item-classification-api/v1/profiles/${this.companyId}/default`
              )
              .then((response) => {
                this.currentDefaultProfile = response.data.profileName;
                this.$store.commit("stopLoading");
                this.loading = false;
              })
              .catch((error) => {
                if (error.response.status === 404) {
                  this.currentDefaultProfile = "";
                  this.$store.commit("recordError", "");
                }
                this.$store.commit("stopLoading");
                this.loading = false;
              });
          });
      })
      .catch((error) => {
        this.$store.commit("stopLoading");
        this.displayError(error);
      });
  }

  private displayError(error) {
    const errorCode = error.response.data.code;
    if (_.has(errorsUtil.errorMessages, errorCode)) {
      this.$store.commit("recordError", "");
      this.errorMessageTop = errorsUtil.errorMessages[errorCode];
    }
  }

  private saveProfile() {
    if (this.profileName === "") {
      this.isProfileNameInvalid = true;
      return;
    }
    if (
      this.isDefaultProfile &&
      this.currentDefaultProfile &&
      this.currentDefaultProfile !== "" &&
      this.profileName !== this.currentDefaultProfile
    ) {
      this.openDefaultDialog();
    } else {
      this.updateProfile();
    }
  }

  private openDefaultDialog() {
    (this.$refs["set-default-profile"] as any).showModal();
  }

  private updateProfile() {
    this.closeDefaultDialog();
    this.$store.commit("startLoading");
    const categoryPaths = this.dedupedCategoryPaths();
    this.$api
      .put(
        `item-classification-api/v1/profiles/${this.companyId}/${this.profileId}`,
        {
          categories: categoryPaths,
          profileName: this.profileName,
        }
      )
      .then((response) => {
        if (this.isDefaultProfile) {
          this.$api
            .put(
              `item-classification-api/v1/profiles/${this.companyId}/default`,
              null,
              { params: { profileId: this.profileId } }
            )
            .then((res) => {
              this.redirectToProfiles();
            });
        } else {
          this.redirectToProfiles();
        }
      })
      .catch((error) => {
        this.$store.commit("stopLoading");
        this.displayError(error);
      });
  }

  private redirectToProfiles() {
    this.$store.commit("stopLoading");
    this.$store.commit(
      "recordToast",
      `"${this.profileName}" successfully updated!`
    );
    this.$router.replace({
      path: `/classification/${this.accountId}/${this.companyId}/profiles`,
    });
  }

  private closeDefaultDialog() {
    (this.$refs["set-default-profile"] as any).close();
  }

  private cancel() {
    this.$router.push({
      path: `/classification/${this.accountId}/${this.companyId}/profiles`,
    });
  }

  private dedupedCategoryPaths() {
    const allPaths = this.selectedCategoryPaths;
    return this.selectedCategoryPaths.filter((path) => {
      return !allPaths
        .filter((p) => p !== path)
        .some((p) => p.startsWith(path) && p.length > path.length);
    });
  }

  private removeOutdatedPaths(paths: string[]): string[] {
    const allPossibleNamePaths = this.categories.flatMap((c) => {
      const categoryPath = c.namePath;
      const subcategoryPaths = c.subcategories
        ? c.subcategories.map((s) => s.namePath)
        : [];
      const leafPaths = c.subcategories
        ? c.subcategories.flatMap((s) =>
            s.subcategories ? s.subcategories.map((leaf) => leaf.namePath) : []
          )
        : [];
      return [categoryPath, ...subcategoryPaths, ...leafPaths];
    });
    return paths.filter((p) => allPossibleNamePaths.indexOf(p) >= 0);
  }

  private expandedCategoryPaths(categoryPaths: string[]): string[] {
    const allPaths = categoryPaths.flatMap((path) => {
      const splits = path.split(" > ");
      return [
        splits[0],
        splits.length >= 2 ? `${splits[0]} > ${splits[1]}` : splits[0],
        splits.length === 3 ? path : splits[0],
      ];
    });
    return this.removeOutdatedPaths([...new Set(allPaths)]);
  }
}
</script>

<style scoped lang="scss">
.category-select-intro {
  color: var(--color-gray-dark);
}
.tooltip-wrap {
  display: initial;
  line-height: normal;
}
.tooltip-wrap .tooltip-content {
  display: none;
  position: fixed;
  background-color: var(--color-gray-lightest);
  z-index: 1;
  max-width: 300px;
}
.tooltip-wrap:hover .tooltip-content {
  display: initial;
}
.info-icon {
  s-icon {
    color: var(--color-blue-dark);
  }
}
.category,
.subcategories {
  label {
    font-weight: 600;
  }
  s-icon {
    cursor: pointer;
  }
}
</style>
