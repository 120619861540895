<template>
  <div>
    <SearchBar />
    <s-row>
      <s-col span="3" v-for="c in categories" v-bind:key="c.name">
        <router-link
          v-bind:to="{
            name: 'search_category',
            query: { category: c.name, tab: 'decision_tree' },
          }"
        >
          <h2 class="category-heading">{{ c.name }}</h2>
        </router-link>
        <div class="mt-2" v-for="s in c.subcategories" v-bind:key="s.name">
          <router-link
            v-bind:data-cy="s.name + '_subcategories'"
            v-bind:to="{
              name: 'search_category',
              query: {
                category: c.name,
                subcategory: s.name,
                tab: 'decision_tree',
              },
            }"
          >
            <div class="subcategory-heading">{{ s.name }}</div>
          </router-link>
        </div>
      </s-col>
    </s-row>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Provide } from "vue-property-decorator";
import SearchBar from "@/components/SearchBar.vue";
@Component({
  components: {
    SearchBar,
  },
})
export default class Home extends Vue {
  @Provide("$api") $api: any;
  private drawer = false;

  created() {
    this.$store.commit("startLoading");
    this.$store.state.allCategories = [];
    this.$api.get("api/categories/").then((result: any) => {
      this.$store.state.allCategories = result.data;
      this.$store.commit("stopLoading");
    });
  }

  get categories() {
    return this.$store.state.allCategories;
  }

  openDrawer() {
    this.drawer = true;
  }
}
</script>

<style scoped lang="scss">
a {
  text-decoration-line: none;
}

a:hover {
  color: orange;
}

.category-heading {
  border-bottom: 1px #e7e7e7 solid;
  color: var(--color-orange-light);
}
</style>
