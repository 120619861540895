<template>
  <div class="margin-top-sm">
    <h1 class="margin-bottom-none">Classification schema</h1>
    <s-row v-show="errorMessageTop !== ''">
      <s-col>
        <s-alert nodismiss class="margin-top-sm" status="error">
          <div>
            {{ errorMessageTop }}
          </div>
        </s-alert>
      </s-col>
    </s-row>
    <div class="intro">
      Enter the details of the schema based on your tax category classification
      requirements.
    </div>
    <s-row
      v-if="!loading"
      class="margin-top-lg margin-left-none margin-right-none schema-form"
    >
      <s-col span="9" class="pad-left-none pad-right-none">
        <div class="box">
          <h3 class="margin-top-none margin-bottom-xs">
            Fields Configuration
          </h3>
          <s-row class="margin-top-lg">
            <s-col span="3" class="field-name large">
              <div for="new-field-name" class="label">
                Field name
              </div>
              <input
                type="text"
                id="new-field-name"
                :value="newFieldName"
                @input="(e) => onNewFieldNameChange(e)"
              />
              <div v-if="isAutoGenerateFieldPossible()">
                <input
                  type="checkbox"
                  id="create-field"
                  name="create-field"
                  :checked="createFieldForItemId"
                  v-model="createFieldForItemId"
                />
                <label for="create-field"
                  >Autogenerate this field as itemcode</label
                >
              </div>
            </s-col>
            <s-col span="2"
              ><button
                @click="addField"
                class="primary action-margin margin-bottom-md"
                :disabled="!isAddPossible()"
              >
                + Add
              </button></s-col
            >
          </s-row>
          <s-row>
            <s-col span="3">
              <div
                class="added-field-text"
                v-for="field in addedFields"
                :key="field.name"
              >
                {{
                  field.autogenerate
                    ? field.name + " (Autogenerated)"
                    : field.name
                }}
              </div>
            </s-col>
            <s-col span="2" class="field-action">
              <div v-for="(item, index) in addedFields" :key="index">
                <button
                  @click="(e) => deleteField(e, item.name)"
                  class="ghost icon  margin-bottom-sm text-xs"
                  aria-label="close"
                >
                  <s-icon name="trash"></s-icon>
                </button>
              </div>
            </s-col>
          </s-row>
        </div>
      </s-col>
      <s-col span="3" class="pad-left-none pad-right-none"
        ><div class="box margin-left-lg">
          <h3 class="margin-top-none margin-bottom-xs">
            File Configuration
          </h3>
          <div class="file-configuration">
            <label for="file-type" id="type" class="label margin-top-lg">
              File type
            </label>
            <s-select
              nosearch
              id="file-type"
              inputid="file-type"
              :optionsList="
                JSON.stringify(
                  fileTypes.map((type) => {
                    return {
                      label: type,
                      value: type,
                      selected: type == fileType,
                    };
                  })
                )
              "
              v-on:s-select="(e) => onFileTypeSelected(e)"
            ></s-select>
            <div v-if="fileType == 'Other'" class="file-separator">
              <label
                for="file-separator"
                id="separator"
                class="label margin-top-lg"
              >
                Separator
              </label>
              <input
                type="text"
                id="file-separator"
                :value="fileSeparator"
                @input="(e) => onFileSeparatorChange(e)"
              />
            </div>
            <label for="has-header" class="label maergin-top-lg"
              >Has column headers ?</label
            >
            <s-select
              nosearch
              id="has-header"
              inputid="has-header"
              :optionsList="
                JSON.stringify(
                  hasHeaderTypes.map((type) => {
                    return {
                      label: type,
                      value: type,
                      selected:
                        (hasFileHeader && type == 'Yes') ||
                        (!hasFileHeader && type == 'No'),
                    };
                  })
                )
              "
              v-on:s-select="(e) => onHasHeaderSelected(e)"
            ></s-select>
          </div>
        </div>
      </s-col>
    </s-row>
    <s-row v-if="!loading">
      <s-col class="pad-bottom-none">
        <button
          @click="saveChanges"
          class="primary icon-trailing"
          :disabled="!isSavePossible()"
        >
          Save and continue<s-icon name="arrow-right"></s-icon>
        </button>
        <button @click="cancel" class="ghost">Cancel</button>
      </s-col>
    </s-row>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Provide, Watch } from "vue-property-decorator";
import CompaniesSwitcher from "@/components/CompaniesSwitcher.vue";
import errorsUtil from "../utils/errorMappings";
import _ from "lodash";

@Component({
  components: {
    CompaniesSwitcher,
  },
})
export default class BulkSchemaCreate extends Vue {
  @Provide("$api") $api: any;
  @Provide("$_") $_: any;

  @Prop() private companyId!: string;
  @Prop() private accountId!: string;
  @Prop() private data!: any;

  private loading = false;
  private errorMessageTop = "";
  private newFieldName = "";
  private fileTypes = ["CSV", "TSV", "PSV", "Other"];
  private fileType = this.fileTypes[0];
  private fileSeparator = "";
  private hasHeaderTypes = ["Yes", "No"];
  private hasFileHeader = false;
  private addedFields: any[] = [];
  private createFieldForItemId = false;

  mounted() {
    if (!this.data) {
      this.fileType = this.fileTypes[0];
      this.fileSeparator = "";
      this.hasFileHeader = true;
      this.addedFields = [];
    } else {
      this.fileType = this.getFileType(this.data.schema.delimiter);
      this.fileSeparator = this.data.schema.delimiter;
      this.hasFileHeader = this.data.schema.hasHeader;
      this.addedFields = this.data.schema.fields;
    }
  }

  isAutoGenerateFieldPossible() {
    return this.addedFields.every((f) => !f.autogenerate);
  }

  private displayError(error) {
    const errorCode = error.response.data.code;
    if (_.has(errorsUtil.errorMessages, errorCode)) {
      this.$store.commit("recordError", "");
      this.errorMessageTop = errorsUtil.errorMessages[errorCode];
    }
  }

  isSavePossible() {
    return this.getDelimiter() != "" && this.addedFields.length != 0;
  }

  onFileTypeSelected(e) {
    this.fileType = e.detail.item.value;
  }

  onHasHeaderSelected(e) {
    this.hasFileHeader = e.detail.item.value == "Yes";
  }

  getDelimiter() {
    if (this.fileType == "CSV") {
      return ",";
    } else if (this.fileType == "PSV") {
      return "|";
    } else if (this.fileType == "TSV") {
      return "\t";
    } else {
      return this.fileSeparator;
    }
  }

  getFileType(delimiter: string) {
    if (delimiter == ",") {
      return "CSV";
    } else if (delimiter == "|") {
      return "PSV";
    } else if (delimiter == "\t") {
      return "TSV";
    } else {
      return "Other";
    }
  }

  cancel() {
    (this.$parent as any).previousStep();
  }

  async saveChanges() {
    this.loading = true;
    this.$store.commit("startLoading");
    const schemaData = {
      schema: {
        delimiter: this.getDelimiter(),
        fields: this.addedFields,
        hasHeader: this.hasFileHeader,
      },
      schemaType: "delimited",
    };
    try {
      const saveSchemaResponse = await this.$api.put(
        `item-classification-api/v1/company/${this.companyId}/defaultSchema`,
        schemaData
      );
      (this.$parent as any).nextStep();
      this.loading = false;
      this.$store.commit("stopLoading");
    } catch (error) {
      this.displayError(error);
      this.loading = false;
      this.$store.commit("stopLoading");
    }
  }

  onNewFieldNameChange(e) {
    this.newFieldName = e.target.value;
  }
  onFileSeparatorChange(e) {
    this.fileSeparator = e.target.value;
  }

  addField() {
    this.addedFields = this.addedFields.concat([
      {
        name: this.newFieldName,
        required: false,
        type: "string",
        autogenerate: this.createFieldForItemId,
      },
    ]);
    this.newFieldName = "";
    this.createFieldForItemId = false;
  }

  isAddPossible() {
    return this.newFieldName.trim() != "";
  }

  deleteField(e, fieldName) {
    this.addedFields = this.addedFields.filter((f) => f.name != fieldName);
  }
}
</script>

<style scoped lang="scss">
.intro {
  color: var(--color-gray-dark);
}
.box {
  padding-top: 20px;
  padding-bottom: 30px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 15px;
  padding-left: 15px;
  min-height: 407px;
}
.label {
  color: black;
  font-size: 0.98rem;
  font-weight: 500;
  padding-bottom: 10px;
}
.schema-form {
  input {
    min-width: 200px;
    max-width: 280px;
  }

  s-select {
    min-width: 100px;
    max-width: 100px;
  }

  .large {
    s-select {
      min-width: 200px;
      max-width: 280px;
    }
  }
  .pad-bottom-label {
    padding-bottom: 8px;
  }
  .action-margin {
    margin-top: 32px;
  }
  .added-field-text {
    margin-top: 24px;
    font-size: 0.98rem;
  }
  .delete-button {
    margin-bottom: 24px;
  }
  .field-action {
    margin-top: 16px;
  }
}
</style>
