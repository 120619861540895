<template>
  <div class="fs-exclude">
    <s-row>
      <s-col span="3" class="back-button">
        <router-link to="/taxability">
          <s-icon class="align-self-center" name="arrow-left"></s-icon
          ><span>Back</span>
        </router-link>
      </s-col>
    </s-row>
    <s-row class="heading-decsription">
      <s-col span="8">
        <h3>Build a taxability matrix</h3>
        <p>
          Add tax codes to create your own taxability matrix for specific tax
          codes and jurisdictions.
        </p>
      </s-col>
    </s-row>
    <s-row>
      <s-col span="4">
        <label class="label-info"
          >Add up to {{ taxcodeLimit }} Avalara tax codes
          <div class="tooltip-wrap">
            <s-icon
              class="margin-left-xs"
              name="help-circle-filled"
              role="img"
              aria-label="account alert"
            ></s-icon>
            <div class="tooltip-content pad-all-sm">
              Add multiple tax codes by copying and pasting them in space
              separated format.
            </div>
          </div></label
        >
        <CustomSelectBox
          name="searchTaxcodes"
          placeholder="PF052132"
          :data="allTaxCodesData"
          :onSelect="onTaxCodeSelected"
          :onDeselect="onTaxCodeDeselected"
          :selectedItems="selectedTaxCodesParsed"
          :onInputChanged="searchTaxCodes"
          :key="selectBoxKey"
          :isInputDisabled="$store.state.loading"
          :isError="
            getSelectedTaxCodesOptions().length > taxcodeLimit ||
            isSearchInputError
          "
          :isSearcheable="true"
        />
        <div
          v-if="getSelectedTaxCodesOptions().length > taxcodeLimit"
          class="limit-error"
        >
          Add up to {{ taxcodeLimit }} tax codes.
        </div>
        <div v-else-if="isSearchInputError" class="limit-error">
          Invalid tax codes. Check that your tax codes are correct and separated
          by a space or comma.
        </div>
      </s-col>
      <s-col span="3">
        <div>
          <label for="select-country">Select a country</label>
          <CustomSelectBox
            name="searchCountries"
            placeholder="Select Country"
            :data="allRegionsDataToBeUpdated"
            :onSelect="onRegionSelected"
            :onDeselect="onRegionDeselected"
            :onInputChanged="function noop() {}"
            :key="selectBoxKey"
            :isInputDisabled="$store.state.loading"
            :isError="false"
            :isSearcheable="true"
            inputid="select-country"
            id="select-country"
          >
          </CustomSelectBox>
        </div>
      </s-col>
      <s-col span="3">
        <div
          v-if="
            getSelectedRegionsToBeUpdated().includes(Region.US) ||
            getSelectedRegionsToBeUpdated().includes(Region.CA)
          "
        >
          <label>{{ getStatesSelectBoxLabel() }}</label>
          <CustomSelectBox
            :name="getStatesSelectBoxLabel()"
            placeholder="Alaska"
            :data="allJurisdictionsData"
            :onSelect="onJurisdictionSelected"
            :onDeselect="onJurisdictionDeselected"
            :onInputChanged="searchJurisdictions"
            :hasFooter="true"
            :onSelectAll="onSelectAllJurisdictions"
            :onUnselectAll="onUnselectAllJurisdictions"
            :key="selectBoxKey"
            :isSearcheable="true"
          />
        </div>
      </s-col>
      <s-col span="2">
        <div class="margin-top-lg search-row">
          <h3 class="margin-top-none">
            <a href="/taxability/search">
              <button class="ghost text-xs" title="clear all selections" link>
                Clear All
              </button>
            </a>
          </h3>
          <button
            @click="onTaxabilitySearch"
            class="primary icon-leading text-xs margin-right-lg"
            :disabled="
              getSelectedRegionsToBeUpdated().length === 0 ||
              getSelectedTaxCodesOptions().length === 0 ||
              ((getSelectedRegionsToBeUpdated().includes(Region.US) ||
                getSelectedRegionsToBeUpdated().includes(Region.CA)) &&
                getSelectedJurisdictionsOptions().length === 0) ||
              getSelectedTaxCodesOptions().length > taxcodeLimit
            "
          >
            <s-icon name="search"></s-icon>Create
          </button>
        </div>
      </s-col>
    </s-row>
    <s-row>
      <s-col span="12" class="margin-bottom-none pad-bottom-none">
        <hr class="margin-top-sm margin-bottom-sm" />
      </s-col>
    </s-row>
    <s-row
      v-if="
        getSelectedRegions().length === 0 ||
        selectedTaxCodesParsed.length === 0 ||
        ((getSelectedRegions().includes(Region.US) ||
          getSelectedRegions().includes(Region.CA)) &&
          selectedJurisdictionsParsed.length === 0) ||
        taxabilities.length === 0
      "
    >
      <s-col span="12">
        <div class="margin-top-xl placeholder">
          <img
            :src="`${$store.getters.assetsPath}/img/placeholder.png`"
            class="block margin-right-auto margin-left-auto"
          />
          <p class="text-center">
            Search for an Avalara tax code to get started.
          </p>
        </div>
      </s-col>
    </s-row>
    <TaxabilityTable
      v-if="
        getSelectedRegions().length > 0 &&
        selectedTaxCodesParsed.length > 0 &&
        ((!getSelectedRegions().includes(Region.US) &&
          !getSelectedRegions().includes(Region.CA)) ||
          selectedJurisdictionsParsed.length > 0) &&
        taxabilities.length > 0
      "
      :taxcodes="selectedTaxCodesParsed"
      :jurisdictions="selectedJurisdictionsParsed"
      :regions="getSelectedRegions()"
      :codeToJurisdictionTaxability="codeToJurisdictionTaxability"
    />
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop, Watch, Provide } from "vue-property-decorator";
import router from "@/router";
import axios from "axios";
import _ from "lodash";
import CustomSelectBox from "@/components/CustomSelectBox.vue";
import TaxabilityTable from "@/components/TaxabilityTable.vue";
import analyticsLayerUtil from "./../utils/index";
interface TaxCode {
  code: string;
  description: string;
  additionalDescription: string;
  categories: string[];
}
interface TaxCodeSearchResponse {
  code: string;
  short_desc: string;
}
interface TaxabilityRule {
  taxability: string;
  jurisdiction_name: string;
  jurisdiction_code: string;
  country_code: string;
  jurisdiction_type: string;
  additional_info: string;
}
interface TaxabilityRules {
  taxcode: string;
  rules: TaxabilityRule[];
}
interface SelectOption {
  label: string;
  value: string;
  selected?: boolean;
}

interface Jurisdiction {
  name: string;
  code: string;
  type: string;
}

interface JurisdictionResponse {
  country_code: string;
  country_name: string;
  jurisdictions: Jurisdiction[];
}

enum Region {
  US = "US",
  CA = "CA",
}
@Component({
  components: {
    CustomSelectBox,
    TaxabilityTable,
  },
})
export default class TaxabilitySearch extends Vue {
  @Provide("$api") $api: any;
  @Prop({ default: "" }) private selectedTaxCodes!: string;
  @Prop({ default: "" }) private selectedJurisdictions!: string;
  @Prop({ default: Region.US }) private selectedRegion!: string;
  Region = Region;
  taxcodeLimit = 20;
  selectBoxKey = "0";
  selectedTaxCodesParsed: string[] =
    this.selectedTaxCodes.length > 0 ? this.selectedTaxCodes.split(",") : [];
  selectedJurisdictionsParsed: string[] =
    this.selectedJurisdictions.length > 0
      ? this.selectedJurisdictions.split(",")
      : [];
  selectedRegionOptions: string[] =
    this.selectedRegion.length > 0 ? this.selectedRegion.split(",") : [];
  allRegionsData: SelectOption[] = [];
  allRegionsDataToBeUpdated: SelectOption[] = [];
  allTaxCodesData: SelectOption[] = [];
  allJurisdictionsData: SelectOption[] = [];
  taxabilities: TaxabilityRules[] = [];
  codeToJurisdictionTaxability: {
    [taxCode: string]: { [jurisdiction: string]: TaxabilityRule };
  } = {};
  isSearchInputError = false;

  async mounted() {
    await this.populateJurisdictions();
    await this.loadTaxabilityUsingRoute();
  }

  public async populateJurisdictions() {
    try {
      const response = await this.$api.get(`/api/jurisdictions/`);

      const allJurisdictionsForCountries: JurisdictionResponse[] =
        response.data;
      allJurisdictionsForCountries.forEach((country) => {
        if (
          country.country_code != Region.US &&
          country.country_code != Region.CA
        ) {
          this.allRegionsData.push({
            label: country.country_name,
            value: country.country_code,
            selected: this.selectedRegionOptions.includes(country.country_code),
          });
        }
      });
      this.allRegionsData.sort((a, b) => (a.label < b.label ? -1 : 1));
      this.allRegionsData.splice(
        0,
        0,
        {
          label: "United States",
          value: Region.US,
          selected: this.selectedRegionOptions.includes(Region.US),
        },
        {
          label: "Canada",
          value: Region.CA,
          selected: this.selectedRegionOptions.includes(Region.CA),
        }
      );
      this.allRegionsDataToBeUpdated = this.allRegionsData;

      allJurisdictionsForCountries.forEach((countryJurisdictions) => {
        this.$store.commit("setCountryJurisdictions", {
          countryCode: countryJurisdictions.country_code,
          jurisdictionNames: countryJurisdictions.jurisdictions.map(
            (j) => j.name
          ),
        });
      });

      const jurisdictionsToPopulate: string[] = this.getSelectedRegions()
        .filter(
          (countryCode) =>
            countryCode === Region.US || countryCode === Region.CA
        )
        .map((countryCode) =>
          this.$store.getters.countryJurisdictions(countryCode)
        )
        .flat();

      this.allJurisdictionsData = jurisdictionsToPopulate.map(
        (jurisdiction: string) => {
          const jurisdictionData: SelectOption = {
            label: jurisdiction,
            value: jurisdiction,
            selected:
              this.selectedJurisdictionsParsed.length > 0 &&
              this.selectedJurisdictionsParsed.indexOf(jurisdiction) > -1,
          };
          return jurisdictionData;
        }
      );
    } catch {
      this.$store.commit("stopLoading");
    }
  }

  public async loadTaxabilityUsingRoute() {
    try {
      const response = await this.$api.post(
        "/api/taxcodes/",
        this.selectedTaxCodesParsed
      );

      const allTaxCodes: TaxCode[] = response.data;
      this.allTaxCodesData = allTaxCodes.map((value: TaxCode) => ({
        label: `${value.code} - ${value.description.replace(/\s{2,}/g, " ")}`,
        value: value.code,
        selected: true,
      }));
      if (
        this.selectedTaxCodesParsed.length > 0 &&
        ((!this.selectedRegionOptions.includes(Region.US) &&
          !this.selectedRegionOptions.includes(Region.CA)) ||
          this.selectedJurisdictionsParsed.length > 0)
      )
        this.loadTaxability();
    } catch (error) {
      this.$store.commit("stopLoading");
    }
  }

  public loadTaxability(): void {
    if (this.getSelectedTaxCodesOptions().length > this.taxcodeLimit) {
      return;
    }
    this.$store.commit("startLoading");
    this.$api
      .post("/api/taxability/", {
        taxcodes: this.selectedTaxCodesParsed,
        state_codes: [],
        country_codes: this.getSelectedRegions(),
      })
      .then((response) => {
        this.taxabilities = response.data;
        this.taxabilities.length === 0
          ? this.$store.commit("stopLoading")
          : this.updateCodeToJurisdictionTaxabilityMap();
        this.captureTaxabilitySearch(
          this.selectedTaxCodesParsed,
          this.getSelectedRegions(),
          this.selectedJurisdictionsParsed
        );
        this.$store.commit("stopLoading");
      })
      .catch((error) => this.$store.commit("stopLoading"));
  }

  public captureTaxabilitySearch(
    taxcodes: string[],
    country: string[],
    states: string[]
  ) {
    const allJurisSelected = this.allJurisdictionsData.every(
      (jurisdiction) => jurisdiction.selected
    );
    const analyticsJurisData = [
      ...(allJurisSelected ? ["all-jurisdictions"] : []),
      ...states,
    ];
    const props = {
      taxibilityMatrix: {
        taxibilityMatrixInfo: {
          taxCodes: taxcodes.join(","),
          selectedCountry: country,
          selectedStates: analyticsJurisData.join("|"),
        },
      },
    };
    analyticsLayerUtil.addToDataLayer(props);
    if (this.$store.getters.analyticsEnabled) {
      window._satellite.track("taxability_build");
    }
  }

  public updateCodeToJurisdictionTaxabilityMap(): void {
    this.taxabilities.forEach((taxability: TaxabilityRules) => {
      const taxCode: string = taxability.taxcode;
      const jurisdictionsToTaxability: { [key: string]: TaxabilityRule } = {};
      taxability.rules.forEach((rule: TaxabilityRule) => {
        const jurisdiction: string = rule.jurisdiction_name;
        jurisdictionsToTaxability[jurisdiction] = rule;
      });
      Vue.set(
        this.codeToJurisdictionTaxability,
        taxCode,
        jurisdictionsToTaxability
      );
    });
  }
  public searchJurisdictions(e: any): void {
    return e.detail.inputValue;
  }

  public bulkSelectTaxcodes(searchQuery: string) {
    const taxcodes =
      searchQuery.indexOf(" ") > 0
        ? searchQuery.trim().split(/\s+/)
        : searchQuery.trim().split(",");

    const uniqTaxcodes = _.uniq(taxcodes);
    this.$store.commit("startLoading");
    this.$api
      .post("/api/taxcodes/", uniqTaxcodes)
      .then((response) => {
        const bulkTaxCodes: TaxCode[] = _.uniqBy(
          response.data,
          (taxcode) => taxcode.code
        );
        const isInputValid = uniqTaxcodes.length === bulkTaxCodes.length;
        if (!isInputValid) {
          this.isSearchInputError = true;
          this.$store.commit("stopLoading");
          return;
        }
        this.isSearchInputError = false;
        const bulkTaxCodesData: SelectOption[] = bulkTaxCodes.map(
          (value: TaxCode) => ({
            label: `${value.code} - ${value.description.replace(
              /\s{2,}/g,
              " "
            )}`,
            value: value.code,
            selected: true,
          })
        );
        const allTaxCodesDataDeduped = this.allTaxCodesData.filter(
          (data) =>
            bulkTaxCodes
              .map((bulkTaxCode) => bulkTaxCode.code)
              .indexOf(data.value) < 0
        );
        this.allTaxCodesData = [...allTaxCodesDataDeduped, ...bulkTaxCodesData];
        this.selectBoxKey = this.selectBoxKey + 1;
        this.$store.commit("stopLoading");
      })
      .catch((error) => this.$store.commit("stopLoading"));
  }

  public searchTaxCodes(e: any): void {
    this.isSearchInputError = false;
    const searchQuery: string = e.detail.inputValue.trim();
    const pattern = /^([a-zA-Z0-9 ,]*)$/;
    if (searchQuery === "" || !pattern.test(searchQuery)) return;
    if (searchQuery.indexOf(",") >= 0 || searchQuery.indexOf(" ") >= 0) {
      this.bulkSelectTaxcodes(searchQuery);
    } else {
      this.$api
        .get(`/api/taxcode_autosuggest/?searchterm=${searchQuery}`)
        .then((response) => {
          const selectedTaxCodesData: SelectOption[] =
            this.allTaxCodesData.filter((taxCodeData) => {
              return taxCodeData.selected;
            });
          const searchedTaxCodes: TaxCodeSearchResponse[] = response.data
            .results
            ? response.data.results
            : [];

          const searchedTaxCodesFiltered: TaxCodeSearchResponse[] =
            searchedTaxCodes.filter(
              (taxCode) =>
                selectedTaxCodesData
                  .map((taxCodeData) => taxCodeData.value)
                  .indexOf(taxCode.code) < 0
            );
          const searchedTaxCodesData: SelectOption[] =
            searchedTaxCodesFiltered.map((searchResponse) => ({
              label: `${
                searchResponse.code
              } - ${searchResponse.short_desc.replace(/\s{2,}/g, " ")}`,
              value: searchResponse.code,
              selected: undefined,
            }));
          this.allTaxCodesData = [
            ...selectedTaxCodesData,
            ...searchedTaxCodesData,
          ];
        })
        .catch((error) => this.$store.commit("stopLoading"));
    }
  }

  public onRegionDeselected(e: any) {
    const selectedOption = e.detail.item.value;
    this.allRegionsDataToBeUpdated = this.allRegionsDataToBeUpdated.map(
      (regionData) =>
        regionData.value === selectedOption
          ? { ...regionData, selected: undefined }
          : regionData
    );

    if (selectedOption === Region.CA || selectedOption === Region.US) {
      const jurisdictionsToPopulate: string[] =
        this.getSelectedRegionsToBeUpdated()
          .filter(
            (countryCode) =>
              countryCode === Region.US || countryCode === Region.CA
          )
          .map((countryCode) =>
            this.$store.getters.countryJurisdictions(countryCode)
          )
          .flat();

      this.allJurisdictionsData = jurisdictionsToPopulate.map(
        (jurisdiction: string) => {
          const jurisdictionData: SelectOption = {
            label: jurisdiction,
            value: jurisdiction,
            selected:
              this.getSelectedJurisdictionsOptions().indexOf(jurisdiction) > -1,
          };
          return jurisdictionData;
        }
      );
      this.selectBoxKey = this.selectBoxKey + 1;
    }
  }

  public onRegionSelected(e: any) {
    const selectedOption = e.detail.item.value;
    this.allRegionsDataToBeUpdated = this.allRegionsDataToBeUpdated.map(
      (regionData) =>
        regionData.value === selectedOption
          ? { ...regionData, selected: true }
          : regionData
    );

    const jurisdictionsToPopulate: string[] =
      this.getSelectedRegionsToBeUpdated()
        .filter(
          (countryCode) =>
            countryCode === Region.US || countryCode === Region.CA
        )
        .map((countryCode) =>
          this.$store.getters.countryJurisdictions(countryCode)
        )
        .flat();

    this.allJurisdictionsData = jurisdictionsToPopulate.map(
      (jurisdiction: string) => {
        const jurisdictionData: SelectOption = {
          label: jurisdiction,
          value: jurisdiction,
          selected:
            this.getSelectedJurisdictionsOptions().indexOf(jurisdiction) > -1,
        };
        return jurisdictionData;
      }
    );
  }

  public onTaxCodeSelected(e: any): void {
    const selectedOption = e.detail.item.value;
    this.allTaxCodesData = this.allTaxCodesData.map((taxCodeData) =>
      taxCodeData.value === selectedOption
        ? { ...taxCodeData, selected: true }
        : taxCodeData
    );
  }
  public onTaxCodeDeselected(e: any): void {
    this.allTaxCodesData = this.allTaxCodesData.map((taxCodeData) => {
      return taxCodeData.value === e.detail.item.value
        ? { ...taxCodeData, selected: undefined }
        : taxCodeData;
    });
    this.selectBoxKey = this.selectBoxKey + 1;
  }
  public onJurisdictionSelected(e: any): void {
    this.allJurisdictionsData = this.allJurisdictionsData.map(
      (jurisdictionData) => {
        return jurisdictionData.value == e.detail.item.value
          ? { ...jurisdictionData, selected: true }
          : jurisdictionData;
      }
    );
  }
  public onJurisdictionDeselected(e: any): void {
    this.allJurisdictionsData = this.allJurisdictionsData.map(
      (jurisdictionData) => {
        return jurisdictionData.value == e.detail.item.value
          ? { ...jurisdictionData, selected: undefined }
          : jurisdictionData;
      }
    );
    this.selectBoxKey = this.selectBoxKey + 1;
  }
  public onSelectAllJurisdictions() {
    const allJurisdictions = this.allJurisdictionsData.map(
      (jurisdictionData) => jurisdictionData.value
    );
    this.allJurisdictionsData = this.allJurisdictionsData.map(
      (jurisdictionData) => {
        return { ...jurisdictionData, selected: true };
      }
    );
    this.selectBoxKey = this.selectBoxKey + 1;
  }
  public onUnselectAllJurisdictions() {
    this.allJurisdictionsData = this.allJurisdictionsData.map(
      (jurisdictionData) => {
        return { ...jurisdictionData, selected: undefined };
      }
    );
    //a hack to re-render and clear the selectbox component to handle a bug in selectbox
    this.selectBoxKey = this.selectBoxKey + 1;
  }
  public onTaxabilitySearch() {
    this.selectedTaxCodesParsed = this.getSelectedTaxCodesOptions();
    this.selectedJurisdictionsParsed = this.getSelectedJurisdictionsOptions();
    this.allRegionsData = this.allRegionsDataToBeUpdated;
    this.updateRoute();
    this.loadTaxability();
  }

  public updateRoute(): void {
    const taxcodes: string = this.selectedTaxCodesParsed.sort().join(",");
    const jurisdictions: string = this.selectedJurisdictionsParsed.join(",");
    const regions = this.getSelectedRegions().sort().join(",");
    router.push({
      name: "taxability_search",
      query: {
        taxcodes,
        jurisdictions,
        region: regions,
      },
    });
  }
  public getSelectedRegions(): string[] {
    return this.allRegionsData
      .filter((region) => region.selected)
      .map((region) => region.value);
  }
  public getSelectedRegionsToBeUpdated(): string[] {
    return this.allRegionsDataToBeUpdated
      .filter((region) => region.selected)
      .map((region) => region.value);
  }
  public getSelectedTaxCodesOptions() {
    const filteredTaxCodes = this.allTaxCodesData
      .filter((taxCodesData) => taxCodesData.selected)
      .map((taxCodesData) => taxCodesData.value);
    return filteredTaxCodes;
  }
  public getSelectedJurisdictionsOptions() {
    const filteredJurisdictions = this.allJurisdictionsData
      .filter((jurisdictionData) => jurisdictionData.selected)
      .map((jurisdictionData) => jurisdictionData.value);
    return filteredJurisdictions;
  }
  public getStatesSelectBoxLabel() {
    if (this.getSelectedRegionsToBeUpdated().includes("CA") && this.getSelectedRegionsToBeUpdated().includes("US")) return "Select states or provinces";
    else if (this.getSelectedRegionsToBeUpdated().includes("CA")) return "Select provinces"
    else return "Select states";
  }
}
</script>
<style scoped lang="scss">
.heading-decsription {
  * {
    line-height: 1.4rem;
  }
  p {
    font-size: 0.88rem;
    font-weight: 400;
    color: var(--color-gray-dark);
  }
  h3 {
    font-weight: bold;
    font-size: 1.7rem;
    color: var(--color-black);
    text-align: left;
  }
}

.search-row {
  h3 {
    float: right;
  }
  button {
    float: right;
  }
  button.ghost {
    color: var(--color-blue);
  }
}

s-col {
  padding-bottom: 0.6rem;
}
hr {
  border-width: 0.15rem 0 0;
}
.limit-error {
  color: var(--color-red-dark);
  font-weight: 600;
}
.label-info {
  s-icon {
    color: var(--color-blue-dark);
  }
}
.tooltip-wrap {
  display: initial;
  line-height: normal;
}
.tooltip-wrap .tooltip-content {
  display: none;
  position: fixed;
  background-color: var(--color-gray-lightest);
  z-index: 1;
  max-width: 300px;
}
.tooltip-wrap:hover .tooltip-content {
  display: initial;
}
.back-button {
  a:visited {
    color: var(--color-blue-dark);
  }
  span {
    line-height: 2;
  }
}
</style>
