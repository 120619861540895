<template>
  <div class="margin-top-sm fs-exclude">
    <a
      href="https://help.avalara.com/z_AvaWeb/Customer_Learning/UKL_Internal/Jaime's_Sandbox/Tax_category_classification"
      class="helper flex align-items-center right"
    >
      <s-icon name="info-circle" class="text-sm margin-right-xs"></s-icon
      ><span class="text-xs-strong">Need help?</span>
    </a>
    <div class="back-button">
      <a v-on:click="backToJob()">
        <s-icon class="align-self-center" name="arrow-left"></s-icon
        ><span>Back to classification details</span>
      </a>
    </div>
    <div v-if="jobDetails.status === JobStatus.InFeedback">
      <div class="flex job-detail-container">
        <h1 class="margin-all-none">
          {{ jobDetails.metadata.jobName }}
        </h1>
        <div
          v-if="gridApi && jobDetails.totalRecords > 0"
          class="margin-left-sm font-semibold"
        >
          {{
            ((totalRecordsCompleted / jobDetails.totalRecords) * 100).toFixed(
              2
            )
          }}% done out of 100
        </div>
        <div v-else class="margin-left-sm font-bold">
          loading progress...
        </div>
      </div>
      <s-row v-show="errorMessage !== ''">
        <s-col>
          <s-alert nodismiss class="margin-top-sm" status="error">
            <div>
              {{ errorMessage }}
            </div>
          </s-alert>
        </s-col>
      </s-row>
      <s-row
        class="job pad-top-sm margin-bottom-xs margin-top-xs margin-left-none margin-right-none"
      >
        <s-col span="sm-12 md-12 lg-12 7">
          <s-row>
            <s-col span="4">
              <div>
                <span class="details-key">Classification Name:</span>
                {{ jobDetails.metadata.jobName }}
              </div>
              <div>
                <span class="details-key">Status:</span> {{ jobDetails.status }}
              </div>
            </s-col>
            <s-col span="4">
              <div>
                <span class="details-key">Total Records:</span>
                {{ jobDetails.totalRecords }}
              </div>
              <div>
                <span class="details-key">Created on:</span>
                {{ formatDate(jobDetails.createdAt) }}
              </div>
            </s-col>
            <s-col span="4">
              <div>
                <span class="details-key">Updated on:</span>
                {{ formatDate(jobDetails.updatedAt) }}
              </div>
            </s-col>
          </s-row>
        </s-col>
        <s-col span="sm-12 md-12 lg-12 5 feedback-actions-col">
          <div class="feedback-actions">
            <button
              @click="finishFeedback"
              :disabled="saving || loading"
              class="primary right margin-right-sm"
            >
              Finish feedback
            </button>
            <button
              :disabled="!hasUnsavedChanges || saving"
              @click="saveDraft"
              class="primary right margin-right-sm"
            >
              Save changes
            </button>
            <router-link
              :to="{
                name: 'taxability_search',
                query: { taxcodes: taxabilityTaxcodes() }
              }"
              class="button primary right"
              target="_blank"
            >
              View Taxability Matrix
            </router-link>
          </div>
        </s-col>
      </s-row>
      <s-row>
        <s-col class="pad-bottom-lg pad-top-sm">
          <button
            @click="openDialog('verify-records')"
            :disabled="gridSelectionRows === 0 || saving"
            class="secondary right"
          >
            Verify
          </button>
          <button
            @click="openDialog('modify-records')"
            :disabled="gridSelectionRows === 0 || saving"
            class="secondary right margin-right-sm"
          >
            Modify
          </button>
          <button
            @click="resetGrid()"
            class="secondary right"
            :disabled="loading"
          >
            Reset View
          </button>
          <button
            @click="loadGridState()"
            class="secondary right"
            :disabled="loading"
          >
            Restore Saved View
          </button>
        </s-col>
      </s-row>
      <div class="spreadsheet-container">
        <ag-grid-vue
          v-if="jobItems.length > 0 && !loading"
          class="ag-theme-alpine spreadsheet"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          rowHeight="80"
          :rowData="rowData"
          rowSelection="multiple"
          animateRows="true"
          @grid-ready="onGridReady"
          @selection-changed="onSelectionChange"
          @filter-changed="onFilterChanged"
          @column-visible="onColumnChange"
          @column-pinned="onColumnChange"
          @column-resized="onColumnChange"
          @column-moved="onColumnChange"
          @sort-changed="onColumnChange"
          :rowClassRules="rowClassRules"
          enableCellTextSelection="true"
          alwaysShowHorizontalScroll="true"
        >
        </ag-grid-vue>
        <div
          v-if="gridApi && jobItems.length > 0 && !loading"
          class="right rowCount font-semibold"
        >
          Showing {{ gridApi.getDisplayedRowCount() }} records out of
          {{ jobItems.length }}
        </div>
        <div
          v-show="jobItems.length === 0 && !loading"
          class="pad-top-xl margin-top-xl"
        >
          <img
            :src="`${$store.getters.assetsPath}/img/placeholder.png`"
            class="block margin-right-auto margin-left-auto margin-top-xl"
          />
          <p
            class="text-center margin-top-xs margin-bottom-xs text-md font-semibold"
          >
            No results found
          </p>
        </div>
      </div>
      <div class="modify-dialog-container">
        <s-dialog
          class="modify-dialog"
          id="modify_dialog"
          aria-labelledby="title"
          aria-modal="true"
          ref="modify-records"
          noscroll=""
        >
          <div slot="header" id="title">Bulk Update</div>
          <div slot="body">
            <div
              v-if="
                gridApi &&
                  gridApi.getSelectedNodes().length === 1 &&
                  recommendedTaxcodes().length > 0
              "
            >
              Select from one of our recommended taxcodes or use the search
              below:
              <s-table-container>
                <table class="row-height-sm">
                  <thead>
                    <tr>
                      <th>Taxcode</th>
                      <th>Description</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="recommendedTaxcode in recommendedTaxcodes()"
                      v-bind:key="recommendedTaxcode.taxcode"
                    >
                      <td>{{ recommendedTaxcode.taxcode }}</td>
                      <td>{{ recommendedTaxcode.description }}</td>
                      <td>
                        <button
                          class="primary"
                          :disabled="
                            recommendedTaxcode.taxcode === selectedTaxcode
                          "
                          @click="
                            onSelectRecommendedTaxcode(
                              recommendedTaxcode.taxcode,
                              recommendedTaxcode.description
                            )
                          "
                        >
                          Select
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </s-table-container>
            </div>
            <div v-else>
              Bulk edit <strong>{{ gridSelectionRows }}</strong> record(s) with
              the below taxcode:
            </div>
            <label for="taxcode" id="taxcode-label">Finalised Tax code</label>
            <s-select
              v-if="gridApi"
              inputid="taxcode"
              id="taxcode-selector"
              async
              :optionsList="
                JSON.stringify(
                  taxcodeData.concat(recommendedModifySelectValues())
                )
              "
              placeholder="Search taxcode by description"
              class="select-box"
              @s-select="onTaxCodeSelect"
              @s-input="onTaxcodeInputChanged"
            >
              <div
                slot="no-matches"
                class="flex flex-dir-col align-items-center"
              >
                <span>No results found.</span>
              </div>
            </s-select>
            <label for="comment">Customer Review Comments</label>
            <textarea
              rows="6"
              cols="60"
              name="comment"
              v-model="customerComment"
            >
            </textarea>
          </div>
          <div slot="footer">
            <router-link
              :disabled="!searchBoxQuery"
              class="margin-right-sm button small secondary"
              target="_blank"
              :to="{
                name: 'search_category',
                query: { q: searchBoxQuery, displayType: 'group' }
              }"
            >
              View decision trees
            </router-link>
            <button
              class="secondary small"
              @click="closeDialog('modify-records')"
            >
              Cancel
            </button>
            <button
              :disabled="
                (!isAtcc() && customerComment.trim() === '') ||
                  selectedTaxcode === ''
              "
              class="primary small"
              @click="modifyRecords()"
            >
              Update
            </button>
          </div>
        </s-dialog>
      </div>
      <s-dialog
        class="verify-dialog"
        id="verify_dialog"
        aria-labelledby="title"
        aria-modal="true"
        ref="verify-records"
        noscroll=""
      >
        <div slot="header" id="title">Bulk Verify</div>
        <div slot="body">
          Do you want to mark {{ gridSelectionRows }} records as verified ?
        </div>
        <div slot="footer">
          <button
            class="secondary small"
            @click="closeDialog('verify-records')"
          >
            No
          </button>
          <button class="primary small" @click="verifyRecords()">Yes</button>
        </div>
      </s-dialog>
      <s-dialog
        class="page-leave-dialog"
        id="page_leave_dialog"
        aria-labelledby="title"
        aria-modal="true"
        ref="unload_dialog"
        noscroll=""
      >
        <div slot="header" id="title">Save Changes</div>
        <div slot="body">
          You have some unsaved changes, do you want to save them ?
        </div>
        <div slot="footer">
          <button class="secondary small" @click="backWithoutSave()">
            No
          </button>
          <button class="primary small" @click="saveBeforeBack()">Yes</button>
        </div>
      </s-dialog>
    </div>
    <div class="loader-text" v-if="dialogLoaderText != ''">
      <img
        v-if="showSetupImage"
        :src="`${$store.getters.assetsPath}/img/taxability_search.png`"
        class="block margin-right-auto margin-left-auto margin-bottom-md"
      />
      {{ dialogLoaderText }}
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Provide, Vue } from "vue-property-decorator";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import duration from "dayjs/plugin/duration";
import errorsUtil from "./../utils/errorMappings";
import _ from "lodash";
import { AgGridVue } from "ag-grid-vue";
import { FeatureLevel } from "@/common/privilege";
import { JobStatus } from "@/common/job";

dayjs.extend(duration);
dayjs.extend(relativeTime);

@Component({
  components: {
    AgGridVue
  }
})
export default class BulkFeedback extends Vue {
  @Provide("$api") $api: any;
  @Provide("$_") $_: any;

  @Prop() private companyId!: string;
  @Prop() private accountId!: string;
  @Prop() private jobId!: string;
  @Prop({ default: () => 1 }) private page!: number;
  private loading = true;
  private paginationTotalRecords = 0;
  private jobDetails: any = {};
  private jobItems: any[] = [];
  private jobColumns: any = [];
  private jobColumnOptions: any = [];
  private errorMessage = "";
  private taxcodeData: any[] = [];
  private selectedTaxcode = "";
  private selectedTaxcodeDescription = "";
  private customerComment = "";
  private dialogLoaderText = "";
  private hasUnsavedChanges = false;
  private showSetupImage = false;
  private columnApi: any = null;
  gridApi: any = null;
  gridSelectionRows = 0;
  JobStatus = JobStatus;
  rowClassRules = {
    modified: params => params.data.customer_review_status === "Modified",
    verified: params => params.data.customer_review_status === "Verified"
  };

  private stepsByRoles = {
    customerOrRepWithSupport: [
      "Upload product catalog",
      "Fill Questionnaire",
      "Review and create"
    ],
    customerOrRepWithoutSupport: [
      "Upload product catalog",
      "Fill Questionnaire",
      "Map columns",
      "Select profile/categories",
      "Review and create"
    ],
    expert: [
      "Upload product catalog",
      "Fill Questionnaire",
      "Upload curated catalog",
      "Map columns",
      "Select profile/categories",
      "Review and create"
    ]
  };
  private saving = false;
  private toBeSavedNodeIds: Set<string> = new Set();
  private gridConfig: any = null;

  async mounted() {
    this.$store.commit("startLoading");
    this.loading = true;
    await this.setupJob();
  }

  taxabilityTaxcodes() {
    if (!this.gridApi) {
      return "";
    }

    const selectedNodesTaxcodes = this.gridApi.getSelectedNodes().map(node => {
      const data = node.data;
      return data.final_tax_code || data.recommended_avatax_code;
    });

    if (selectedNodesTaxcodes.length > 0) {
      return this.topByCount(selectedNodesTaxcodes);
    } else {
      return this.topTaxcodes;
    }
  }

  private topByCount(taxcodes: string[]): string {
    const topTaxCodes = _.chain(taxcodes)
      .countBy()
      .toPairs()
      .orderBy(1, "desc")
      .value()
      .slice(0, 20)
      .map(taxCodeCount => taxCodeCount[0]);

    return topTaxCodes.join(",");
  }

  get topTaxcodes() {
    if (!this.gridApi) {
      return "";
    }

    const taxcodes: any = [];

    this.gridApi.forEachNode(rowNode => {
      taxcodes.push(
        rowNode.data["final_tax_code"] ||
          rowNode.data["recommended_avatax_code"]
      );
    });

    return this.topByCount(taxcodes);
  }

  // Ag grid
  onGridReady(params) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.loadGridState();
  }

  onSelectionChange(event) {
    this.gridSelectionRows = event.api.getSelectedNodes().length;
  }

  onFilterChanged(event) {
    this.hasUnsavedChanges = true;
    event.api.deselectAll();
  }

  onColumnChange(event) {
    this.hasUnsavedChanges = true;
  }

  get columnDefs() {
    const unnecessaryColumns = new Set([
      "code_1",
      "code_2",
      "code_3",
      "description_1",
      "description_2",
      "description_3",
      "confidenceLevel_1",
      "confidenceLevel_3",
      "confidenceLevel_2",
      "url",
      "delivery_method",
      "UPC_lookup_code",
      "UPC_lookup_confidence"
    ]);

    if (
      this.$store.getters.featureLevel("item-classification") ===
      FeatureLevel.AtccFull
    ) {
      unnecessaryColumns.add("reviewer_comments");
    }

    const modifyableColumns = new Set([
      "feedback_comments",
      "final_tax_code",
      "final_tax_code_desc",
      "customer_review_status"
    ]);

    const columnDefs = this.jobColumns
      .filter(column => !unnecessaryColumns.has(column))
      .map(column => {
        const columnDef = { field: column };
        if (!modifyableColumns.has(column)) {
          columnDef["cellClass"] = "user-provided-column";
        }
        return columnDef;
      });

    columnDefs.push({
      field: "select",
      width: 52,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      pinned: "left",
      cellClass: "user-provided-column",
      resizable: false,
      lockVisible: true,
      lockPosition: "left"
    });

    return columnDefs;
  }

  get rowData() {
    return this.jobItems;
  }

  get defaultColDef() {
    return {
      sortable: true,
      filter: true,
      width: 200,
      wrapText: true,
      resizable: true
    };
  }

  resetGrid() {
    this.gridApi.setFilterModel(null);
    this.columnApi.resetColumnState();
  }

  async saveGridState() {
    const gridConfig = {
      filterModel: this.gridApi.getFilterModel(),
      columnState: this.columnApi.getColumnState()
    };

    this.gridConfig = gridConfig;

    await this.$api.post(
      `item-classification-api/v1/jobs/${this.companyId}/${this.jobId}/feedback/grid`,
      gridConfig
    );
  }

  loadGridState() {
    if (this.gridConfig) {
      this.gridApi.setFilterModel(this.gridConfig["filterModel"]);
      this.columnApi.applyColumnState({
        state: this.gridConfig["columnState"],
        applyOrder: true
      });
    } else {
      this.gridConfig = {
        filterModel: this.gridApi.getFilterModel(),
        columnState: this.columnApi.getColumnState()
      };
    }
  }

  get totalRecordsCompleted() {
    let count = 0;
    this.gridApi.forEachNode(node => {
      if (node.data.customer_review_status != "") {
        count += 1;
      }
    });
    return count;
  }

  // Back with unsaved changes
  backToJob() {
    if (this.hasUnsavedChanges) {
      this.openDialog("unload_dialog");
    } else {
      this.$router.push({
        path: `/classification/${this.accountId}/${
          this.companyId
        }/jobs/create?jobId=${this.jobId}&step=${this.jobStep()}`
      });
    }
  }

  backWithoutSave() {
    this.closeDialog("unload_dialog");
    this.$router.push({
      path: `/classification/${this.accountId}/${
        this.companyId
      }/jobs/create?jobId=${this.jobId}&step=${this.jobStep()}`
    });
  }

  async saveBeforeBack() {
    this.closeDialog("unload_dialog");
    await this.saveDraft();
    this.$router.push({
      path: `/classification/${this.accountId}/${
        this.companyId
      }/jobs/create?jobId=${this.jobId}&step=${this.jobStep()}`
    });
  }

  // Job step handling
  getSteps(jobDetails) {
    if (this.$store.state.herculeRole === "ClassificationInternalExpert") {
      return this.stepsByRoles["expert"];
    } else {
      const isFullClassificationAdmin =
        this.$store.state.herculeRole === "ClassificationAdmin" &&
        (this.$store.getters.featureLevel("item-classification") ===
          FeatureLevel.MtccFull ||
          this.$store.getters.featureLevel("item-classification") ===
            FeatureLevel.AtccFull);
      if (isFullClassificationAdmin) {
        return jobDetails.metadata.support &&
          jobDetails.metadata.support == "true"
          ? this.stepsByRoles["customerOrRepWithSupport"]
          : this.stepsByRoles["customerOrRepWithoutSupport"];
      } else {
        return this.stepsByRoles["customerOrRepWithSupport"];
      }
    }
  }

  jobStep() {
    const job = this.jobDetails;
    const categoryStepCompleted = job.categories && job.categories.length > 0;
    const fieldMappingStepCompleted =
      job.metadata.field_mapping_source &&
      job.metadata.field_mapping_source === "user" &&
      Object.keys(job.fieldMapping).length > 0;
    const curatedUploadStepCompleted = "curatedFileName" in job.metadata;
    const questionnaireStepCompleted =
      job.metadata.isQuestionnaireDone == "true";
    const uploadStepCompleted =
      job.metadata.fileName && job.metadata.fileName !== "";
    const steps = this.getSteps(job);
    const conditionByStepName = {
      "Upload product catalog": uploadStepCompleted,
      "Fill Questionnaire": questionnaireStepCompleted,
      "Upload curated catalog": curatedUploadStepCompleted,
      "Map columns": fieldMappingStepCompleted,
      "Select profile/categories": categoryStepCompleted,
      "Review and create": false
    };

    for (let step = 0; step < steps.length; step++) {
      const stepName = steps[step];
      if (!conditionByStepName[stepName]) {
        return step;
      }
    }

    return steps.length;
  }

  // Feedback Setup
  async setupJob() {
    this.$api
      .get(`item-classification-api/v1/jobs/${this.companyId}/${this.jobId}`)
      .then(async response => {
        this.jobDetails = response.data;
        if (this.jobDetails.status != JobStatus.InFeedback) {
          this.$store.commit("stopLoading");
          this.loading = false;
          this.dialogLoaderText =
            "Setting up the feedback for you, please come back after sometime..";
          this.showSetupImage = true;
          setTimeout(async () => {
            await this.setupJob();
          }, 500);
        } else {
          this.dialogLoaderText = "";
          this.showSetupImage = false;
          await this.populateJobsList();
          await this.populateGridConfig();
        }
      })
      .catch(error => {
        this.$store.commit("stopLoading");
        this.loading = false;
        const errorCode = (error as any).response.data.code;
        if (_.has(errorsUtil.errorMessages, errorCode)) {
          this.$store.commit("recordError", "");
          this.errorMessage = errorsUtil.errorMessages[errorCode];
        }
      });
  }

  async populateGridConfig() {
    this.loading = true;
    this.$store.commit("startLoading");

    try {
      const feedbackGridConfigResponse = await this.$api.get(
        `item-classification-api/v1/jobs/${this.companyId}/${this.jobId}/feedback/grid`
      );
      this.gridConfig = feedbackGridConfigResponse["data"]["config"];
    } catch (error) {
      const errorCode = (error as any).response.data.code;
      if (_.has(errorsUtil.errorMessages, errorCode)) {
        this.$store.commit("recordError", "");
        this.errorMessage = errorsUtil.errorMessages[errorCode];
      }
    }

    this.loading = false;
    this.$store.commit("stopLoading");
  }

  async populateJobsList() {
    this.loading = true;
    this.$store.commit("startLoading");

    try {
      const jobItemsResponse = await this.$api.get(
        `item-classification-api/v1/jobs/${this.companyId}/${this.jobId}/feedback`
      );

      this.paginationTotalRecords = jobItemsResponse.data.recordsetCount;
      this.jobItems = jobItemsResponse.data.value;

      this.jobColumns =
        this.jobItems.length > 0
          ? Object.keys(this.jobItems[0])
          : this.jobColumns;

      this.jobColumnOptions = this.jobColumns.map(col => {
        return { label: col, value: col };
      });
    } catch (error) {
      const errorCode = (error as any).response.data.code;
      if (_.has(errorsUtil.errorMessages, errorCode)) {
        this.$store.commit("recordError", "");
        this.errorMessage = errorsUtil.errorMessages[errorCode];
      }
    }

    this.$store.commit("stopLoading");
    this.loading = false;
  }

  // Verify and Modify Dialogues
  verifyRecords() {
    const rowDataToUpdate = this.gridApi.getSelectedNodes().map(node => {
      const data = node.data;
      this.toBeSavedNodeIds.add(node.id);
      data.customer_review_status = "Verified";
      data.final_tax_code = `${data.recommended_avatax_code}`;
      data.final_tax_code_desc = `${data.recommended_avatax_code_desc}`;
      return data;
    });

    this.gridApi.applyTransaction({ update: rowDataToUpdate });
    this.gridApi.deselectAll();
    this.closeDialog("verify-records");
    this.hasUnsavedChanges = true;
  }

  isAtcc() {
    const featureLevel = this.$store.getters.featureLevel(
      "item-classification"
    );
    return (
      featureLevel === FeatureLevel.AtccFull ||
      featureLevel === FeatureLevel.AtccTrial
    );
  }

  modifyRecords() {
    const rowDataToUpdate = this.gridApi.getSelectedNodes().map(node => {
      const data = node.data;
      this.toBeSavedNodeIds.add(node.id);
      data.customer_review_status =
        this.selectedTaxcode == data.recommended_avatax_code
          ? "Verified"
          : "Modified";
      data.final_tax_code = this.selectedTaxcode;
      data.feedback_comments = this.customerComment;
      data.final_tax_code_desc = this.selectedTaxcodeDescription;
      return data;
    });

    this.gridApi.applyTransaction({ update: rowDataToUpdate });
    this.gridApi.deselectAll();
    this.closeDialog("modify-records");
    this.hasUnsavedChanges = true;
  }

  public onTaxCodeSelect(e: any): void {
    this.selectedTaxcode = e.detail.item.value.taxcode;
    this.selectedTaxcodeDescription = e.detail.item.value.description;
  }

  public onSelectRecommendedTaxcode(taxcode, description): void {
    this.selectedTaxcode = taxcode;
    this.selectedTaxcodeDescription = description;
  }

  searchBoxQuery = "";
  async onTaxcodeInputChanged(e: any) {
    this.$store.commit("startLoading");
    const searchQuery: string = e.detail.inputValue.trim();
    this.searchBoxQuery = searchQuery;
    const pattern = /^([a-zA-Z0-9 ,]*)$/;
    if (searchQuery === "" || !pattern.test(searchQuery)) return;
    try {
      const response = await this.$api.get(
        `/api/taxcode_autosuggest/?searchterm=${searchQuery}`
      );
      this.$store.commit("stopLoading");
      this.taxcodeData = response.data.results.map(result => {
        return {
          label: `${result.code} - ${result.short_desc.replace(
            /\s{2,}/g,
            " "
          )}`,
          value: {
            taxcode: result.code,
            description: result.short_desc.replace(/\s{2,}/g)
          },
          selected: undefined
        };
      });
    } catch (error) {
      const errorCode = (error as any).response.data.code;
      if (_.has(errorsUtil.errorMessages, errorCode)) {
        this.$store.commit("recordError", "");
        this.errorMessage = errorsUtil.errorMessages[errorCode];
      }
      this.$store.commit("stopLoading");
      this.loading = false;
    }
  }

  recommendedModifySelectValues() {
    return this.recommendedTaxcodes().map(a => ({
      label: `${a.taxcode} - ${a.description}`,
      value: {
        taxcode: a.taxcode,
        description: a.description
      },
      selected: a.taxcode === this.selectedTaxcode
    }));
  }

  recommendedTaxcodes() {
    const selectedRows = this.gridApi.getSelectedNodes();

    if (selectedRows.length != 1) {
      return [];
    }

    const data = selectedRows[0].data;

    const recommendedTaxcodes = [
      {
        taxcode: data.code_1,
        description: data.description_1
      },
      {
        taxcode: data.code_2,
        description: data.description_2
      },
      {
        taxcode: data.code_3,
        description: data.description_3
      }
    ];

    return recommendedTaxcodes.filter(item => item.taxcode);
  }

  private openDialog(refId) {
    this.selectedTaxcode = "";
    this.customerComment = "";
    this.taxcodeData = [];
    (this.$refs[refId] as any).showModal();
  }

  private closeDialog(refId) {
    (this.$refs[refId] as any).close();
  }

  // feedback actions (finish and save)
  async finishFeedback() {
    try {
      await this.saveDraft();
      this.$store.commit("startLoading");
      this.loading = true;
      await this.$api.post(
        `item-classification-api/v1/jobs/${this.companyId}/${this.jobId}/feedback/publish`
      );
      this.$router.replace({
        path: `/classification/${this.accountId}/${this.companyId}/jobs`
      });
      this.$store.commit("stopLoading");
    } catch (error) {
      const errorCode = (error as any).response.data.code;
      if (_.has(errorsUtil.errorMessages, errorCode)) {
        this.$store.commit("recordError", "");
        this.errorMessage = errorsUtil.errorMessages[errorCode];
      }
      this.$store.commit("stopLoading");
      this.loading = false;
    }
  }

  async saveDraft() {
    try {
      this.saving = true;
      this.$store.commit("startLoading");

      const recordsToUpdate: Array<unknown> = [];

      this.toBeSavedNodeIds.forEach(nodeId => {
        const node = this.gridApi.getRowNode(nodeId);
        recordsToUpdate.push(node.data);
      });

      if (recordsToUpdate.length) {
        await this.$api.put(
          `item-classification-api/v1/jobs/${this.companyId}/${this.jobId}/feedback/save`,
          recordsToUpdate
        );
      }

      await this.saveGridState();

      this.toBeSavedNodeIds = new Set();
      this.$store.commit("stopLoading");
      this.hasUnsavedChanges = false;
      this.saving = false;
    } catch (error) {
      const errorCode = (error as any).response.data.code;
      if (_.has(errorsUtil.errorMessages, errorCode)) {
        this.$store.commit("recordError", "");
        this.errorMessage = errorsUtil.errorMessages[errorCode];
      }
      this.$store.commit("stopLoading");
      this.saving = false;
    }
  }

  private formatDate(date) {
    return dayjs(date).format("YYYY/MM/DD");
  }
}
</script>

<style lang="scss">
@import "~ag-grid-community/styles/ag-grid.css";
@import "~ag-grid-community/styles/ag-theme-alpine.css";

a {
  text-decoration-line: none;
  :visited {
    color: var(--color-blue-dark);
  }
  :hover {
    color: var(--color-blue-dark);
  }
}

.refresh {
  color: var(--color-blue-dark);
  s-icon {
    color: var(--color-blue-dark);
  }
}

.back-button {
  a:visited {
    color: var(--color-blue-dark);
  }
  span {
    line-height: 2;
  }
  position: initial;
}

button.ghost:hover {
  color: var(--color-blue-dark);
}

info {
  color: var(--color-gray-dark);
}

.job {
  border: solid 1px darkgrey;
  s-col {
    padding-bottom: 0.5rem;
  }
}

.job-detail-container {
  align-items: baseline;
}

.feedback-actions-col {
  align-self: center;
}

.feedback-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  .button {
    height: auto;
    min-height: 2.25rem;
  }
  button {
    height: auto;
    min-height: 2.25rem;
  }
}

.details-key {
  min-width: 35%;
  display: inline-block;
  font-weight: 600;
}

.selector {
  top: -12px;
}

.modify-dialog-container {
  .modify-dialog {
    width: 850px;
  }
}

.loader-text {
  top: 50%;
  margin-left: 38%;
  font-weight: 600;
  font-size: large;
  left: 0;
  z-index: 1000;
  position: fixed;
}

.modified {
  background-color: var(--color-yellow-lightest);
}

.verified {
  background-color: var(--color-green-lightest);
}

.ag-theme-alpine {
  .ag-row-hover {
    .user-provided-column {
      background-color: var(--ag-row-hover-color);
    }
  }
  .ag-row-selected {
    .user-provided-column {
      background-color: var(--ag-selected-row-background-color);
    }
  }
  .user-provided-column {
    background-color: var(--color-gray-lightest);
  }
}

.spreadsheet-container {
  height: 60vh;
  display: flex;
  flex-direction: column;

  .rowCount {
    text-align: right;
  }
}

// NOTE: skylab adds relative position globally on all elements which interferes with ag-grid filters
.ag-theme-alpine {
  position: initial !important;
  .ag-cell-value {
    overflow: auto;
    max-height: 4.875rem;
  }

  .ag-checkbox-input {
    position: initial !important;
  }
}

.ag-dnd-ghost {
  position: absolute !important;
}

.spreadsheet {
  height: 100%;
  width: 100%;
}
</style>
