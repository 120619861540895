<template>
  <div class="fs-exclude">
    <s-row>
      <s-col span="3" class="back-button">
        <router-link to="/taxability">
          <s-icon class="align-self-center" name="arrow-left"></s-icon>Go back
        </router-link>
      </s-col>
    </s-row>
    <s-row class="heading-decsription">
      <s-col span="10">
        <h3>
          Generate a taxability matrix
          <CompaniesSwitcher
            v-bind:currentId="companyId"
            v-on:company-switched="companySwitched"
            v-if="this.$store.getters.companies.length > 0"
          />
        </h3>
        <p>
          The generated taxability matrix uses the Avalara tax codes currently
          set up in AvaTax. To see more tax codes or jurisdictions, add them in
          AvaTax.
        </p>
      </s-col>
    </s-row>
    <s-row>
      <s-col span="12">
        <hr class="margin-top-sm margin-bottom-sm" />
      </s-col>
    </s-row>
    <s-row v-if="taxabilities.length === 0" class="margin-bottom-xl">
      <s-col span="4" center>
        <div v-if="!isTableLoading" class="text-center">
          <img
            :src="`${$store.getters.assetsPath}/img/error.png`"
            class="inline-block pad-top-xl"
          />
          <p class="margin-top-none text-md">
            <b>We can't create a taxability matrix</b>
          </p>
          <p class="margin-top-none text-sm">
            There aren't any tax codes or jurisdictions set up in AvaTax. To get
            a taxability matrix, either map your products to tax codes in AvaTax
            or build a taxability matrix from scratch by adding tax codes and
            jurisdictions.
          </p>
          <a
            :href="itemsUrl"
            class="margin-left-auto margin-right-auto items-link"
          >
            <button class="primary icon-leading margin-bottom-sm">
              Map products
            </button>
          </a>
          <p class="margin-top-none margin-bottom-xs text-sm">OR</p>
          <p class="inline-block margin-top-none text-sm">
            <router-link
              :to="{ name: 'taxability_search', path: '/taxability/search' }"
            >
              Build a taxability matrix
            </router-link>
          </p>
        </div>
      </s-col>
    </s-row>
    <s-alert
      status="warning"
      class="margin-bottom-md"
      nodismiss=""
      v-if="
        taxabilities.length > 0 &&
          taxabilitySummary.iteratedItems < taxabilitySummary.totalItems
      "
    >
      <div class="flex text-strong">
        {{ avataxTaxcodeMessage }}
        <a href="/taxability/search" class="margin-left-sm"
          >Build your own Taxability Matrix</a
        >
      </div>
    </s-alert>
    <TaxabilityTable
      class="margin-bottom-xl"
      v-if="taxabilities.length > 0"
      :taxcodes="getTaxCodes()"
      :jurisdictions="getJurisdictions()"
      :region="getRegion()"
      :codeToJurisdictionTaxability="codeToJurisdictionTaxability"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch, Provide } from "vue-property-decorator";
import router from "@/router";
import axios from "axios";

import TaxabilityTable from "@/components/TaxabilityTable.vue";
import CompaniesSwitcher from "../components/CompaniesSwitcher.vue";

interface TaxabilityRule {
  taxability: string;
  jurisdiction_name: string;
  jurisdiction_code: string;
  country_code: string;
  jurisdiction_type: string;
  additional_info: string;
}

interface TaxabilityRules {
  taxcode: string;
  rules: TaxabilityRule[];
}

enum Region {
  US = "US",
  CA = "CA",
}

@Component({
  components: { TaxabilityTable, CompaniesSwitcher },
  beforeRouteEnter(to: any, from: any, next: any) {
    next((vm: any) => {
      if (from.name === "taxability_search") {
        vm.$store.state.backRoute = from;
      }
    });
  },
})
export default class TaxabilityAvatax extends Vue {
  @Provide("$api") $api: any;
  @Provide("$_") $_: any;
  @Prop({ default: "" }) private companyId!: string;

  private isTableLoading = false;
  private itemsUrl = "";

  Region = Region;

  taxabilities: TaxabilityRules[] = [];
  taxabilitySummary: {
    totalItems: number;
    iteratedItems: number;
    iteratedTaxCodes: number;
  } = { totalItems: 0, iteratedItems: 0, iteratedTaxCodes: 0 };
  codeToJurisdictionTaxability: {
    [taxCode: string]: { [jurisdiction: string]: TaxabilityRule };
  } = {};

  mounted() {
    this.loadItemsUrl();
    this.loadTaxability();
  }

  @Watch("companyId")
  public loadItemsUrl(): void {
    if ((window as any).itemsUrl) {
      this.itemsUrl = (window as any).itemsUrl
        .replace("{accountId}", this.$store.getters.accountID)
        .replace("{companyId}", this.companyId);
    }
  }

  get avataxTaxcodeMessage() {
    return `Below Taxability Matrix is generated only for the ${this.taxabilitySummary.iteratedTaxCodes} Avalara tax codes present in the first ${this.taxabilitySummary.iteratedItems} items out of total ${this.taxabilitySummary.totalItems} items in your AvaTax account. To see the taxability for other tax codes, use`;
  }

  @Watch("companyId")
  public loadTaxability(): void {
    this.$store.commit("startLoading");
    this.isTableLoading = true;
    this.$api
      .get(`/api/taxability/${this.companyId}/`, { validateStatus: false })
      .then((response) => {
        if (response.status === 200) {
          this.taxabilities = response.data.taxability;
          this.taxabilitySummary = response.data.taxCodesSummary;
          this.taxabilities.length === 0
            ? this.$store.commit("stopLoading")
            : this.updateCodeToJurisdictionTaxabilityMap();
        } else {
          this.taxabilities = [];
          this.$store.commit("stopLoading");
        }
        this.isTableLoading = false;
      })
      .catch((error) => this.$store.commit("stopLoading"));
  }

  public updateCodeToJurisdictionTaxabilityMap(): void {
    this.taxabilities.forEach((taxability: TaxabilityRules) => {
      const taxCode: string = taxability.taxcode;
      const jurisdictionsToTaxability: { [key: string]: TaxabilityRule } = {};
      taxability.rules.forEach((rule: TaxabilityRule) => {
        const jurisdiction: string = rule.jurisdiction_name;
        jurisdictionsToTaxability[jurisdiction] = rule;
      });
      this.codeToJurisdictionTaxability[taxCode] = jurisdictionsToTaxability;
    });
  }

  public getRegion() {
    if (this.taxabilities.length > 0 && this.taxabilities[0].rules.length > 0)
      return this.taxabilities[0].rules[0].country_code;
    return Region.US;
  }

  public getTaxCodes() {
    if (Object.keys(this.codeToJurisdictionTaxability).length > 0)
      return Object.keys(this.codeToJurisdictionTaxability);
    return [];
  }

  public getJurisdictions() {
    const allJurisdictions: string[] = this.taxabilities.flatMap(
      (taxability: TaxabilityRules) => {
        return taxability.rules.map((rule) => rule.jurisdiction_name);
      }
    );
    const allDistinctJurisdictions: string[] = Array.from(
      new Set(allJurisdictions)
    );
    return allDistinctJurisdictions;
  }

  companySwitched(event) {
    const params = Object.assign({}, this.$route.params, {
      companyId: event.companyId,
    });

    this.$router.push({ name: "taxability_avatax", params: params });
  }

  get back() {
    return this.$store.state.backRoute || {};
  }
}
</script>

<style scoped lang="scss">
.heading-decsription {
  * {
    line-height: 1.4rem;
  }
  p {
    font-size: 0.88rem;
    font-weight: 400;
    color: var(--color-gray-dark);
  }
  h3 {
    font-weight: bold;
    font-size: 1.7rem;
    color: var(--color-black);
    text-align: left;
  }
}

.items-link {
  display: block !important;
}

s-col {
  padding-bottom: 0.1rem;
}
hr {
  border-width: 0.15rem 0 0;
}

.text-strong {
  color: black;
  font-weight: 400;
}
</style>
