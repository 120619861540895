var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('s-row',[_c('s-col',{attrs:{"span":"1"}},[_c('div',[_c('p',{staticClass:"result-text"},[_vm._v("Results")])])]),_c('s-col',{attrs:{"span":"9"}},[_c('div',{staticClass:"padding-top"},[_c('span',{staticClass:"taxability taxability-exempt border-dark"}),_c('span',{staticClass:"text-center"},[_vm._v("Exempt")]),_c('span',{staticClass:"taxability taxability-special border-dark"}),_vm._v("Special tax rates "),_c('span',{staticClass:"taxability taxability-unsupported border-dark"}),_vm._v("Unsupported ")])]),_c('s-col',{attrs:{"span":"2"}},[_c('h3',[_c('a',{staticClass:"right"},[_c('button',{staticClass:"ghost icon-leading pad-top-xs text-xs",attrs:{"title":"download matrix","link":""},on:{"click":_vm.onDownloadTaxability}},[_c('s-icon',{attrs:{"name":"download"}}),_vm._v(" Download this matrix ")],1)])])]),_c('s-col',{attrs:{"span":"12"}},[_c('div',{staticClass:"table-container"},[_c('table',[_c('thead',[_c('tr',[(_vm.regions.includes(_vm.Region.US) || _vm.regions.includes(_vm.Region.CA))?_c('th',{staticClass:"jurisdiction-header"},[_vm._v("JURISDICTIONS")]):_vm._e(),(!_vm.regions.includes(_vm.Region.US) && !_vm.regions.includes(_vm.Region.CA))?_c('th',{staticClass:"jurisdiction-header"},[_vm._v("COUNTRY")]):_vm._e(),_vm._l((_vm.taxcodes),function(taxCode,index){return _c('th',{key:index},[_c('div',[_vm._v(_vm._s(taxCode))]),(
                  _vm.taxcodeDetails[taxCode] &&
                    _vm.taxcodeDetails[taxCode].effectiveFrom &&
                    _vm.isNewTaxcode(_vm.taxcodeDetails[taxCode].effectiveFrom)
                )?_c('div',{staticClass:"new-taxcode text-xs font-italic font-bold"},[_vm._v(" "+_vm._s(_vm.newTaxcodeText(_vm.taxcodeDetails[taxCode].effectiveFrom))+" ")]):_vm._e(),(
                  !_vm.showFullDescription &&
                    _vm.taxcodeDetails[taxCode].description &&
                    _vm.taxcodeDetails[taxCode].description.length >
                      _vm.descriptionLengthLimit
                )?_c('p',{staticClass:"taxcode-desc margin-top-none"},[_vm._v(" "+_vm._s(_vm.getShortTaxcodeDescription(taxCode))+" "),_c('a',{on:{"click":function($event){return _vm.seeMore(taxCode)}}},[_vm._v("...See more")])]):_c('p',{staticClass:"taxcode-desc margin-top-none"},[_vm._v(" "+_vm._s(_vm.taxcodeDetails[taxCode].description || "")+" ")])])})],2)]),_c('tbody',[_vm._l((_vm.getCountryNames()),function(countryName){return _c('tr',{key:countryName},[_c('td',{staticClass:"jurisdiction-cell"},[_c('s-box',[_vm._v(_vm._s(countryName))])],1),_vm._l((_vm.taxcodes),function(taxCode,index){return _c('td',{key:index},[_c('s-box',{class:['big-cell', _vm.getTaxabilityClass(taxCode, countryName)]},[_c('div',[_c('span',{},[_vm._v(" "+_vm._s(_vm.getTaxabilityByCodeAndJurisdiction(taxCode, [countryName]).taxability)+" ")]),_c('span',{staticClass:"special-tax"},[_vm._v(" "+_vm._s(_vm.getTaxabilityByCodeAndJurisdiction( taxCode, [countryName] ).specialTax))])]),_c('div',{staticClass:"additional-info"},[_c('div',[_vm._v(" "+_vm._s(_vm.getTaxabilityByCodeAndJurisdiction(taxCode, [countryName]) .attributes.rate_type)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.getTaxabilityByCodeAndJurisdiction(taxCode, [countryName]) .additional_info)+" ")])])])],1)})],2)}),_vm._l((_vm.jurisdictions),function(jurisdiction,index){return _c('tr',{key:index},[_c('td',{staticClass:"jurisdiction-cell"},[_c('s-box',[_vm._v(_vm._s(_vm.getCountryNameWithPrefix(jurisdiction)))])],1),_vm._l((_vm.taxcodes),function(taxCode,index){return _c('td',{key:index},[(
                  _vm.getTaxabilityByCodeAndJurisdiction(taxCode, jurisdiction)
                    .country_code === _vm.Region.CA
                )?_c('s-box',{class:['small-cell', _vm.getTaxabilityClass(taxCode, 'Canada')]},[_c('span',{staticClass:"tax-type"},[_vm._v("Country Tax: ")]),_vm._v(_vm._s(_vm.getTaxabilityByCodeAndJurisdiction(taxCode, "Canada") .taxability)+" ")]):_vm._e(),(
                  _vm.getTaxabilityByCodeAndJurisdiction(taxCode, jurisdiction)
                    .country_code === _vm.Region.CA
                )?_c('s-box',{class:[
                  'small-cell',
                  _vm.getTaxabilityClass(taxCode, jurisdiction),
                ]},[_c('div',[_c('span',{staticClass:"tax-type"},[_vm._v("State Tax: ")]),_vm._v(" "+_vm._s(_vm.getTaxabilityByCodeAndJurisdiction(taxCode, jurisdiction) .taxability)+" "),_c('div',{staticClass:"additional-info"},[_vm._v(" "+_vm._s(_vm.getTaxabilityByCodeAndJurisdiction(taxCode, jurisdiction) .additional_info)+" ")])])]):(_vm.regions.includes(_vm.Region.US))?_c('s-box',{class:[
                  'big-cell',
                  _vm.getTaxabilityClass(taxCode, jurisdiction),
                ]},[_c('div',[_c('span',[_vm._v(_vm._s(_vm.getTaxabilityByCodeAndJurisdiction(taxCode, jurisdiction) .taxability))]),_c('span',{staticClass:"special-tax"},[_vm._v(" "+_vm._s(_vm.getTaxabilityByCodeAndJurisdiction( taxCode, jurisdiction ).specialTax))])]),_c('div',{staticClass:"additional-info"},[_vm._v(" "+_vm._s(_vm.getTaxabilityByCodeAndJurisdiction(taxCode, jurisdiction) .additional_info)+" ")])]):_vm._e()],1)})],2)})],2)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }