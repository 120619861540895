<template>
  <div>
    <h1 ref="uploadHeader" class="margin-bottom-none">
      Upload your product catalog
    </h1>
    <s-row v-show="errorMessageTop !== ''">
      <s-col>
        <s-alert nodismiss class="margin-top-sm" status="error">
          <div>
            {{ errorMessageTop }}
          </div>
        </s-alert>
      </s-col>
    </s-row>
    <s-row>
      <s-col span="10" class="intro pad-bottom-md">
        Upload a spreadsheet including a list of your products and services that
        includes a description and unique item code. If available, also include
        catalog numbers such as SKU, UPC, EAN, or ASIN. Your spreadsheet must
        have fewer than 30,000 rows.
      </s-col>
      <s-col>
        <s-alert status="info">
          <div>
            Tip: For the best recommendations, upload a spreadsheet with similar
            categories at the same time, for example all clothing or all food.
          </div>
        </s-alert></s-col
      >
      <s-col span="3" class="pad-bottom-lg">
        <label for="job-name" class="required">Name</label>
        <input
          type="text"
          id="job-name"
          :disabled="
            metadata.isQuestionnaireDone == 'true' && metadata.support == 'true'
          "
          v-model="jobName"
        />
        <label for="tags">Tags</label>
        <div class="intro text-xs">You can add upto 5 tags</div>
        <CustomSelectBox
          name="You can add upto 5 tags"
          :key="tagsSelectKey"
          :data="allTags"
          :onSelect="onTagSelected"
          :onDeselect="onTagDeselected"
          :onInputChanged="onTagInput"
          :isInputDisabled="
            $store.state.loading ||
              (metadata.isQuestionnaireDone == 'true' &&
                metadata.support == 'true')
          "
          :isError="findSelectedTags().length > tagsLimit"
          :isSearcheable="true"
        />
        <div v-if="findSelectedTags().length > tagsLimit" class="limit-error">
          Add up to {{ tagsLimit }} tags only.
        </div>
        <div
          class="margin-top-sm"
          v-if="
            $store.state.herculeRole == 'ClassificationInternalExpert' ||
              ($store.state.herculeRole == 'ClassificationAdmin' &&
                $store.getters.featureLevel('item-classification') ===
                  FeatureLevel.MtccFull)
          "
        >
          <input
            type="checkbox"
            id="ask-support"
            name="ask-support"
            :checked="requireSupportOrNotif"
            v-model="requireSupportOrNotif"
            :disabled="metadata.isQuestionnaireDone == 'true'"
          />
          <label for="ask-support">{{
            $store.state.herculeRole == "ClassificationInternalExpert"
              ? "Send email notification"
              : "Need support from classification expert"
          }}</label>
          <div class="tooltip-wrap info-icon">
            <s-icon
              class="margin-left-sm"
              name="help-circle-filled"
              role="img"
              aria-label="account alert"
            ></s-icon>
            <div class="tooltip-content pad-all-sm">
              {{
                $store.state.herculeRole == "ClassificationInternalExpert"
                  ? "The classification expert will perform the subsequent steps like field mapping, category selection for your job."
                  : "Send email to our experts for better classifications"
              }}
            </div>
          </div>
        </div>
        <div
          v-if="
            $store.state.herculeRole == 'ClassificationInternalExpert' ||
              ($store.state.herculeRole == 'ClassificationAdmin' &&
                ($store.getters.featureLevel('item-classification') === FeatureLevel.MtccFull || $store.getters.featureLevel('item-classification') === FeatureLevel.AtccFull))
          "
        >
          <input
            type="checkbox"
            id="use-schema"
            name="use-schema"
            :checked="useDefaultSchema"
            v-model="useDefaultSchema"
            :disabled="!permitUseSchemaAndAuto()"
          />
          <label for="use-schema">Use default schema</label>
          <div class="tooltip-wrap info-icon">
            <s-icon
              class="margin-left-sm"
              name="help-circle-filled"
              role="img"
              aria-label="account alert"
            ></s-icon>
            <div class="tooltip-content pad-all-sm">
              Default schema will be used to map fields in the uploaded file
            </div>
          </div>
        </div>
        <div
          v-if="
            $store.state.herculeRole == 'ClassificationInternalExpert' ||
              ($store.state.herculeRole == 'ClassificationAdmin' &&
                ($store.getters.featureLevel('item-classification') === FeatureLevel.MtccFull || $store.getters.featureLevel('item-classification') === FeatureLevel.AtccFull) &&
                !useDefaultSchema)
          "
        >
          <input
            type="checkbox"
            id="autogenerate"
            name="autogenerate"
            :checked="isAutogenerateFieldEnabled"
            v-model="isAutogenerateFieldEnabled"
            :disabled="!permitUseSchemaAndAuto()"
          />
          <label for="autogenerate">Autogenerate Item code</label>
          <div v-if="isAutogenerateFieldEnabled" class="margin-top-sm">
            <label for="auto-field-name">Field name</label>
            <input
              type="text"
              id="auto-field-name"
              :disabled="!permitUseSchemaAndAuto()"
              v-model="autogenerateField"
            />
          </div>
        </div>
      </s-col>
      <s-col span="12" v-show="errorMessages.length > 0" class="pad-bottom-md">
        <s-alert nodismiss status="error">
          <div>
            <div class="text-md">
              We can't upload your file! Check out the following errors.
            </div>
            <ul class="margin-left-md margin-top-xs">
              <li v-for="message in errorMessages" :key="message">
                {{ message }}
              </li>
            </ul>
          </div>
        </s-alert>
      </s-col>
    </s-row>
    <s-row>
      <s-col v-if="file === null && (!fileName || fileName === '')">
        <s-uploader v-on:s-attach="fileChosen" accept=".csv,.tsv,.psv,.txt">
          <div slot="guidelines">
            <div>File types accepted with schema: .csv .tsv .psv or .txt</div>
            <div>File types accepted without schema: .csv .tsv or .psv</div>
            <div>Max file size: 30,000 rows</div>
          </div>
        </s-uploader>
      </s-col>
      <s-col v-else span="4">
        <div class="selected-file">
          <hr class="margin-bottom-none" />
          <s-icon
            name="file-spreadsheet"
            role="img"
            aria-label="file-spreadsheet"
          ></s-icon>
          <span class="pad-left-xs">{{ fileName }}</span>
          <s-icon
            name="close"
            role="img"
            aria-label="close"
            class="right pad-right-xs"
            v-on:click="removeUploadedFile"
          ></s-icon>
          <hr class="margin-top-none" />
          <div class="file-success">
            <s-icon
              name="check"
              role="img"
              aria-label="check"
              class="pad-right-xs"
            ></s-icon>
            <span>File uploaded successfully</span>
          </div>
        </div>
      </s-col>
    </s-row>
    <s-row
      ><s-col>
        <button
          @click="nextStep"
          class="primary icon-trailing"
          :disabled="isSaveDisabled()"
        >
          Save and continue
          <s-icon name="arrow-right"></s-icon>
        </button>
        <button @click="cancel" class="ghost">Cancel</button>
      </s-col></s-row
    >
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Provide, Watch } from "vue-property-decorator";
import CustomSelectBox from "@/components/CustomSelectBox.vue";
import errorsUtil from "./../utils/errorMappings";
import _ from "lodash";
import { JobStatus } from "@/common/job";
import { FeatureLevel } from "@/common/privilege";

interface SelectOption {
  label: string;
  value: string;
  selected?: boolean;
}

@Component({
  components: { CustomSelectBox },
})
export default class BulkUpload extends Vue {
  @Provide("$api") $api: any;

  @Prop() private accountId!: string;
  @Prop() private companyId!: string;
  @Prop() private jobId!: string;

  private currentJobId = this.jobId;
  private file: any = null;
  private fileName = "";

  private jobName = "";
  private metadata: any = {};
  private requireSupportOrNotif = false;
  private jobStatus = "";

  private allTags: SelectOption[] = [];
  private tagsLimit = 5;
  private tagsSelectKey = 0;

  private errorMessages: string[] = [];
  private errorMessageTop = "";
  private useDefaultSchema =
    this.$store.state.herculeRole == "ClassificationInternalExpert" ||
    (this.$store.state.herculeRole == "ClassificationAdmin" &&
      this.$store.getters.featureLevel("item-classification") ===
        FeatureLevel.MtccFull);
  private isAutogenerateFieldEnabled = false;
  private autogenerateField = "";
  FeatureLevel = FeatureLevel;

  mounted() {
    if (this.currentJobId) this.getJobDetails();
  }

  permitUseSchemaAndAuto() {
    const uploadStepCompleted = !!this.currentJobId;
    return (
      !uploadStepCompleted ||
      (!(this.metadata.isQuestionnaireDone == "true") &&
        (this.fileName == "" || this.file)) ||
      (this.metadata.isQuestionnaireDone == "true" &&
        (this.fileName == "" || this.file) &&
        this.$store.getters.featureLevel("item-classification") ===
          FeatureLevel.MtccFull &&
        this.$store.state.herculeRole === "ClassificationAdmin" &&
        !this.requireSupportOrNotif)
    );
  }

  @Watch("errorMessages")
  @Watch("errorMessageTop")
  focusErrorAlert() {
    if (this.errorMessages.length !== 0 || this.errorMessageTop !== "") {
      (this.$refs.uploadHeader as any).scrollIntoView();
    }
  }

  private displayError(error) {
    const errorCode = error.response.data.code;
    if (
      error.response &&
      error.response.data &&
      error.response.data.details &&
      error.response.data.details.length > 0
    ) {
      this.$store.commit("recordError", "");
      this.errorMessages = error.response.data.details.map((e) => {
        if (_.has(errorsUtil.specialErrors, e.code))
          return errorsUtil.getErrorMessage(e);
        else return e.message;
      });
    } else if (_.has(errorsUtil.errorMessages, errorCode)) {
      this.$store.commit("recordError", "");
      this.errorMessageTop = errorsUtil.getErrorMessage(error.response.data);
    }
  }

  getJobDetails() {
    this.$store.commit("startLoading");
    this.$api
      .get(
        `item-classification-api/v1/jobs/${this.companyId}/${this.currentJobId}`
      )
      .then((response) => {
        const jobDetails = response.data;
        this.jobName = jobDetails.metadata.jobName;
        const selectedTags = jobDetails.tags || [];
        this.metadata = jobDetails.metadata;
        this.allTags = selectedTags.map((t) => {
          return {
            label: t,
            value: t,
            selected: true,
          };
        });
        this.jobStatus = jobDetails.status;
        this.fileName = jobDetails.metadata.fileName;
        this.requireSupportOrNotif =
          this.$store.state.herculeRole === "ClassificationInternalExpert"
            ? jobDetails.metadata.notify == "true"
            : jobDetails.metadata.support == "true";
        this.useDefaultSchema =
          jobDetails.metadata.defaultSchema &&
          jobDetails.metadata.defaultSchema == "true";
        this.isAutogenerateFieldEnabled =
          jobDetails.metadata.generated_field_name &&
          jobDetails.metadata.generated_field_name != "";
        this.autogenerateField = jobDetails.metadata.generated_field_name
          ? jobDetails.metadata.generated_field_name
          : "";
        this.$store.commit("stopLoading");
      })
      .catch((error) => {
        this.$store.commit("stopLoading");
        this.displayError(error);
      });
  }

  private onTagInput(e: any) {
    const searchQuery: string = e.detail.inputValue.trim();
    const isAlreadyPresent =
      this.findSelectedTags().findIndex((t) => t.value === searchQuery) >= 0;
    if (searchQuery === "" || isAlreadyPresent) return;
    const addTagOption: SelectOption = {
      label: `Add the tag: ${searchQuery}`,
      value: searchQuery,
      selected: false,
    };
    this.allTags = [
      addTagOption,
      ...this.allTags.filter((t) => !t.label.startsWith("Add the tag:")),
    ];
  }

  private onTagSelected(e: any) {
    const selectedOptionLabel: string = e.detail.item.label;
    const selectedOptionValue = e.detail.item.value;
    this.allTags = [
      ...this.allTags.filter((t) => !t.label.startsWith("Add the tag:")),
      {
        label: selectedOptionValue,
        value: selectedOptionValue,
        selected: true,
      },
    ];
    this.tagsSelectKey = this.tagsSelectKey + 1;
  }

  private onTagDeselected(e: any) {
    const selectedOption = e.detail.item.value;
    this.allTags = this.allTags.filter((t) => t.value !== selectedOption);
    this.tagsSelectKey = this.tagsSelectKey + 1;
  }

  private findSelectedTags() {
    return this.allTags.filter((t) => t.selected);
  }

  private fileChosen(e) {
    this.file = e.detail.files[0];
    this.fileName = this.file.name;
  }

  private findFileType(file: any) {
    const acceptedFileTypes = [".csv", ".psv", ".tsv", ".txt"];
    if (
      this.fileName &&
      this.fileName.length > 4 &&
      acceptedFileTypes.indexOf(this.fileName.slice(-4).toLowerCase()) >= 0
    ) {
      return this.fileName.slice(-3).toLowerCase();
    }
    return "";
  }

  private removeUploadedFile() {
    if (
      !(this.metadata.isQuestionnaireDone == "true") ||
      (this.$store.getters.featureLevel("item-classification") ===
        FeatureLevel.MtccFull &&
        this.$store.state.herculeRole === "ClassificationAdmin" &&
        !this.requireSupportOrNotif)
    ) {
      this.file = null;
      this.fileName = "";
    }
  }

  private isSaveDisabled() {
    return (
      this.jobName.trim() === "" ||
      !this.fileName ||
      this.fileName === "" ||
      this.allTags.length > this.tagsLimit ||
      this.$store.state.loading ||
      (!this.useDefaultSchema &&
        this.isAutogenerateFieldEnabled &&
        this.autogenerateField.trim() === "")
    );
  }

  async nextStep() {
    const fileType = this.findFileType(this.file);
    const withSchemaTypes = ["tsv", "psv", "csv", "txt"];
    const withoutSchemaTypes = ["tsv", "csv", "psv"];
    if (
      (!this.useDefaultSchema && withoutSchemaTypes.indexOf(fileType) < 0) ||
      (this.useDefaultSchema && withSchemaTypes.indexOf(fileType) < 0)
    ) {
      this.errorMessageTop = "File type is not allowed";
      return;
    }
    this.$store.commit("startLoading");
    try {
      await this.createOrUpdateJob();
      if (this.file) {
        const formData = new FormData();
        formData.append("file", this.file);
        const uploadResponse = await this.$api.put(
          `item-classification-api/v1/jobs/${this.companyId}/${this.currentJobId}/upload`,
          formData,
          {
            params: { fileType, defaultSchema: this.useDefaultSchema },
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        if (this.isAutogenerateFieldEnabled && !this.useDefaultSchema) {
          const autogenerateResponse = await this.$api.put(
            `item-classification-api/v1/jobs/${this.companyId}/${this.currentJobId}/generateItemcode`,
            null,
            {
              params: { fieldName: this.autogenerateField },
            }
          );
        }
        this.$store.commit("stopLoading");
      }
      const query = Object.assign({}, this.$route.query);
      query.jobId = this.currentJobId;
      this.$router.push({ query });
      this.$emit("step-done");
    } catch (error) {
      this.$store.commit("stopLoading");
      this.displayError(error);
    }
  }

  private isSupportEnabled() {
    if (
      this.$store.getters.featureLevel("item-classification") ===
        FeatureLevel.MtccFull &&
      this.$store.state.herculeRole === "ClassificationAdmin"
    ) {
      return this.requireSupportOrNotif;
    }

    return (
      this.$store.getters.featureLevel("item-classification") !==
      FeatureLevel.AtccFull &&
      this.$store.getters.featureLevel("item-classification") !==
      FeatureLevel.AtccTrial
    );
  }

  private isNotificationRequired() {
    if (this.$store.state.herculeRole === "ClassificationInternalExpert") {
      return this.requireSupportOrNotif;
    }

    if (
      this.$store.getters.featureLevel("item-classification") ===
        FeatureLevel.MtccFull &&
      this.$store.state.herculeRole === "ClassificationAdmin"
    ) {
      return this.requireSupportOrNotif;
    }

    return (
      this.$store.getters.featureLevel("item-classification") !==
      FeatureLevel.AtccFull
    );
  }

  async createOrUpdateJob() {
    const isSupportRequired = this.isSupportEnabled();
    const isNotificationRequired = this.isNotificationRequired();

    if (this.currentJobId) {
      const metadataUpdated = Object.assign(this.metadata, {
        support: isSupportRequired ? "true" : "false",
        notify: isNotificationRequired ? "true" : "false",
        isQuestionnaireDone:
          this.metadata.isQuestionnaireDone == "true" ? "true" : "false",
      });
      if (this.jobStatus == JobStatus.Created) {
        await this.$api.put(
          `item-classification-api/v1/jobs/${this.companyId}/${this.currentJobId}`,
          {
            jobName: this.jobName,
            tags: this.allTags.map((t) => t.value),
            metadata: metadataUpdated,
          }
        );
      }
    } else {
      const newJobResponse = await this.$api.post(
        `item-classification-api/v1/jobs/${this.companyId}`,
        {
          categories: [],
          jobName: this.jobName,
          tags: this.allTags.map((t) => t.value),
          metadata: {
            support: isSupportRequired ? "true" : "false",
            notify: isNotificationRequired ? "true" : "false",
            isQuestionnaireDone: "false",
          },
        }
      );
      this.currentJobId = newJobResponse.data["jobId"];
      (this.$parent as any).updateJobId(this.currentJobId);
    }
  }

  private cancel() {
    this.$router.push({
      path: `/classification/${this.accountId}/${this.companyId}/jobs`,
    });
  }
}
</script>

<style scoped lang="scss">
.intro {
  color: var(--color-gray-dark);
}
label.required:after {
  color: red;
  content: " *";
}
.selected-file {
  line-height: 36px;
  s-icon {
    line-height: 2.2rem;
  }
  s-icon:first-of-type {
    color: var(--color-green-dark);
  }
}
.file-success {
  line-height: 2rem;
  color: var(--color-green-dark);
  font-weight: 600;
}
.limit-error {
  color: var(--color-red-dark);
  font-weight: 600;
}
.selected-file {
  s-icon:nth-of-type(2) {
    cursor: pointer;
  }
}
.tooltip-wrap {
  display: initial;
  line-height: normal;
}
.tooltip-wrap .tooltip-content {
  display: none;
  position: fixed;
  background-color: var(--color-gray-lightest);
  z-index: 1;
  max-width: 300px;
}
.tooltip-wrap:hover .tooltip-content {
  display: initial;
}
.info-icon {
  s-icon {
    color: var(--color-blue-dark);
  }
}
</style>
