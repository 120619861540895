<template>
  <div v-if="tree && tab == 'interactive'">
    <TreeViewInteractive
      v-bind:tree="tree"
      v-bind:decisionId="decisionId"
      v-bind:decisionName="decisionName"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Provide, Watch } from "vue-property-decorator";
import TreeViewInteractive from "../components/TreeViewInteractive.vue";
import analyticsLayerUtil from "./../utils/index";

@Component({
  components: {
    TreeViewInteractive
  }
})
export default class TreeView extends Vue {
  @Prop() private tree!: string;
  @Prop() private decisionId!: string;
  @Prop() private decisionName!: string;
  @Prop() private tab!: string;
  captureInteractiveSession(question, answer, description) {
    const props = {
      page: {
        pageInfo: {
          treeName: description
        }
      },
      search: {
        interactive: {
          question,
          answer
        }
      }
    };
    analyticsLayerUtil.addToDataLayer(props);
    analyticsLayerUtil.commitDataLayer("interactive_session");
  }
  captureInteractivePageLoad(description) {
    const props = {
      page: {
        pageInfo: {
          treeName: description
        }
      }
    };
    analyticsLayerUtil.firePageLoad(props);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
