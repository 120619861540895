<template>
  <span>
    <span class="switch-company text-uppercase current-company">{{
      currentCompany.name
    }}</span>
    <s-pipe></s-pipe>
    <a class="switch-company" @click="openChooser()">Switch company</a>
    <s-dialog
      id="company-chooser"
      aria-labelledby="company-chooser"
      aria-modal="true"
      ref="company-chooser"
    >
      <div slot="header" id="dialog-title">Choose company</div>
      <div slot="body">
        <s-input-extended
          inputid="company-search"
          @s-input="onCompanySearchChange"
          @s-clear="onCompanySearchClear"
          type="search"
        />
        <s-table-container class="margin-top-sm">
          <table class="table-container">
            <thead>
              <tr>
                <th>COMPANY</th>
                <th>COMPANY CODE</th>
                <th>ID</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="company in filteredSortedCompanies"
                v-bind:key="company.id"
                :class="[
                  company.id === currentCompany.id ? 'current-chosen-class' : ''
                ]"
              >
                <td>{{ company.name }}</td>
                <td>{{ company.companyCode }}</td>
                <td>{{ company.id }}</td>
                <td>
                  <button
                    class="primary"
                    :disabled="company.id === currentCompany.id"
                    @click="chooseCompany(company.id)"
                  >
                    Select
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </s-table-container>
      </div>
    </s-dialog>
  </span>
</template>

<script lang="ts">
import { Component, Prop, Vue, Provide, Watch } from "vue-property-decorator";

@Component({
  components: {}
})
export default class CompaniesSwitcher extends Vue {
  @Provide("$_") $_: any;

  @Prop() private currentId!: string;

  private currentCompany = { id: null };
  private companySearch = "";

  @Watch("currentId")
  updateCurrentCompany() {
    const companies = this.$store.getters.companies;

    let currentCompany;
    if (companies.length === 1) {
      currentCompany = companies[0];
    } else {
      currentCompany = this.$_.find(companies, company =>
        this.currentId ? company.id == this.currentId : company.isDefault
      );
    }
    this.currentCompany = currentCompany;
    this.$store.commit("recordCurrentCompany", this.currentCompany.id);
  }

  onCompanySearchChange({ detail }) {
    this.companySearch = detail.value.trim().toLowerCase();
  }

  onCompanySearchClear() {
    this.companySearch = "";
  }

  get filteredSortedCompanies() {
    const pinnedCompanyFilter = company => {
      return company.isDefault || company.id == this.currentCompany.id;
    };

    const partitionedCompanies = this.$_.partition(
      this.$store.getters.companies,
      pinnedCompanyFilter
    );
    const pinnedCompanies = partitionedCompanies[0];
    const companies = partitionedCompanies[1];

    const companySearchFilter = company => {
      return (
        company.name.toLowerCase().search(this.companySearch) != -1 ||
        company.companyCode.toLowerCase().search(this.companySearch) != -1 ||
        company.id.toString().search(this.companySearch) != -1
      );
    };

    const companySort = (companyA, companyB) => {
      if (companyA.name.toLowerCase() > companyB.name.toLowerCase()) {
        return 1;
      } else if (companyA.name.toLowerCase() < companyB.name.toLowerCase()) {
        return -1;
      } else {
        return 0;
      }
    };

    const pinnedCompanySort = (companyA, companyB) => {
      if (companyA.id == this.currentCompany.id) {
        return -1;
      } else if (companyB.companyCOde == this.currentCompany.id) {
        return 1;
      } else {
        return 0;
      }
    };

    return pinnedCompanies
      .filter(companySearchFilter)
      .sort(pinnedCompanySort)
      .concat(companies.filter(companySearchFilter).sort(companySort));
  }

  openChooser() {
    (this.$refs["company-chooser"] as any).showModal();
  }
  closeChooser() {
    (this.$refs["company-chooser"] as any).close();
  }

  chooseCompany(id) {
    this.closeChooser();

    this.$emit("company-switched", { companyId: id });
  }

  mounted() {
    this.updateCurrentCompany();
  }
}
</script>

<style scoped lang="scss">
.current-company {
  color: var(--color-gray-dark);
  font-size: 1.25rem;
}
.switch-company {
  display: inline !important;
  font-size: 1rem;
  font-weight: 500;
}
.current-chosen-class {
  background-color: var(--color-blue-lighter);
}

.table-container {
  width: 36rem;
  overflow-x: hidden;
}
</style>
