<template>
  <div v-if="!loading">
    <h1 ref="questionnaireHeader" class="margin-bottom-none">
      Qualification Questionnaire
    </h1>
    <s-row v-show="errorMessageTop !== ''">
      <s-col>
        <s-alert nodismiss class="margin-top-sm" status="error">
          <div>
            {{ errorMessageTop }}
          </div>
        </s-alert>
      </s-col>
    </s-row>
    <s-row class="margin-left-none">
      <s-col span="10" class="intro pad-bottom-md pad-left-none">
        Answer the following questionnaire for better classification by our
        experts.
      </s-col>
      <s-col span="10" class="questionnaire">
        <s-row class="company-questions">
          <s-col span="12" class="pad-bottom-sm">
            <h3 class="header margin-bottom-none margin-top-none">
              Company specific questions
            </h3>
          </s-col>
          <s-col
            :span="question.questionType === 'singleArea' ? 10 : 5"
            v-for="(question, index) in questionnaire.companyQuestions"
            :key="index"
            class="question margin-top-sm pad-bottom-sm"
          >
            <div
              class="single-type"
              v-if="
                question.questionType === 'single' && showQuestion(question)
              "
            >
              <div
                :for="`question${index}`"
                :class="[question.required ? 'required' : '', 'question-label']"
              >
                {{ question.question }}
              </div>
              <input
                type="text"
                :id="`question${index}`"
                :value="question.answer"
                :disabled="
                  jobDetails.metadata.isQuestionnaireDone == 'true' &&
                    jobDetails.metadata.support == 'true'
                "
                @input="(e) => onSingleTextChange(e, 'companyQuestions', index)"
              />
            </div>
            <div
              class="single-area-type"
              v-if="
                question.questionType === 'singleArea' && showQuestion(question)
              "
            >
              <div
                :for="`question${index}`"
                :class="[question.required ? 'required' : '', 'question-label']"
              >
                {{ question.question }}
              </div>
              <textarea
                cols="35"
                wrap="soft"
                type="text"
                :id="`question${index}`"
                :value="question.answer"
                @input="(e) => onSingleTextChange(e, 'companyQuestions', index)"
                :placeholder="question.description"
                :disabled="
                  jobDetails.metadata.isQuestionnaireDone == 'true' &&
                    jobDetails.metadata.support == 'true'
                "
              />
            </div>
            <div
              class="single-select-type"
              v-if="
                question.questionType === 'singleSelect' &&
                  showQuestion(question)
              "
            >
              <div
                class="question-label"
                :for="`question${index}`"
                :class="[question.required ? 'required' : '', 'question-label']"
              >
                {{ question.question }}
              </div>
              <s-select
                nosearch
                :id="`select-question${index}`"
                :inputid="`question${index}`"
                :disabled="
                  jobDetails.metadata.isQuestionnaireDone == 'true' &&
                    jobDetails.metadata.support == 'true'
                "
                :optionsList="
                  JSON.stringify(
                    question.options.map((option) => {
                      return {
                        label: option,
                        value: option,
                        selected: question.answer == option,
                      };
                    })
                  )
                "
                v-on:s-select="
                  (e) => onSingleSelectChange(e, 'companyQuestions', index)
                "
              ></s-select>
            </div>
            <div
              class="multi-select-type"
              v-if="
                question.questionType === 'multiSelect' &&
                  showQuestion(question)
              "
            >
              <div
                class="question-label"
                :for="`question${index}`"
                :class="[question.required ? 'required' : '', 'question-label']"
              >
                {{ question.question }}
              </div>
              <s-select
                nosearch
                :id="`select-question${index}`"
                :inputid="`question${index}`"
                :disabled="
                  jobDetails.metadata.isQuestionnaireDone == 'true' &&
                    jobDetails.metadata.support == 'true'
                "
                :optionsList="
                  JSON.stringify(
                    question.options.map((option) => {
                      return {
                        label: option,
                        value: option,
                        selected: question.answer.indexOf(option) >= 0,
                      };
                    })
                  )
                "
                multiple=""
                v-on:s-select="
                  (e) =>
                    onMultiselectChange(e, 'companyQuestions', index, false)
                "
                v-on:s-deselect="
                  (e) => onMultiselectChange(e, 'companyQuestions', index, true)
                "
              ></s-select>
            </div>
          </s-col>
        </s-row>
        <s-row
          v-if="areJobQuestionsPresent(questionnaire.jobQuestions)"
          class="job-questions margin-top-lg"
        >
          <s-col span="12" class="pad-bottom-sm">
            <h3 class="header margin-bottom-none margin-top-none">
              Job specific questions
            </h3>
          </s-col>
          <s-col
            :span="
              question.questionType === 'multiQuestion' ||
              question.questionType === 'singleArea'
                ? 10
                : 5
            "
            v-for="(question, index) in questionnaire.jobQuestions"
            :key="`job-${index}`"
            class="question margin-top-sm pad-bottom-sm"
          >
            <div
              class="single-type"
              v-if="
                question.questionType === 'single' && showQuestion(question)
              "
            >
              <div
                :for="`job-question${index}`"
                :class="[question.required ? 'required' : '', 'question-label']"
              >
                {{ question.question }}
              </div>
              <input
                type="text"
                :id="`job-question${index}`"
                :value="question.answer"
                :disabled="
                  jobDetails.metadata.isQuestionnaireDone == 'true' &&
                    jobDetails.metadata.support == 'true'
                "
                @input="(e) => onSingleTextChange(e, 'jobQuestions', index)"
              />
            </div>
            <div
              class="single-area-type"
              v-if="
                question.questionType === 'singleArea' && showQuestion(question)
              "
            >
              <div
                :for="`question${index}`"
                :class="[question.required ? 'required' : '', 'question-label']"
              >
                {{ question.question }}
              </div>
              <textarea
                cols="35"
                wrap="soft"
                type="text"
                :id="`question${index}`"
                :value="question.answer"
                :disabled="
                  jobDetails.metadata.isQuestionnaireDone == 'true' &&
                    jobDetails.metadata.support == 'true'
                "
                @input="(e) => onSingleTextChange(e, 'jobQuestions', index)"
                :placeholder="question.description"
              />
            </div>
            <div
              class="multiQuestion-type margin-bottom-lg"
              v-if="
                question.questionType === 'multiQuestion' &&
                  showQuestion(question)
              "
            >
              <div
                :class="[question.required ? 'required' : '', 'question-label']"
              >
                {{ question.question }}
              </div>
              <div v-for="(option, index2) in question.options" :key="index2">
                <fieldset role="radiogroup" class="margin-top-xs">
                  <span
                    class="option pad-left-lg margin-right-lg"
                    :id="`title${index2}`"
                    >{{ option.question }}</span
                  >
                  <input
                    type="radio"
                    :id="`option1${index2}`"
                    :name="`radio${index2}`"
                    :disabled="
                      jobDetails.metadata.isQuestionnaireDone == 'true' &&
                        jobDetails.metadata.support == 'true'
                    "
                    value="yes"
                    :checked="option.answer === 'yes'"
                    @click="
                      (e) =>
                        onMultiquestionChange(
                          'yes',
                          'jobQuestions',
                          index,
                          index2
                        )
                    "
                  />
                  <label
                    :id="`label1${index2}`"
                    :for="`option1${index2}`"
                    class="margin-left-xl"
                    >Yes</label
                  >
                  <input
                    type="radio"
                    :id="`option2${index2}`"
                    :name="`radio${index2}`"
                    value="no"
                    :checked="option.answer === 'no'"
                    :disabled="
                      jobDetails.metadata.isQuestionnaireDone == 'true' &&
                        jobDetails.metadata.support == 'true'
                    "
                    @click="
                      (e) =>
                        onMultiquestionChange(
                          'no',
                          'jobQuestions',
                          index,
                          index2
                        )
                    "
                  />
                  <label
                    :id="`label2${index2}`"
                    :for="`option2${index2}`"
                    class="margin-left-xl"
                    >No</label
                  >
                </fieldset>
              </div>
            </div>
            <div
              class="date-type"
              v-if="question.questionType === 'date' && showQuestion(question)"
            >
              <div
                :for="`job-question${index}`"
                :class="[question.required ? 'required' : '', 'question-label']"
              >
                {{ question.question }}
              </div>
              <s-datepicker
                :inputid="`job-question${index}`"
                :disabled="
                  jobDetails.metadata.isQuestionnaireDone == 'true' &&
                    jobDetails.metadata.support == 'true'
                "
                :value="question.answer"
                v-on:s-select="(e) => onDateChange(e, 'jobQuestions', index)"
                inputname="date"
              ></s-datepicker>
            </div>
          </s-col>
        </s-row>
      </s-col>
    </s-row>
    <s-row>
      <s-col>
        <button @click="previous" class="tertiary icon-leading">
          <s-icon name="arrow-left"></s-icon>Previous
        </button>
        <button
          @click="saveAndNext"
          class="primary icon-trailing"
          :disabled="!isSavePossible()"
        >
          Save and continue<s-icon name="arrow-right"></s-icon>
        </button>
        <button @click="cancel" class="ghost">Cancel</button>
      </s-col>
    </s-row>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Provide, Watch } from "vue-property-decorator";
import errorsUtil from "../utils/errorMappings";
import _ from "lodash";
import { JobStatus } from "@/common/job";

@Component({})
export default class BulkQuestionnaire extends Vue {
  @Provide("$api") $api: any;
  @Provide("$_") $_: any;

  @Prop() private accountId!: string;
  @Prop() private companyId!: string;
  @Prop() private jobId!: string;

  private questionnaire: any = {};
  private jobDetails: any = {};

  private loading = false;

  private errorMessageTop = "";

  async mounted() {
    this.$store.commit("startLoading");
    this.loading = true;
    await this.getJobDetails();
    await this.getQuestionnaire();
    this.loading = false;
    this.$store.commit("stopLoading");
  }

  async getJobDetails() {
    try {
      const response = await this.$api.get(
        `item-classification-api/v1/jobs/${this.companyId}/${this.jobId}`
      );
      this.jobDetails = response.data;
    } catch (error) {
      this.$store.commit("stopLoading");
      this.loading = false;
      this.displayError(error);
    }
  }

  async getQuestionnaire() {
    try {
      const response = await this.$api.get(
        `item-classification-api/v1/jobs/${this.companyId}/${this.jobId}/questionnaire`
      );
      this.questionnaire = response.data;
    } catch (error) {
      this.$store.commit("stopLoading");
      this.loading = false;
      this.displayError(error);
    }
  }

  async saveAndNext() {
    try {
      this.$store.commit("startLoading");
      if (this.jobDetails.status == JobStatus.Created) {
        const saveResponse = await this.$api.put(
          `item-classification-api/v1/jobs/${this.companyId}/${this.jobId}/questionnaire`,
          this.questionnaire
        );
      }
      if (
        this.jobDetails.metadata.support == "true" &&
        this.jobDetails.metadata.isQuestionnaireDone == "false"
      ) {
        const statusChangeResponse = await this.$api.post(
          `item-classification-api/v1/jobs/${this.companyId}/${this.jobId}/support`,
          null,
          { params: { notify: this.jobDetails?.metadata?.notify } }
        );
      }
      this.$store.commit("stopLoading");
      this.$emit("step-done");
    } catch (error) {
      this.$store.commit("stopLoading");
      this.displayError(error);
    }
  }

  @Watch("questionnaire", {
    immediate: true,
    deep: true,
  })
  private isSavePossible() {
    const companyQuestions: any[] = this.questionnaire.companyQuestions || [];
    const jobQuestions: any[] = this.questionnaire["jobQuestions"] || [];
    const allQuestions: any[] = companyQuestions.concat(jobQuestions);
    return (
      allQuestions.length > 0 &&
      allQuestions
        .filter((question) => question.required && this.showQuestion(question))
        .every((question) => {
          if (this.jobDetails.metadata.isQuestionnaireDone === "true") {
            return true;
          } else if (
            ["single", "singleArea", "date", "singleSelect"].indexOf(
              question.questionType
            ) >= 0
          ) {
            return (question.answer as string).trim() !== "";
          } else if (question.questionType === "multiSelect") {
            return (question.answer as Array<string>).length > 0;
          } else if (question.questionType === "multiQuestion") {
            return question.options.every((option) => option.answer != "");
          } else {
            return false;
          }
        })
    );
  }

  private areJobQuestionsPresent(questions: any[]) {
    return (
      questions &&
      questions.filter((question) => this.showQuestion(question)).length > 0
    );
  }

  private showQuestion(question: any) {
    const rules: any[] = question.rules;
    const herculeRole = this.$store.state.herculeRole;
    const featureLevel = this.$store.getters.featureLevel(
      "item-classification"
    );
    const basedOnRules = !rules
      ? true
      : rules.some(
          (rule) =>
            rule.featureLevel === featureLevel &&
            rule.roles.indexOf(herculeRole) >= 0
        );
    const basedOnSupport = !question.support
      ? true
      : question.support.toString() == this.jobDetails.metadata.support;
    return basedOnRules && basedOnSupport;
  }

  @Watch("errorMessageTop")
  focusErrorAlert() {
    if (this.errorMessageTop != "") {
      (this.$refs.questionnaireHeader as any).scrollIntoView();
    }
  }

  private displayError(error) {
    const errorCode = error.response.data.code;
    if (_.has(errorsUtil.errorMessages, errorCode)) {
      this.$store.commit("recordError", "");
      this.errorMessageTop = errorsUtil.errorMessages[errorCode];
    }
  }

  private previous() {
    (this.$parent as any).previousStep();
  }

  private cancel() {
    this.$router.push({
      path: `/classification/${this.accountId}/${this.companyId}/jobs`,
    });
  }

  private onSingleTextChange(e: any, type: string, questionIndex: number) {
    this.questionnaire = _.set(
      this.questionnaire,
      `${type}[${questionIndex}].answer`,
      e.target.value
    );
  }

  private onSingleSelectChange(e: any, type: string, questionIndex: number) {
    const targetValue: string = e.detail.item.value;
    this.questionnaire = _.set(
      this.questionnaire,
      `${type}[${questionIndex}].answer`,
      e.detail.item.value
    );
  }

  private onDateChange(e: any, type: string, questionIndex: number) {
    this.questionnaire = _.set(
      this.questionnaire,
      `${type}[${questionIndex}].answer`,
      e.detail.value
    );
  }

  private onMultiselectChange(
    e: any,
    type: string,
    questionIndex: number,
    isRemoved: boolean
  ) {
    const targetValue: string = e.detail.item.value;
    const currentValue: string[] =
      this.questionnaire[type][questionIndex].answer || [];
    const updatedValue = isRemoved
      ? currentValue.filter((item) => item != targetValue)
      : currentValue.concat(targetValue);
    this.questionnaire = _.set(
      this.questionnaire,
      `${type}[${questionIndex}].answer`,
      updatedValue
    );
  }

  private onMultiquestionChange(
    value: string,
    type: string,
    questionIndex: number,
    radioIndex: number
  ) {
    this.questionnaire = _.set(
      this.questionnaire,
      `${type}[${questionIndex}].options[${radioIndex}].answer`,
      value
    );
  }
}
</script>

<style lang="scss">
.intro {
  color: var(--color-gray-dark);
}
.required-fields {
  label:after {
    color: red;
    content: " *";
  }
}
.question {
  input {
    min-width: 300px;
    max-width: 400px;
  }
  textarea {
    min-width: 300px;
    max-width: 400px;
  }
}
.company-questions {
  padding-top: 20px;
  padding-bottom: 30px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 15px;
  s-select {
    max-width: 400px;
  }
  s-select div[ref="select-input-container"] input[ref="select-input"] {
    width: -webkit-fill-available;
  }
}
.job-questions {
  padding-top: 20px;
  padding-bottom: 30px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 15px;
  s-select {
    max-width: 400px;
  }
  s-select div[ref="select-input-container"] input[ref="select-input"] {
    width: -webkit-fill-available;
  }
}
.header {
  font-size: 1.2rem;
}

.option {
  display: inline-block;
  width: 350px;
}
.question-label {
  color: black;
  font-size: 0.98rem;
  font-weight: 500;
  padding-bottom: 10px;
}
</style>
