import { render, staticRenderFns } from "./BulkCategorySelection.vue?vue&type=template&id=06020ab2&scoped=true"
import script from "./BulkCategorySelection.vue?vue&type=script&lang=ts"
export * from "./BulkCategorySelection.vue?vue&type=script&lang=ts"
import style0 from "./BulkCategorySelection.vue?vue&type=style&index=0&id=06020ab2&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06020ab2",
  null
  
)

export default component.exports