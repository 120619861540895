<template>
  <div v-if="!loading">
    <h1 ref="mappingHeader" class="margin-bottom-none">Map your columns</h1>
    <s-row v-show="errorMessageTop !== ''">
      <s-col>
        <s-alert nodismiss class="margin-top-sm" status="error">
          <div>
            {{ errorMessageTop }}
          </div>
        </s-alert>
      </s-col>
    </s-row>
    <s-row class="fields">
      <s-col span="10" class="intro pad-bottom-md">
        Please map columns from your catalog data to the expected classification
        fields.
      </s-col>
    </s-row>
    <s-row>
      <s-col span="12" class="pad-bottom-md">
        <h3 class="margin-bottom-none">Item identifiers</h3>
      </s-col>

      <s-col span="2">
        <div span="2" class="required-field pad-bottom-md">
          <label for="item-code">
            Item Code
          </label>
          <div class="intro text-xs">
            Select the column that has item code
          </div>
          <s-select
            nosearch
            id="item-code"
            inputid="item-code"
            :disabled="autogeneratedField != ''"
            :optionsList="
              JSON.stringify(inputFieldOptionsForSingleselect('itemCode'))
            "
            v-on:s-select="(e) => singleMappingSelected(e, 'itemCode')"
          ></s-select>
        </div>
        <div span="2" class="pad-bottom-md">
          <label for="upc">
            UPC
          </label>
          <div class="intro text-xs">
            Select the column that has UPC
          </div>
          <s-select
            nosearch
            id="upc"
            inputid="upc"
            :optionsList="
              JSON.stringify(inputFieldOptionsForSingleselect('upc'))
            "
            v-on:s-select="(e) => singleMappingSelected(e, 'upc')"
          ></s-select>
        </div>
        <div v-if="!hideIdentifiers" span="2" class="pad-bottom-md">
          <label for="sku">
            SKU
          </label>
          <div class="intro text-xs">
            Select the column that has SKU
          </div>
          <s-select
            nosearch
            id="sku"
            inputid="sku"
            :optionsList="
              JSON.stringify(inputFieldOptionsForSingleselect('sku'))
            "
            v-on:s-select="(e) => singleMappingSelected(e, 'sku')"
          ></s-select>
        </div>
        <div v-if="!hideIdentifiers" span="2" class="pad-bottom-md">
          <label for="ean">
            EAN
          </label>
          <div class="intro text-xs">
            Select the column that has EAN
          </div>
          <s-select
            nosearch
            id="ean"
            inputid="ean"
            :optionsList="
              JSON.stringify(inputFieldOptionsForSingleselect('ean'))
            "
            v-on:s-select="(e) => singleMappingSelected(e, 'ean')"
          ></s-select>
        </div>
        <div v-if="!hideIdentifiers" span="2" class="pad-bottom-md">
          <label for="asin">
            ASIN
          </label>
          <div class="intro text-xs">
            Select the column that has ASIN
          </div>
          <s-select
            nosearch
            id="asin"
            inputid="asin"
            :optionsList="
              JSON.stringify(inputFieldOptionsForSingleselect('asin'))
            "
            v-on:s-select="(e) => singleMappingSelected(e, 'asin')"
          ></s-select>
        </div>
      </s-col>
      <s-col :span="2" class="pad-left-sm pad-bottom-md">
        <p class="show-hide margin-top-none text-xs">
          <a @click="toggleIdentifiersView()">{{
            hideIdentifiers ? "Show all identifiers" : "Hide identifiers"
          }}</a>
        </p>
      </s-col>
      <s-col span="12" class="pad-bottom-md">
        <h3 class="margin-bottom-none">Item attributes</h3>
      </s-col>
    </s-row>
    <s-row class="fields">
      <s-col span="3" class="item-title required-field">
        <label for="item-title">
          Item title
        </label>
        <div class="intro text-xs">
          Select all the column(s) that has your item title
        </div>
        <s-select
          nosearch
          id="item-title"
          inputid="item-title"
          multiple=""
          :optionsList="
            JSON.stringify(inputFieldOptionsForMultiselect('title'))
          "
          v-on:s-select="(e) => multiMappingSelected(e, 'title')"
          v-on:s-deselect="(e) => multiMappingDeselected(e, 'title')"
        ></s-select>
        <div
          v-if="areMultipleColumnsSelected('title')"
          class="recommended margin-top-xs"
        >
          <s-icon
            name="info-circle-filled"
            role="img"
            aria-label="info-circle-filled"
            class="margin-right-xs text-xs-strong"
          ></s-icon>
          Fields are selected in the order:
          <b>{{ finalMappingsToSave["title"].fields.join(" > ") }}</b>
        </div>
      </s-col>
      <s-col span="2" v-if="areMultipleColumnsSelected('title')">
        <div class="text-xs intro join-types">Join type</div>
        <s-select
          nosearch
          id="title-join-method"
          inputid="title-join-method"
          :optionsList="JSON.stringify(joinMethodOptions('title'))"
          v-on:s-select="(e) => joinMethodSelected(e, 'title')"
        ></s-select>
      </s-col>
      <s-col
        span="2"
        v-if="
          finalMappingsToSave.title &&
            finalMappingsToSave.title.joinType == 'concat' &&
            areMultipleColumnsSelected('title')
        "
      >
        <div class="text-xs intro join-types">Separator</div>
        <input
          type="text"
          id="title-separator"
          :value="
            finalMappingsToSave.title
              ? finalMappingsToSave.title.delimiter
              : '-'
          "
          @input="(e) => onSeparatorChange(e, 'title')"
        />
      </s-col>
    </s-row>
    <s-row class="fields">
      <s-col span="3" class="description ">
        <label for="item-desc">
          Item description
        </label>
        <div class="intro text-xs">
          Select all the column(s) that has your item description
        </div>
        <s-select
          nosearch
          id="item-desc"
          inputid="item-desc"
          multiple=""
          :optionsList="
            JSON.stringify(inputFieldOptionsForMultiselect('description'))
          "
          v-on:s-select="(e) => multiMappingSelected(e, 'description')"
          v-on:s-deselect="(e) => multiMappingDeselected(e, 'description')"
        ></s-select>
        <div
          v-if="areMultipleColumnsSelected('description')"
          class="recommended margin-top-xs"
        >
          <s-icon
            name="info-circle-filled"
            role="img"
            aria-label="info-circle-filled"
            class="margin-right-xs text-xs-strong"
          ></s-icon>
          Fields are selected in the order:
          <b>{{ finalMappingsToSave["description"].fields.join(" > ") }}</b>
        </div>
      </s-col>
      <s-col span="2" v-if="areMultipleColumnsSelected('description')">
        <div class="text-xs intro join-types">Join type</div>
        <s-select
          nosearch
          id="desc-join-method"
          inputid="desc-join-method"
          :optionsList="JSON.stringify(joinMethodOptions('description'))"
          v-on:s-select="(e) => joinMethodSelected(e, 'description')"
        ></s-select>
      </s-col>
      <s-col
        span="2"
        v-if="
          finalMappingsToSave.description &&
            finalMappingsToSave.description.joinType == 'concat' &&
            areMultipleColumnsSelected('description')
        "
      >
        <div class="text-xs intro join-types">Separator</div>
        <input
          type="text"
          id="desc-separator"
          :value="
            finalMappingsToSave.description
              ? finalMappingsToSave.description.delimiter
              : '-'
          "
          @input="(e) => onSeparatorChange(e, 'description')"
        />
      </s-col>
    </s-row>
    <s-row class="fields">
      <s-col span="3" class="category ">
        <label for="item-category">
          Item category(ies)
        </label>
        <div class="intro text-xs">
          Select all the column(s) that has your item categories
        </div>
        <s-select
          nosearch
          id="item-category"
          inputid="item-category"
          multiple=""
          :optionsList="
            JSON.stringify(inputFieldOptionsForMultiselect('category'))
          "
          v-on:s-select="(e) => multiMappingSelected(e, 'category')"
          v-on:s-deselect="(e) => multiMappingDeselected(e, 'category')"
        ></s-select>
        <div
          v-if="areMultipleColumnsSelected('category')"
          class="recommended margin-top-xs"
        >
          <s-icon
            name="info-circle-filled"
            role="img"
            aria-label="info-circle-filled"
            class="margin-right-xs text-xs-strong"
          ></s-icon>
          Fields are selected in the order:
          <b>{{ finalMappingsToSave["category"].fields.join(" > ") }}</b>
        </div>
      </s-col>
      <s-col span="2" v-if="areMultipleColumnsSelected('category')">
        <div class="text-xs intro join-types">Join type</div>
        <s-select
          nosearch
          id="category-join-method"
          inputid="category-join-method"
          :optionsList="JSON.stringify(joinMethodOptions('category'))"
          v-on:s-select="(e) => joinMethodSelected(e, 'category')"
        ></s-select>
      </s-col>
      <s-col
        span="2"
        v-if="
          finalMappingsToSave.category &&
            finalMappingsToSave.category.joinType == 'concat' &&
            areMultipleColumnsSelected('category')
        "
      >
        <div class="text-xs intro join-types">Separator</div>
        <input
          type="text"
          id="category-separator"
          :value="
            finalMappingsToSave.category
              ? finalMappingsToSave.category.delimiter
              : '-'
          "
          @input="(e) => onSeparatorChange(e, 'category')"
        />
      </s-col>
    </s-row>
    <s-row class="fields">
      <s-col span="3" class="pad-bottom-md avatax-category ">
        <label for="avatax-category">
          Avatax category
        </label>
        <div class="intro text-xs">
          Select the column(s) that has Avatax category
        </div>
        <s-select
          nosearch
          id="avatax-category"
          inputid="avatax-category"
          :optionsList="
            JSON.stringify(inputFieldOptionsForSingleselect('avataxCategory'))
          "
          v-on:s-select="(e) => singleMappingSelected(e, 'avataxCategory')"
        ></s-select>
      </s-col>
    </s-row>
    <s-row class="fields">
      <s-col span="12" class="pad-bottom-md">
        <h3 class="margin-bottom-none">
          Additional customer fields for review
        </h3>
      </s-col>

      <s-col span="3">
        <div span="3" class="pad-bottom-md">
          <label for="additional-1">
            Additional Field 1
          </label>
          <div class="intro text-xs">
            Select a column that will be included in the review screen
          </div>
          <s-select
            nosearch
            id="additional-1"
            inputid="additional-1"
            :optionsList="
              JSON.stringify(
                inputFieldOptionsForSingleselect('additionalField1')
              )
            "
            v-on:s-select="(e) => singleMappingSelected(e, 'additionalField1')"
          ></s-select>
        </div>
        <div span="3" class="pad-bottom-md">
          <label for="additional-2">
            Additional Field 2
          </label>
          <div class="intro text-xs">
            Select a column that will be included in the review screen
          </div>
          <s-select
            nosearch
            id="additional-2"
            inputid="additional-2"
            :optionsList="
              JSON.stringify(
                inputFieldOptionsForSingleselect('additionalField2')
              )
            "
            v-on:s-select="(e) => singleMappingSelected(e, 'additionalField2')"
          ></s-select>
        </div>
        <div v-if="!hideOtherFields" span="3" class="pad-bottom-md">
          <label for="additional-3">
            Additional Field 3
          </label>
          <div class="intro text-xs">
            Select a column that will be included in the review screen
          </div>
          <s-select
            nosearch
            id="additional-3"
            inputid="additional-3"
            :optionsList="
              JSON.stringify(
                inputFieldOptionsForSingleselect('additionalField3')
              )
            "
            v-on:s-select="(e) => singleMappingSelected(e, 'additionalField3')"
          ></s-select>
        </div>
        <div v-if="!hideOtherFields" span="3" class="pad-bottom-md">
          <label for="additional-4">
            Additional Field 4
          </label>
          <div class="intro text-xs">
            Select a column that will be included in the review screen
          </div>
          <s-select
            nosearch
            id="additional-4"
            inputid="additional-4"
            :optionsList="
              JSON.stringify(
                inputFieldOptionsForSingleselect('additionalField4')
              )
            "
            v-on:s-select="(e) => singleMappingSelected(e, 'additionalField4')"
          ></s-select>
        </div>
        <div v-if="!hideOtherFields" span="3" class="pad-bottom-md">
          <label for="additional-5">
            Additional Field 5
          </label>
          <div class="intro text-xs">
            Select a column that will be included in the review screen
          </div>
          <s-select
            nosearch
            id="additional-5"
            inputid="additional-5"
            :optionsList="
              JSON.stringify(
                inputFieldOptionsForSingleselect('additionalField5')
              )
            "
            v-on:s-select="(e) => singleMappingSelected(e, 'additionalField5')"
          ></s-select>
        </div>
      </s-col>
      <s-col :span="2" class="pad-left-sm pad-bottom-md">
        <p class="show-hide margin-top-none text-xs">
          <a @click="toggleOtherFieldsView()">{{
            hideOtherFields ? "Show all" : "Hide"
          }}</a>
        </p>
      </s-col>
    </s-row>
    <s-row class="pad-top-sm">
      <s-col>
        <button @click="previous" class="tertiary icon-leading">
          <s-icon name="arrow-left"></s-icon>Previous
        </button>
        <button
          @click="saveAndNext"
          class="primary icon-trailing"
          :disabled="!isSavePossible()"
        >
          Save and continue<s-icon name="arrow-right"></s-icon>
        </button>
        <button @click="cancel" class="ghost">Cancel</button>
      </s-col>
    </s-row>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Provide, Watch } from "vue-property-decorator";
import errorsUtil from "./../utils/errorMappings";
import _ from "lodash";

@Component({})
export default class BulkSchemaMapping extends Vue {
  @Provide("$api") $api: any;
  @Provide("$_") $_: any;

  @Prop() private accountId!: string;
  @Prop() private companyId!: string;
  @Prop() private jobId!: string;

  private schemaData: any = null;
  private inputFields = [];
  private loading = false;

  private requiredFields = ["title", "itemCode"];
  private finalMappingsToSave = {};
  private errorMessageTop = "";
  private hideIdentifiers = true;
  private hideOtherFields = true;
  private autogeneratedField = "";

  async mounted() {
    this.$store.commit("startLoading");
    this.loading = true;
    try {
      const response = await this.$api.get(
        `item-classification-api/v1/company/${this.companyId}/defaultSchema`
      );
      this.schemaData = response.data;
      this.$store.commit("stopLoading");
      this.loading = false;
    } catch (error) {
      this.displayError(error);
      this.$store.commit("stopLoading");
      this.loading = false;
    }
    this.inputFields = this.schemaData.schema.fields.map((f) => f.name);
    this.autogeneratedField =
      this.schemaData.schema.fields.find((f) => f.autogenerate)?.name || "";
    if (this.autogeneratedField != "") {
      Vue.set(this.finalMappingsToSave, "itemCode", {
        delimiter: "-",
        fields: [this.autogeneratedField],
        joinType: "concat",
      });
    }
  }

  toggleIdentifiersView() {
    this.hideIdentifiers = !this.hideIdentifiers;
  }

  toggleOtherFieldsView() {
    this.hideOtherFields = !this.hideOtherFields;
  }

  private displayError(error) {
    const errorCode = error.response.data.code;
    if (_.has(errorsUtil.errorMessages, errorCode)) {
      this.$store.commit("recordError", "");
      this.errorMessageTop = errorsUtil.errorMessages[errorCode];
    }
  }

  areMultipleColumnsSelected(bulkField) {
    return (
      this.finalMappingsToSave[bulkField] &&
      this.finalMappingsToSave[bulkField].fields.length > 1
    );
  }

  joinMethodOptions(bulkField) {
    return ["concat", "coalesce"].map((option) => {
      return {
        label: option,
        value: option,
        selected:
          this.finalMappingsToSave[bulkField] &&
          this.finalMappingsToSave[bulkField].joinType == option,
      };
    });
  }

  joinMethodSelected(e, bulkField) {
    const currentSelections = this.finalMappingsToSave[bulkField]
      ? this.finalMappingsToSave[bulkField].fields
      : [];
    this.$set(this.finalMappingsToSave, bulkField, {
      delimiter: "-",
      fields: currentSelections,
      joinType: e.detail.item.value,
    });
  }

  onSeparatorChange(e, bulkField) {
    const currentSelections = this.finalMappingsToSave[bulkField]
      ? this.finalMappingsToSave[bulkField].fields
      : [];
    this.$set(this.finalMappingsToSave, bulkField, {
      delimiter: e.target.value,
      fields: currentSelections,
      joinType: "concat",
    });
  }

  async saveAndNext() {
    this.$store.commit("startLoading");
    this.loading = true;
    const isSuccess = await this.saveMappings();
    if (isSuccess) {
      (this.$parent as any).gotoStep(0);
    }
  }

  async saveMappings() {
    const finalMappings = this.nonEmptyMappings(this.finalMappingsToSave);
    try {
      const saveMappingsResponse = await this.$api.put(
        `item-classification-api/v1/company/${this.companyId}/defaultMapping`,
        finalMappings
      );
      return true;
    } catch (error) {
      this.displayError(error);
      this.loading = false;
      this.$store.commit("stopLoading");
      return false;
    }
  }

  private singleMappingSelected(e, bulkField) {
    const selectedInputField = e.detail.item.value;
    this.$set(this.finalMappingsToSave, bulkField, {
      delimiter: "-",
      fields: [selectedInputField],
      joinType: "concat",
    });
  }

  private multiMappingSelected(e, bulkField) {
    const selectedInputField = e.detail.item.value;
    const currentSelections = this.finalMappingsToSave[bulkField]
      ? this.finalMappingsToSave[bulkField].fields
      : [];
    this.$set(this.finalMappingsToSave, bulkField, {
      delimiter: "-",
      fields: currentSelections.concat([selectedInputField]),
      joinType: "concat",
    });
  }

  private multiMappingDeselected(e, bulkField) {
    const selectedInputField = e.detail.item.value;
    const currentSelections = this.finalMappingsToSave[bulkField]
      ? this.finalMappingsToSave[bulkField].fields
      : [];
    this.$set(this.finalMappingsToSave, bulkField, {
      delimiter: "-",
      fields: currentSelections.filter(
        (option) => option != selectedInputField
      ),
      joinType: "concat",
    });
  }

  inputFieldOptionsForSingleselect(bulkField) {
    if (!this.inputFields || this.inputFields.length == 0) return [];
    const currentSelection: string =
      this.finalMappingsToSave[bulkField] &&
      this.finalMappingsToSave[bulkField].fields.length > 0
        ? this.finalMappingsToSave[bulkField].fields[0]
        : "";
    return [{ label: "", value: "", selected: currentSelection === "" }].concat(
      this.inputFields.map((x) => {
        return {
          label: x,
          value: x,
          selected: currentSelection === x,
        };
      })
    );
  }

  inputFieldOptionsForMultiselect(bulkField) {
    if (!this.inputFields || this.inputFields.length == 0) return [];
    const currentSelections: string[] = this.finalMappingsToSave[bulkField]
      ? this.finalMappingsToSave[bulkField].fields
      : [];
    return this.inputFields.map((x: string) => {
      return {
        label: x,
        value: x,
        selected: currentSelections.indexOf(x) >= 0,
      };
    });
  }

  private nonEmptyMappings(mappings: object): object {
    return this.$_.pickBy(
      mappings,
      (x) => !this.$_.isEmpty(x.fields) && x.fields[0] != ""
    );
  }

  private isSavePossible() {
    const finalMappings = this.nonEmptyMappings(this.finalMappingsToSave);
    const finalMappingsCount = Object.keys(finalMappings).length;
    const areRequiredFieldsPopulated = this.requiredFields.every(
      (requiredField) =>
        finalMappings[requiredField] &&
        finalMappings[requiredField].fields.length > 0
    );
    return areRequiredFieldsPopulated && finalMappingsCount > 0;
  }

  private previous() {
    (this.$parent as any).previousStep();
  }

  private cancel() {
    (this.$parent as any).gotoStep(0);
  }
}
</script>

<style lang="scss" scoped>
.fields {
  label {
    font-size: 00.92rem;
  }
}
.intro {
  color: var(--color-gray-dark);
}
.required-field {
  label:after {
    color: red;
    content: " *";
  }
}
.recommended {
  s-icon {
    color: var(--color-blue-dark);
  }
  font-size: 0.75rem !important;
}
.field-mappings {
  s-select {
    max-width: 60%;
  }
  s-select div[ref="select-input-container"] input[ref="select-input"] {
    width: -webkit-fill-available;
  }
}
.join-type {
  font-weight: 600;
}
.avatax-category-link {
  a {
    display: inline-block !important;
  }
}
.link {
  text-decoration: underline;
  a {
    display: initial !important;
  }
  font-weight: 500;
  white-space: initial;
}

.show-hide {
  position: absolute;
  bottom: 3px;
  text-decoration: underline;
  a {
    display: initial !important;
  }
  font-weight: 500;
  white-space: initial;
}
.join-types {
  padding-top: 21px;
}
</style>
