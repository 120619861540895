<template>
  <div class="category-only-view">
    <s-row>
      <s-col>
        <fieldset role="filters" aria-labelledby="filtersLabel">
          <div v-for="category in categories" :key="category.name">
            <div class="category">
              <s-icon
                :id="`icon_${category.name}`"
                :name="
                  isExpanded(category.namePath)
                    ? 'chevron-down'
                    : 'chevron-right'
                "
                class="margin-right-xs margin-top-sm"
                v-on:click="(e) => toggleCategoryVisibility(e, category)"
              ></s-icon>
              <input
                type="checkbox"
                :id="`category_${category.name}`"
                name="category"
                :value="category.name"
                v-on:click="(e) => onCategoryClicked(e, category)"
                :checked="isCategorySelected(category)"
                :indeterminate.prop="isAnySubcategorySelected(category)"
                :ref="`category_${category.name}`"
              />
              <label :for="`category_${category.name}`" class="margin-top-sm"
                >{{ category.name }}
              </label>
            </div>
            <div class="subcategories" v-show="isExpanded(category.namePath)">
              <div
                v-for="(subCategory, index) in category.subcategories"
                :key="`${category.name}${index}`"
                class="pad-left-lg"
              >
                <s-icon
                  v-show="
                    subCategory.subcategories &&
                      subCategory.subcategories.length > 0
                  "
                  :id="`icon_${category.name}${index}`"
                  :name="
                    isSelected(subCategory.namePath)
                      ? 'chevron-down'
                      : 'chevron-right'
                  "
                  class="margin-right-xs margin-top-sm"
                  v-on:click="(e) => onSubcategoryClicked(e, subCategory)"
                ></s-icon>
                <input
                  type="checkbox"
                  :id="`${category.name}${index}`"
                  name="subcategory"
                  :value="subCategory.name"
                  :checked="isSelected(subCategory.namePath)"
                  v-on:click="(e) => onSubcategoryClicked(e, subCategory)"
                />
                <label
                  :for="`${category.name}${index}`"
                  :class="[
                    !subCategory.subcategories ||
                    subCategory.subcategories.length === 0
                      ? 'margin-left-lg'
                      : '',
                    'margin-top-sm',
                  ]"
                  >{{ subCategory.name }}
                </label>
                <div class="leaves" v-show="isSelected(subCategory.namePath)">
                  <div
                    v-for="(leaf, index) in subCategory.subcategories"
                    :key="`${category.name}${subCategory.name}${index}`"
                    class="pad-left-lg"
                  >
                    <input
                      type="checkbox"
                      :id="`${category.name}${subCategory.name}${index}`"
                      name="leaf"
                      :value="leaf.name"
                      :checked="isSelected(leaf.namePath)"
                      v-on:click="(e) => onLeafClicked(e, leaf)"
                    />
                    <label
                      :for="`${category.name}${subCategory.name}${index}`"
                      class="margin-left-lg margin-top-sm"
                      >{{ leaf.name }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <s-row
              ><s-col span="8"><hr class="margin-bottom-xs"/></s-col
            ></s-row>
          </div>
        </fieldset>
      </s-col>
    </s-row>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Vue,
  Prop,
  Provide,
  PropSync
} from "vue-property-decorator";

interface LeafCategory {
  name: string;
  namePath: string;
}

interface Subcategory {
  name: string;
  namePath: string;
  subcategories: LeafCategory[];
}

export interface Category {
  name: string;
  subcategories: Subcategory[];
  namePath: string;
}

@Component({
  components: {},
})
export default class BulkCategorySelection extends Vue {
  @Provide("$api") $api: any;
  @Provide("$_") $_: any;

  @Prop({ default: [] }) categories!: Category[];

  @PropSync("categoryPaths") selectedCategoryPaths!: string[];
  expandedCategories: string[] = [];
  isDefaultProfile = false;
  profileName = "";
  isProfileNameInvalid = false;

  isSelected(namePath: string) {
    return this.selectedCategoryPaths.includes(namePath);
  }

  isCategorySelected(category: Category) {
    return category.subcategories.every(sc =>
      this.selectedCategoryPaths.includes(sc.namePath)
    );
  }

  isAnySubcategorySelected(category: Category) {
    return (
      !this.isCategorySelected(category) &&
      category.subcategories.some(sc =>
        this.selectedCategoryPaths.includes(sc.namePath)
      )
    );
  }

  isExpanded(namePath: string) {
    return this.expandedCategories.includes(namePath);
  }

  private onCategoryClicked(e, category: Category) {
    if (this.isCategorySelected(category)) {
      this.selectedCategoryPaths = this.selectedCategoryPaths.filter(
        path => !path.startsWith(category.namePath)
      );
    } else {
      const newPaths = category.subcategories.map(
        subcategory => subcategory.namePath
      );
      this.selectedCategoryPaths.push(...newPaths);
      this.selectedCategoryPaths = [...new Set(this.selectedCategoryPaths)];
    }
  }

  private onSubcategoryClicked(e, subcategory: Subcategory) {
    this.toggleCategoryPathSelection(subcategory.namePath);
  }

  private onLeafClicked(e, leaf: LeafCategory) {
    this.toggleCategoryPathSelection(leaf.namePath);
  }

  private toggleCategoryVisibility(e, category: Category) {
    if (this.expandedCategories.indexOf(category.namePath) >= 0) {
      this.expandedCategories = this.expandedCategories.filter(
        (p) => !p.startsWith(category.namePath)
      );
    } else {
      this.expandedCategories = [...this.expandedCategories, category.namePath];
    }
  }

  private toggleCategoryPathSelection(namePath: string) {
    if (this.selectedCategoryPaths.indexOf(namePath) >= 0) {
      this.selectedCategoryPaths = this.selectedCategoryPaths.filter(
        (p) => !p.startsWith(namePath)
      );
    } else {
      this.selectedCategoryPaths = [...this.selectedCategoryPaths, namePath];
    }
  }
}
</script>

<style scoped lang="scss">
.category-select-intro {
  color: var(--color-gray-dark);
}
.category,
.subcategories {
  label {
    font-weight: 600;
  }
  s-icon {
    cursor: pointer;
  }
}
</style>
