<template>
  <div>
    <s-row>
      <s-col>
        <fieldset role="filters" aria-labelledby="filtersLabel">
          <a @click="defilter" v-if="backText">Back to {{ backText }}</a>
          <div
            v-for="(categoryDetail, category) in categoryData"
            :key="category"
          >
            <h3>
              {{ header(category, categoryDetail) }}
            </h3>
            <div
              v-for="subCategory in categoryDetail.subcategories"
              :key="subCategory.name"
            >
              <div v-if="subCategory.name">
                <input
                  type="checkbox"
                  :id="`subCategory_${subCategory.name}`"
                  name="subcategory"
                  :value="subCategory.name"
                  @click="filter(subCategory.name)"
                />
                <label :for="`subCategory_${subCategory.name}`"
                  >{{ subCategory.name }}
                </label>
                <br />
              </div>
            </div>
          </div>
        </fieldset>
      </s-col>
    </s-row>
    <hr />
    <s-row>
      <s-col>
        <fieldset role="displayType" aria-labelledby="displayTypeLabel">
          <h3>Display</h3>
          <input
            type="radio"
            id="taxcodes"
            name="displayType"
            value="taxcodes"
            :checked="userDisplayType == 'taxcodes'"
            @click="display('taxcodes')"
          />
          <label for="taxcodes"
            >Only Tax codes
            <s-badge
              :count="taxcodesCount"
              aria-label="Number of Tax codes"
              role="img"
            ></s-badge>
          </label>
          <br />
          <input
            type="radio"
            id="group"
            name="displayType"
            value="group"
            :checked="userDisplayType == 'group'"
            @click="display('group')"
          />
          <label for="group"
            >Recommended Groupings
            <s-badge
              :count="groupCount"
              aria-label="Number of Groupings"
              role="img"
            ></s-badge>
          </label>
        </fieldset>
      </s-col>
    </s-row>
    <hr />
    <s-row>
      <s-col>
        <fieldset role="itemType" aria-labelledby="itemTypeLabel">
          <h3>Item Types</h3>
          <div
            v-for="treeType in treeTypesRender"
            :key="'treeType' + treeType.name"
          >
            <input
              type="checkbox"
              :id="`itemType_${treeType.name}`"
              name="itemType"
              :value="treeType.name"
              :checked="treeType.active"
              @click="filterTreeType(treeType.name)"
            />
            <label :for="`itemType_${treeType.name}`"
              >{{ treeType.name }}
              <s-badge
                :count="itemTypeCount(treeType)"
                aria-label="Number of Groupings"
                role="img"
              ></s-badge>
            </label>
            <br />
          </div>
        </fieldset>
      </s-col>
    </s-row>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Provide, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class SearchSideBar extends Vue {
  @Prop() private aggregatedCategories!: any;
  @Prop() private category!: string;
  @Prop() private subcategory!: string;
  @Prop() private leaf!: string;
  @Prop() private treeTypes!: any;
  @Prop() private displayType!: string;
  @Prop() private groupCount!: number;
  @Prop() private taxcodesCount!: number;

  private expanded = true;

  get userDisplayType() {
    return this.displayType || "taxcodes";
  }

  itemTypeCount(type: any) {
    if (this.displayType === "group") return type.treeCount;

    return type.count;
  }

  categoryCount(category: any) {
    if (this.displayType === "group") return category.trees.size;

    return category.count;
  }

  get treeTypesRender() {
    return Object.keys(this.treeTypes)
      .filter((t) => this.itemTypeCount(this.treeTypes[t]) > 0)
      .map((t, i) => {
        return {
          name: t,
          active: this.isTreeTypeSelected(t),
          count: this.treeTypes[t].count,
          treeCount: this.treeTypes[t].treeCount,
        };
      });
  }

  get categoryData() {
    if (!this.category) {
      return {
        "": {
          subcategories: Object.values(this.aggregatedCategories),
        },
      };
    } else if (!this.subcategory) {
      return {
        [this.category]: {
          subcategories: Object.values(
            this.aggregatedCategories[this.category].subcategories
          ),
        },
      };
    } else if (!this.leaf) {
      return {
        [this.subcategory]: {
          subcategories: Object.values(
            this.aggregatedCategories[this.category].subcategories[
              this.subcategory
            ].leaves
          ),
        },
      };
    } else {
      return {};
    }
  }

  header(category, categoryDetail) {
    if (
      category &&
      categoryDetail.subcategories.filter((s) => s.name).length > 0
    )
      return category;
    else if (category) return "";
    else return "Categories";
  }

  display(t) {
    const query = Object.assign({}, this.$route.query);
    query.displayType = t;

    this.$router.push({ query });
  }

  filter(c) {
    const query = Object.assign({}, this.$route.query);

    if (!this.category) query.category = c;
    else if (!this.subcategory) query.subcategory = c;
    else if (!this.leaf) query.leaf = c;

    this.$router.push({ query });
  }

  filterTreeType(treeType, e) {
    const query = Object.assign({}, this.$route.query);

    if (!query.treeTypes) {
      query.treeTypes = [treeType];
    } else if (query.treeTypes.includes(treeType)) {
      query.treeTypes = (query.treeTypes as []).filter(
        (t: string) => t !== treeType
      );
    } else {
      query.treeTypes = (query.treeTypes as []).concat(treeType);
    }

    this.$router.push({ query });
  }

  isTreeTypeSelected(treeType) {
    const query = this.$route.query;
    const treeTypes = query.treeTypes;

    if (!treeTypes) {
      return false;
    }

    return treeTypes.includes(treeType);
  }

  defilter() {
    const query = Object.assign({}, this.$route.query);
    if (this.leaf) delete query.leaf;
    else if (this.subcategory) delete query.subcategory;
    else if (this.category) delete query.category;

    this.$router.push({ query });
  }

  get backText() {
    if (this.leaf) return this.subcategory;
    if (this.subcategory) return this.category;
    if (this.category && this.$route.query.q) return "all categories";
    return "";
  }
}
</script>

<style scoped lang="scss">
h3 {
  margin-bottom: 0;
}
</style>
