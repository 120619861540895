export enum JobStatus {
  ClassificationComplete = "CLASSIFICATION COMPLETE",
  Created = "CREATED",
  Support = "SUPPORT",
  Failure = "FAILURE",
  Processing = "PROCESSING",
  Started = "STARTED",
  Success = "SUCCESS",
  PendingReview = "PENDING REVIEW",
  ReviewComplete = "REVIEW COMPLETE",
  InFeedback = "IN FEEDBACK",
  InitiatedFeedback = "INITIATED FEEDBACK"
}
