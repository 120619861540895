<template>
  <div>
    <h1 class="margin-bottom-none">
      {{
        jobStatus === JobStatus.Created || jobStatus === JobStatus.Support
          ? jobStatus === JobStatus.Created
            ? "Review Details"
            : "Classification support request submitted"
          : jobDetails.metadata
          ? jobDetails.metadata.jobName
          : ""
      }}
    </h1>
    <s-row v-show="errorMessageTop !== ''">
      <s-col>
        <s-alert nodismiss class="margin-top-sm" status="error">
          <div>
            {{ errorMessageTop }}
          </div>
        </s-alert>
      </s-col>
    </s-row>
    <div class="intro margin-bottom-xs" v-if="jobStatus === JobStatus.Created">
      Review the details once before starting the tax category classification.
    </div>
    <div class="intro margin-bottom-xs" v-else-if="jobStatus === JobStatus.Support">
      Classification request submitted for expert support with the following
      details. We will let you know once the finalized classification is ready.
    </div>
    <div
      class="job-error margin-bottom-xs margin-top-lg"
      v-if="jobStatus === JobStatus.Failure"
    >
      <s-alert nodismiss status="error" nodeismiss="">
        <div>
          The job you've created is failed due to internal server error. If you
          have any queries, reach out to our support team.
          <a href="https://help.avalara.com/Contact_Avalara" class="inline"
            >Contact Support</a
          >.
        </div>
      </s-alert>
    </div>
    <div
      v-if="jobStatus === JobStatus.Processing || jobStatus === JobStatus.Started"
      class="job-progress"
    >
      <s-row>
        <s-col span="12">
          <div class="margin-bottom-xs">
            Your job is running. After the job is completed, you can download
            the item classification.
          </div>
        </s-col>
      </s-row>
      <s-row>
        <s-col span="6">
          <div :class="['job-status', 'job-status-processing']">
            {{ jobStatus === JobStatus.Started ? JobStatus.Processing : jobStatus }}
          </div>
          <s-progress
            class="text-xs info medium"
            :displaytext="progressText"
            :valuenow="progress"
          ></s-progress>
        </s-col>
      </s-row>
    </div>
    <s-row class="name step-details margin-top-md">
      <s-col class="pad-bottom-none" span="4">
        <div class="vertical-align-items-center">
          <s-icon
            name="check-circle-filled"
            role="img"
            aria-label="check-circle-filled"
            class="medium margin-right-sm"
          ></s-icon>
          <h3 class="inline margin-bottom-none margin-top-none">Name</h3>
        </div>
        <div class="margin-top-sm margin-left-xl pad-left-xs">
          {{ jobDetails.metadata ? jobDetails.metadata.jobName : "" }}
        </div>
      </s-col>
      <s-col
        v-if="jobStatus === JobStatus.Created || isInternalExpertWithSupport()"
        span="1"
      >
        <a v-on:click="changeStep('0')" class="change-link text-underline">
          Change
        </a>
      </s-col>
    </s-row>
    <s-row class="tags step-details margin-top-md">
      <s-col class="pad-bottom-none" span="4">
        <div class="vertical-align-items-center">
          <s-icon
            name="check-circle-filled"
            role="img"
            aria-label="check-circle-filled"
            class="medium margin-right-sm"
          ></s-icon>
          <h3 class="inline margin-bottom-none margin-top-none">Tags</h3>
        </div>
        <div
          v-if="jobDetails.tags && jobDetails.tags.length > 0"
          class="margin-top-sm margin-left-xl pad-left-xs"
        >
          <s-tag
            v-for="tag in jobDetails.tags"
            nodismiss=""
            :id="tag"
            :key="tag"
            >{{ tag }}
          </s-tag>
        </div>
        <div v-else class="margin-top-sm margin-left-xl pad-left-xs">
          No tags added
        </div>
      </s-col>
      <s-col
        span="1"
        v-if="jobStatus === JobStatus.Created || isInternalExpertWithSupport()"
      >
        <a v-on:click="changeStep('0')" class="change-link text-underline">
          Change
        </a>
      </s-col>
    </s-row>
    <s-row class="file-upload step-details margin-top-md">
      <s-col class="pad-bottom-none" span="4">
        <div class="vertical-align-items-center">
          <s-icon
            name="check-circle-filled"
            role="img"
            aria-label="check-circle-filled"
            class="medium margin-right-sm"
          ></s-icon>
          <h3 class="inline margin-bottom-none margin-top-none">
            Uploaded file
          </h3>
        </div>
        <div
          class="vertical-align-items-center margin-top-sm margin-left-xl pad-left-xs"
        >
          <s-icon
            name="spreadsheet"
            role="img"
            aria-label="spreadsheet"
            class="margin-right-xs"
          ></s-icon>
          <span>{{ jobDetails.metadata.fileName }}</span>
        </div>
      </s-col>
      <s-col
        span="1"
        v-if="jobStatus === JobStatus.Created || isInternalExpertWithSupport()"
      >
        <a v-on:click="changeStep('0')" class="change-link text-underline">
          Change
        </a>
      </s-col>
    </s-row>
    <s-row
      v-if="jobDetails.metadata.isQuestionnaireDone == 'true'"
      class="company-questionnaire step-details margin-top-md"
    >
      <s-col class="pad-bottom-none" span="4">
        <div class="vertical-align-items-center">
          <s-icon
            name="check-circle-filled"
            role="img"
            aria-label="check-circle-filled"
            class="medium margin-right-sm"
          ></s-icon>
          <h3 class="inline margin-bottom-none margin-top-none">
            Company Questionnaire
          </h3>
        </div>
        <div class="mappings-arrow margin-top-sm margin-left-xl pad-left-xs">
          <div
            v-for="(question, index) in questionnaire.companyQuestions"
            :key="index"
          >
            <div
              class="vertical-align-items-center"
              v-if="showQuestion(question)"
            >
              <span>{{ question.question }}</span>
              <s-icon
                class="margin-left-xs margin-right-xs"
                name="arrow-right"
              ></s-icon>
              {{ answerFor(question) }}
            </div>
          </div>
        </div>
      </s-col>
      <s-col
        span="1"
        v-if="jobStatus === JobStatus.Created || isInternalExpertWithSupport()"
      >
        <a v-on:click="changeStep('1')" class="change-link text-underline">
          Change
        </a>
      </s-col>
    </s-row>
    <s-row
      v-if="
        jobDetails.metadata.isQuestionnaireDone == 'true' &&
          areJobQuestionsPresent(questionnaire.jobQuestions)
      "
      class="job-questionnaire step-details margin-top-md"
    >
      <s-col class="pad-bottom-none" span="4">
        <div class="vertical-align-items-center">
          <s-icon
            name="check-circle-filled"
            role="img"
            aria-label="check-circle-filled"
            class="medium margin-right-sm"
          ></s-icon>
          <h3 class="inline margin-bottom-none margin-top-none">
            Job Questionnaire
          </h3>
        </div>
        <div class="mappings-arrow margin-top-sm margin-left-xl pad-left-xs">
          <div
            v-for="(question, index) in questionnaire.jobQuestions"
            :key="index"
          >
            <div
              class="vertical-align-items-center question"
              v-if="showQuestion(question)"
            >
              {{
                question.question.length > 59
                  ? question.question.substring(0, 33) + "..."
                  : question.question
              }}
              <s-icon
                class="margin-left-xs margin-right-xs"
                name="arrow-right"
              ></s-icon>
              <span
                v-if="question.questionType == 'multiQuestion'"
                v-on:click="toggleMultiquestion()"
                class="view-link text-underline"
              >
                {{ showMultiquestion ? "Hide" : "View" }}
              </span>
              <span v-else>{{ answerFor(question) }}</span>
            </div>
            <div
              class="pad-bottom-lg"
              v-if="
                showQuestion(question) &&
                  showMultiquestion &&
                  question.questionType == 'multiQuestion'
              "
            >
              <div
                v-for="(option, index) in question.options"
                :key="index"
                class="mappings-arrow margin-top-sm margin-left-xl pad-left-xs"
              >
                <b>{{ option.question }}:</b> {{ option.answer }}
              </div>
            </div>
          </div>
        </div>
      </s-col>
      <s-col
        span="1"
        v-if="jobStatus === JobStatus.Created || isInternalExpertWithSupport()"
      >
        <a v-on:click="changeStep('1')" class="change-link text-underline">
          Change
        </a>
      </s-col>
    </s-row>
    <s-row
      v-if="
        jobDetails.metadata.curatedFileName != '' &&
          isInternalExpertWithSupport()
      "
      class="curated file-upload step-details margin-top-md"
    >
      <s-col class="pad-bottom-none" span="4">
        <div class="vertical-align-items-center">
          <s-icon
            name="check-circle-filled"
            role="img"
            aria-label="check-circle-filled"
            class="medium margin-right-sm"
          ></s-icon>
          <h3 class="inline margin-bottom-none margin-top-none">
            Uploaded curated file
          </h3>
        </div>
        <div
          class="vertical-align-items-center margin-top-sm margin-left-xl pad-left-xs"
        >
          <s-icon
            name="spreadsheet"
            role="img"
            aria-label="spreadsheet"
            class="margin-right-xs"
          ></s-icon>
          <span>{{ jobDetails.metadata.curatedFileName }}</span>
        </div>
      </s-col>
      <s-col
        span="1"
        v-if="jobStatus === JobStatus.Created || isInternalExpertWithSupport()"
      >
        <a v-on:click="changeStep('2')" class="change-link text-underline">
          Change
        </a>
      </s-col>
    </s-row>
    <s-row
      v-if="jobStatus !== JobStatus.Support || isInternalExpertWithSupport()"
      class="mappings step-details margin-top-md"
    >
      <s-col class="pad-bottom-none" span="4">
        <div class="vertical-align-items-center">
          <s-icon
            name="check-circle-filled"
            role="img"
            aria-label="check-circle-filled"
            class="medium margin-right-sm"
          ></s-icon>
          <h3 class="inline margin-bottom-none margin-top-none">
            Mapped columns
          </h3>
        </div>
        <div class="mappings-arrow margin-top-sm margin-left-xl pad-left-xs">
          <div
            class="vertical-align-items-center"
            v-for="k in fieldMappingKeys()"
            :key="k"
          >
            {{ k }}
            <s-icon
              class="margin-left-xs margin-right-xs"
              name="arrow-right"
            ></s-icon>
            {{ formatFieldMapping(jobDetails.fieldMapping[k]) }}
          </div>
        </div>
      </s-col>
      <s-col
        span="1"
        v-if="jobStatus === JobStatus.Created || isInternalExpertWithSupport()"
      >
        <a
          v-on:click="changeStep(jobStatus === JobStatus.Created ? '2' : '3')"
          class="change-link text-underline"
        >
          Change
        </a>
      </s-col>
    </s-row>
    <s-row
      v-if="jobStatus !== JobStatus.Support || isInternalExpertWithSupport()"
      class="category-selection step-details margin-top-md"
    >
      <s-col class="pad-bottom-none" span="4">
        <div class="vertical-align-items-center">
          <s-icon
            name="check-circle-filled"
            role="img"
            aria-label="check-circle-filled"
            class="medium margin-right-sm"
          ></s-icon>
          <h3 class="inline margin-bottom-none margin-top-none">
            Selected classification profile/categories
          </h3>
        </div>
        <div class="margin-top-sm margin-left-xl pad-left-xs">
          <div
            v-show="
              jobDetails.metadata.profileName &&
                jobDetails.metadata.profileName !== ''
            "
          >
            Classification profile:
            <b>{{ jobDetails.metadata.profileName }}</b>
          </div>
          <CategoryPathsViewer
            v-if="jobDetails.categories"
            :categoryPaths="jobDetails.categories"
          />
        </div>
      </s-col>
      <s-col
        span="1"
        v-if="jobStatus === JobStatus.Created || isInternalExpertWithSupport()"
      >
        <a
          v-on:click="changeStep(jobStatus === JobStatus.Created ? '3' : '4')"
          id="change-category"
          class="change-link text-underline"
        >
          Change
        </a>
      </s-col>
    </s-row>
    <s-row
      v-if="jobStatus === JobStatus.Created || isInternalExpertWithSupport()"
      class="start-job margin-top-lg pad-top-xs"
    >
      <s-col>
        <button @click="previous()" class="tertiary icon-leading">
          <s-icon name="arrow-left"></s-icon>Previous
        </button>
        <button @click="startJob()" class="primary icon-trailing" v-if="!trialLimitExceeded">
          Start<s-icon name="arrow-right"></s-icon>
        </button>
        <button @click="cancel()" class="ghost">Cancel</button>
      </s-col>
    </s-row>
    <s-row
      v-if="
        jobStatus === JobStatus.Success ||
          jobStatus === JobStatus.PendingReview ||
          jobStatus === JobStatus.ReviewComplete ||
          jobStatus === JobStatus.InFeedback ||
          jobStatus === JobStatus.InitiatedFeedback ||
          jobStatus === JobStatus.ClassificationComplete
      "
      class="download-job  margin-top-lg pad-top-xs"
    >
      <s-col>
        <button
          v-if="finalDownloadVisible()"
          @click="downloadOutput('output')"
          class="primary icon-leading margin-right-sm"
        >
          <s-icon name="download"></s-icon>
          Download final file
        </button>
        <button
          v-if="isMtcc && recommendationsDownloadVisible()"
          @click="downloadOutput('output')"
          class="primary icon-leading margin-right-sm"
        >
          <s-icon name="download"></s-icon>
          Download recommendations file
        </button>
        <button
          v-if="predictionDownloadVisible()"
          @click="downloadOutput('classificationoutput')"
          class="ghost icon-leading download-ghost"
        >
          <s-icon name="download"></s-icon> Download predictions file
        </button>
        <button
          v-if="
            jobStatus === JobStatus.ReviewComplete ||
              jobStatus === JobStatus.InFeedback ||
              jobStatus === JobStatus.InitiatedFeedback ||
              jobStatus === JobStatus.ClassificationComplete
          "
          @click="feedback()"
          class="ghost icon-leading download-ghost"
        >
          <s-icon name="compose"></s-icon> Feedback
        </button>
      </s-col>
    </s-row>
    <s-row
      v-if="
        jobStatus === JobStatus.Support &&
          $store.state.herculeRole != 'ClassificationInternalExpert'
      "
      class="start-job margin-top-lg pad-top-xs"
    >
      <s-col>
        <button @click="cancel()" class="primary icon-trailing">
          All classifications<s-icon name="arrow-right"></s-icon>
        </button>
      </s-col>
    </s-row>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Provide } from "vue-property-decorator";
import CategoryPathsViewer from "@/components/CategoryPathsViewer.vue";
import errorsUtil from "./../utils/errorMappings";
import _ from "lodash";
import { JobStatus } from "@/common/job";
import { FeatureLevel } from "@/common/privilege";
import trialUsage from "./../utils/trialUsageFetch";

@Component({
  components: { CategoryPathsViewer },
})
export default class BulkProgress extends Vue {
  @Provide("$api") $api: any;
  @Provide("$_") $_: any;

  @Prop() private accountId!: string;
  @Prop() private companyId!: string;
  @Prop() private jobId!: string;

  private jobDetails: any = {};
  private questionnaire: any = {};

  //updated on periodic status check calls
  private jobStatus = "";
  private progress = 0;
  private progressText = "";

  private fromJobsList = false;
  private downloadTriggered = false;
  private syncToItemManager = false;
  private loading = false;
  private errorMessageTop = "";
  private showMultiquestion = false;
  private trialLimitExceeded = false;

  JobStatus = JobStatus;

  mounted() {
    this.populateTrialLimitExceeded(this.$store.getters.featureLevel("item-classification"), this.$api);
    this.getJobDetails();
    this.getQuestionnaire();
  }

  toggleMultiquestion() {
    this.showMultiquestion = !this.showMultiquestion;
  }

  private displayError(error) {
    const errorCode = error.response.data.code;
    if (_.has(errorsUtil.errorMessages, errorCode)) {
      this.$store.commit("recordError", "");
      this.errorMessageTop = errorsUtil.errorMessages[errorCode];
    }
  }

  isInternalExpertWithSupport() {
    return (
      this.$store.state.herculeRole == "ClassificationInternalExpert" &&
      this.jobStatus == JobStatus.Support
    );
  }

  async populateTrialLimitExceeded(featureLevel, api) {
    if (featureLevel == FeatureLevel.AtccTrial) {
      const trialLimit = await trialUsage.populateTrialLimitExceeded(api, this.accountId, this.companyId);
      this.trialLimitExceeded = trialLimit.data.limitReached;
    }
  }

  async getTrialUsageFromAPI(api) {
    const trialLimit = api.get(
          `item-classification-api/v1/trialUsage?companyId=${this.companyId}&accountId=${this.accountId}`
        ); 
    return trialLimit;    
  }

  async feedback() {
    try {
      if (this.jobDetails.status === JobStatus.ReviewComplete || this.jobDetails.status === JobStatus.ClassificationComplete) {
        this.loading = true;
        this.$store.commit("startLoading");
        await this.$api.post(
          `item-classification-api/v1/jobs/${this.companyId}/${this.jobId}/feedback`
        );
        this.$store.commit("stopLoading");
      }
      this.$router.push({
        path: `/classification/${this.accountId}/${this.companyId}/${this.jobId}/feedback`,
      });
    } catch (error) {
      this.displayError(error);
      this.$store.commit("stopLoading");
      this.loading = false;
    }
  }

  getJobDetails() {
    this.$store.commit("startLoading");
    this.loading = true;
    this.$api
      .get(`item-classification-api/v1/jobs/${this.companyId}/${this.jobId}`)
      .then((response) => {
        this.jobDetails = response.data;
        this.jobStatus = this.jobDetails.status;
        if (
          (this.jobStatus === JobStatus.Processing || this.jobStatus === JobStatus.Started) &&
          this.jobDetails.totalRecords > 0
        ) {
          this.progress =
            (this.jobDetails.processedRecords / this.jobDetails.totalRecords) *
            100;
          this.progressText = `${this.jobDetails.processedRecords} out of ${this.jobDetails.totalRecords} items processed`;
          setTimeout(this.checkStatus, 5000);
        }
        this.$store.commit("stopLoading");
        this.loading = false;
      })
      .catch((error) => {
        this.$store.commit("stopLoading");
        this.loading = false;
        this.displayError(error);
      });
  }

  async getQuestionnaire() {
    try {
      const response = await this.$api.get(
        `item-classification-api/v1/jobs/${this.companyId}/${this.jobId}/questionnaire`
      );
      this.questionnaire = response.data;
    } catch (error) {
      this.$store.commit("stopLoading");
      this.loading = false;
      this.displayError(error);
    }
  }

  private areJobQuestionsPresent(questions: any[]) {
    return (
      questions &&
      questions.filter((question) => this.showQuestion(question)).length > 0
    );
  }

  private showQuestion(question: any) {
    const rules: any[] = question.rules;
    const herculeRole = this.$store.state.herculeRole;
    const featureLevel = this.$store.getters.featureLevel(
      "item-classification"
    );
    const basedOnRules = !rules
      ? true
      : rules.some(
          (rule) =>
            rule.featureLevel === featureLevel &&
            rule.roles.indexOf(herculeRole) >= 0
        );
    const basedOnSupport = !question.support
      ? true
      : question.support.toString() == this.jobDetails.metadata.support;
    return basedOnRules && basedOnSupport;
  }

  private answerFor(question: any) {
    switch (question.questionType) {
      case "singleArea":
        return (question?.answer as string).trim() === ""
          ? "None"
          : (question?.answer as string).trim();
      case "single":
        return (question?.answer as string).trim() === ""
          ? "None"
          : (question?.answer as string).trim();
      case "multiSelect":
        return (question?.answer as Array<string>).join(", ");
      case "date":
        return (question?.answer as string).trim() === ""
          ? "None"
          : (question?.answer as string).trim();
      case "singleSelect":
        return (question?.answer as string).trim();
    }
  }

  checkStatus() {
    this.$api
      .get(
        `item-classification-api/v1/jobs/${this.companyId}/${this.jobId}/status`
      )
      .then((response) => {
        this.jobStatus = response.data.status;
        const totalRecords = response.data.totalRecords;
        const processedRecords = response.data.processedRecords;
        this.$store.commit("stopLoading");
        if ((this.jobStatus === JobStatus.Processing || this.jobStatus === JobStatus.Started) && totalRecords > 0) {
          this.progress = (processedRecords / totalRecords) * 100;
          this.progressText = `${processedRecords} out of ${totalRecords} items processed`;
          setTimeout(this.checkStatus, 5000);
        }
      })
      .catch((error) => {
        this.$store.commit("stopLoading");
        this.loading = false;
        this.displayError(error);
      });
  }

  async startJob() {
    this.$store.commit("startLoading");
    this.$api
      .post(
        `item-classification-api/v1/jobs/${this.companyId}/${this.jobId}/start`,
        null,
        { params: { syncToItemManager: this.syncToItemManager } }
      )
      .then((response) => {
        this.getJobDetails();
        this.$emit("job-start");
        this.$store.commit("stopLoading");
        this.$store.commit("recordToast", "Job started successfully");
      })
      .catch((error) => {
        this.$store.commit("stopLoading");
        this.displayError(error);
      });
  }

  predictionDownloadVisible() {
    const predictionDownloadVisibleStatus = [
      JobStatus.ClassificationComplete,
      JobStatus.Success,
      JobStatus.PendingReview,
      JobStatus.ReviewComplete,
      JobStatus.InFeedback,
      JobStatus.InitiatedFeedback
    ];

    return predictionDownloadVisibleStatus.some(
      status => status === this.jobStatus
    );
  }

  finalDownloadVisible() {
    return this.jobStatus === JobStatus.Success;
  }

  get isMtcc() {
    return (
      this.$store.getters.featureLevel("item-classification") ===
        FeatureLevel.MtccFull ||
      this.$store.getters.featureLevel("item-classification") ===
        FeatureLevel.MtccLite
    );
  }

  recommendationsDownloadVisible() {
    const finalDownloadVisibleStatus = [
      JobStatus.InFeedback,
      JobStatus.InitiatedFeedback,
      JobStatus.ReviewComplete
    ];

    return finalDownloadVisibleStatus.some(status => status === this.jobStatus);
  }

  async downloadOutput(downloadType: string) {
    try {
      this.$store.commit("startLoading");
      const downloadResponse = await this.$api.get(
        `item-classification-api/v1/jobs/${this.companyId}/${this.jobId}/${downloadType}`,
        {
          params: {},
          responseType: "arraybuffer",
          headers: {
            Accept: "application/octet-stream",
          },
        }
      );

      const url = window.URL.createObjectURL(new Blob([downloadResponse.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "output.csv");
      document.body.appendChild(link);
      link.click();
      this.$store.commit("stopLoading");
    } catch (e) {
      this.$store.commit("stopLoading");
      this.loading = false;
      this.displayError(e);
    }
  }

  private fieldMappingKeys() {
    return this.jobDetails.fieldMapping
      ? Object.keys(this.jobDetails.fieldMapping)
      : [];
  }

  private formatFieldMapping(mapping) {
    if (mapping.fields.length == 1) {
      return mapping.fields[0];
    } else {
      return mapping.joinType == "concat"
        ? mapping.fields.join(mapping.delimiter) + " (concatenated)"
        : mapping.fields.join(" or ") + " (coalesced)";
    }
  }

  private changeStep(step) {
    (this.$parent as any).gotoStep(step);
  }

  private previous() {
    (this.$parent as any).previousStep();
  }

  private cancel() {
    this.$router.push({
      path: `/classification/${this.accountId}/${this.companyId}/jobs`,
    });
  }
}
</script>

<style scoped lang="scss">
button.ghost:hover {
  color: var(--color-blue-dark);
}
.intro {
  color: var(--color-gray-dark);
}
.vertical-align-items-center {
  display: flex;
  align-items: center;
}
.change-link {
  line-height: 30px;
}
.view-link {
  line-height: 30px;
  cursor: pointer;
}
.step-details {
  s-icon {
    color: var(--color-green-dark);
  }
}
.mappings-arrow {
  s-icon {
    color: var(--color-gray-darkest);
  }
}
.job-status {
  height: fit-content;
  width: fit-content;
  padding-left: 10px;
  padding-right: 10px;
  color: var(--color-gray-darkest);
  text-align: center;
  line-height: 30px;
  border-radius: 4px;
  border: 1px solid;
}
.job-status-processing {
  background: var(--color-yellow-lightest);
  border-color: var(--color-yellow-dark);
}
.download-ghost {
  color: var(--color-blue-dark);
  s-icon {
    color: var(--color-blue-dark);
  }
}
.question-text {
  min-width: 50px;
}
.question {
  word-break: break-all;
}
</style>
