<template>
  <div>
    <SearchBar
      v-bind:query="query"
      v-bind:category="category"
      v-bind:subcategory="subcategory"
      v-bind:leaf="leaf"
      v-bind:tab="tab"
    />
    <s-row>
      <s-col span="3" class="back-button">
        <router-link :to="backLink()"> View all categories </router-link>
      </s-col>
    </s-row>
    <s-row v-if="!searchLoading" class="results-header">
      <s-col v-if="searchResults.length > 0" span="12">
        <h2 class="accent--text">{{ resultsFor }}</h2>
      </s-col>
      <s-col v-if="searchResults.length === 0" span="12">
        <h2 class="no-results">
          Sorry, we couldn't find any results matching "{{ query }}"
        </h2>
      </s-col>
    </s-row>

    <template
      v-if="
        !searchLoading &&
        searchResults.length > 0 &&
        (!tab || tab == 'decision_tree')
      "
    >
      <s-row class="mb-5">
        <s-col span="3" class="divider-right">
          <SearchSideBar
            v-bind:category="category"
            v-bind:subcategory="subcategory"
            v-bind:leaf="leaf"
            v-bind:aggregatedCategories="aggregatedCategories"
            v-bind:treeTypes="treeTypesInResults"
            v-bind:displayType="displayType"
            v-bind:groupCount="groupedResults.size"
            v-bind:taxcodesCount="taxcodesInResults.length"
          />
        </s-col>
        <s-col span="9" v-if="displayType === 'group'">
          <s-row
            v-for="(gr, gindex) in $_.chunk(
              Array.from(groupedResults.values()),
              3
            )"
            v-bind:key="gr[0].name"
          >
            <s-col v-for="(r, rindex) in gr" v-bind:key="r.name" span="4">
              <SearchResultGroupedItem
                v-bind:item="r"
                v-bind:rank="gindex * 3 + rindex"
              />
            </s-col>
          </s-row>
        </s-col>
        <s-col span="9" v-else-if="!displayType || displayType === 'taxcodes'">
          <template v-if="taxcodeLookup().length > 0">
            <s-row
              v-for="tg in $_.chunk(taxcodeLookup(), 3)"
              v-bind:key="tg.code"
            >
              <s-col v-for="t in tg" v-bind:key="t.code" span="4">
                <SearchResultItem
                  v-bind:item="{ resultType: 'Taxcode', resultText: t.code }"
                />
              </s-col>
            </s-row>
            <template v-if="relatedTaxcodes.length > 0">
              <h2>Related Tax codes</h2>
            </template>
          </template>

          <s-row
            v-for="tg in $_.chunk(relatedTaxcodes, 3)"
            v-bind:key="tg.code"
          >
            <s-col v-for="t in tg" v-bind:key="t.code" span="4">
              <SearchResultItem
                v-bind:item="{ resultType: 'Taxcode', resultText: t.code }"
              />
            </s-col>
          </s-row>
          <s-row v-if="query">
            <s-col span="4">
              <SearchResultItem
                v-bind:item="{ resultType: 'Taxcode', resultText: 'P0000000' }"
              />
            </s-col>
          </s-row>
        </s-col>
      </s-row>
    </template>
    <template
      v-else-if="!searchLoading && Object.keys(taxcodesInResults).length > 0"
    >
      <template v-if="taxcodeLookup().length > 0">
        <s-row
          v-for="taxcode in taxcodeLookup()"
          v-bind:key="taxcode.code"
          class="mb-5"
        >
          <s-col span="9">
            <div class="card mx-auto">
              <div three-line>
                <div>
                  <div class="display-1 mb-4">{{ taxcode.code }}</div>
                  <div class="text--primary subtitle-1">
                    <b>Description:</b>
                    {{ taxcodeDetails(taxcode.code).description }}
                  </div>

                  <div
                    v-if="
                      taxcodeDetails(taxcode.code).additionalDescription &&
                      taxcodeDetails(taxcode.code).additionalDescription !=
                        taxcodeDetails(taxcode.code).description
                    "
                    class="mt-4 subtitle-2"
                  >
                    <span class="text--primary">
                      <b>Details:</b>
                    </span>
                    {{
                      taxcodeDetails(taxcode.code).additionalDescription.trim()
                    }}
                  </div>
                </div>
              </div>
            </div>
          </s-col>
        </s-row>
        <template v-if="relatedTaxcodes.length > 0">
          <s-row>
            <h2 class="related-header accent--text mr-4">Related Tax Codes</h2>
          </s-row>
        </template>
      </template>

      <s-row
        v-for="taxcode in relatedTaxcodes"
        v-bind:key="taxcode.code"
        class="mb-5"
      >
        <s-col span="9">
          <div class="card mx-auto">
            <div three-line>
              <div>
                <div class="display-1 mb-4">
                  {{ taxcode.code }}
                  <button
                    class="primary btn-use-this"
                    title="Use this taxcode"
                    v-if="widget"
                    v-on:click.prevent="onUseThis(taxcode.code)"
                  >
                    Use this
                  </button>
                </div>
                <div class="text--primary subtitle-1">
                  <b>Description:</b>
                  {{ taxcodeDetails(taxcode.code).description }}
                </div>

                <div
                  v-if="
                    taxcodeDetails(taxcode.code).additionalDescription &&
                    taxcodeDetails(taxcode.code).additionalDescription !=
                      taxcodeDetails(taxcode.code).description
                  "
                  class="mt-4 subtitle-2"
                >
                  <span class="text--primary">
                    <b>Details:</b>
                  </span>
                  {{
                    taxcodeDetails(taxcode.code).additionalDescription.trim()
                  }}
                </div>
              </div>
            </div>
          </div>
        </s-col>
      </s-row>
      <s-row v-if="query">
        <s-col span="4">
          <SearchResultItem
            v-bind:item="{ resultType: 'Taxcode', resultText: 'P0000000' }"
          />
        </s-col>
      </s-row>
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch, Provide } from "vue-property-decorator";
import SearchBar from "../components/SearchBar.vue";
import SearchResultGroupedItem from "@/components/SearchResultGroupedItem.vue";
import SearchResultItem from "@/components/SearchResultItem.vue";
import SearchSideBar from "@/components/SearchSideBar.vue";

import Debounce from "debounce-decorator";
import analyticsLayerUtil from "./../utils/index";

@Component({
  components: {
    SearchBar,
    SearchSideBar,
    SearchResultGroupedItem,
    SearchResultItem,
  },
})
export default class SearchCategory extends Vue {
  @Prop() private query!: string;
  @Prop() private category!: string;
  @Prop() private subcategory!: string;
  @Prop() private leaf!: string;
  @Prop() private tab!: string;
  @Prop() private treeTypes!: Array<string>;
  @Prop() private displayType!: string;

  private groupedResults = new Map();
  private aggregatedCategories = {};
  private treeTypesInResults = {};

  private drawer = false;
  private searchResults = [];
  private finalResults = new Map();
  private allTaxcodeResults = [];
  private taxcodesInResults = [];
  private taxcodesCount = 0;
  private userTab: string = this.tab || "decision_tree";

  private searchLoading = true;

  private productTaxcodes = ["P", "D"];
  private serviceTaxcodes = ["S"];
  private otherTaxcodes = ["O"];

  @Provide("$api") $api: any;
  @Provide("$_") $_: any;

  @Watch("query")
  @Watch("category")
  @Watch("subcategory")
  @Watch("leaf")
  @Watch("pageNumber")
  getResults() {
    this.$store.commit("startLoading");

    this.searchLoading = true;
    this.taxcodesInResults = [];
    this.searchResults = [];
    this.$api
      .get("api/get-trees-by-categories/v2/", {
        params: {
          categoryPath: this.categoryPath,
          searchTerm: this.query,
        },
      })
      .then((response: any) => {
        this.$api
          .get("api/get-taxcodes-by-categories/v3/", {
            params: {
              categoryPath: this.categoryPath,
              searchTerm: this.query,
            },
          })
          .then((result: any) => {
            this.allTaxcodeResults = result.data.search_results.filter(
              (t: any) => !t.is_related_taxcode
            );
            this.allTaxcodeResults.forEach((taxcode: any) => {
              this.$store.commit("setTaxcode", {
                code: taxcode.code,
                description: taxcode.description,
                additionalDescription: taxcode.additionalDescription,
                effectiveFrom: taxcode.effectiveFrom,
              });
            });
            this.searchResults = response.data;
            this.searchLoading = false;
            this.$store.commit("stopLoading");
          });
      });
  }

  treeClassification(taxcodes: Array<string>) {
    const taxcodeTypes = new Set(
      taxcodes.map((t) => {
        if (this.productTaxcodes.includes(t[0])) return "Goods";
        else if (this.serviceTaxcodes.includes(t[0])) return "Services";
        else return "Goods and services";
      })
    );

    if (taxcodeTypes.size === 1) {
      return taxcodeTypes.values().next().value;
    }

    return "Goods and services";
  }

  @Watch("searchResults")
  resultsForDisplay() {
    this.finalResults = new Map();
    const taxcodesProcessed = new Set();
    this.searchResults.forEach((x: any) => {
      let resultId,
        resultType,
        resultText,
        resultDetails,
        resultInclude = true;

      if ((x.type === "edge" && !x.interactive_node) || !x.name) {
        if (taxcodesProcessed.has(x.taxcodes[0])) resultInclude = false;

        resultId = x.taxcodes[0];
        resultType = "Taxcode";
        resultText = x.taxcodes[0];
        resultDetails = {
          treeId: x.tree_slug,
          tree: x.tree_name,
          treeTaxcodes: x.tree_taxcodes,
          possibleTaxcodes: [x.taxcodes[0]],
          categoryPaths: x.category_paths,
        };
      } else if (x.type === "edge") {
        if (
          (taxcodesProcessed.size > 0 &&
            Array.from(taxcodesProcessed.keys()).every((t) =>
              x.taxcodes.includes(t)
            )) ||
          x.taxcodes.every((t) => taxcodesProcessed.has(t))
        )
          resultInclude = false;

        resultId = x.interactive_node.id;
        resultType = "Decision";
        resultText = x.interactive_node.question;
        resultDetails = {
          decisionName: x.interactive_node.name,
          decisionId: x.interactive_node.id,
          possibleTaxcodes: x.taxcodes,
          tree: x.tree_name,
          treeTaxcodes: x.tree_taxcodes,
          treeId: x.tree_slug,
          categoryPaths: x.category_paths,
        };
      } else if (x.type === "decision") {
        if (
          (taxcodesProcessed.size > 0 &&
            Array.from(taxcodesProcessed.keys()).every((t) =>
              x.taxcodes.includes(t)
            )) ||
          x.taxcodes.every((t) => taxcodesProcessed.has(t))
        )
          resultInclude = false;

        resultId = x.interactive_node.id;
        resultType = "Decision";
        resultText = x.interactive_node.question;
        resultDetails = {
          decisionName: x.interactive_node.name,
          decisionId: x.interactive_node.id,
          possibleTaxcodes: x.taxcodes,
          tree: x.tree_name,
          treeTaxcodes: x.tree_taxcodes,
          treeId: x.tree_slug,
          categoryPaths: x.category_paths,
        };
      } else if (x.type === "tree") {
        if (
          (taxcodesProcessed.size > 0 &&
            Array.from(taxcodesProcessed.keys()).every((t) =>
              x.taxcodes.includes(t)
            )) ||
          x.taxcodes.every((t) => taxcodesProcessed.has(t))
        )
          resultInclude = false;

        resultId = x.tree_slug;
        resultType = "Tree";
        resultText = x.tree_name;
        resultDetails = {
          treeId: x.tree_slug,
          tree: x.tree_name,
          treeTaxcodes: x.tree_taxcodes,
          possibleTaxcodes: x.taxcodes,
          categoryPaths: x.category_paths,
        };
      } else {
        resultInclude = false;
      }

      if (this.finalResults.has(resultId) || !resultId) return;

      this.finalResults.set(resultId, {
        resultInclude,
        resultType,
        resultText,
        resultDetails,
      });
      if (resultInclude) {
        resultDetails.possibleTaxcodes.forEach(
          taxcodesProcessed.add,
          taxcodesProcessed
        );
      }
    });
  }

  @Watch("finalResults")
  @Watch("treeTypes")
  groupFinalResults() {
    this.groupedResults = new Map();
    this.aggregatedCategories = {};
    this.treeTypesInResults = {};

    this.finalResults.forEach((v: any, k: any) => {
      if (this.treeTypes && this.treeTypes.length > 0) {
        if (
          !this.treeTypes.includes(
            this.treeClassification(v.resultDetails.treeTaxcodes)
          )
        ) {
          return;
        }
      }

      if (this.groupedResults.has(v.resultDetails.treeId)) {
        const currentGroup = this.groupedResults.get(v.resultDetails.treeId);
        currentGroup.results.set(k, v);
        currentGroup.categoryPaths = new Set([
          ...currentGroup.categoryPaths,
          ...v.resultDetails.categoryPaths,
        ]);
      } else {
        this.groupedResults.set(v.resultDetails.treeId, {
          name: v.resultDetails.treeId,
          description: v.resultDetails.tree,
          taxcodes: v.resultDetails.treeTaxcodes,
          categoryPaths: new Set(v.resultDetails.categoryPaths),
          results: new Map().set(k, v),
        });
      }
    });

    this.taxcodesInResults = this.allTaxcodeResults.filter((t: any) => {
      return (
        !this.treeTypes ||
        this.treeTypes.length === 0 ||
        this.treeTypes.includes(this.treeClassification([t.code]))
      );
    });

    this.taxcodesInResults.forEach((t: any) => {
      const taxcodeClassification = this.treeClassification([t.code]);
      const existingTreeType = this.treeTypesInResults[
        taxcodeClassification
      ] || {
        count: 0,
        treeCount: 0,
      };
      existingTreeType.count += 1;
      this.treeTypesInResults[taxcodeClassification] = existingTreeType;
    });

    this.groupedResults.forEach((v: any, k: any) => {
      const treeClassification = this.treeClassification(v.taxcodes);
      const existingTreeType = this.treeTypesInResults[treeClassification] || {
        count: 0,
        treeCount: 0,
      };
      existingTreeType.treeCount += 1;
      this.treeTypesInResults[treeClassification] = existingTreeType;

      v.categoryPaths.forEach((c: string) => {
        const [categoryName, subCategoryName, leaf] = c.split(" > ");

        const existingCategory = this.aggregatedCategories[categoryName] || {
          name: categoryName,
          count: 0,
          trees: new Set(),
          subcategories: {},
        };

        const existingSubCategory = existingCategory.subcategories[
          subCategoryName
        ] || {
          name: subCategoryName,
          count: 0,
          trees: new Set(),
          leaves: {},
        };

        const existingLeaf = existingSubCategory.leaves[leaf] || {
          name: leaf,
          trees: new Set(),
          count: 0,
        };

        existingLeaf.count += 1;
        existingSubCategory.count += 1;
        existingCategory.count += 1;
        existingLeaf.trees.add(v.name);
        existingSubCategory.trees.add(v.name);
        existingCategory.trees.add(v.name);

        existingSubCategory.leaves[leaf] = existingLeaf;
        existingCategory.subcategories[subCategoryName] = existingSubCategory;
        this.aggregatedCategories[categoryName] = existingCategory;
      });
    });
  }

  taxcodeLookup() {
    if (!this.query) return [];
    return this.taxcodesInResults.filter((t: any) =>
      t.code.toLowerCase().startsWith(this.query.toLowerCase())
    );
  }

  get relatedTaxcodes() {
    if (!this.query) return this.taxcodesInResults;
    const filtered = this.taxcodesInResults.filter(
      (t: any) => !t.code.toLowerCase().startsWith(this.query.toLowerCase())
    );
    return filtered;
  }

  get widget() {
    return this.$store.state.widget;
  }

  onUseThis(code: any) {
    const taxcode: any = this.taxcodesInResults.find(
      (t: any) => t.code === code
    );
    const taxcodeDetail = {
      code: code,
      desc: taxcode.description,
      short_desc: taxcode.description,
    };
    window.parent.postMessage(JSON.stringify(taxcodeDetail), "*");
  }

  get resultsFor() {
    return (
      "Showing results " +
      (this.query ? " for " + this.query : "") +
      this.currentCategoryText()
    );
  }

  get categoryPath() {
    return [this.category, this.subcategory, this.leaf]
      .filter(function (x) {
        return x && x != "";
      })
      .join(" > ");
  }

  backLink() {
    const name = "home";
    return { name };
  }

  backText() {
    return "Return to home";
  }

  currentCategoryText() {
    return this.categoryPath ? " in " + this.categoryPath : "";
  }

  noResultsText() {
    return (
      "No results found" +
      (this.query ? " for " + this.query : "") +
      this.currentCategoryText()
    );
  }
  onSelectTree() {
    if (this.$store.getters.analyticsEnabled) {
      window._satellite.track("interactive_session");
    }
  }
  mounted() {
    this.getResults();
  }

  openDrawer() {
    this.drawer = true;
  }

  @Watch("searchLoading")
  @Debounce(1000)
  completeDataLayer() {
    if (this.searchLoading) return;
    let props: any = {};
    if (this.query && this.query.length !== 0) {
      props = {
        searchInfo: {
          searchTerm: this.query,
          searchCount:
            "" +
            (this.finalResults.size === 0 ? "Null" : this.finalResults.size),
        },
      };
    }
    analyticsLayerUtil.firePageLoad(props);
  }

  taxcodeDetails(code) {
    return this.$store.getters.taxcode(code);
  }
}
</script>

<style scoped lang="scss">
.v-toolbar__title a {
  color: white;
  text-decoration: none;
}
.results-header {
  margin-top: 5px;
  margin-bottom: 0px;
  s-col {
    margin-bottom: 0;
  }
}

.related-header {
  margin-bottom: 10px;
}

.treeType {
  height: 16px !important;
  cursor: default !important;
}

.treeType-Products,
.treeType-Products:hover {
  background: #e91e63 !important;
}
.treeType-Services,
.treeType-Services:hover {
  background: #009688 !important;
}
.treeType-Mixed,
.treeType-Mixed:hover {
  background: #ffc107 !important;
}
s-tabs {
  width: 100%;
  s-tab {
    a {
      color: var(--color-gray-dark);
      padding-bottom: 13px;
    }
  }
  s-tab[selected] {
    a {
      color: var(--color-blue-dark);
    }
  }
}
s-row a {
  width: 100%;
  text-decoration: none;
}
h2,
h3 {
  margin-top: 5px;
  margin-bottom: 5px;
}
s-col {
  padding-bottom: 0.8em;
}
s-col.back-button {
  padding-bottom: 0;
}
.no-results {
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 5rem;
}
</style>
