<template>
  <div>
    <s-row>
      <s-col span="3" class="back-button" v-if="Object.keys(back).length > 0">
        <router-link :to="back">
          <s-icon name="arrow-left-turn"></s-icon>Return to Search Results
        </router-link>
      </s-col>
    </s-row>
    <s-row>
      <s-col span="12">
        <TreeView
          v-bind:tree="tree"
          v-bind:decisionId="decisionId"
          v-bind:decisionName="decisionName"
          v-bind:tab="tab"
        />
      </s-col>
    </s-row>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import TreeView from "../components/TreeView.vue";
@Component({
  components: {
    TreeView,
  },
  beforeRouteEnter(to: any, from: any, next: any) {
    next((vm: any) => {
      if (from.name === "search_category") {
        vm.$store.state.backRoute = from;
      }
    });
  },
})
export default class Tree extends Vue {
  @Prop() private q!: string;
  @Prop() private rank!: string;
  @Prop() private tree!: string;
  @Prop() private decisionId!: string;
  @Prop() private decisionName!: string;
  @Prop() private tab!: string;

  private drawer = false;
  get back() {
    return this.$store.state.backRoute || {};
  }
}
</script>

<style scoped lang="scss">
.v-toolbar__title a {
  color: white;
  text-decoration: none;
}
.back-button__row {
  margin-top: 10px;
}
</style>
