<template>
  <div v-if="step.question">
    <div class="card mx-auto" max-width="100%" outlined>
      <div class="three-line">
        <div class="item-content">
          <div style="white-space: normal;" class="headline mb-1">
            {{ step.question }}
          </div>
        </div>
      </div>

      <div
        v-for="parameter in step.parameters"
        v-bind:key="parameter.parameterName"
        class="actions"
      >
        <template v-if="buttonOrDropdown(parameter) == 'button'">
          <button
            v-for="(answer, buttonIndex) in parameter.possibleValues"
            v-bind:key="answer.value"
            v-bind:data-cy="'stepId-' + id + ' option-' + buttonIndex"
            v-bind:data-cy-opt="'stepId-' + id"
            v-on:click="
              response(
                parameter.parameterName,
                answer.value,
                step,
                id,
                $event,
                answer.displayName
              )
            "
            outlined
            v-bind:class="
              chosenAnswers[parameter.parameterName] === answer.value
                ? 'primary--text'
                : 'tertiary'
            "
          >
            {{ answer.displayName }}
          </button>
        </template>
        <template v-else>
          <form>
            <label>
              {{ parameter.parameterName }}
              <select
                v-on:change="
                  response(
                    parameter.parameterName,
                    $event.target.value,
                    step,
                    id,
                    $event
                  )
                "
              >
                <option value disabled selected>Select an option</option>
                <option
                  v-for="item in parameter.possibleValues"
                  v-bind:value="item.value"
                  :key="item.value"
                  >{{ item.displayName }}</option
                >
              </select>
            </label>
          </form>
        </template>
      </div>
    </div>
  </div>

  <div v-else>
    <div class="card" outlined>
      <div three-line>
        <div>
          <div class="overline mb-4">RESULT</div>
          <div
            data-cy="decision-tree-final-result"
            class="headline mb-1 decision-tree-final-result"
          >
            {{ step.name }}
          </div>
          <div
            class="new-taxcode text-xs font-italic font-bold"
            v-if="
              effectiveFrom(step.name) && isNewTaxcode(effectiveFrom(step.name))
            "
          >
            {{ newTaxcodeText(effectiveFrom(step.name)) }}
          </div>
          <div>Description: {{ taxCodes[step.name] }}</div>
        </div>
      </div>

      <div>
        <button v-on:click="restart" class="secondary red--text" outlined text>
          Restart
        </button>
        <button
          class="primary"
          title="Use this taxcode"
          v-if="widget"
          v-on:click.prevent="onUseThis(step)"
        >
          Use this
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Debounce from "debounce-decorator";
import { Component, Prop, Vue } from "vue-property-decorator";
import dayjs from "dayjs";

@Component
export default class Step extends Vue {
  @Prop() private stepResult!: any;
  @Prop() private id!: number;
  @Prop() private taxCodes!: any;

  private stepData!: any;
  private chosenAnswers = {};
  private rangeAnswer: any = null;
  get widget() {
    return this.$store.state.widget;
  }
  restart() {
    (this.$parent as any).restart();
  }

  @Debounce(650)
  response(
    parameter: string,
    answer: any,
    r: any,
    id: number,
    event: any,
    displayName?: any
  ) {
    this.$set(this.chosenAnswers, parameter, answer);
    if (Object.keys(this.chosenAnswers).length == this.step.parameters.length) {
      (this.$parent as any).getNext(r, this.chosenAnswers, id, displayName);
    }
  }

  buttonOrDropdown(parameter: any) {
    if (parameter.possibleValues.length > 3) {
      return "dropdown";
    }

    if (
      parameter.possibleValues.map((x) => x.displayName).join(" ").length > 75
    ) {
      return "dropdown";
    }

    return "button";
  }

  get parameterNames() {
    return this.step.parameters.map((p: any) => p.parameterName).join(", ");
  }

  get step() {
    let type = "result";
    let name = this.stepResult.value;

    if (!name) {
      type = "question";
      name = this.stepResult.name;
      const question = this.stepResult.question;
      const parameters = this.stepResult.parameters.map((p: any) => {
        return {
          answerType: p.type,
          parameterName: p.name,
          possibleValues: p.metadata.possibleValues,
          range: p.metadata.range,
        };
      });
      const decisionId = this.stepResult.decisionId;

      this.stepData = {
        type,
        question,
        name,
        decisionId,
        parameters,
      };
    } else {
      this.stepData = { type, name };
    }
    return this.stepData;
  }
  onUseThis(step: any) {
    const taxcodeDetail = {
      code: step.name,
      desc: this.taxCodes[step.name],
      short_desc: this.taxCodes[step.name],
    };
    window.parent.postMessage(JSON.stringify(taxcodeDetail), "*");
  }

  private newTaxcodeText(date) {
    return date.endsWith("Z")
      ? "New tax code! Code will be active from " +
          dayjs(date.substr(0, date.length - 1)).format("D MMM, YYYY")
      : "";
  }

  private isNewTaxcode(date) {
    return dayjs(date).isAfter(dayjs(new Date()));
  }

  effectiveFrom(taxcode: string) {
    return this.$store.getters.taxcode(taxcode)?.effectiveFrom;
  }
}
</script>

<style scoped lang="scss">
.card.mx-auto {
  margin-bottom: 20px;
}
.item-content {
  padding: 5px 0;
}
.actions {
  padding-bottom: 8px;
  button {
    border: thin solid;
    margin-top: 10px;
    margin-right: 0.75rem;
  }
  & button + button {
    margin-left: 0;
  }
  & select {
    padding: 0rem 0.35rem;
  }
}
.decision-tree-final-result {
  margin-bottom: 5px;
}
.primary--text,
button:focus {
  color: #059bd2;
  box-shadow: none;
  background-color: white;
}
.red--text {
  margin-top: 1rem;
}
.new-taxcode {
  color: var(--color-blue-dark);
}
</style>
