var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.$_.isEmpty(_vm.item))?_c('router-link',{class:['card-link', 'item', _vm.isTaxcodesDisplay ? 'item-taxcodes' : ''],attrs:{"to":_vm.itemLink}},[_c('s-box',{staticClass:"margin-top-xs",on:_vm._d({},[_vm.taxCodeClick,function($event){$event.preventDefault();return _vm.taxCodeClickHandler.apply(null, arguments)}])},[(_vm.item.resultType == 'Taxcode')?_c('div',{staticClass:"taxcode"},[_c('div',{class:[
          _vm.isNewTaxcode(_vm.taxcodeDetails.effectiveFrom)
            ? 'margin-bottom-none'
            : '',
          'text-sm',
          'text-light',
        ]},[_vm._v(" "+_vm._s(_vm.item.resultText)+" "),(!_vm.widget)?_c('span',[_c('s-icon',{staticClass:"text-sm",attrs:{"name":"copy","role":"img","aria-label":"copy"}})],1):_vm._e()]),(
          _vm.taxcodeDetails.effectiveFrom &&
          _vm.isNewTaxcode(_vm.taxcodeDetails.effectiveFrom)
        )?_c('div',{staticClass:"new-taxcode text-xs font-italic font-bold"},[_vm._v(" "+_vm._s(_vm.newTaxcodeText(_vm.taxcodeDetails.effectiveFrom))+" ")]):_vm._e(),_c('div',{staticClass:"description text-md text-dark"},[_vm._v(" "+_vm._s(_vm.taxcodeDetails.description)+" ")]),(!_vm.$_.isEmpty(_vm.taxcodeDetails.additionalDescription))?_c('div',{staticClass:"additional-description text-sm pad-top-xs text-light"},[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm.taxcodeDetails.additionalDescription,_vm.truncateLength - _vm.taxcodeDetails.description.length))+" ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"use-button"},[(_vm.widget)?_c('button',{staticClass:"primary right",attrs:{"title":"Use this taxcode"},on:{"click":function($event){$event.preventDefault();return _vm.onUseThis(_vm.item.resultText)}}},[_vm._v(" Use this ")]):_vm._e()]),(_vm.item.resultType == 'Decision')?_c('div',{staticClass:"decision"},[_c('h3',[_vm._v(_vm._s(_vm.item.resultText))]),_c('s-icon',{staticClass:"right right-icon",attrs:{"name":"chevron-right","role":"img","aria-label":"click"}})],1):_vm._e()])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }