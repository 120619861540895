<template>
  <div class="margin-top-sm fs-exclude">
    <CompaniesSwitcher
      v-bind:currentId="companyId"
      v-on:company-switched="companySwitched"
      v-if="this.$store.getters.companies.length > 0"
    />
    <a
      href="https://help.avalara.com/z_AvaWeb/Customer_Learning/UKL_Internal/Jaime's_Sandbox/Tax_category_classification"
      class="helper flex align-items-center right"
    >
      <s-icon name="info-circle" class="text-sm margin-right-xs"></s-icon
      ><span class="text-xs-strong">Need help?</span>
    </a>
    <s-dialog
      class="delete-dialog"
      id="delete_dialog"
      aria-labelledby="title"
      aria-modal="true"
      ref="profile-delete"
      noscroll=""
    >
      <div slot="header" id="title">Delete classification profile</div>
      <div slot="body">
        Are you sure you want to delete "{{ profileToDelete.profileName }}"
        classification profile?
      </div>
      <div slot="footer">
        <button class="secondary small" @click="closeDeleteDialog()">
          Cancel
        </button>
        <button class="primary small" @click="deleteProfile()">Delete</button>
      </div>
    </s-dialog>
    <s-dialog
      class="set-default-dialog"
      id="default_dialog"
      aria-labelledby="title"
      aria-modal="true"
      ref="set-default-profile"
      noscroll=""
      @s-dismiss="closeDefaultDialog()"
    >
      <div slot="header" id="title">Set default profile</div>
      <div slot="body">
        <div v-if="!profileToDefault.isOutdated">
          Are you sure you want to set "{{ profileToDefault.profileName }}" as
          your default profile?
        </div>
        <div v-else>
          Cannot select this profile as default, please edit this profile or
          select a profile not impacted by category changes.
        </div>
      </div>
      <div slot="footer">
        <div class="set-default" v-if="!profileToDefault.isOutdated">
          <button class="secondary small" @click="closeDefaultDialog()">
            No
          </button>
          <button class="primary small" @click="setDefaultProfile()">
            Yes
          </button>
        </div>
        <div v-else>
          <button class="primary small" @click="closeDefaultDialog()">
            Ok
          </button>
        </div>
      </div>
    </s-dialog>
    <h1 class="margin-bottom-none">Classification profiles</h1>
    <s-row v-show="errorMessageTop !== ''">
      <s-col>
        <s-alert nodismiss class="margin-top-sm" status="error">
          <div>
            {{ errorMessageTop }}
          </div>
        </s-alert>
      </s-col>
    </s-row>
    <div class="profiles-intro">
      Create or edit profiles based on your tax category classification
      requirements.
    </div>
    <s-row v-if="paginationTotalRecords > 0" class="pad-top-md">
      <s-col span="4" class="pad-bottom-sm">
        <s-input-extended
          v-if="false"
          type="search"
          inputid="search"
        ></s-input-extended>
      </s-col>
      <s-col offset="6" span="2" class="pad-bottom-sm">
        <h3 class="margin-top-none margin-bottom-none">
          <router-link
            class="right"
            :to="{
              name: 'bulk_profile_new',
              params: { companyId, accountId },
            }"
          >
            <a>
              <button class="primary">
                New profile
              </button>
            </a>
          </router-link>
        </h3>
      </s-col>
    </s-row>
    <s-row v-if="!loading">
      <s-col v-if="paginationTotalRecords > 0">
        <fieldset role="default_profile" :key="profilesData">
          <s-table-container class="margin-bottom-xl" tabindex="0">
            <table class="row-height-sm">
              <thead class="table-header">
                <tr>
                  <th
                    :class="[
                      'name-header',
                      currentSortField === profileNameField ? 'active' : '',
                    ]"
                    @click="onSortChange(profileNameField)"
                  >
                    <div class="flex align-items-center margin-left-xl">
                      Name<s-icon
                        :name="sortIconType(profileNameField)"
                        role="img"
                        aria-label="arrow-up"
                        class="margin-left-xs"
                      ></s-icon>
                    </div>
                  </th>
                  <th class="category-header">Categories/Sub-categories</th>
                  <th
                    :class="[
                      'created-at-header',
                      currentSortField === 'createdAt' ? 'active' : '',
                    ]"
                    @click="onSortChange('createdAt')"
                  >
                    <div class="flex align-items-center">
                      Created on<s-icon
                        :name="sortIconType('createdAt')"
                        role="img"
                        aria-label="arrow-down"
                        class="margin-left-xs"
                      ></s-icon>
                    </div>
                  </th>
                  <th>
                    <div class="flex align-items-center">
                      Default
                      <div class="tooltip-wrap info-icon">
                        <s-icon
                          class="margin-left-sm"
                          name="help-circle-filled"
                          role="img"
                          aria-label="account alert"
                        ></s-icon>
                        <div class="tooltip-content pad-all-sm text-xs">
                          The default profile is displayed first in the list.<br />
                          You can't delete the default classification profile.
                        </div>
                      </div>
                    </div>
                  </th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr class="default-profile">
                  <td>
                    <div class="flex align-items-center">
                      <div
                        v-if="defaultProfile.isOutdated"
                        class="tooltip-wrap warning-icon"
                      >
                        <s-icon
                          name="alert-triangle-filled"
                          role="img"
                          aria-label="account alert"
                        ></s-icon>
                        <div class="tooltip-content pad-all-sm text-xs">
                          This profile could have been impacted by category
                          changes, please edit to ensure accurate predictions.
                        </div>
                      </div>
                      <div
                        :class="[
                          defaultProfile.isOutdated
                            ? 'margin-left-sm'
                            : 'margin-left-xl',
                        ]"
                      >
                        {{ defaultProfile.profileName }}
                      </div>
                    </div>
                  </td>
                  <td>
                    <CategoryPathsViewer
                      :categoryPaths="defaultProfile.categories"
                    />
                  </td>
                  <td>{{ formatDate(defaultProfile.createdAt) }}</td>
                  <td>
                    <input
                      type="radio"
                      :id="`default_${defaultProfile.profileName}`"
                      :key="defaultProfile.name"
                      name="default_profile"
                      :value="`${defaultProfile.profileName}`"
                      :checked="defaultProfile.isDefault"
                    />
                    <label
                      class="margin-top-none"
                      :for="`default_${defaultProfile.profileName}`"
                      >Use as default</label
                    >
                  </td>
                  <td>
                    <span
                      :id="`edit_${defaultProfile.profileName}`"
                      class="edit inline-block"
                    >
                      <router-link
                        :to="{
                          name: 'bulk_profile_edit',
                          params: {
                            companyId,
                            accountId,
                            profileId: defaultProfile.profileId,
                          },
                        }"
                      >
                        <a>
                          <button class="primary icon small" aria-label="close">
                            <s-icon name="edit-pencil"></s-icon>
                          </button>
                        </a>
                      </router-link>
                    </span>
                  </td>
                </tr>
                <tr v-for="profile in profilesData" :key="profile.name">
                  <td>
                    <div class="flex align-items-center">
                      <div
                        v-if="profile.isOutdated"
                        class="tooltip-wrap warning-icon"
                      >
                        <s-icon
                          name="alert-triangle-filled"
                          role="img"
                          aria-label="account alert"
                        ></s-icon>
                        <div class="tooltip-content pad-all-sm text-xs">
                          This profile could have been impacted by category
                          changes, please edit to ensure accurate predictions.
                        </div>
                      </div>
                      <div
                        :class="[
                          profile.isOutdated
                            ? 'margin-left-sm'
                            : 'margin-left-xl',
                        ]"
                      >
                        {{ profile.profileName }}
                      </div>
                    </div>
                  </td>
                  <td>
                    <CategoryPathsViewer :categoryPaths="profile.categories" />
                  </td>
                  <td>{{ formatDate(profile.createdAt) }}</td>
                  <td>
                    <input
                      type="radio"
                      :id="`default_${profile.profileName}`"
                      :key="profile.name"
                      name="default_profile"
                      :value="`${profile.profileName}`"
                      :checked="profile.isDefault"
                      @click="(e) => openDefaultDialog(e, profile)"
                    />
                    <label
                      class="margin-top-none"
                      :for="`default_${profile.profileName}`"
                      >Use as default</label
                    >
                  </td>
                  <td>
                    <span
                      :id="`edit_${profile.profileName}`"
                      class="edit inline-block"
                    >
                      <router-link
                        :to="{
                          name: 'bulk_profile_edit',
                          params: {
                            companyId,
                            accountId,
                            profileId: profile.profileId,
                          },
                        }"
                      >
                        <a>
                          <button class="primary icon small" aria-label="close">
                            <s-icon name="edit-pencil"></s-icon>
                          </button>
                        </a>
                      </router-link>
                    </span>
                    <span :id="`delete_${profile.profileName}`" class="delete">
                      <button
                        class="primary icon small"
                        aria-label="close"
                        @click="(e) => openDeleteDialog(e, profile)"
                      >
                        <s-icon name="trash"></s-icon>
                      </button>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </s-table-container>
        </fieldset>
      </s-col>
      <s-col v-else>
        <div class="pad-top-xl margin-top-xl">
          <img
            :src="`${$store.getters.assetsPath}/img/error.png`"
            class="block margin-right-auto margin-left-auto margin-top-xl"
          />
          <p
            class="text-center margin-top-xs margin-bottom-xs text-md font-semibold"
          >
            You haven't created any classification profiles yet!
          </p>
          <p class="text-center margin-top-xs profiles-intro">
            Get started by creating a new classification profile that you can
            re-use in the future for similar products.
          </p>
          <h3 class="margin-top-lg">
            <router-link
              :to="{
                name: 'bulk_profile_new',
                params: { companyId, accountId },
              }"
            >
              <a class="margin-left-auto margin-right-auto">
                <button class="primary">
                  New profile
                </button>
              </a>
            </router-link>
          </h3>
        </div>
      </s-col>
    </s-row>
    <div class="pad-bottom-xl" v-show="paginationTotalRecords > 0 && !loading">
      <s-pagination
        :startindex="paginationStartIndex()"
        :rowsperpage="paginationRowsPerPage"
        :totalrecords="paginationTotalRecords"
        @s-paginate="onPageChange"
      ></s-pagination>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Provide, Watch } from "vue-property-decorator";
import CompaniesSwitcher from "@/components/CompaniesSwitcher.vue";
import CategoryPathsViewer from "@/components/CategoryPathsViewer.vue";
import dayjs from "dayjs";
import errorsUtil from "./../utils/errorMappings";
import _ from "lodash";

interface Profile {
  accountId: string;
  companyId: string;
  categories: string[];
  createdBy: string;
  createdAt: string;
  deletedAt: string;
  isDefault: boolean;
  profileId: string;
  profileName: string;
  updatedBy: string;
  updatedAt: string;
}

@Component({
  components: {
    CompaniesSwitcher,
    CategoryPathsViewer,
  },
})
export default class BulkProfileList extends Vue {
  @Provide("$api") $api: any;
  @Provide("$_") $_: any;

  @Prop() private companyId!: string;
  @Prop() private accountId!: string;
  @Prop({ default: () => 1 }) private page!: number;

  private from = "";

  private pageNumber = this.page;
  private paginationRowsPerPage = 20;
  private paginationTotalRecords = 0;

  private profilesData: Profile[] = [];
  private profileToDelete = {};
  private profileToDefault = {};
  private defaultProfile = {};
  private loading = true;
  private currentSortField = "createdAt";
  private sortTypeASC = false;
  private profileNameField = "profileName";
  private errorMessageTop = "";

  mounted() {
    this.getProfiles();
  }

  private displayError(error) {
    const errorCode = error.response.data.code;
    if (_.has(errorsUtil.errorMessages, errorCode)) {
      this.$store.commit("recordError", "");
      this.errorMessageTop = errorsUtil.errorMessages[errorCode];
    }
  }

  @Watch("companyId")
  @Watch("currentSortField")
  @Watch("sortTypeASC")
  async getProfiles() {
    this.$store.commit("startLoading");
    this.loading = true;
    try {
      const response = await this.$api.get(
        `item-classification-api/v1/profiles/${this.companyId}/default`
      );
      this.defaultProfile = response.data;
    } catch (error) {
      if (error.response.status === 404) {
        this.$store.commit("recordError", "");
      } else {
        this.$store.commit("stopLoading");
        this.displayError(error);
        return;
      }
    }
    this.$store.commit("startLoading");
    const skip = this.paginationStartIndex();
    const top = this.paginationRowsPerPage;
    const orderBy =
      this.currentSortField + (this.sortTypeASC ? " ASC" : " DESC");
    this.$api
      .get(
        `item-classification-api/v1/profiles/${this.companyId}?skip=${skip}&top=${top}&orderBy=${orderBy}`
      )
      .then((response) => {
        this.paginationTotalRecords = response.data.recordsetCount;
        const profiles: Profile[] = response.data.value;
        this.profilesData = profiles.filter((p) => !p.isDefault) || [];
        this.$store.commit("stopLoading");
        this.loading = false;
      })
      .catch((error) => {
        this.$store.commit("stopLoading");
        this.loading = false;
        this.displayError(error);
      });
  }

  deleteProfile() {
    this.closeDeleteDialog();
    this.$store.commit("startLoading");
    this.$api
      .delete(
        `item-classification-api/v1/profiles/${this.companyId}/${
          (this.profileToDelete as Profile).profileId
        }`
      )
      .then((response) => {
        this.$store.commit("stopLoading");
        this.getProfiles();
        this.$store.commit(
          "recordToast",
          `"${(this.profileToDelete as Profile).profileName}" was deleted.`
        );
      })
      .catch((error) => {
        this.$store.commit("stopLoading");
        this.displayError(error);
      });
  }

  setDefaultProfile() {
    (this.$refs["set-default-profile"] as any).close();
    const profile = this.profileToDefault as Profile;
    this.$store.commit("startLoading");
    this.$api
      .put(
        `item-classification-api/v1/profiles/${this.companyId}/default`,
        null,
        { params: { profileId: profile.profileId } }
      )
      .then((response) => {
        this.$store.commit("stopLoading");
        this.getProfiles();
        this.$store.commit(
          "recordToast",
          `"${
            (this.profileToDefault as Profile).profileName
          }" successfully set as the default classification profile.`
        );
      })
      .catch((error) => {
        this.$store.commit("stopLoading");
        this.displayError(error);
      });
  }

  companySwitched(event) {
    const params = Object.assign({}, this.$route.params, {
      companyId: event.companyId,
    });

    this.$router.push({ name: "profile_list", params: params });
  }

  async onPageChange(e: any) {
    const hasPageChanged =
      e.detail.currentPage !== 0 && e.detail.currentPage !== this.pageNumber;
    const hasRowsPerPageChanged =
      e.detail.rowsPerPage !== this.paginationRowsPerPage;
    if (hasPageChanged) {
      this.pageNumber = e.detail.currentPage;
      const query = Object.assign({}, this.$route.query);
      query.page = e.detail.currentPage;
      this.$router.push({ query });
    }
    this.paginationRowsPerPage = e.detail.rowsPerPage;
    if (hasPageChanged || hasRowsPerPageChanged) this.getProfiles();
  }

  private paginationStartIndex() {
    return (this.pageNumber - 1) * this.paginationRowsPerPage;
  }

  private openDeleteDialog(e, profile) {
    this.profileToDelete = profile;
    (this.$refs["profile-delete"] as any).showModal();
  }

  private openDefaultDialog(e, profile) {
    this.profileToDefault = profile;
    (this.$refs["set-default-profile"] as any).showModal();
  }

  private closeDeleteDialog() {
    (this.$refs["profile-delete"] as any).close();
  }

  private closeDefaultDialog() {
    (this.$refs["set-default-profile"] as any).close();
    this.getProfiles();
  }

  private formatDate(date) {
    return dayjs(date).format("YYYY/MM/DD");
  }

  private onSortChange(field) {
    if (this.currentSortField === field) this.sortTypeASC = !this.sortTypeASC;
    else {
      this.currentSortField = field;
      this.sortTypeASC = false;
    }
  }

  sortIconType(field) {
    if (this.currentSortField === field)
      return this.sortTypeASC ? "arrow-up" : "arrow-down";
    return "arrow-down";
  }
}
</script>

<style scoped lang="scss">
a:visited {
  color: var(--color-blue-dark);
}
.profiles-intro {
  color: var(--color-gray-dark);
}
.new-profile {
  a:hover {
    color: white;
  }
}
.table-header {
  tr {
    background-color: var(--color-gray-lightest);
  }
}

.default-profile {
  td {
    background-color: var(--color-gray-lightest);
  }
}

.edit {
  button.primary {
    color: var(--color-blue-dark);
    background-color: var(--color-blue-lightest);
    border-color: var(--color-blue-dark);
  }
}
.delete-dialog {
  button.primary {
    background-color: var(--color-red-dark);
  }
}
.delete {
  button.primary {
    color: var(--color-red-dark);
    background-color: var(--color-red-lightest);
    border-color: var(--color-red-dark);
  }
}
.tooltip-wrap {
  display: initial;
  line-height: normal;
}
.tooltip-wrap .tooltip-content {
  display: none;
  position: absolute;
  background-color: var(--color-gray-lightest);
  z-index: 1;
  max-width: 300px;
  min-width: 250px;
  font-weight: normal;
}
.tooltip-wrap:hover .tooltip-content {
  display: initial;
}
.info-icon {
  s-icon {
    color: var(--color-blue-dark);
  }
}
.warning-icon {
  s-icon {
    color: var(--color-yellow-dark);
  }
}
.created-at-header {
  width: 15%;
  cursor: pointer;
  s-icon {
    visibility: hidden;
  }
}
.created-at-header.active {
  s-icon {
    visibility: visible;
  }
}
.created-at-header:hover {
  s-icon {
    visibility: visible;
  }
}
.name-header {
  width: 20%;
  cursor: pointer;
  s-icon {
    visibility: hidden;
  }
}
.name-header.active {
  s-icon {
    visibility: visible;
  }
}
.name-header:hover {
  s-icon {
    visibility: visible;
  }
}
.category-header {
  width: 40%;
}
</style>
