var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('router-link',{staticClass:"card-link",attrs:{"to":{
    name: 'tree',
    query: {
      tree: _vm.item.name,
      tab: 'interactive',
      q: _vm.$route.query.q,
      rank: _vm.rank,
    },
  }}},[_c('s-box',{class:[
      'card',
      'mx-auto',
      'decision-interactive',
      _vm.isSearch ? 'decision-interactive-search' : '',
    ],attrs:{"data-cy":_vm.item.name + '-decision-interactive'},on:{"click":_vm.onSelectTree}},[_c('div',{staticClass:"headline mb-1"},[_vm._v(_vm._s(_vm.item.description))]),_c('div',{staticClass:"badges"},[_c('s-badge',{attrs:{"count":_vm.treeClassification(_vm.item.taxcodes)}}),_c('s-badge',{staticClass:"taxcodes-badge",attrs:{"count":_vm.item.taxcodes.length + ' Tax Codes'}})],1),_c('SearchResultItem',{attrs:{"item":_vm.highlightedResult(),"rank":_vm.rank}}),_c('h4',{staticClass:"group-view"},[_vm._v(" View grouping "),_c('span',[_c('s-icon',{staticClass:"right right-icon",attrs:{"name":"chevron-right","role":"img","aria-label":"click"}})],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }