var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.treeNotFound)?_c('div',[_c('s-row',[_c('s-col',{staticClass:"no-bm",attrs:{"span":"12"}},[_c('h2',{staticClass:"results-header accent--text"},[_vm._v(" Interactive session for "+_vm._s(_vm.description)+" ")])])],1),_c('s-row',[_c('s-col',{attrs:{"span":"6"}},_vm._l((_vm.steps),function(step,i){return _c('Step',{key:step.name || step.value,attrs:{"taxCodes":_vm.taxcodesDict,"stepResult":step,"id":i}})}),1),_c('s-col',{attrs:{"span":"6"}},[_c('s-row',[_c('s-col',{attrs:{"span":"12"}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"title"},[_vm._v(" Tax Codes "),(Object.keys(_vm.taxCodesLeft).length > 0)?_c('s-badge',{staticClass:"green lighten-2 white--text ml-3 taxcodes-badge",attrs:{"count":Object.keys(_vm.taxCodesLeft).length +
                  (Object.keys(_vm.taxCodesLeft).length > 1
                    ? ' Tax codes'
                    : ' Tax code') +
                  ' remaining',"data-cy":"remaining-taxcodes","label":""}}):_vm._e()],1),_c('div',{staticClass:"simple-table"},[[_c('table',[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Code")]),_c('th',{staticClass:"text-left"},[_vm._v("Description")]),_c('th',{staticClass:"text-left"})])]),_c('tbody',_vm._l((_vm.taxcodesDict),function(description,code){return _c('tr',{key:code,class:_vm.taxCodesLeft[code] === true
                          ? ['green', 'lighten-3']
                          : [],attrs:{"data-cy":_vm.taxCodesLeft[code] === true ? 'higlithed-tax-row' : ''}},[_c('td',[_vm._v(_vm._s(code))]),_c('td',[_c('div',[_vm._v(" "+_vm._s(description)+" "),(
                              _vm.effectiveFrom(code) &&
                              _vm.isNewTaxcode(_vm.effectiveFrom(code))
                            )?_c('div',{staticClass:"new-taxcode text-xs font-italic font-bold"},[_vm._v(" "+_vm._s(_vm.newTaxcodeText(_vm.effectiveFrom(code)))+" ")]):_vm._e()])]),_c('td',[(_vm.widget)?_c('button',{staticClass:"primary",attrs:{"title":"Use this taxcode"},on:{"click":function($event){$event.preventDefault();return _vm.onUseThis(code, description)}}},[_vm._v(" Use this ")]):_vm._e()])])}),0)])]],2)])])],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }