import store from "../store";

export default {
    onUseThis(code: string) {
        const taxCodeDetailsFromStore = store.getters.taxcode(code)
        const taxcodeDetail = {
            code: taxCodeDetailsFromStore.code,
            desc: taxCodeDetailsFromStore.description,
            short_desc: taxCodeDetailsFromStore.description
        };
        window.parent.postMessage(JSON.stringify(taxcodeDetail), "*");
    }
}