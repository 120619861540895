import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import SearchCategory from "../views/SearchCategory.vue";
import Tree from "../views/Tree.vue";
import BulkJobCreate from "../views/BulkJobCreate.vue";
import BulkJobList from "../views/BulkJobList.vue";
import BulkFeedback from "../views/BulkFeedback.vue";
import BulkProfileCreate from "../views/BulkProfileCreate.vue";
import BulkProfileUpdate from "../views/BulkProfileUpdate.vue";
import BulkProfileList from "../views/BulkProfileList.vue";
import BulkSchema from "../views/BulkSchema.vue";
import analyticsLayerUtil from "./../utils/index";
import TaxabilitySearch from "../views/TaxabilitySearch.vue";
import Taxability from "../views/Taxability.vue";
import TaxabilityAvatax from "../views/TaxabilityAvatax.vue";
import SupportSwitchAccount from "../views/SupportSwitchAccount.vue";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      name: "Home",
      category: "Home",
      navigateCommit: true,
      navLabel: "Home",
    },
  },
  {
    path: "/search",
    name: "search_category",
    meta: { name: "Search", navigateCommit: false },
    component: SearchCategory,
    props: (route: any) => ({
      query: route.query.q,
      category: route.query.category,
      subcategory: route.query.subcategory,
      leaf: route.query.leaf,
      pageNumber: route.query.p,
      tab: route.query.tab,
      treeTypes: route.query.treeTypes,
      displayType: route.query.displayType,
    }),
  },
  {
    path: "/tree",
    name: "tree",
    components: {
      default: Tree,
    },
    meta: { name: "Interactive", navigateCommit: false },
    props: {
      default: (route: any) => ({
        q: route.query.q,
        rank: route.query.rank,
        tree: route.query.tree,
        decisionId: route.query.decisionId,
        decisionName: route.query.decisionName,
        tab: route.query.tab,
      }),
    },
  },
  {
    path: "/classification/:accountId/:companyId/jobs",
    name: "bulk_job_list",
    components: {
      default: BulkJobList,
    },
    meta: {
      name: "Bulk Jobs List",
      navigateCommit: false,
      requiresAuth: true,
      navLabel: "Tax category classification",
    },
    props: {
      default: (route: any) => ({
        accountId: route.params.accountId,
        companyId: route.params.companyId,
        page: route.query.page,
      }),
    },
  },
  {
    path: "/classification/:accountId/:companyId/jobs/create",
    name: "bulk_job",
    components: {
      default: BulkJobCreate,
    },
    meta: {
      name: "Bulk Job Create",
      navigateCommit: false,
      requiresAuth: true,
      navLabel: "Tax category classification",
    },
    props: {
      default: (route: any) => ({
        accountId: route.params.accountId,
        companyId: route.params.companyId,
        jobId: route.query.jobId,
        step: route.query.step,
      }),
    },
  },
  {
    path: "/classification/:accountId/:companyId/:jobId/feedback",
    name: "job_feedback",
    components: {
      default: BulkFeedback,
    },
    meta: {
      name: "Bulk job Feedback",
      navigateCommit: false,
      requiresAuth: true,
      navLabel: "Tax category classification",
    },
    props: {
      default: (route: any) => ({
        accountId: route.params.accountId,
        companyId: route.params.companyId,
        jobId: route.params.jobId,
        page: route.query.page,
      }),
    },
  },
  {
    path: "/classification/:accountId/:companyId/profiles",
    name: "profile_list",
    components: {
      default: BulkProfileList,
    },
    meta: {
      name: "Bulk Profiles List",
      navigateCommit: false,
      requiresAuth: true,
      navLabel: "Tax category classification",
    },
    props: {
      default: (route: any) => ({
        accountId: route.params.accountId,
        companyId: route.params.companyId,
        page: route.query.page,
      }),
    },
  },
  {
    path: "/classification/:accountId/:companyId/schemas",
    name: "schema_list",
    components: {
      default: BulkSchema,
    },
    meta: {
      name: "Bulk Schemas List",
      navigateCommit: false,
      requiresAuth: true,
      navLabel: "Tax category classification",
    },
    props: {
      default: (route: any) => ({
        accountId: route.params.accountId,
        companyId: route.params.companyId,
      }),
    },
  },
  {
    path: "/classification/:accountId/:companyId/profiles/new",
    name: "bulk_profile_new",
    components: {
      default: BulkProfileCreate,
    },
    meta: {
      name: "Bulk Profile Creation",
      navigateCommit: false,
      requiresAuth: true,
      navLabel: "Tax category classification",
    },
    props: {
      default: (route: any) => ({
        accountId: route.params.accountId,
        companyId: route.params.companyId,
      }),
    },
  },
  {
    path: "/classification/:accountId/:companyId/profiles/:profileId/edit",
    name: "bulk_profile_edit",
    components: {
      default: BulkProfileUpdate,
    },
    meta: {
      name: "Bulk Profile Update",
      navigateCommit: false,
      requiresAuth: true,
      navLabel: "Tax category classification",
    },
    props: {
      default: (route: any) => ({
        accountId: route.params.accountId,
        companyId: route.params.companyId,
        profileId: route.params.profileId,
      }),
    },
  },
  {
    path: "/taxability",
    name: "taxability",
    components: {
      default: Taxability,
    },
    meta: {
      name: "Taxability",
      navigateCommit: true,
      navLabel: "Taxability Matrix",
    },
  },
  {
    path: "/taxability/search",
    name: "taxability_search",
    components: {
      default: TaxabilitySearch,
    },
    meta: {
      name: "Taxability Search",
      navigateCommit: false,
      navLabel: "Taxability Matrix",
    },
    props: {
      default: (route: any) => ({
        selectedTaxCodes: route.query.taxcodes,
        selectedJurisdictions: route.query.jurisdictions,
        selectedRegion: route.query.region,
      }),
    },
  },
  {
    path: "/taxability/:companyId",
    name: "taxability_avatax",
    redirect: "/taxability",
    components: {
      default: TaxabilityAvatax,
    },
    meta: {
      name: "Taxability Avatax",
      navigateCommit: false,
      navLabel: "Taxability Matrix",
    },
    props: {
      default: (route: any) => ({
        companyId: route.params.companyId,
      }),
    },
  },
  {
    path: "/support/switchaccount",
    name: "switch_account",
    meta: { name: "Switch Account", navigateCommit: false },
    component: SupportSwitchAccount,
  },
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
});

router.afterEach((to, from) => {
  to.matched.map((record) => {
    const user = (window as any).user;
    const accountID =
      user && user.customerAvataxAccountId ? user.customerAvataxAccountId : "";
    const isLoggedIn = user && user.isLoggedIn ? "YES" : "NO";
    const isAvalaraUser =
      user && user.email && (user.email as string).endsWith("@avalara.com")
        ? "YES"
        : "NO";
    const { subcategory } = to.query;
    const category = to.query.category || record.meta.category;
    analyticsLayerUtil.generateBaseDataLayer({
      name: record.meta.name,
      category,
      subcategory,
      accountID,
      isLoggedIn,
      isAvalaraUser,
    });
    if (record.meta.navigateCommit) analyticsLayerUtil.firePageLoad();
  });
});

router.beforeEach((to, from, next) => {
  store.commit("recordError", "");
  next();
});

// router.beforeEach((to, from, next) => {

//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     if (store.state.user && store.state.user.isLoggedIn) {
//       next()
//     } else {
//       next({
//         path: '/auth/login',
//         // query: { returnUrl: `http://localhost:8080${to.fullPath}` }
//       })
//     }
//   } else {
//     next()
//   }
// })

export default router;
