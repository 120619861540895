<template>
  <div class="margin-top-sm fs-exclude">
    <CompaniesSwitcher
      v-bind:currentId="companyId"
      v-on:company-switched="companySwitched"
      v-if="this.$store.getters.companies.length > 0"
    />
    <a
      href="https://help.avalara.com/z_AvaWeb/Customer_Learning/UKL_Internal/Jaime's_Sandbox/Tax_category_classification"
      class="helper flex align-items-center right"
    >
      <s-icon name="info-circle" class="text-sm margin-right-xs"></s-icon
      ><span class="text-xs-strong">Need help?</span>
    </a>
    <h1 class="margin-bottom-none">Tax category classification<sup v-if="getFeatureLevelFromStore() == 'atcc-trial'" style="color:var(--color-orange-medium);font-size:60%;">Free Trial</sup></h1>
    <div class="jobs-intro">
      Create or edit classifications based on your items. <span class="jobs-intro" v-if="(getFeatureLevelFromStore() == 'atcc-trial') && trialLimitFetched">{{trialLimitUsed}} records used out of {{trialLimit}}</span> 
    </div>
    <s-row v-show="errorMessage !== ''">
      <s-col>
        <s-alert nodismiss class="margin-top-sm" status="error">
          <div>
            {{ errorMessage }}
          </div>
        </s-alert>
      </s-col>
    </s-row>
    <s-row v-if="jobsList.length > 0" class="pad-top-md">
      <s-col span="4">
        <s-input-extended
          v-if="false"
          type="search"
          inputid="search"
        ></s-input-extended>
      </s-col>
      <s-col offset="5" span="3">
        <h3 class="margin-top-none margin-bottom-none">
          <router-link
            class="right"
            :to="{
              name: 'bulk_job',
              params: { companyId, accountId },
            }"
          >
            <a>
              <button class="primary" v-if="!trialLimitExceeded">
                New classification
              </button>
            </a>
          </router-link>
          <button
            @click="populateJobsList"
            class="ghost icon-leading right refresh margin-right-sm"
          >
            <s-icon name="refresh"></s-icon>
            Refresh data
          </button>
        </h3>
      </s-col>
    </s-row>
    <s-row v-if="!loading">
      <s-col v-if="jobsList.length > 0">
        <s-table-container class="margin-bottom-sm" tabindex="0">
          <table class="row-height-sm">
            <thead class="table-header">
              <tr>
                <th
                  :class="[
                    'name-header',
                    currentSortField === jobNameField ? 'active' : '',
                  ]"
                  @click="onSortChange(jobNameField)"
                >
                  <div class="flex align-items-center">
                    Name<s-icon
                      :name="sortIconType(jobNameField)"
                      role="img"
                      aria-label="arrow-up"
                      class="margin-left-xs"
                    ></s-icon>
                  </div>
                </th>
                <th class="category-header">Categories/Sub-categories</th>
                <th>Tags</th>
                <th class="user-header">Created by</th>
                <th
                  :class="[
                    'created-at-header',
                    currentSortField === 'createdAt' ? 'active' : '',
                  ]"
                  @click="onSortChange('createdAt')"
                >
                  <div class="flex align-items-center">
                    Created on<s-icon
                      :name="sortIconType('createdAt')"
                      role="img"
                      aria-label="arrow-down"
                      class="margin-left-xs"
                    ></s-icon>
                  </div>
                </th>
                <th class="status-header">Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr class="job-link" v-for="j in jobsList" v-bind:key="j.job_id">
                <td>
                  <div v-if="j.metadata.jobName">
                    <router-link :to="jobLink(j)">
                      {{ jobName(j) }}
                    </router-link>
                  </div>
                  <div v-else>N/A</div>
                </td>
                <td>
                  <div v-if="j.categories && j.categories.length > 0">
                    <CategoryPathsViewer :categoryPaths="j.categories" />
                  </div>
                  <div v-else class="not-available pad-left-lg">
                    N/A
                  </div>
                </td>
                <td>
                  <div v-if="j.tags && jobTags(j).length > 0">
                    <s-tag
                      class="margin-top-xs"
                      v-for="tag in jobTags(j)"
                      nodismiss=""
                      :id="tag"
                      :key="tag"
                      >{{ tag }}</s-tag
                    >
                  </div>
                  <div v-else class="not-available pad-left-sm">N/A</div>
                </td>
                <td>
                  <p
                    :data-letters="
                      jobUser(j).length > 1
                        ? jobUser(j)
                            .substring(0, 2)
                            .toUpperCase()
                        : 'US'
                    "
                  >
                    {{ jobUser(j) }}
                  </p>
                </td>
                <td>
                  {{ formatDate(j.createdAt) }}
                </td>
                <td>
                  <div
                    :id="`status_${jobName(j)}`"
                    :class="[
                      'job-status',
                      'job-status-' +
                        jobStatus(j)
                          .toLowerCase()
                          .replace(' ', '-'),
                      'margin-top-xs',
                    ]"
                  >
                    {{ jobStatus(j) }}
                  </div>
                  <s-progress
                    class="text-xs info"
                    :displaytext="
                      `${j.processedRecords} out of ${j.totalRecords} items processed`
                    "
                    :valuenow="jobProgress(j)"
                  ></s-progress>
                </td>
                <td class="download">
                  <button
                    :id="`download_${jobName(j)}`"
                    v-if="
                      j.status === JobStatus.Success ||
                        j.status === JobStatus.PendingReview ||
                        j.status === JobStatus.ReviewComplete ||
                        j.status === JobStatus.InFeedback ||
                        j.status === JobStatus.InitiatedFeedback ||
                        j.status === JobStatus.ClassificationComplete
                    "
                    class="primary icon small"
                    aria-label="close"
                    @click="downloadOutput(j)"
                  >
                    <s-icon name="download"></s-icon>
                  </button>
                  <div class="not-available pad-left-xs" v-else>N/A</div>
                </td>
              </tr>
            </tbody>
          </table>
        </s-table-container>
      </s-col>
      <s-col v-else>
        <div class="pad-top-xl margin-top-xl">
          <img
            :src="`${$store.getters.assetsPath}/img/placeholder.png`"
            class="block margin-right-auto margin-left-auto margin-top-xl"
          />
          <p
            class="text-center margin-top-xs margin-bottom-xs text-md font-semibold"
          >
            You haven't created any tax category classifications yet!
          </p>
          <p class="text-center margin-top-xs jobs-intro">
            Get started by creating a new classification for your items.
          </p>
          <h3 class="margin-top-lg">
            <router-link
              :to="{
                name: 'bulk_job',
                params: { companyId, accountId },
              }"
            >
              <a class="margin-left-auto margin-right-auto">
                <button class="primary">
                  New classification
                </button>
              </a>
            </router-link>
          </h3>
        </div>
      </s-col>
    </s-row>
    <div
      class="margin-bottom-xl"
      v-show="paginationTotalRecords > 0 && !loading"
    >
      <s-pagination
        :startindex="paginationStartIndex()"
        :rowsperpage="paginationRowsPerPage"
        :totalrecords="paginationTotalRecords"
        @s-paginate="onPageChange"
      ></s-pagination>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Provide, Watch } from "vue-property-decorator";
import CompaniesSwitcher from "@/components/CompaniesSwitcher.vue";
import CategoryPathsViewer from "@/components/CategoryPathsViewer.vue";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import duration from "dayjs/plugin/duration";
import errorsUtil from "./../utils/errorMappings";
import _ from "lodash";
import { FeatureLevel } from "@/common/privilege";
import { JobStatus } from "@/common/job";
import trialUsage from "./../utils/trialUsageFetch";
dayjs.extend(duration);
dayjs.extend(relativeTime);

@Component({
  components: {
    CompaniesSwitcher,
    CategoryPathsViewer,
  },
})
export default class BulkJobList extends Vue {
  @Provide("$api") $api: any;
  @Provide("$_") $_: any;

  @Prop() private companyId!: string;
  @Prop() private accountId!: string;
  @Prop({ default: () => 1 }) private page!: number;

  private pageNumber = this.page;
  private jobsList = [];
  private loading = true;
  private trialLimitExceeded = false;
  private trialLimit = 500;
  private trialLimitFetched = false;
  private trialLimitUsed = 0;
  private paginationRowsPerPage = 20;
  private paginationTotalRecords = 0;
  private currentSortField = "createdAt";
  private sortTypeASC = false;
  private jobNameField = "metadata->>'jobName'";
  private errorMessage = "";

  private stepsByRoles = {
    customerOrRepWithSupport: [
      "Upload product catalog",
      "Fill Questionnaire",
      "Review and create",
    ],
    customerOrRepWithoutSupport: [
      "Upload product catalog",
      "Fill Questionnaire",
      "Map columns",
      "Select profile/categories",
      "Review and create",
    ],
    expert: [
      "Upload product catalog",
      "Fill Questionnaire",
      "Upload curated catalog",
      "Map columns",
      "Select profile/categories",
      "Review and create",
    ],
  };

  JobStatus = JobStatus;

  async beforeMount() {
    await this.populateTrialLimitExceeded(this.$store.getters.featureLevel("item-classification"), this.$api);
  }
  mounted() {
    this.populateJobsList();
  }

  async populateTrialLimitExceeded(featureLevel, api) {
    if (featureLevel == FeatureLevel.AtccTrial) {
      const trialLimit = await trialUsage.populateTrialLimitExceeded(api, this.accountId, this.companyId);
      this.trialLimitExceeded = trialLimit.data.limitReached;
      this.trialLimit = trialLimit.data.limit;
      this.trialLimitUsed = trialLimit.data.recordsProcessed;
      this.trialLimitFetched = true;
    }
  }

  @Watch("currentSortField")
  @Watch("sortTypeASC")
  @Watch("companyId")
  async populateJobsList() {
    this.loading = true;
    this.$store.commit("startLoading");
    try {
      const skip = this.paginationStartIndex();
      const top = this.paginationRowsPerPage;
      const orderBy =
        this.currentSortField + (this.sortTypeASC ? " ASC" : " DESC");
      const jobsListResponse = await this.$api.get(
        `item-classification-api/v1/jobs/${this.companyId}?skip=${skip}&top=${top}&orderBy=${orderBy}`
      );
      this.paginationTotalRecords = jobsListResponse.data.recordsetCount;
      this.jobsList = jobsListResponse.data.value;
      this.$store.commit("stopLoading");
      this.loading = false;
    } catch (error) {
      const errorCode = error.response.data.code;
      if (_.has(errorsUtil.errorMessages, errorCode)) {
        this.$store.commit("recordError", "");
        this.errorMessage = errorsUtil.errorMessages[errorCode];
      }
      this.$store.commit("stopLoading");
      this.loading = false;
    }
  }

  private paginationStartIndex() {
    return (this.pageNumber - 1) * this.paginationRowsPerPage;
  }

  private onPageChange(e: any) {
    const hasPageChanged =
      e.detail.currentPage !== 0 && e.detail.currentPage !== this.pageNumber;
    const hasRowsPerPageChanged =
      e.detail.rowsPerPage !== this.paginationRowsPerPage;
    if (hasPageChanged) {
      this.pageNumber = e.detail.currentPage;
      const query = Object.assign({}, this.$route.query);
      query.page = e.detail.currentPage;
      this.$router.push({ query });
    }
    this.paginationRowsPerPage = e.detail.rowsPerPage;
    if (hasPageChanged || hasRowsPerPageChanged) this.populateJobsList();
  }

  jobStatus(job) {
    return this.$_.isEmpty(job.status)
      ? "CREATED"
      : job.status === "STARTED"
      ? "PROCESSING"
      : job.status;
  }

  getSteps(jobDetails) {
    if (this.$store.state.herculeRole === "ClassificationInternalExpert") {
      return this.stepsByRoles["expert"];
    } else {
      const isFullClassificationAdmin =
        this.$store.state.herculeRole === "ClassificationAdmin" &&
        (this.$store.getters.featureLevel("item-classification") ===
          FeatureLevel.MtccFull ||
          this.$store.getters.featureLevel("item-classification") ===
            FeatureLevel.AtccFull);
      if (isFullClassificationAdmin) {
        return jobDetails.metadata.support &&
          jobDetails.metadata.support == "true"
          ? this.stepsByRoles["customerOrRepWithSupport"]
          : this.stepsByRoles["customerOrRepWithoutSupport"];
      } else {
        return this.stepsByRoles["customerOrRepWithSupport"];
      }
    }
  }

  getFeatureLevelFromStore() {
    return this.$store.getters.featureLevel("item-classification")
  }

  jobLink(job) {
    const categoryStepCompleted = job.categories && job.categories.length > 0;
    const fieldMappingStepCompleted =
      job.metadata.field_mapping_source &&
      job.metadata.field_mapping_source === "user" &&
      Object.keys(job.fieldMapping).length > 0;
    const curatedUploadStepCompleted = "curatedFileName" in job.metadata;
    const questionnaireStepCompleted = job.metadata.isQuestionnaireDone == "true";
    const uploadStepCompleted =
      job.metadata.fileName && job.metadata.fileName !== "";
    const steps = this.getSteps(job);
    const conditionByStepName = {
      "Upload product catalog": uploadStepCompleted,
      "Fill Questionnaire": questionnaireStepCompleted,
      "Upload curated catalog": curatedUploadStepCompleted,
      "Map columns": fieldMappingStepCompleted,
      "Select profile/categories": categoryStepCompleted,
      "Review and create": false,
    };
    let step = 0;
    for (step = 0; step <= steps.length - 1; step++) {
      const stepName = steps[step];
      if (!conditionByStepName[stepName]) break;
    }

    return {
      name: "bulk_job",
      params: {
        accountId: this.accountId,
        companyId: this.companyId,
      },
      query: {
        jobId: job.jobId,
        step,
      },
    };
  }

  jobTags(job) {
    return job.tags.map((tag) =>
      tag.length > 20 ? (tag as string).substring(0, 18) + "..." : tag
    );
  }

  jobName(job) {
    return job.metadata.jobName
      ? job.metadata.jobName
          .split(" ")
          .map((word) =>
            word.length > 40 ? (word as string).substring(0, 37) + "..." : word
          )
          .join(" ")
      : "";
  }

  jobUser(job) {
    return job.userName.length > 35
      ? job.userName.substring(0, 33) + "..."
      : job.userName;
  }

  async downloadOutput(job) {
    try {
      this.$store.commit("startLoading");
      const downloadLocation =
        job.status !== "PENDING REVIEW" ? "output" : "classificationoutput";
      const downloadResponse = await this.$api.get(
        `item-classification-api/v1/jobs/${this.companyId}/${job.jobId}/${downloadLocation}`,
        {
          params: {},
          responseType: "arraybuffer",
          headers: {
            Accept: "application/octet-stream",
          },
        }
      );
      const url = window.URL.createObjectURL(new Blob([downloadResponse.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "output.csv");
      document.body.appendChild(link);
      link.click();
      this.$store.commit("stopLoading");
    } catch (error) {
      const errorCode = error.response.data.code;
      if (_.has(errorsUtil.errorMessages, errorCode)) {
        this.$store.commit("recordError", "");
        this.errorMessage = errorsUtil.errorMessages[errorCode];
      }
      this.$store.commit("stopLoading");
    }
  }

  private formatDate(date) {
    return dayjs(date).format("YYYY/MM/DD");
  }

  isNewJobAllowed() {
    return (
      this.getFeatureLevelFromStore() !== "disabled"
    );
  }

  jobProgress(job) {
    return 100 * (job.processedRecords / job.totalRecords);
  }

  companyName(id) {
    return this.$store.getters.companyName(id);
  }

  async companySwitched(event) {
    const params = Object.assign({}, this.$route.params, {
      companyId: event.companyId,
    });

    this.$router.push({ name: "bulk_job_list", params: params });
  }

  onSortChange(field) {
    if (this.currentSortField === field) this.sortTypeASC = !this.sortTypeASC;
    else {
      this.currentSortField = field;
      this.sortTypeASC = false;
    }
  }

  sortIconType(field) {
    if (this.currentSortField === field)
      return this.sortTypeASC ? "arrow-up" : "arrow-down";
    return "arrow-down";
  }
}
</script>

<style scoped lang="scss">
a {
  text-decoration-line: none;
}
a:visited {
  color: var(--color-blue-dark);
}

a:hover {
  color: var(--color-blue-dark);
}

.refresh {
  color: var(--color-blue-dark);
  s-icon {
    color: var(--color-blue-dark);
  }
}

button.ghost:hover {
  color: var(--color-blue-dark);
}

.job-status {
  height: fit-content;
  width: fit-content;
  padding-left: 10px;
  padding-right: 10px;
  color: var(--color-gray-darkest);
  text-align: center;
  line-height: 30px;
  border-radius: 4px;
  border: 1px solid;
}

.job-status-not-started,
.job-status-created {
  background: var(--color-gray-lightest);
  border-color: var(--color-gray-dark);
}
.job-status-started,
.job-status-processing {
  background: var(--color-yellow-lightest);
  border-color: var(--color-yellow-dark);
}

.job-status-success {
  background: var(--color-green-lightest);
  border-color: var(--color-green-dark);
}

.job-status-pending-review {
  background: var(--color-purple-lightest);
  border-color: var(--color-purple-dark);
}

.job-status-failure {
  background: var(--color-red-lightest);
  border-color: var(--color-red-dark);
}
.start-job {
  margin-left: auto;
  margin-right: auto;
  height: 45px;
}
.jobs-intro {
  color: var(--color-gray-dark);
}
.download {
  button.primary {
    color: var(--color-blue-dark);
    background-color: var(--color-blue-lightest);
    border-color: var(--color-blue-dark);
  }
}
.table-header {
  tr {
    background-color: var(--color-gray-lightest);
  }
}
.not-available,
info {
  color: var(--color-gray-dark);
}
.category-header {
  width: 25%;
}
.status-header {
  width: 18%;
}
.name-header {
  width: 10%;
  cursor: pointer;
  s-icon {
    visibility: hidden;
  }
}
.name-header.active {
  s-icon {
    visibility: visible;
  }
}
.name-header:hover {
  s-icon {
    visibility: visible;
  }
}
.user-header {
  width: 20%;
}
.created-at-header {
  cursor: pointer;
  s-icon {
    visibility: hidden;
  }
}
.created-at-header.active {
  s-icon {
    visibility: visible;
  }
}
.created-at-header:hover {
  s-icon {
    visibility: visible;
  }
}
.job-link {
  a:visited {
    color: var(--color-blue-dark);
  }
  a:hover {
    color: var(--color-blue-dark);
  }
}
[data-letters]:before {
  content: attr(data-letters);
  display: inline-block;
  font-size: 1em;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  text-align: center;
  border-radius: 50%;
  background: var(--color-gray-lighter);
  vertical-align: middle;
  margin-right: 0.5em;
  color: var(--color-gray-darkest);
}
</style>
