<template>
  <div>
    <s-row v-if="!widget" class="heading-decsription">
      <s-col span="9">
        <h3>
          Find the Avalara Tax Codes (also called a goods and services type) for
          what you sell
        </h3>
        <p>
          You can use this search page to find the Avalara codes that determine
          the taxability of the goods and services you sell. You can copy and
          paste a code you find here into the
          <b>Tax Codes</b> field in the <b>Items</b> or
          <b>What you sell</b> areas of AvaTax when you are logged into the
          service, or into the appropriate field in your accounting or POS
          system. While we try to make these tools as accurate as possible,
          please remember that you are responsible for determining the
          appropriate tax codes.
        </p>
      </s-col>
    </s-row>
    <s-row class="search-bar">
      <s-col v-if="false" span="4">
        <form>
          <label for="categories">Categories</label>
          <select
            :key="categorySelected"
            v-model="categorySelected"
            id="categories"
          >
            <option
              v-for="category in categories"
              v-bind:value="category.value"
              :key="category.value"
              >{{ category.text }}</option
            >
          </select>
        </form>
      </s-col>
      <s-col span="4">
        <fieldset>
          <label for="user-query" class="pad-bottom-xss"
            >Search goods/services description</label
          >
          <input
            id="user-query"
            v-model="userQuery"
            v-on:keyup.enter="onSearch"
            data-cy="search-product-service"
            type="text"
          />
        </fieldset>
      </s-col>
      <s-col span="4">
        <button
          :disabled="!userQuery"
          v-on:click="onSearch"
          data-cy="search-goods-services-button"
          class="secondary margin-top-lg"
          title="Search goods/services description"
        >
          Search
        </button>
      </s-col>
      <s-col span="4">
        <h3 class="right pad-top-md">
          <a :href="downloadPath">
            <button
              class="ghost-blue"
              title="download Avalara goods and service type list"
            >
              <s-icon name="download"></s-icon>Avalara goods and services type
              list (.xlsx)
            </button>
          </a>
        </h3>
      </s-col>
    </s-row>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Provide, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class SearchBar extends Vue {
  @Prop() private category!: string;
  @Prop() private subcategory!: string;
  @Prop() private leaf!: string;
  @Prop() private query!: string;
  @Prop() private tab!: string;

  @Provide("$herculeApi") $herculeApi: any;
  @Provide("$api") $api: any;

  created() {
    if (this.$store.state.allCategories.length === 0) {
      this.$store.commit("startLoading");
      this.$api.get("api/categories/").then((result: any) => {
        this.$store.state.allCategories = result.data;
        this.$store.commit("stopLoading");
      });
    }
  }

  private _categorySelected: string | null = null;
  private userQuery: string = this.query || "";
  private taxCodesLoading = true;
  private taxcodesDict: any = {};
  allCategories: any;
  get categorySelected() {
    const { _categorySelected, category } = this;
    return _categorySelected || category || "";
  }
  set categorySelected(value) {
    this.$store.state.category = value;
    this._categorySelected = value;
    this.$router.push({
      name: "search_category",
      query: {
        q: this.query,
        category: value,
        tab: this.tab,
      },
    });
  }
  onSearch(event: any) {
    this.$router.push({
      name: "search_category",
      query: {
        q: this.userQuery,
        category: this.category,
        subcategory: this.subcategory,
        leaf: this.leaf,
        tab: this.tab,
      },
    });
  }
  get categories() {
    return [{ text: "All Categories", value: "" }].concat(
      this.$store.state.allCategories.map((x: any) => {
        return { text: x.name, value: x.name };
      })
    );
  }

  get downloadPath() {
    var todayDate = new Date().toISOString().slice(0, 10);
    return (
      this.$store.getters.assetsPathGlobal + "/Avalara_goods_and_services.xlsx" + `?date=${todayDate}`
    );
  }

  get widget() {
    return this.$store.state.widget;
  }

  mounted() {
    this._categorySelected = this.category || "";
  }
}
</script>

<style scoped lang="scss">
h3,
h2 {
  margin-top: 5px;
  margin-bottom: 15px;
}
label {
  font-size: 0.9rem;
}
s-icon {
  margin-right: 10px;
}
s-col {
  padding-bottom: 0;
}
.search-bar {
  border-bottom: 1px solid var(--color-gray-medium);
  padding-bottom: 25px;
  margin-bottom: 15px;
}
.heading-decsription {
  border-bottom: 1px solid var(--color-gray-medium);
  margin-bottom: 15px;
  margin-top: 5px;
  p {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 15px;
    line-height: 1.2;
  }
  h3 {
    font-weight: lighter;
    font-size: 1.3rem;
    color: var(--color-gray-dark);
    text-align: left;
  }
}
.search-goods-services {
  position: absolute;
  bottom: 0;
  padding-right: 0;
  height: 30px;
  padding-left: 10px;
  padding-top: 5px;
}
.pad-bottom-xss {
  padding-bottom: 2px;
}
</style>
