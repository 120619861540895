var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('SearchBar',{attrs:{"query":_vm.query,"category":_vm.category,"subcategory":_vm.subcategory,"leaf":_vm.leaf,"tab":_vm.tab}}),_c('s-row',[_c('s-col',{staticClass:"back-button",attrs:{"span":"3"}},[_c('router-link',{attrs:{"to":_vm.backLink()}},[_vm._v(" View all categories ")])],1)],1),(!_vm.searchLoading)?_c('s-row',{staticClass:"results-header"},[(_vm.searchResults.length > 0)?_c('s-col',{attrs:{"span":"12"}},[_c('h2',{staticClass:"accent--text"},[_vm._v(_vm._s(_vm.resultsFor))])]):_vm._e(),(_vm.searchResults.length === 0)?_c('s-col',{attrs:{"span":"12"}},[_c('h2',{staticClass:"no-results"},[_vm._v(" Sorry, we couldn't find any results matching \""+_vm._s(_vm.query)+"\" ")])]):_vm._e()],1):_vm._e(),(
      !_vm.searchLoading &&
      _vm.searchResults.length > 0 &&
      (!_vm.tab || _vm.tab == 'decision_tree')
    )?[_c('s-row',{staticClass:"mb-5"},[_c('s-col',{staticClass:"divider-right",attrs:{"span":"3"}},[_c('SearchSideBar',{attrs:{"category":_vm.category,"subcategory":_vm.subcategory,"leaf":_vm.leaf,"aggregatedCategories":_vm.aggregatedCategories,"treeTypes":_vm.treeTypesInResults,"displayType":_vm.displayType,"groupCount":_vm.groupedResults.size,"taxcodesCount":_vm.taxcodesInResults.length}})],1),(_vm.displayType === 'group')?_c('s-col',{attrs:{"span":"9"}},_vm._l((_vm.$_.chunk(
            Array.from(_vm.groupedResults.values()),
            3
          )),function(gr,gindex){return _c('s-row',{key:gr[0].name},_vm._l((gr),function(r,rindex){return _c('s-col',{key:r.name,attrs:{"span":"4"}},[_c('SearchResultGroupedItem',{attrs:{"item":r,"rank":gindex * 3 + rindex}})],1)}),1)}),1):(!_vm.displayType || _vm.displayType === 'taxcodes')?_c('s-col',{attrs:{"span":"9"}},[(_vm.taxcodeLookup().length > 0)?[_vm._l((_vm.$_.chunk(_vm.taxcodeLookup(), 3)),function(tg){return _c('s-row',{key:tg.code},_vm._l((tg),function(t){return _c('s-col',{key:t.code,attrs:{"span":"4"}},[_c('SearchResultItem',{attrs:{"item":{ resultType: 'Taxcode', resultText: t.code }}})],1)}),1)}),(_vm.relatedTaxcodes.length > 0)?[_c('h2',[_vm._v("Related Tax codes")])]:_vm._e()]:_vm._e(),_vm._l((_vm.$_.chunk(_vm.relatedTaxcodes, 3)),function(tg){return _c('s-row',{key:tg.code},_vm._l((tg),function(t){return _c('s-col',{key:t.code,attrs:{"span":"4"}},[_c('SearchResultItem',{attrs:{"item":{ resultType: 'Taxcode', resultText: t.code }}})],1)}),1)}),(_vm.query)?_c('s-row',[_c('s-col',{attrs:{"span":"4"}},[_c('SearchResultItem',{attrs:{"item":{ resultType: 'Taxcode', resultText: 'P0000000' }}})],1)],1):_vm._e()],2):_vm._e()],1)]:(!_vm.searchLoading && Object.keys(_vm.taxcodesInResults).length > 0)?[(_vm.taxcodeLookup().length > 0)?[_vm._l((_vm.taxcodeLookup()),function(taxcode){return _c('s-row',{key:taxcode.code,staticClass:"mb-5"},[_c('s-col',{attrs:{"span":"9"}},[_c('div',{staticClass:"card mx-auto"},[_c('div',{attrs:{"three-line":""}},[_c('div',[_c('div',{staticClass:"display-1 mb-4"},[_vm._v(_vm._s(taxcode.code))]),_c('div',{staticClass:"text--primary subtitle-1"},[_c('b',[_vm._v("Description:")]),_vm._v(" "+_vm._s(_vm.taxcodeDetails(taxcode.code).description)+" ")]),(
                    _vm.taxcodeDetails(taxcode.code).additionalDescription &&
                    _vm.taxcodeDetails(taxcode.code).additionalDescription !=
                      _vm.taxcodeDetails(taxcode.code).description
                  )?_c('div',{staticClass:"mt-4 subtitle-2"},[_c('span',{staticClass:"text--primary"},[_c('b',[_vm._v("Details:")])]),_vm._v(" "+_vm._s(_vm.taxcodeDetails(taxcode.code).additionalDescription.trim())+" ")]):_vm._e()])])])])],1)}),(_vm.relatedTaxcodes.length > 0)?[_c('s-row',[_c('h2',{staticClass:"related-header accent--text mr-4"},[_vm._v("Related Tax Codes")])])]:_vm._e()]:_vm._e(),_vm._l((_vm.relatedTaxcodes),function(taxcode){return _c('s-row',{key:taxcode.code,staticClass:"mb-5"},[_c('s-col',{attrs:{"span":"9"}},[_c('div',{staticClass:"card mx-auto"},[_c('div',{attrs:{"three-line":""}},[_c('div',[_c('div',{staticClass:"display-1 mb-4"},[_vm._v(" "+_vm._s(taxcode.code)+" "),(_vm.widget)?_c('button',{staticClass:"primary btn-use-this",attrs:{"title":"Use this taxcode"},on:{"click":function($event){$event.preventDefault();return _vm.onUseThis(taxcode.code)}}},[_vm._v(" Use this ")]):_vm._e()]),_c('div',{staticClass:"text--primary subtitle-1"},[_c('b',[_vm._v("Description:")]),_vm._v(" "+_vm._s(_vm.taxcodeDetails(taxcode.code).description)+" ")]),(
                  _vm.taxcodeDetails(taxcode.code).additionalDescription &&
                  _vm.taxcodeDetails(taxcode.code).additionalDescription !=
                    _vm.taxcodeDetails(taxcode.code).description
                )?_c('div',{staticClass:"mt-4 subtitle-2"},[_c('span',{staticClass:"text--primary"},[_c('b',[_vm._v("Details:")])]),_vm._v(" "+_vm._s(_vm.taxcodeDetails(taxcode.code).additionalDescription.trim())+" ")]):_vm._e()])])])])],1)}),(_vm.query)?_c('s-row',[_c('s-col',{attrs:{"span":"4"}},[_c('SearchResultItem',{attrs:{"item":{ resultType: 'Taxcode', resultText: 'P0000000' }}})],1)],1):_vm._e()]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }