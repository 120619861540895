import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import _ from "lodash";
import VueClipboard from "vue-clipboard2";
import cupIntegration from "./utils/cupIntegration";

Vue.use(VueClipboard);

Vue.config.productionTip = false;

function truncate(text, length, clamp) {
  return _.truncate(text, {
    length: length,
    separator: " ",
  });
}

Vue.filter("truncate", truncate);

function interceptRequestWithSessionRefresh(apiClient) {
  const refreshPath = "refresh";
  apiClient.interceptors.request.use(
    function(config) {
      if (config.url === refreshPath || _.isEmpty(store.state.sessionExpiry))
        return config;

      if (store.state.sessionRefreshing) {
        return store.state.sessionRefreshPromise;
      }

      const sessionExpiry = Date.parse(store.state.sessionExpiry);
      if (Date.now() > sessionExpiry) {
        store.commit("sessionRefreshing", true);
        const refreshCall = apiClient.post(refreshPath).then((response) => {
          store.commit(
            "recordSessionExpiry",
            response.data.accessTokenExpireTime
          );
          store.commit("sessionRefreshPromise", null);
          store.commit("sessionRefreshing", false);
          return Promise.resolve(config);
        });

        store.commit("sessionRefreshPromise", refreshCall);
        return refreshCall;
      }

      return config;
    },
    function(error) {
      return Promise.reject(error);
    }
  );

  return apiClient;
}

function interceptErrorResponse(apiClient) {
  apiClient.interceptors.response.use(
    function(response) {
      return response;
    },
    function(error) {
      let errorMessage = "Error occurred.";
      if (
        error.response &&
        error.response.data &&
        _.has(error.response.data, "code") &&
        (error.response.data["code"] === "BulkError" ||
          error.response.data["code"] === "InternalError")
      ) {
        errorMessage =
          "An unexpected error occurred. Try again and if the problem persists, contact ";
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errorMessage += ` Details: ${error.response.data.message}`;
      }

      store.commit("recordError", "");
      store.commit("recordError", errorMessage);
      return Promise.reject(error);
    }
  );

  return apiClient;
}

Vue.use({
  install(Vue) {
    Vue.prototype.$_ = _;
    Vue.prototype.$api = interceptRequestWithSessionRefresh(
      interceptErrorResponse(axios.create({ baseURL: "/" }))
    );
    Vue.prototype.$cupIntegration = cupIntegration;
  },
});
Vue.config.ignoredElements = [
  "s-alert",
  "s-box",
  "s-vr",
  "s-col",
  "s-icon",
  "s-container",
  "s-row",
  "s-loader",
  "aui-header",
  "aui-footer",
  "aui-service-notification",
  "s-tab",
  "s-dialog",
  "aui-ie-sux",
  "aui-nav",
  "aui-supernav",
  "s-tabs",
  "s-badge",
  "s-pipe",
  "s-table-container",
];
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
