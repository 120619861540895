var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.loading)?_c('div',[_c('h1',{ref:"questionnaireHeader",staticClass:"margin-bottom-none"},[_vm._v(" Qualification Questionnaire ")]),_c('s-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.errorMessageTop !== ''),expression:"errorMessageTop !== ''"}]},[_c('s-col',[_c('s-alert',{staticClass:"margin-top-sm",attrs:{"nodismiss":"","status":"error"}},[_c('div',[_vm._v(" "+_vm._s(_vm.errorMessageTop)+" ")])])],1)],1),_c('s-row',{staticClass:"margin-left-none"},[_c('s-col',{staticClass:"intro pad-bottom-md pad-left-none",attrs:{"span":"10"}},[_vm._v(" Answer the following questionnaire for better classification by our experts. ")]),_c('s-col',{staticClass:"questionnaire",attrs:{"span":"10"}},[_c('s-row',{staticClass:"company-questions"},[_c('s-col',{staticClass:"pad-bottom-sm",attrs:{"span":"12"}},[_c('h3',{staticClass:"header margin-bottom-none margin-top-none"},[_vm._v(" Company specific questions ")])]),_vm._l((_vm.questionnaire.companyQuestions),function(question,index){return _c('s-col',{key:index,staticClass:"question margin-top-sm pad-bottom-sm",attrs:{"span":question.questionType === 'singleArea' ? 10 : 5}},[(
              question.questionType === 'single' && _vm.showQuestion(question)
            )?_c('div',{staticClass:"single-type"},[_c('div',{class:[question.required ? 'required' : '', 'question-label'],attrs:{"for":`question${index}`}},[_vm._v(" "+_vm._s(question.question)+" ")]),_c('input',{attrs:{"type":"text","id":`question${index}`,"disabled":_vm.jobDetails.metadata.isQuestionnaireDone == 'true' &&
                  _vm.jobDetails.metadata.support == 'true'},domProps:{"value":question.answer},on:{"input":(e) => _vm.onSingleTextChange(e, 'companyQuestions', index)}})]):_vm._e(),(
              question.questionType === 'singleArea' && _vm.showQuestion(question)
            )?_c('div',{staticClass:"single-area-type"},[_c('div',{class:[question.required ? 'required' : '', 'question-label'],attrs:{"for":`question${index}`}},[_vm._v(" "+_vm._s(question.question)+" ")]),_c('textarea',{attrs:{"cols":"35","wrap":"soft","type":"text","id":`question${index}`,"placeholder":question.description,"disabled":_vm.jobDetails.metadata.isQuestionnaireDone == 'true' &&
                  _vm.jobDetails.metadata.support == 'true'},domProps:{"value":question.answer},on:{"input":(e) => _vm.onSingleTextChange(e, 'companyQuestions', index)}})]):_vm._e(),(
              question.questionType === 'singleSelect' &&
                _vm.showQuestion(question)
            )?_c('div',{staticClass:"single-select-type"},[_c('div',{staticClass:"question-label",class:[question.required ? 'required' : '', 'question-label'],attrs:{"for":`question${index}`}},[_vm._v(" "+_vm._s(question.question)+" ")]),_c('s-select',{attrs:{"nosearch":"","id":`select-question${index}`,"inputid":`question${index}`,"disabled":_vm.jobDetails.metadata.isQuestionnaireDone == 'true' &&
                  _vm.jobDetails.metadata.support == 'true',"optionsList":JSON.stringify(
                  question.options.map((option) => {
                    return {
                      label: option,
                      value: option,
                      selected: question.answer == option,
                    };
                  })
                )},on:{"s-select":(e) => _vm.onSingleSelectChange(e, 'companyQuestions', index)}})],1):_vm._e(),(
              question.questionType === 'multiSelect' &&
                _vm.showQuestion(question)
            )?_c('div',{staticClass:"multi-select-type"},[_c('div',{staticClass:"question-label",class:[question.required ? 'required' : '', 'question-label'],attrs:{"for":`question${index}`}},[_vm._v(" "+_vm._s(question.question)+" ")]),_c('s-select',{attrs:{"nosearch":"","id":`select-question${index}`,"inputid":`question${index}`,"disabled":_vm.jobDetails.metadata.isQuestionnaireDone == 'true' &&
                  _vm.jobDetails.metadata.support == 'true',"optionsList":JSON.stringify(
                  question.options.map((option) => {
                    return {
                      label: option,
                      value: option,
                      selected: question.answer.indexOf(option) >= 0,
                    };
                  })
                ),"multiple":""},on:{"s-select":(e) =>
                  _vm.onMultiselectChange(e, 'companyQuestions', index, false),"s-deselect":(e) => _vm.onMultiselectChange(e, 'companyQuestions', index, true)}})],1):_vm._e()])})],2),(_vm.areJobQuestionsPresent(_vm.questionnaire.jobQuestions))?_c('s-row',{staticClass:"job-questions margin-top-lg"},[_c('s-col',{staticClass:"pad-bottom-sm",attrs:{"span":"12"}},[_c('h3',{staticClass:"header margin-bottom-none margin-top-none"},[_vm._v(" Job specific questions ")])]),_vm._l((_vm.questionnaire.jobQuestions),function(question,index){return _c('s-col',{key:`job-${index}`,staticClass:"question margin-top-sm pad-bottom-sm",attrs:{"span":question.questionType === 'multiQuestion' ||
            question.questionType === 'singleArea'
              ? 10
              : 5}},[(
              question.questionType === 'single' && _vm.showQuestion(question)
            )?_c('div',{staticClass:"single-type"},[_c('div',{class:[question.required ? 'required' : '', 'question-label'],attrs:{"for":`job-question${index}`}},[_vm._v(" "+_vm._s(question.question)+" ")]),_c('input',{attrs:{"type":"text","id":`job-question${index}`,"disabled":_vm.jobDetails.metadata.isQuestionnaireDone == 'true' &&
                  _vm.jobDetails.metadata.support == 'true'},domProps:{"value":question.answer},on:{"input":(e) => _vm.onSingleTextChange(e, 'jobQuestions', index)}})]):_vm._e(),(
              question.questionType === 'singleArea' && _vm.showQuestion(question)
            )?_c('div',{staticClass:"single-area-type"},[_c('div',{class:[question.required ? 'required' : '', 'question-label'],attrs:{"for":`question${index}`}},[_vm._v(" "+_vm._s(question.question)+" ")]),_c('textarea',{attrs:{"cols":"35","wrap":"soft","type":"text","id":`question${index}`,"disabled":_vm.jobDetails.metadata.isQuestionnaireDone == 'true' &&
                  _vm.jobDetails.metadata.support == 'true',"placeholder":question.description},domProps:{"value":question.answer},on:{"input":(e) => _vm.onSingleTextChange(e, 'jobQuestions', index)}})]):_vm._e(),(
              question.questionType === 'multiQuestion' &&
                _vm.showQuestion(question)
            )?_c('div',{staticClass:"multiQuestion-type margin-bottom-lg"},[_c('div',{class:[question.required ? 'required' : '', 'question-label']},[_vm._v(" "+_vm._s(question.question)+" ")]),_vm._l((question.options),function(option,index2){return _c('div',{key:index2},[_c('fieldset',{staticClass:"margin-top-xs",attrs:{"role":"radiogroup"}},[_c('span',{staticClass:"option pad-left-lg margin-right-lg",attrs:{"id":`title${index2}`}},[_vm._v(_vm._s(option.question))]),_c('input',{attrs:{"type":"radio","id":`option1${index2}`,"name":`radio${index2}`,"disabled":_vm.jobDetails.metadata.isQuestionnaireDone == 'true' &&
                      _vm.jobDetails.metadata.support == 'true',"value":"yes"},domProps:{"checked":option.answer === 'yes'},on:{"click":(e) =>
                      _vm.onMultiquestionChange(
                        'yes',
                        'jobQuestions',
                        index,
                        index2
                      )}}),_c('label',{staticClass:"margin-left-xl",attrs:{"id":`label1${index2}`,"for":`option1${index2}`}},[_vm._v("Yes")]),_c('input',{attrs:{"type":"radio","id":`option2${index2}`,"name":`radio${index2}`,"value":"no","disabled":_vm.jobDetails.metadata.isQuestionnaireDone == 'true' &&
                      _vm.jobDetails.metadata.support == 'true'},domProps:{"checked":option.answer === 'no'},on:{"click":(e) =>
                      _vm.onMultiquestionChange(
                        'no',
                        'jobQuestions',
                        index,
                        index2
                      )}}),_c('label',{staticClass:"margin-left-xl",attrs:{"id":`label2${index2}`,"for":`option2${index2}`}},[_vm._v("No")])])])})],2):_vm._e(),(question.questionType === 'date' && _vm.showQuestion(question))?_c('div',{staticClass:"date-type"},[_c('div',{class:[question.required ? 'required' : '', 'question-label'],attrs:{"for":`job-question${index}`}},[_vm._v(" "+_vm._s(question.question)+" ")]),_c('s-datepicker',{attrs:{"inputid":`job-question${index}`,"disabled":_vm.jobDetails.metadata.isQuestionnaireDone == 'true' &&
                  _vm.jobDetails.metadata.support == 'true',"value":question.answer,"inputname":"date"},on:{"s-select":(e) => _vm.onDateChange(e, 'jobQuestions', index)}})],1):_vm._e()])})],2):_vm._e()],1)],1),_c('s-row',[_c('s-col',[_c('button',{staticClass:"tertiary icon-leading",on:{"click":_vm.previous}},[_c('s-icon',{attrs:{"name":"arrow-left"}}),_vm._v("Previous ")],1),_c('button',{staticClass:"primary icon-trailing",attrs:{"disabled":!_vm.isSavePossible()},on:{"click":_vm.saveAndNext}},[_vm._v(" Save and continue"),_c('s-icon',{attrs:{"name":"arrow-right"}})],1),_c('button',{staticClass:"ghost",on:{"click":_vm.cancel}},[_vm._v("Cancel")])])],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }