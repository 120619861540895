<template>
  <div>
    <h1 ref="uploadHeader" class="margin-bottom-none">
      Upload curated product catalog
    </h1>
    <s-row v-show="errorMessageTop !== ''">
      <s-col>
        <s-alert nodismiss class="margin-top-sm" status="error">
          <div>
            {{ errorMessageTop }}
          </div>
        </s-alert>
      </s-col>
    </s-row>
    <s-row>
      <s-col span="10" class="intro pad-bottom-md">
        Upload a spreadsheet including a list of your products and services that
        includes a description and unique item code. If available, also include
        catalog numbers such as SKU, UPC, EAN, or ASIN. Your spreadsheet must
        have fewer than 30,000 rows.
      </s-col>
      <s-col span="4" class="intro pad-bottom-md">
        <button @click="downloadInputFile" class="primary icon-trailing">
          Download input file
          <s-icon name="download"></s-icon>
        </button>
      </s-col>
      <s-col span="12" v-show="errorMessages.length > 0" class="pad-bottom-md">
        <s-alert nodismiss status="error">
          <div>
            <div class="text-md">
              We can't upload your file! Check out the following errors.
            </div>
            <ul class="margin-left-md margin-top-xs">
              <li v-for="message in errorMessages" :key="message">
                {{ message }}
              </li>
            </ul>
          </div>
        </s-alert>
      </s-col>
    </s-row>
    <s-row>
      <s-col v-if="file === null && (!fileName || fileName === '')">
        <s-uploader v-on:s-attach="fileChosen" accept=".csv,.tsv,.psv">
          <div slot="guidelines">
            <div>File types accepted: .csv or .tsv or .psv</div>
            <div>Max file size: 30,000 rows</div>
          </div>
        </s-uploader>
      </s-col>
      <s-col v-else span="4">
        <div class="selected-file">
          <hr class="margin-bottom-none" />
          <s-icon
            name="file-spreadsheet"
            role="img"
            aria-label="file-spreadsheet"
          ></s-icon>
          <span class="pad-left-xs">{{ fileName }}</span>
          <s-icon
            name="close"
            role="img"
            aria-label="close"
            class="right pad-right-xs"
            v-on:click="removeUploadedFile"
          ></s-icon>
          <hr class="margin-top-none" />
          <div class="file-success">
            <s-icon
              name="check"
              role="img"
              aria-label="check"
              class="pad-right-xs"
            ></s-icon>
            <span>File uploaded successfully</span>
          </div>
        </div>
      </s-col>
    </s-row>
    <s-row
      ><s-col>
        <button @click="previous" class="tertiary icon-leading">
          <s-icon name="arrow-left"></s-icon>Previous
        </button>
        <button
          @click="nextStep"
          class="primary icon-trailing"
          :disabled="isSaveDisabled()"
        >
          Save and continue
          <s-icon name="arrow-right"></s-icon>
        </button>
        <button @click="skip" class="primary icon-trailing">
          Skip
          <s-icon name="arrow-right"></s-icon>
        </button>
        <button @click="cancel" class="ghost">Cancel</button>
      </s-col></s-row
    >
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Provide, Watch } from "vue-property-decorator";
import errorsUtil from "./../utils/errorMappings";
import _ from "lodash";

@Component({})
export default class BulkCuratedUpload extends Vue {
  @Provide("$api") $api: any;
  @Provide("$_") $_: any;

  @Prop() private accountId!: string;
  @Prop() private companyId!: string;
  @Prop() private jobId!: string;

  private currentJobId = this.jobId;
  private file: any = null;
  private fileName = "";
  private jobDetails: any = {};

  private errorMessages: string[] = [];
  private errorMessageTop = "";

  mounted() {
    this.getJobDetails();
  }

  @Watch("errorMessages")
  @Watch("errorMessageTop")
  focusErrorAlert() {
    if (this.errorMessages.length !== 0 || this.errorMessageTop !== "") {
      (this.$refs.uploadHeader as any).scrollIntoView();
    }
  }

  private displayError(error) {
    const errorCode = error.response.data.code;
    if (
      error.response &&
      error.response.data &&
      error.response.data.details &&
      error.response.data.details.length > 0
    ) {
      this.$store.commit("recordError", "");
      this.errorMessages = error.response.data.details.map((e) => {
        if (_.has(errorsUtil.specialErrors, e.code))
          return errorsUtil.getErrorMessage(e);
        else return e.message;
      });
    } else if (_.has(errorsUtil.errorMessages, errorCode)) {
      this.$store.commit("recordError", "");
      this.errorMessageTop = errorsUtil.getErrorMessage(error.response.data);
    }
  }

  private previous() {
    (this.$parent as any).previousStep();
  }

  getJobDetails() {
    this.$store.commit("startLoading");
    this.$api
      .get(
        `item-classification-api/v1/jobs/${this.companyId}/${this.currentJobId}`
      )
      .then((response) => {
        const jobDetails = response.data;
        this.jobDetails = jobDetails;
        this.fileName = jobDetails.metadata.curatedFileName;
        this.$store.commit("stopLoading");
      })
      .catch((error) => {
        this.$store.commit("stopLoading");
        this.displayError(error);
      });
  }

  private fileChosen(e) {
    this.file = e.detail.files[0];
    this.fileName = this.file.name;
  }

  private findFileType(file: any) {
    const acceptedFileTypes = [".csv", ".psv", ".tsv"];
    if (
      this.fileName &&
      this.fileName.length > 4 &&
      acceptedFileTypes.indexOf(this.fileName.slice(-4).toLowerCase()) >= 0
    ) {
      return this.fileName.slice(-3).toLowerCase();
    }
    return "";
  }

  private removeUploadedFile() {
    this.file = null;
    this.fileName = "";
  }

  private isSaveDisabled() {
    return !this.fileName || this.fileName === "" || this.$store.state.loading;
  }

  async downloadInputFile() {
    try {
      this.$store.commit("startLoading");
      const downloadResponse = await this.$api.get(
        `item-classification-api/v1/jobs/${this.companyId}/${this.jobId}/inputFile`,
        {
          params: {},
          responseType: "arraybuffer",
          headers: {
            Accept: "application/octet-stream",
          },
        }
      );
      const url = window.URL.createObjectURL(new Blob([downloadResponse.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "input.csv");
      document.body.appendChild(link);
      link.click();
      this.$store.commit("stopLoading");
    } catch (e) {
      this.$store.commit("stopLoading");
      this.displayError(e);
    }
  }

  async nextStep() {
    this.$store.commit("startLoading");
    try {
      if (this.file) {
        const fileType = this.findFileType(this.file);
        const formData = new FormData();
        formData.append("file", this.file);
        const uploadResponse = await this.$api.put(
          `item-classification-api/v1/jobs/${this.companyId}/${this.currentJobId}/uploadCurated`,
          formData,
          {
            params: { fileType },
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        this.$store.commit("stopLoading");
      }
      this.$emit("step-done");
    } catch (error) {
      this.$store.commit("stopLoading");
      this.displayError(error);
    }
  }

  async skip() {
    this.$store.commit("startLoading");
    try {
      const metadataUpdated = Object.assign(this.jobDetails.metadata, {
        curatedFileName: "",
      });
      const markStepCompletion = await this.$api.put(
        `item-classification-api/v1/jobs/${this.companyId}/${this.currentJobId}`,
        {
          jobName: this.jobDetails.metadata.jobName,
          tags: this.jobDetails.tags,
          metadata: metadataUpdated,
        }
      );
      this.$store.commit("stopLoading");
      this.$emit("step-done");
    } catch (error) {
      this.$store.commit("stopLoading");
      this.displayError(error);
    }
  }

  private cancel() {
    this.$router.push({
      path: `/classification/${this.accountId}/${this.companyId}/jobs`,
    });
  }
}
</script>

<style scoped lang="scss">
.intro {
  color: var(--color-gray-dark);
}
label.required:after {
  color: red;
  content: " *";
}
.selected-file {
  line-height: 36px;
  s-icon {
    line-height: 2.2rem;
  }
  s-icon:first-of-type {
    color: var(--color-green-dark);
  }
}
.file-success {
  line-height: 2rem;
  color: var(--color-green-dark);
  font-weight: 600;
}
.limit-error {
  color: var(--color-red-dark);
  font-weight: 600;
}
.selected-file {
  s-icon:nth-of-type(2) {
    cursor: pointer;
  }
}
.tooltip-wrap {
  display: initial;
  line-height: normal;
}
.tooltip-wrap .tooltip-content {
  display: none;
  position: fixed;
  background-color: var(--color-gray-lightest);
  z-index: 1;
  max-width: 300px;
}
.tooltip-wrap:hover .tooltip-content {
  display: initial;
}
.info-icon {
  s-icon {
    color: var(--color-blue-dark);
  }
}
</style>
