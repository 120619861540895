<template>
  <div v-show="!loading && !$store.state.loading">
    <h1 class="margin-bottom-none">Select classification profile/categories</h1>
    <s-row v-show="errorMessageTop !== ''">
      <s-col>
        <s-alert nodismiss class="margin-top-sm" status="error">
          <div>
            {{ errorMessageTop }}
          </div>
        </s-alert>
      </s-col>
    </s-row>
    <s-row>
      <s-col span="10" class="intro pad-bottom-lg">
        Either select a classification profile or manually select the categories
        for your uploaded product list.
      </s-col>
    </s-row>
    <s-row class="profile-category-view" v-if="!isCategoryOnlyView">
      <s-col v-if="profiles.length > 0" class="profile-selection" span="5">
        <h3>Use an existing classification profile</h3>
        <s-alert
          v-show="isSelectedProfileOutdated()"
          class="margin-top-sm margin-bottom-sm"
          status="warning"
          nodismiss=""
        >
          <div>
            Changes have happened in the classification categories that could
            affect this classification profile. Please edit this profile and
            reselect the relevant categories. This will ensure there is no
            impact to predictions using this profile.
          </div>
        </s-alert>
        <div class="intro">
          Choose the classification profile that best matches your items.
        </div>
        <label for="select-profile">Select a profile</label>
        <s-select
          nosearch
          inputid="select-profile"
          placeholder="Select Profile"
          :optionsList="JSON.stringify(profilesData)"
          @s-select="(e) => onProfileSelected(e)"
        >
        </s-select>
        <div class="margin-top-md margin-bottom-xs">
          Below are the categories and sub-categories selected under this
          classification profile,
        </div>
        <s-box class="pad-top-none pad-bottom-md margin-bottom-lg">
          <CategoryPathsViewer
            :categoryPaths="categoryPaths"
            :key="categoryPaths"
          />
        </s-box>
      </s-col>
      <s-col
        v-if="profiles.length === 0 && !loading"
        span="5"
        class="placeholder margin-bottom-xl pad-bottom-xl"
      >
        <h3>Use an existing classification profile</h3>
        <div class="intro">
          Choose the classification profile that best matches your items.
        </div>
        <div class="pad-top-xl margin-top-xl margin-bottom-xl">
          <img
            :src="`${$store.getters.assetsPath}/img/error.png`"
            class="block margin-right-auto margin-left-auto margin-top-xl"
          />
          <p
            class="text-center margin-top-xs margin-bottom-xs text-md font-semibold"
          >
            No classification profile found!
          </p>
          <p class="text-center margin-top-xs intro">
            You don't have any classification profiles yet. Get started with
            classification profiles (redirects to classification profile page).
          </p>
          <h3 class="margin-top-lg">
            <router-link
              :to="{
                name: 'bulk_profile_new',
                params: { companyId, accountId },
              }"
            >
              <a class="margin-left-auto margin-right-auto">
                <button class="primary">
                  New classification profile
                </button>
              </a>
            </router-link>
          </h3>
        </div>
      </s-col>
      <s-col class="or-line flex flex-dir-col align-items-center" span="1">
        <div class="line"></div>
        <h1>OR</h1>
        <div class="line"></div>
      </s-col>
      <s-col class="category-selection" span="5">
        <h3>Select the categories</h3>
        <div class="intro margin-bottom-lg">
          If none of your profiles fit your product catalog, you can manually
          select the categories. After you've selected your categories, you'll
          have the option to save them as a new classification profile. If you
          choose not to save them, you will not be able to reuse them later.
        </div>
        <button @click="toCategoryOnlyView()" class="primary">
          Select categories
        </button>
      </s-col>
    </s-row>
    <div v-else class="category-only-view">
      <s-col
        span="3"
        class="back-button pad-left-none margin-top-none margin-bottom-md"
      >
        <a v-on:click="toProfilesView()">
          <s-icon class="align-self-center" name="arrow-left"></s-icon
          ><span>Choose classification profile instead</span>
        </a>
      </s-col>
      <h3 class="margin-top-none margin-bottom-xs">
        Select Categories
      </h3>
      <div class="category-select-intro margin-bottom-xs">
        Tell us what categories or subcategories you want to select for this
        profile. Please select atleast one category or sub-category for the
        profile.
      </div>
      <BulkCategorySelection
        :categories="categories"
        :categoryPaths.sync="categoryPaths"
      />
      <div>
        Would you like to create a classification profile for the selected
        categories?
      </div>
      <input
        type="checkbox"
        id="create-profile"
        name="create-profile"
        :checked="createProfileForCategories"
        v-model="createProfileForCategories"
      />
      <label for="create-profile">Create a classification profile</label>
      <s-col span="3" class="margin-top-md" v-show="createProfileForCategories">
        <label for="profile-name">Name</label>
        <input
          type="text"
          id="profile-name"
          v-model="profileName"
          aria-describedby="profile-error-message"
          :class="[isProfileNameInvalid ? 'error' : '']"
        />
        <div id="profile-error-message" class="input-msg">
          <s-icon name="alert-circle-filled" aria-hidden="true"></s-icon>Profile
          name can't be empty
        </div>
      </s-col>
    </div>
    <s-row class="margin-top-xl">
      <s-col>
        <button @click="previous" class="tertiary icon-leading">
          <s-icon name="arrow-left"></s-icon>Previous
        </button>
        <button
          :disabled="categoryPaths.length === 0"
          @click="saveAndNext"
          class="primary icon-trailing"
        >
          Save and continue<s-icon name="arrow-right"></s-icon>
        </button>
        <button @click="cancel" class="ghost">Cancel</button>
      </s-col>
    </s-row>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Provide, Watch } from "vue-property-decorator";
import CategoryPathsViewer from "@/components/CategoryPathsViewer.vue";
import BulkCategorySelection, {
  Category
} from "@/components/BulkCategorySelection.vue";
import errorsUtil from "./../utils/errorMappings";
import _ from "lodash";

interface Profile {
  accountId: string;
  companyId: string;
  categories: string[];
  createdBy: string;
  createdAt: string;
  deletedAt: string;
  isDefault: boolean;
  profileId: string;
  profileName: string;
  updatedBy: string;
  updatedAt: string;
  isOutdated: boolean;
}

interface SelectOption {
  label: string;
  value: string;
  selected?: boolean;
}

@Component({
  components: { CategoryPathsViewer, BulkCategorySelection },
})
export default class BulkCategory extends Vue {
  @Provide("$api") $api: any;

  @Prop() private accountId!: string;
  @Prop() private companyId!: string;
  @Prop() private jobId!: string;

  private isCategoryOnlyView = false;
  private profiles: Profile[] = [];
  private profilesData: SelectOption[] = [];
  private selectedProfile = "";
  private categoryPaths: string[] = [];
  private categories: Category[] = [];
  private loading = false;
  private createProfileForCategories = false;
  private profileName = "";
  private isProfileNameInvalid = false;

  private errorMessageTop = "";

  async mounted() {
    this.loading = true;
    this.$store.commit("startLoading");
    this.loadData();
  }

  private displayError(error) {
    const errorCode = error.response.data.code;
    if (_.has(errorsUtil.errorMessages, errorCode)) {
      this.$store.commit("recordError", "");
      this.errorMessageTop = errorsUtil.errorMessages[errorCode];
    }
  }

  async loadData() {
    this.$api
      .get(`item-classification-api/v1/jobs/${this.companyId}/${this.jobId}`)
      .then((response) => {
        this.$store.commit("startLoading");
        const jobDetails = response.data;
        this.loadProfiles(
          jobDetails.metadata.profileName,
          jobDetails.categories
        );
      })
      .catch((error) => {
        this.loading = false;
        this.$store.commit("stopLoading");
        this.displayError(error);
      });
  }

  async loadProfiles(metadataProfile: string, categories: string[]) {
    this.$api
      .get(`item-classification-api/v1/profiles/${this.companyId}`, {})
      .then((response) => {
        this.profiles = response.data.value;
        const defaultProfile: string =
          this.profiles.find((p) => p.isDefault)?.profileName || "";
        //new job
        if (metadataProfile === undefined) {
          this.isCategoryOnlyView = false;
          this.selectedProfile = defaultProfile;
          this.categoryPaths =
            this.profiles.find((p) => p.profileName === this.selectedProfile)
              ?.categories || [];
        }
        //update job and category only view
        else if (metadataProfile === "") {
          this.isCategoryOnlyView = true;
          this.selectedProfile = defaultProfile;
          this.categoryPaths = categories;
        }
        //update job and profile view
        else {
          this.isCategoryOnlyView = false;
          this.selectedProfile = this.profiles.some(
            (p) => p.profileName === metadataProfile
          )
            ? metadataProfile
            : defaultProfile;
          this.categoryPaths =
            this.profiles.find((p) => p.profileName === this.selectedProfile)
              ?.categories || [];
        }
        this.profilesData =
          this.profiles.map((p) => {
            return {
              label: p.isDefault ? `${p.profileName} (Default)` : p.profileName,
              value: p.profileName,
              selected: p.profileName === this.selectedProfile,
            };
          }) || [];
        this.loadCategories();
      })
      .catch(error => {
        this.loading = false;
        this.$store.commit("stopLoading");
        this.displayError(error);
      });
  }

  async loadCategories() {
    this.$api
      .get("api/categories/all")
      .then(result => {
        this.categories = result.data;
        this.loading = false;
        this.$store.commit("stopLoading");
      })
      .catch(error => {
        this.loading = false;
        this.$store.commit("stopLoading");
        this.displayError(error);
      });
  }

  private onProfileSelected(e) {
    this.selectedProfile = e.detail.item.value;
    this.profilesData = this.profiles.map((p) => {
      return {
        label: p.isDefault ? `${p.profileName} (Default)` : p.profileName,
        value: p.profileName,
        selected: p.profileName === this.selectedProfile,
      };
    });
    this.categoryPaths =
      this.profiles.find((p) => p.profileName === this.selectedProfile)
        ?.categories || [];
  }

  private toProfilesView() {
    //selected profile and profiles data remain same as last one
    this.isCategoryOnlyView = false;
    this.categoryPaths =
      this.profiles.find((p) => p.profileName === this.selectedProfile)
        ?.categories || [];
  }

  private toCategoryOnlyView() {
    this.isCategoryOnlyView = true;
    this.categoryPaths = [];
  }

  private isSelectedProfileOutdated() {
    return (
      this.profiles.find((p) => p.profileName === this.selectedProfile)
        ?.isOutdated || false
    );
  }

  async saveAndNext() {
    if (
      this.profileName === "" &&
      this.isCategoryOnlyView &&
      this.createProfileForCategories
    ) {
      this.isProfileNameInvalid = true;
      return;
    }
    this.$store.commit("startLoading");
    if (this.isCategoryOnlyView && this.createProfileForCategories) {
      await this.createProfileAndUpdateCategories();
    } else {
      await this.uploadCategories();
    }
  }

  async createProfileAndUpdateCategories() {
    this.$api
      .post(`item-classification-api/v1/profiles/${this.companyId}`, {
        categories: this.categoryPaths,
        profileName: this.profileName,
      })
      .then((response) => {
        this.selectedProfile = this.profileName;
        this.uploadCategories();
      })
      .catch((error) => {
        this.$store.commit("stopLoading");
        this.displayError(error);
      });
  }

  async uploadCategories() {
    this.$api
      .put(
        `item-classification-api/v1/jobs/${this.companyId}/${this.jobId}/categories`,
        {
          categories: this.categoryPaths,
          profileName:
            this.isCategoryOnlyView && !this.createProfileForCategories
              ? ""
              : this.selectedProfile,
        }
      )
      .then((response) => {
        this.$store.commit("stopLoading");
        this.$emit("step-done");
      })
      .catch((error) => {
        this.$store.commit("stopLoading");
        this.displayError(error);
      });
  }

  private previous() {
    (this.$parent as any).previousStep();
  }

  private cancel() {
    this.$router.push({
      path: `/classification/${this.accountId}/${this.companyId}/jobs`,
    });
  }
}
</script>

<style scoped lang="scss">
.intro {
  color: var(--color-gray-dark);
}
.line {
  height: 100%;
  border: solid 1px;
  color: var(--color-gray-lighter);
}
.profile-selection {
  s-select {
    max-width: 350px;
  }
  s-box {
    max-width: 500px;
  }
}
.or-line {
  h1 {
    color: var(--color-gray-medium);
  }
}
.back-button {
  a:visited {
    color: var(--color-blue-dark);
  }
  span {
    line-height: 2;
  }
}
</style>
