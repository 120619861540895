<template>
  <router-link
    :class="['card-link', 'item', isTaxcodesDisplay ? 'item-taxcodes' : '']"
    :to="itemLink"
    v-if="!$_.isEmpty(item)"
  >
    <s-box class="margin-top-xs" @[taxCodeClick].prevent="taxCodeClickHandler">
      <div class="taxcode" v-if="item.resultType == 'Taxcode'">
        <div
          :class="[
            isNewTaxcode(taxcodeDetails.effectiveFrom)
              ? 'margin-bottom-none'
              : '',
            'text-sm',
            'text-light',
          ]"
        >
          {{ item.resultText }}
          <span v-if="!widget">
            <s-icon
              name="copy"
              role="img"
              aria-label="copy"
              class="text-sm"
            ></s-icon>
          </span>
        </div>
        <div
          v-if="
            taxcodeDetails.effectiveFrom &&
            isNewTaxcode(taxcodeDetails.effectiveFrom)
          "
          class="new-taxcode text-xs font-italic font-bold"
        >
          {{ newTaxcodeText(taxcodeDetails.effectiveFrom) }}
        </div>
        <div class="description text-md text-dark">
          {{ taxcodeDetails.description }}
        </div>
        <div
          class="additional-description text-sm pad-top-xs text-light"
          v-if="!$_.isEmpty(taxcodeDetails.additionalDescription)"
        >
          {{
            taxcodeDetails.additionalDescription |
              truncate(truncateLength - taxcodeDetails.description.length)
          }}
        </div>
      </div>
      <div class="use-button">
        <button
          class="primary right"
          title="Use this taxcode"
          v-if="widget"
          v-on:click.prevent="onUseThis(item.resultText)"
        >
          Use this
        </button>
      </div>
      <div class="decision" v-if="item.resultType == 'Decision'">
        <h3>{{ item.resultText }}</h3>
        <s-icon
          class="right right-icon"
          name="chevron-right"
          role="img"
          aria-label="click"
        ></s-icon>
      </div>
    </s-box>
  </router-link>
</template>

<script lang="ts">
import { Component, Prop, Vue, Provide, Watch } from "vue-property-decorator";
import dayjs from "dayjs";

@Component({
  components: {},
})
export default class SearchResultItem extends Vue {
  @Prop() private item!: any;
  @Prop() private rank!: any;
  @Prop() private type!: any;

  @Provide("$_") $_: any;
  @Provide("$cupIntegration") $cupIntegration: any;

  get isTaxcodesDisplay() {
    return (
      (!this.$route.query.displayType ||
        this.$route.query.displayType === "taxcodes") &&
      this.item.resultType === "Taxcode"
    );
  }

  get truncateLength() {
    return this.isTaxcodesDisplay ? 260 : 120;
  }

  get itemLink() {
    if (this.item.resultType === "Decision") {
      return {
        name: "tree",
        query: {
          q: this.$route.query.q,
          rank: this.rank,
          tree: this.item.resultDetails.treeId,
          decisionId: this.item.resultDetails.decisionId,
          decisionName: this.item.resultDetails.decisionName,
          tab: "interactive",
        },
      };
    } else if (this.item.resultType === "Tree") {
      return {
        name: "tree",
        query: {
          q: this.$route.query.q,
          rank: this.rank,
          tree: this.item.resultDetails.treeId,
          tab: "interactive",
        },
      };
    }

    return "#";
  }

  get taxCodeClick() {
    return this.item.resultType === "Taxcode" ? "click" : null;
  }

  async taxCodeClickHandler() {
    if (this.widget) {
      this.$cupIntegration.onUseThis(this.item.resultText);
    } else {
      await this.$copyText(this.item.resultText);
      this.$store.commit(
        "recordToast",
        `${this.item.resultText} copied to clipboard`
      );
    }
  }

  onUseThis(code: any) {
    const taxcode: any = this.taxcodeDetails;
    const taxcodeDetail = {
      code: code,
      desc: taxcode.description,
      short_desc: taxcode.description,
    };
    window.parent.postMessage(JSON.stringify(taxcodeDetail), "*");
  }

  get taxcodeDetails() {
    if (this.item.resultText == "P0000000") {
      return {
        code: "P0000000",
        description: "None of these categories describe this item",
        additionalDescription:
          "If Avalara does not have a category for a product or service, it usually means that you can skip this step because the item is fully taxable",
        effectiveFrom: "2013-10-31T00:00:00Z",
      };
    }
    const details = this.$store.getters.taxcode(this.item.resultText);
    if (details.description === details.additionalDescription) {
      details.additionalDescription = "";
    }
    return details;
  }

  private newTaxcodeText(date) {
    return date.endsWith("Z")
      ? "New tax code! Code will be active from " +
          dayjs(date.substr(0, date.length - 1)).format("D MMM, YYYY")
      : "";
  }

  private isNewTaxcode(effFrom) {
    return dayjs(effFrom).isAfter(dayjs(new Date()));
  }

  get widget() {
    return this.$store.state.widget;
  }
}
</script>

<style scoped lang="scss">
.text-light {
  color: var(--color-gray-darker);
}
.result-item {
  overflow-y: auto;
}

.num-taxcodes {
  height: 16px !important;
  cursor: default !important;
}

.taxcode {
  color: black;
  position: relative;
}
.decision {
  h3 {
    width: 99%;
  }
}
.right-icon {
  position: absolute;
  top: 2%;
  right: -10px;
  font-size: 25px;
}

.item s-box {
  width: 100%;
  max-height: 192px;
}
.item-taxcodes s-box {
  width: 100%;
  max-height: 320px;
  min-height: 320px;
}
.new-taxcode {
  color: var(--color-blue-dark);
}
.item:hover {
  text-decoration: none;
}
.use-button {
  width: 90%;
  position: absolute;
  top: 250px;
  float: right !important;
}
.text-dark {
  font-weight: 600;
}
</style>
