var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"fs-exclude"},[_c('s-row',[_c('s-col',{staticClass:"back-button",attrs:{"span":"3"}},[_c('router-link',{attrs:{"to":"/taxability"}},[_c('s-icon',{staticClass:"align-self-center",attrs:{"name":"arrow-left"}}),_c('span',[_vm._v("Back")])],1)],1)],1),_c('s-row',{staticClass:"heading-decsription"},[_c('s-col',{attrs:{"span":"8"}},[_c('h3',[_vm._v("Build a taxability matrix")]),_c('p',[_vm._v(" Add tax codes to create your own taxability matrix for specific tax codes and jurisdictions. ")])])],1),_c('s-row',[_c('s-col',{attrs:{"span":"4"}},[_c('label',{staticClass:"label-info"},[_vm._v("Add up to "+_vm._s(_vm.taxcodeLimit)+" Avalara tax codes "),_c('div',{staticClass:"tooltip-wrap"},[_c('s-icon',{staticClass:"margin-left-xs",attrs:{"name":"help-circle-filled","role":"img","aria-label":"account alert"}}),_c('div',{staticClass:"tooltip-content pad-all-sm"},[_vm._v(" Add multiple tax codes by copying and pasting them in space separated format. ")])],1)]),_c('CustomSelectBox',{key:_vm.selectBoxKey,attrs:{"name":"searchTaxcodes","placeholder":"PF052132","data":_vm.allTaxCodesData,"onSelect":_vm.onTaxCodeSelected,"onDeselect":_vm.onTaxCodeDeselected,"selectedItems":_vm.selectedTaxCodesParsed,"onInputChanged":_vm.searchTaxCodes,"isInputDisabled":_vm.$store.state.loading,"isError":_vm.getSelectedTaxCodesOptions().length > _vm.taxcodeLimit ||
          _vm.isSearchInputError,"isSearcheable":true}}),(_vm.getSelectedTaxCodesOptions().length > _vm.taxcodeLimit)?_c('div',{staticClass:"limit-error"},[_vm._v(" Add up to "+_vm._s(_vm.taxcodeLimit)+" tax codes. ")]):(_vm.isSearchInputError)?_c('div',{staticClass:"limit-error"},[_vm._v(" Invalid tax codes. Check that your tax codes are correct and separated by a space or comma. ")]):_vm._e()],1),_c('s-col',{attrs:{"span":"3"}},[_c('div',[_c('label',{attrs:{"for":"select-country"}},[_vm._v("Select a country")]),_c('CustomSelectBox',{key:_vm.selectBoxKey,attrs:{"name":"searchCountries","placeholder":"Select Country","data":_vm.allRegionsDataToBeUpdated,"onSelect":_vm.onRegionSelected,"onDeselect":_vm.onRegionDeselected,"onInputChanged":function noop() {},"isInputDisabled":_vm.$store.state.loading,"isError":false,"isSearcheable":true,"inputid":"select-country","id":"select-country"}})],1)]),_c('s-col',{attrs:{"span":"3"}},[(
          _vm.getSelectedRegionsToBeUpdated().includes(_vm.Region.US) ||
          _vm.getSelectedRegionsToBeUpdated().includes(_vm.Region.CA)
        )?_c('div',[_c('label',[_vm._v(_vm._s(_vm.getStatesSelectBoxLabel()))]),_c('CustomSelectBox',{key:_vm.selectBoxKey,attrs:{"name":_vm.getStatesSelectBoxLabel(),"placeholder":"Alaska","data":_vm.allJurisdictionsData,"onSelect":_vm.onJurisdictionSelected,"onDeselect":_vm.onJurisdictionDeselected,"onInputChanged":_vm.searchJurisdictions,"hasFooter":true,"onSelectAll":_vm.onSelectAllJurisdictions,"onUnselectAll":_vm.onUnselectAllJurisdictions,"isSearcheable":true}})],1):_vm._e()]),_c('s-col',{attrs:{"span":"2"}},[_c('div',{staticClass:"margin-top-lg search-row"},[_c('h3',{staticClass:"margin-top-none"},[_c('a',{attrs:{"href":"/taxability/search"}},[_c('button',{staticClass:"ghost text-xs",attrs:{"title":"clear all selections","link":""}},[_vm._v(" Clear All ")])])]),_c('button',{staticClass:"primary icon-leading text-xs margin-right-lg",attrs:{"disabled":_vm.getSelectedRegionsToBeUpdated().length === 0 ||
            _vm.getSelectedTaxCodesOptions().length === 0 ||
            ((_vm.getSelectedRegionsToBeUpdated().includes(_vm.Region.US) ||
              _vm.getSelectedRegionsToBeUpdated().includes(_vm.Region.CA)) &&
              _vm.getSelectedJurisdictionsOptions().length === 0) ||
            _vm.getSelectedTaxCodesOptions().length > _vm.taxcodeLimit},on:{"click":_vm.onTaxabilitySearch}},[_c('s-icon',{attrs:{"name":"search"}}),_vm._v("Create ")],1)])])],1),_c('s-row',[_c('s-col',{staticClass:"margin-bottom-none pad-bottom-none",attrs:{"span":"12"}},[_c('hr',{staticClass:"margin-top-sm margin-bottom-sm"})])],1),(
      _vm.getSelectedRegions().length === 0 ||
      _vm.selectedTaxCodesParsed.length === 0 ||
      ((_vm.getSelectedRegions().includes(_vm.Region.US) ||
        _vm.getSelectedRegions().includes(_vm.Region.CA)) &&
        _vm.selectedJurisdictionsParsed.length === 0) ||
      _vm.taxabilities.length === 0
    )?_c('s-row',[_c('s-col',{attrs:{"span":"12"}},[_c('div',{staticClass:"margin-top-xl placeholder"},[_c('img',{staticClass:"block margin-right-auto margin-left-auto",attrs:{"src":`${_vm.$store.getters.assetsPath}/img/placeholder.png`}}),_c('p',{staticClass:"text-center"},[_vm._v(" Search for an Avalara tax code to get started. ")])])])],1):_vm._e(),(
      _vm.getSelectedRegions().length > 0 &&
      _vm.selectedTaxCodesParsed.length > 0 &&
      ((!_vm.getSelectedRegions().includes(_vm.Region.US) &&
        !_vm.getSelectedRegions().includes(_vm.Region.CA)) ||
        _vm.selectedJurisdictionsParsed.length > 0) &&
      _vm.taxabilities.length > 0
    )?_c('TaxabilityTable',{attrs:{"taxcodes":_vm.selectedTaxCodesParsed,"jurisdictions":_vm.selectedJurisdictionsParsed,"regions":_vm.getSelectedRegions(),"codeToJurisdictionTaxability":_vm.codeToJurisdictionTaxability}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }