var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"category-only-view"},[_c('s-row',[_c('s-col',[_c('fieldset',{attrs:{"role":"filters","aria-labelledby":"filtersLabel"}},_vm._l((_vm.categories),function(category){return _c('div',{key:category.name},[_c('div',{staticClass:"category"},[_c('s-icon',{staticClass:"margin-right-xs margin-top-sm",attrs:{"id":`icon_${category.name}`,"name":_vm.isExpanded(category.namePath)
                  ? 'chevron-down'
                  : 'chevron-right'},on:{"click":(e) => _vm.toggleCategoryVisibility(e, category)}}),_c('input',{ref:`category_${category.name}`,refInFor:true,attrs:{"type":"checkbox","id":`category_${category.name}`,"name":"category"},domProps:{"value":category.name,"checked":_vm.isCategorySelected(category),"indeterminate":_vm.isAnySubcategorySelected(category)},on:{"click":(e) => _vm.onCategoryClicked(e, category)}}),_c('label',{staticClass:"margin-top-sm",attrs:{"for":`category_${category.name}`}},[_vm._v(_vm._s(category.name)+" ")])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isExpanded(category.namePath)),expression:"isExpanded(category.namePath)"}],staticClass:"subcategories"},_vm._l((category.subcategories),function(subCategory,index){return _c('div',{key:`${category.name}${index}`,staticClass:"pad-left-lg"},[_c('s-icon',{directives:[{name:"show",rawName:"v-show",value:(
                  subCategory.subcategories &&
                    subCategory.subcategories.length > 0
                ),expression:"\n                  subCategory.subcategories &&\n                    subCategory.subcategories.length > 0\n                "}],staticClass:"margin-right-xs margin-top-sm",attrs:{"id":`icon_${category.name}${index}`,"name":_vm.isSelected(subCategory.namePath)
                    ? 'chevron-down'
                    : 'chevron-right'},on:{"click":(e) => _vm.onSubcategoryClicked(e, subCategory)}}),_c('input',{attrs:{"type":"checkbox","id":`${category.name}${index}`,"name":"subcategory"},domProps:{"value":subCategory.name,"checked":_vm.isSelected(subCategory.namePath)},on:{"click":(e) => _vm.onSubcategoryClicked(e, subCategory)}}),_c('label',{class:[
                  !subCategory.subcategories ||
                  subCategory.subcategories.length === 0
                    ? 'margin-left-lg'
                    : '',
                  'margin-top-sm',
                ],attrs:{"for":`${category.name}${index}`}},[_vm._v(_vm._s(subCategory.name)+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSelected(subCategory.namePath)),expression:"isSelected(subCategory.namePath)"}],staticClass:"leaves"},_vm._l((subCategory.subcategories),function(leaf,index){return _c('div',{key:`${category.name}${subCategory.name}${index}`,staticClass:"pad-left-lg"},[_c('input',{attrs:{"type":"checkbox","id":`${category.name}${subCategory.name}${index}`,"name":"leaf"},domProps:{"value":leaf.name,"checked":_vm.isSelected(leaf.namePath)},on:{"click":(e) => _vm.onLeafClicked(e, leaf)}}),_c('label',{staticClass:"margin-left-lg margin-top-sm",attrs:{"for":`${category.name}${subCategory.name}${index}`}},[_vm._v(_vm._s(leaf.name)+" ")])])}),0)],1)}),0),_c('s-row',[_c('s-col',{attrs:{"span":"8"}},[_c('hr',{staticClass:"margin-bottom-xs"})])],1)],1)}),0)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }