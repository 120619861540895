var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"margin-top-sm fs-exclude"},[(this.$store.getters.companies.length > 0)?_c('CompaniesSwitcher',{attrs:{"currentId":_vm.companyId},on:{"company-switched":_vm.companySwitched}}):_vm._e(),_c('a',{staticClass:"helper flex align-items-center right",attrs:{"href":"https://help.avalara.com/z_AvaWeb/Customer_Learning/UKL_Internal/Jaime's_Sandbox/Tax_category_classification"}},[_c('s-icon',{staticClass:"text-sm margin-right-xs",attrs:{"name":"info-circle"}}),_c('span',{staticClass:"text-xs-strong"},[_vm._v("Need help?")])],1),_c('h1',{staticClass:"margin-bottom-none"},[_vm._v("Tax category classification"),(_vm.getFeatureLevelFromStore() == 'atcc-trial')?_c('sup',{staticStyle:{"color":"var(--color-orange-medium)","font-size":"60%"}},[_vm._v("Free Trial")]):_vm._e()]),_c('div',{staticClass:"jobs-intro"},[_vm._v(" Create or edit classifications based on your items. "),((_vm.getFeatureLevelFromStore() == 'atcc-trial') && _vm.trialLimitFetched)?_c('span',{staticClass:"jobs-intro"},[_vm._v(_vm._s(_vm.trialLimitUsed)+" records used out of "+_vm._s(_vm.trialLimit))]):_vm._e()]),_c('s-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.errorMessage !== ''),expression:"errorMessage !== ''"}]},[_c('s-col',[_c('s-alert',{staticClass:"margin-top-sm",attrs:{"nodismiss":"","status":"error"}},[_c('div',[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])])],1)],1),(_vm.jobsList.length > 0)?_c('s-row',{staticClass:"pad-top-md"},[_c('s-col',{attrs:{"span":"4"}},[(false)?_c('s-input-extended',{attrs:{"type":"search","inputid":"search"}}):_vm._e()],1),_c('s-col',{attrs:{"offset":"5","span":"3"}},[_c('h3',{staticClass:"margin-top-none margin-bottom-none"},[_c('router-link',{staticClass:"right",attrs:{"to":{
            name: 'bulk_job',
            params: { companyId: _vm.companyId, accountId: _vm.accountId },
          }}},[_c('a',[(!_vm.trialLimitExceeded)?_c('button',{staticClass:"primary"},[_vm._v(" New classification ")]):_vm._e()])]),_c('button',{staticClass:"ghost icon-leading right refresh margin-right-sm",on:{"click":_vm.populateJobsList}},[_c('s-icon',{attrs:{"name":"refresh"}}),_vm._v(" Refresh data ")],1)],1)])],1):_vm._e(),(!_vm.loading)?_c('s-row',[(_vm.jobsList.length > 0)?_c('s-col',[_c('s-table-container',{staticClass:"margin-bottom-sm",attrs:{"tabindex":"0"}},[_c('table',{staticClass:"row-height-sm"},[_c('thead',{staticClass:"table-header"},[_c('tr',[_c('th',{class:[
                  'name-header',
                  _vm.currentSortField === _vm.jobNameField ? 'active' : '',
                ],on:{"click":function($event){return _vm.onSortChange(_vm.jobNameField)}}},[_c('div',{staticClass:"flex align-items-center"},[_vm._v(" Name"),_c('s-icon',{staticClass:"margin-left-xs",attrs:{"name":_vm.sortIconType(_vm.jobNameField),"role":"img","aria-label":"arrow-up"}})],1)]),_c('th',{staticClass:"category-header"},[_vm._v("Categories/Sub-categories")]),_c('th',[_vm._v("Tags")]),_c('th',{staticClass:"user-header"},[_vm._v("Created by")]),_c('th',{class:[
                  'created-at-header',
                  _vm.currentSortField === 'createdAt' ? 'active' : '',
                ],on:{"click":function($event){return _vm.onSortChange('createdAt')}}},[_c('div',{staticClass:"flex align-items-center"},[_vm._v(" Created on"),_c('s-icon',{staticClass:"margin-left-xs",attrs:{"name":_vm.sortIconType('createdAt'),"role":"img","aria-label":"arrow-down"}})],1)]),_c('th',{staticClass:"status-header"},[_vm._v("Status")]),_c('th',[_vm._v("Actions")])])]),_c('tbody',_vm._l((_vm.jobsList),function(j){return _c('tr',{key:j.job_id,staticClass:"job-link"},[_c('td',[(j.metadata.jobName)?_c('div',[_c('router-link',{attrs:{"to":_vm.jobLink(j)}},[_vm._v(" "+_vm._s(_vm.jobName(j))+" ")])],1):_c('div',[_vm._v("N/A")])]),_c('td',[(j.categories && j.categories.length > 0)?_c('div',[_c('CategoryPathsViewer',{attrs:{"categoryPaths":j.categories}})],1):_c('div',{staticClass:"not-available pad-left-lg"},[_vm._v(" N/A ")])]),_c('td',[(j.tags && _vm.jobTags(j).length > 0)?_c('div',_vm._l((_vm.jobTags(j)),function(tag){return _c('s-tag',{key:tag,staticClass:"margin-top-xs",attrs:{"nodismiss":"","id":tag}},[_vm._v(_vm._s(tag))])}),1):_c('div',{staticClass:"not-available pad-left-sm"},[_vm._v("N/A")])]),_c('td',[_c('p',{attrs:{"data-letters":_vm.jobUser(j).length > 1
                      ? _vm.jobUser(j)
                          .substring(0, 2)
                          .toUpperCase()
                      : 'US'}},[_vm._v(" "+_vm._s(_vm.jobUser(j))+" ")])]),_c('td',[_vm._v(" "+_vm._s(_vm.formatDate(j.createdAt))+" ")]),_c('td',[_c('div',{class:[
                    'job-status',
                    'job-status-' +
                      _vm.jobStatus(j)
                        .toLowerCase()
                        .replace(' ', '-'),
                    'margin-top-xs',
                  ],attrs:{"id":`status_${_vm.jobName(j)}`}},[_vm._v(" "+_vm._s(_vm.jobStatus(j))+" ")]),_c('s-progress',{staticClass:"text-xs info",attrs:{"displaytext":`${j.processedRecords} out of ${j.totalRecords} items processed`,"valuenow":_vm.jobProgress(j)}})],1),_c('td',{staticClass:"download"},[(
                    j.status === _vm.JobStatus.Success ||
                      j.status === _vm.JobStatus.PendingReview ||
                      j.status === _vm.JobStatus.ReviewComplete ||
                      j.status === _vm.JobStatus.InFeedback ||
                      j.status === _vm.JobStatus.InitiatedFeedback ||
                      j.status === _vm.JobStatus.ClassificationComplete
                  )?_c('button',{staticClass:"primary icon small",attrs:{"id":`download_${_vm.jobName(j)}`,"aria-label":"close"},on:{"click":function($event){return _vm.downloadOutput(j)}}},[_c('s-icon',{attrs:{"name":"download"}})],1):_c('div',{staticClass:"not-available pad-left-xs"},[_vm._v("N/A")])])])}),0)])])],1):_c('s-col',[_c('div',{staticClass:"pad-top-xl margin-top-xl"},[_c('img',{staticClass:"block margin-right-auto margin-left-auto margin-top-xl",attrs:{"src":`${_vm.$store.getters.assetsPath}/img/placeholder.png`}}),_c('p',{staticClass:"text-center margin-top-xs margin-bottom-xs text-md font-semibold"},[_vm._v(" You haven't created any tax category classifications yet! ")]),_c('p',{staticClass:"text-center margin-top-xs jobs-intro"},[_vm._v(" Get started by creating a new classification for your items. ")]),_c('h3',{staticClass:"margin-top-lg"},[_c('router-link',{attrs:{"to":{
              name: 'bulk_job',
              params: { companyId: _vm.companyId, accountId: _vm.accountId },
            }}},[_c('a',{staticClass:"margin-left-auto margin-right-auto"},[_c('button',{staticClass:"primary"},[_vm._v(" New classification ")])])])],1)])])],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.paginationTotalRecords > 0 && !_vm.loading),expression:"paginationTotalRecords > 0 && !loading"}],staticClass:"margin-bottom-xl"},[_c('s-pagination',{attrs:{"startindex":_vm.paginationStartIndex(),"rowsperpage":_vm.paginationRowsPerPage,"totalrecords":_vm.paginationTotalRecords},on:{"s-paginate":_vm.onPageChange}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }