<template>
  <div class="fs-exclude">
    <s-row class="heading-decsription">
      <s-col span="11" class="pad-bottom-none">
        <h3>
          Avalara Taxability Matrix
        </h3>
        <p class="margin-bottom-md">
          Understand how your transactions are taxed across jurisdictions using
          a taxability matrix. The matrix displays each individual tax code in a
          table so you can easily see the jurisdiction and the different tax
          rates based on the jurisdiction's rules.
        </p>
      </s-col>
      <s-col span="10" class="pad-top-none pad-bottom-md"
      >
        <s-alert status="info" nodismiss="">
          <div class="flex">
            The taxability matrix is updated on the first day of each month.<a
              href="https://help.avalara.com/Avalara_AvaTax_Update/View_a_taxability_matrix"
              class="margin-left-sm"
          >Learn more</a
          >
          </div>
        </s-alert>
      </s-col
      >
    </s-row>
    <s-row>
      <s-col v-if="false" offset="1" span="5">
        <s-box>
          <s-row
          >
            <s-col class="pad-bottom-none" span="3"
            ><img
                class="left"
                :src="
                  `${$store.getters.assetsPath}/img/taxability_avatax.png`
                "/></s-col
            >
            <s-col class="pad-bottom-none" span="9">
              <div class="left pad-left-sm">
                <h3 class="header">
                  Generate a taxability matrix
                </h3>
                <div>
                  Use the existing tax codes and jurisdictions set up in your
                  Avatax.
                </div>
                <h3>
                  <router-link
                      :to="{
                      name: 'taxability_avatax',
                      params: { companyId: $store.getters.companyID },
                    }"
                  >
                    <a>
                      <button
                          class="primary"
                          title="generate taxability for your setup"
                      >
                        Generate
                      </button>
                    </a>
                  </router-link>
                </h3>
              </div>
            </s-col
            >
          </s-row
          >
        </s-box>
      </s-col>
      <s-col span="5" class="taxability-search">
        <s-box>
          <s-row
          >
            <s-col class="pad-bottom-none" span="3"
            ><img
                :src="
                  `${$store.getters.assetsPath}/img/taxability_search.png`
                "/></s-col
            >
            <s-col class="pad-bottom-none" span="9">
              <div class="left pad-left-sm">
                <h3 class="header">
                  Build a taxability matrix
                </h3>
                <div>
                  Build a custom taxability matrix by searching for specific tax
                  codes, countries, and regions.
                </div>
                <h3>
                  <a>
                    <button
                        @click="onTaxabilityBuild"
                        class="primary"
                        title="build your own taxability matrix"
                    >
                      Build
                    </button>
                  </a>
                </h3>
              </div>
            </s-col>
          </s-row
          >
        </s-box>
      </s-col>
      <s-col span="5">
        <s-box>
          <s-row
          >
            <s-col class="pad-bottom-none" span="3"
            ><img
                :src="
                  `${$store.getters.assetsPath}/img/taxability_download.png`
                "/></s-col
            >
            <s-col class="pad-bottom-none" span="9">
              <div class="left pad-left-sm">
                <h3 class="header">
                  Download a full taxability matrix
                </h3>
                <div>
                  <label for="select-country">Select country</label>
                  <s-select
                      search
                      inputid="select-country"
                      id="select-country"
                      placeholder="Select Country"
                      :optionsList="JSON.stringify(allRegionsData)"
                      @s-select="onRegionSelected"
                  >
                  </s-select>
                </div>
                <h3>
                  <a
                      :href="
                      '/api/taxability-download/taxability_' +
                        selectedRegion +
                        '.xlsx'
                    "
                  >
                    <button
                        @click="captureFullTaxabilityDownload"
                        class="primary icon-leading"
                        title="download full taxability matrix"
                        link
                    >
                      <s-icon name="download"></s-icon>
                      Download (.xlsx)
                    </button>
                  </a>
                </h3>
              </div>
            </s-col>
          </s-row
          >
        </s-box>
      </s-col>
    </s-row>
  </div>
</template>
<script lang="ts">
import {Component, Vue, Prop, Watch, Provide} from "vue-property-decorator";
import analyticsLayerUtil from "./../utils/index";

enum Region {
  US = "US",
  CA = "CA",
}

interface SelectOption {
  label: string;
  value: string;
  selected?: boolean;
}

interface JurisdictionResponse {
  country_code: string;
  country_name: string;
  jurisdictions: object[];
}

@Component({})
export default class Taxability extends Vue {
  @Provide("$api") $api: any;
  allRegionsData: SelectOption[] = [];
  selectedRegion = "ALL";

  mounted() {
    this.$api
        .get(`/api/jurisdictions/`)
        .then((response) => {
          const allJurisdictionsForCountries: JurisdictionResponse[] =
              response.data;
          // this.allRegionsData.push({label: "United States", value: Region.US}, {label: "Canada", value: Region.CA})
          allJurisdictionsForCountries.forEach((country) => {
                if (country.country_code != Region.US && country.country_code != Region.CA) {
                  this.allRegionsData.push({
                    label: country.country_name,
                    value: country.country_code
                  })
                }
              }
          );
          this.allRegionsData.sort((a, b) => a.label < b.label ? -1 : 1)
          this.allRegionsData.splice(0, 0, {label: "All Countries", value: "ALL"}, {label: "United States", value: Region.US}, {label: "Canada", value: Region.CA})
        })
        .catch((error) => this.$store.commit("stopLoading"));
  }

  public onRegionSelected(e: any) {
    this.selectedRegion = e.detail.item.value;
  }

  public onTaxabilityBuild(e: any) {
    analyticsLayerUtil.commitDataLayer();
    this.$router.push({
      path: `/taxability/search`,
    });
  }

  public captureFullTaxabilityDownload() {
    const props = {
      taxibilityMatrix: {
        taxibilityMatrixInfo: {
          selectedCountry: this.selectedRegion,
        },
      },
    };
    analyticsLayerUtil.addToDataLayer(props);
    if (this.$store.getters.analyticsEnabled) {
      window._satellite.track("taxability_full_download");
    }
  }
}
</script>
<style scoped lang="scss">
.heading-decsription {
  * {
    line-height: 1.4rem;
  }

  p {
    font-size: 0.92rem;
    font-weight: 400;
    color: var(--color-gray-dark);
  }

  h3 {
    font-weight: bold;
    font-size: 1.7rem;
  }
}

.header {
  font-size: 1.1rem;
}

.taxability-search {
  s-box {
    height: 100%;
  }
}
</style>
