<template>
  <router-link
    class="card-link"
    :to="{
      name: 'tree',
      query: {
        tree: item.name,
        tab: 'interactive',
        q: $route.query.q,
        rank: rank,
      },
    }"
  >
    <s-box
      v-on:click="onSelectTree"
      :class="[
        'card',
        'mx-auto',
        'decision-interactive',
        isSearch ? 'decision-interactive-search' : '',
      ]"
      v-bind:data-cy="item.name + '-decision-interactive'"
    >
      <div class="headline mb-1">{{ item.description }}</div>
      <div class="badges">
        <s-badge :count="treeClassification(item.taxcodes)"></s-badge>
        <s-badge
          class="taxcodes-badge"
          :count="item.taxcodes.length + ' Tax Codes'"
        ></s-badge>
      </div>
      <SearchResultItem v-bind:item="highlightedResult()" v-bind:rank="rank" />
      <h4 class="group-view">
        View grouping
        <span>
          <s-icon
            class="right right-icon"
            name="chevron-right"
            role="img"
            aria-label="click"
          ></s-icon>
        </span>
      </h4>
    </s-box>
  </router-link>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import SearchResultItem from "@/components/SearchResultItem.vue";

@Component({
  components: {
    SearchResultItem,
  },
})
export default class SearchResultGroupedItem extends Vue {
  @Prop() private item!: any;
  @Prop() private rank!: any;

  private productTaxcodes = ["P", "D"];
  private serviceTaxcodes = ["S"];
  private otherTaxcodes = ["O"];

  get isSearch() {
    return this.$route.query.q && this.$route.query.q !== "";
  }

  get itemLink() {
    return {};
  }

  highlightedResult() {
    return Array.from(this.item.results.values()).find(
      (x: any) => x.resultType !== "Tree" && x.resultInclude
    );
  }

  additionalResults() {
    return Array.from(this.item.results.entries()).filter(
      (x: any) => !x[1].resultInclude
    );
  }

  treeClassification(taxcodes: Array<string>) {
    const taxcodeTypes = new Set(
      taxcodes.map((t) => {
        if (this.productTaxcodes.includes(t[0])) return "Goods";
        else if (this.serviceTaxcodes.includes(t[0])) return "Services";
        else return "Goods and services";
      })
    );

    if (taxcodeTypes.size === 1) {
      return taxcodeTypes.values().next().value;
    }

    return "Goods and services";
  }

  onSelectTree() {
    if (this.$store.getters.analyticsEnabled) {
      window._satellite.track("interactive_session");
    }
  }
}
</script>

<style scoped lang="scss">
s-badge[count="Goods"] {
  background-color: var(--color-green-lighter);
  color: var(--color-grey-dark);
  border: 1px solid var(--color-green-medium);
}
s-badge[count="Services"] {
  background-color: var(--color-yellow-lighter);
  color: var(--color-grey-dark);
  border: 1px solid var(--color-yellow-medium);
}
s-badge[count="Goods and services"] {
  background-color: var(--color-orange-lighter);
  color: var(--color-grey-dark);
  border: 1px solid var(--color-orange-medium);
}
.decision-interactive {
  width: 100%;
  height: 120px;
}

.decision-interactive-search {
  height: 320px;
}

.decision-interactive:hover {
  background-color: var(--color-gray-lightest);
  box-shadow: 3px 3px 3px var(--color-gray-lightest);
}

.group-view {
  position: absolute;
  bottom: 0;
  right: 0;
}

.right-icon {
  line-height: 1.5;
}
</style>
