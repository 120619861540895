<template>
  <div class="margin-top-sm fs-exclude">
    <h1 class="margin-bottom-none">Switch Account</h1>
    <div class="account-search-intro">
      Search by account name or account ID to find an account
    </div>
    <s-row v-show="errorMessage !== ''">
      <s-col>
        <s-alert nodismiss class="margin-top-sm" status="error">
          <div>
            {{ errorMessage }}
          </div>
        </s-alert>
      </s-col>
    </s-row>
    <s-row class="pad-top-md">
      <s-col span="4">
        <input
          type="text"
          autofocus="true"
          v-model="searchTerm"
          @keyup.enter.stop.prevent="onSearchSubmit($event)"
        />
      </s-col>
      <s-col span="1">
        <h3 class="margin-top-none margin-bottom-none">
          <button
            class="primary"
            @click="onSearchClicked($event)"
            v-bind:disabled="searchTerm.trim().length === 0"
          >
            Search
          </button>
        </h3>
      </s-col>
    </s-row>
    <s-row v-if="!loading">
      <s-col v-if="isLaunchingAccount == true">
        <div class="pad-top-xl margin-top-xl">
          <p class="font-semibold text-center text-md">
            Account context switched successfully. Redirecting to
            <a href="/" class="redirect-link">Home</a> page....
          </p>
        </div>
      </s-col>
      <s-col v-else-if="accountList.length > 0">
        <s-table-container class="margin-bottom-sm" tabindex="0">
          <table class="row-height-sm">
            <thead class="table-header">
              <tr>
                <th>Account ID</th>
                <th>Account Name</th>
                <th>Account Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="account in accountList" v-bind:key="account.id">
                <td>
                  {{ account.id }}
                </td>
                <td>
                  {{ account.name }}
                </td>
                <td>
                  {{ account.accountStatusId }}
                </td>
                <td>
                  <button
                    @click="onAccountLaunchClicked($event, account.id)"
                    class="primary small"
                  >
                    Launch
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </s-table-container>
      </s-col>
      <s-col v-else-if="isSearchClickedAtleastOnce == true">
        <div class="pad-top-xl margin-top-xl">
          <p class="font-semibold text-center text-md">
            No accounts found! Please modify search term and try again.
          </p>
        </div>
      </s-col>
    </s-row>
    <s-dialog id="case-number" noscroll="" aria-modal="true" ref="case-number">
      <div slot="header" id="dialog-title">
        Enter the Salesforce case number
      </div>
      <div slot="body">
        <s-container>
          <s-row>
            <s-col span="6" class="font-semibold">Account ID</s-col>
            <s-col span="6" class="font-semibold">
              {{ selectedAccountId }}
            </s-col>
          </s-row>
          <s-row>
            <s-col span="6" class="font-semibold">
              Case Number <span style="color: red">*</span>
            </s-col>
            <s-col span="6">
              <input type="text" v-model="caseNumber" />
            </s-col>
          </s-row>
        </s-container>
      </div>
      <div slot="footer">
        <button
          class="primary small"
          @click="onSaveCaseNumberDialog()"
          v-bind:disabled="caseNumber.trim().length < 2"
        >
          Save
        </button>
        <button class="secondary small" @click="onCloseCaseNumberDialog()">
          Cancel
        </button>
      </div>
    </s-dialog>
  </div>
</template>

<script lang="ts">
import { Vue, Provide, Component } from "vue-property-decorator";
import errorsUtil from "./../utils/errorMappings";
import _ from "lodash";

@Component({
  components: {},
})
export default class SupportSwitchAccount extends Vue {
  @Provide("$api") $api: any;
  @Provide("$_") $_: any;

  private accountList = [];
  private loading = false;
  private errorMessage = "";
  private searchTerm = "";
  private isSearchClickedAtleastOnce = false;
  private selectedAccountId = "";
  private caseNumber = "";
  private isLaunchingAccount = false;

  onSearchSubmit(event) {
    if (this.searchTerm.trim().length === 0) {
      this.searchTerm = "";
      return;
    }
    this.onSearchClicked(event);
  }

  async onSearchClicked(event) {
    this.isLaunchingAccount = false;
    this.isSearchClickedAtleastOnce = true;
    this.searchTerm = this.searchTerm.trim();
    this.accountList = [];
    this.loading = true;
    this.$store.commit("startLoading");
    try {
      const accountListResponse = await this.$api.get(
        `api/support/accountsearch?term=${this.searchTerm}`
      );
      this.accountList = accountListResponse.data.value;
      this.$store.commit("stopLoading");
      this.loading = false;
    } catch (error) {
      this.$store.commit("stopLoading");
      this.loading = false;
      const errorCode = error.response.data.code;
      if (_.has(errorsUtil.errorMessages, errorCode)) {
        this.$store.commit("recordError", "");
        this.errorMessage = errorsUtil.errorMessages[errorCode];
      }
      this.$store.commit("stopLoading");
      this.loading = false;
    }
  }

  onAccountLaunchClicked(event, accountId) {
    this.selectedAccountId = accountId;
    this.openCaseNumberDialog();
  }

  openCaseNumberDialog() {
    (this.$refs["case-number"] as any).showModal();
  }

  onCloseCaseNumberDialog() {
    this.selectedAccountId = "";
    this.caseNumber = "";
    (this.$refs["case-number"] as any).close();
  }

  async onSaveCaseNumberDialog() {
    (this.$refs["case-number"] as any).close();
    try {
      this.caseNumber = this.caseNumber.trim();
      this.loading = true;
      this.$store.commit("startLoading");
      await this.$api.get(
        `api/support/accountoverride/${this.selectedAccountId}/${this.caseNumber}`
      );
      this.$store.commit("stopLoading");
      this.isLaunchingAccount = true;
      this.loading = false;
      window.location.href = "/";
    } catch (error) {
      this.$store.commit("stopLoading");
      this.loading = false;
      const errorCode = error.response.data.code;
      if (_.has(errorsUtil.errorMessages, errorCode)) {
        this.$store.commit("recordError", "");
        this.errorMessage = errorsUtil.errorMessages[errorCode];
      }
    }
  }
}
</script>

<style scoped lang="scss">
.table-header {
  tr {
    background-color: var(--color-gray-lightest);
  }
}
.account-search-intro {
  color: var(--color-gray-dark);
}

a.redirect-link {
  display: inline !important;
}
</style>
