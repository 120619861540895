<template>
  <div v-if="!treeNotFound">
    <s-row>
      <s-col span="12" class="no-bm">
        <h2 class="results-header accent--text">
          Interactive session for {{ description }}
        </h2>
      </s-col>
    </s-row>
    <s-row>
      <s-col span="6">
        <Step
          v-for="(step, i) in steps"
          v-bind:key="step.name || step.value"
          v-bind:taxCodes="taxcodesDict"
          v-bind:stepResult="step"
          v-bind:id="i"
        />
      </s-col>
      <s-col span="6">
        <s-row>
          <s-col span="12">
            <div class="card">
              <div class="title">
                Tax Codes
                <s-badge
                  :count="
                    Object.keys(taxCodesLeft).length +
                    (Object.keys(taxCodesLeft).length > 1
                      ? ' Tax codes'
                      : ' Tax code') +
                    ' remaining'
                  "
                  class="green lighten-2 white--text ml-3 taxcodes-badge"
                  data-cy="remaining-taxcodes"
                  label
                  v-if="Object.keys(taxCodesLeft).length > 0"
                ></s-badge>
              </div>

              <div class="simple-table">
                <template>
                  <table>
                    <thead>
                      <tr>
                        <th class="text-left">Code</th>
                        <th class="text-left">Description</th>
                        <th class="text-left"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(description, code) in taxcodesDict"
                        :key="code"
                        :data-cy="
                          taxCodesLeft[code] === true ? 'higlithed-tax-row' : ''
                        "
                        :class="
                          taxCodesLeft[code] === true
                            ? ['green', 'lighten-3']
                            : []
                        "
                      >
                        <td>{{ code }}</td>
                        <td>
                          <div>
                            {{ description }}
                            <div
                              class="new-taxcode text-xs font-italic font-bold"
                              v-if="
                                effectiveFrom(code) &&
                                isNewTaxcode(effectiveFrom(code))
                              "
                            >
                              {{ newTaxcodeText(effectiveFrom(code)) }}
                            </div>
                          </div>
                        </td>
                        <td>
                          <button
                            class="primary"
                            title="Use this taxcode"
                            v-if="widget"
                            v-on:click.prevent="onUseThis(code, description)"
                          >
                            Use this
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </template>
              </div>
            </div>
          </s-col>
        </s-row>
      </s-col>
    </s-row>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Provide, Watch } from "vue-property-decorator";
import Step from "@/components/Step.vue";
import dayjs from "dayjs";

interface DictObj {
  [key: string]: any;
}
interface ContainsDictObj {
  [key: string]: boolean;
}

@Component({
  components: {
    Step,
  },
})
export default class TreeViewInteractive extends Vue {
  @Provide("$api") $api: any;

  @Prop() private tree!: string;
  @Prop() private decisionId!: string;
  @Prop() private decisionName!: string;

  private treeNotFound = false;
  private description = "";
  private steps: Array<any> = [];
  private parameters: Array<any> = [];
  private taxcodesList: Array<string> = [];
  private taxcodesDict = {};
  private taxCodesLeft: ContainsDictObj = {};
  private taxCodesLoading = true;
  private parametersLoading = true;
  private showTaxability = false;
  private taxabilityMatrix: DictObj = {};
  private search: Array<string> = [];
  private taxabilityLoading = true;
  private taxabilityOnlyActive = false;
  private taxabilityDialogShown = false;

  @Watch("tree")
  getTree() {
    if (this.tree) {
      this.$store.commit("startLoading");
      this.$api
        .get("api/tree/" + this.tree)
        .then((response: any) => {
          const treeDetails = response.data;
          this.description = treeDetails["name"];
          this.parameters = treeDetails["parameters"];
          this.taxcodesList = treeDetails["taxcodes"];
          this.$api
            .post("api/taxcodes/", this.taxcodesList)
            .then((response: any) => {
              const result = response.data;
              result.sort((a: any, b: any) =>
                a.code < b.code ? -1 : a.code > b.code ? 1 : 0
              );
              result.forEach((taxcode: any) => {
                this.$store.commit("setTaxcode", {
                  code: taxcode.code,
                  description: taxcode.description,
                  additionalDescription: taxcode.additionalDescription,
                  effectiveFrom: taxcode.effectiveFrom,
                });
              });
              result.forEach((taxcode: any) => {
                this.$set(this.taxcodesDict, taxcode.code, taxcode.description);
              });
            })
            .finally(() => {
              this.$store.commit("stopLoading");
              this.taxCodesLoading = false;
            });
          if (this.decisionId) {
            this.$store.commit("startLoading");
            this.$api
              .post("api/decision/" + this.decisionId)
              .then((response: any) => {
                const stepResult = response.data[0];
                this.getNextTaxCodes(stepResult);
                this.steps.push(stepResult);
                this.$store.commit("stopLoading");
              });
          } else {
            this.$store.commit("startLoading");
            this.$api
              .post(
                "api/next-step/",
                {},
                {
                  params: {
                    node_name: this.description,
                    node_type: "Tree",
                    q: this.$route.query.q,
                    rank: this.$route.query.rank,
                  },
                }
              )
              .then((response: any) => {
                const stepResult = response.data[0];
                this.getNextTaxCodes(stepResult);
                this.steps.push(stepResult);
                this.$store.commit("stopLoading");
              });
          }
        })
        .catch((error) => {
          this.$store.commit("stopLoading");
          this.$store.commit(
            "recordError",
            `Session for ${this.tree} not found. Try reloading the page`
          );

          this.treeNotFound = true;
        });
    }
  }

  restart() {
    this.steps = [];
    this.getTree();
  }

  getNext(r: any, answers: any, id: number, displayName?: any) {
    this.$store.commit("startLoading");
    this.steps.splice(id + 1);
    this.$api
      .post("api/next-step/", answers, {
        params: {
          node_name: r.name,
          node_id: r.decisionId,
          q: this.$route.query.q,
          rank: this.$route.query.rank,
        },
      })
      .then((response: any) => {
        const stepResult = response.data[0];
        this.getNextTaxCodes(stepResult);
        this.steps.push(stepResult);
        this.$store.commit("stopLoading");
      });
    const answer = Object.values(answers)[0];
    (this.$parent as any).captureInteractiveSession(
      r.question,
      displayName || answer,
      this.description
    );
  }

  @Watch("description")
  captureTreeName() {
    if (this.description.length > 0)
      (this.$parent as any).captureInteractivePageLoad(this.description);
  }

  onUseThis(code, description) {
    const taxcodeDetail = {
      code,
      desc: description,
      short_desc: description,
    };
    window.parent.postMessage(JSON.stringify(taxcodeDetail), "*");
  }

  getNextTaxCodes(r: any) {
    if (r.value) {
      this.taxCodesLeft = { [r.value]: true };
      return;
    }
    this.$store.commit("startLoading");
    this.$api
      .get("api/decision/" + r.decisionId + "/taxcodes/")
      .then((response: any) => {
        this.taxCodesLeft = {};
        for (const t in response.data) {
          this.taxCodesLeft[response.data[t]] = true;
        }
        this.$store.commit("stopLoading");
      });
  }

  get widget() {
    return this.$store.state.widget;
  }

  mounted() {
    this.getTree();
  }

  reload() {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  private newTaxcodeText(date) {
    return date.endsWith("Z")
      ? "New tax code! Code will be active from " +
          dayjs(date.substr(0, date.length - 1)).format("D MMM, YYYY")
      : "";
  }

  private isNewTaxcode(effFrom) {
    return dayjs(effFrom).isAfter(dayjs(new Date()));
  }

  effectiveFrom(taxcode: string) {
    return this.$store.getters.taxcode(taxcode)?.effectiveFrom;
  }
}
</script>
<style lang="scss">
a.inline {
  display: inline !important;
}
.taxability-table table {
  th {
    background-color: #656565 !important;
    color: #fff !important;
    min-width: 128px;
    max-width: 128px;
  }

  td {
    padding: 0 !important;
    min-width: 128px;
    max-width: 128px;
  }

  th:first-child {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 5;
  }

  td:first-child {
    background-color: #afb3af !important;
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 1;
  }

  tr:last-child {
    .taxability-item-active {
      border-bottom: solid #a5d6a7;
    }
  }

  .taxability-item {
    height: 100%;
    width: 100%;
    position: relative;

    border-color: black;
    border: 1px;
    span {
      position: absolute;
      top: 30%;
      left: 0;
      width: 100%;
    }

    &.taxability-item-Taxable {
      background: #fff;
    }
    &.taxability-item-Exempt {
      background: #fff9c4;
    }
    &.taxability-item-active {
      border-left: solid #a5d6a7;
      border-right: solid #a5d6a7;
    }
    &[class*="%"] {
      color: brown;
      font-weight: bolder;
      background: #eeeeee;
    }
  }
}
.headline {
  font-size: 1.5rem !important;
  font-weight: 400;
  letter-spacing: normal !important;
}
table {
  td,
  th {
    padding: 5px 16px;
  }
  .green.lighten-3 {
    background-color: var(--color-green-light);
    border-color: var(--color-green-light);
  }
}
.title {
  font-size: 20px;
  font-weight: 600;
  s-badge {
    padding: 10px 10px;
    font-size: 14px;
    margin-left: 5px;
  }
}
.no-bm {
  margin-bottom: 0;
  padding-bottom: 0;
}
td {
  button.primary {
    width: max-content;
    margin: 5px;
    margin-right: 15px;
  }
}
.new-taxcode {
  color: var(--color-blue-dark);
}
</style>
