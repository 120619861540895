<template>
  <div class="margin-top-sm fs-exclude">
    <CompaniesSwitcher
      class="margin-bottom-xs"
      v-bind:currentId="companyId"
      v-on:company-switched="companySwitched"
      v-if="this.$store.getters.companies.length > 0 && currentStep == 0"
    />
    <a
      href="https://help.avalara.com/z_AvaWeb/Customer_Learning/UKL_Internal/Jaime's_Sandbox/Tax_category_classification"
      class="helper flex align-items-center right"
    >
      <s-icon name="info-circle" class="text-sm margin-right-xs"></s-icon
      ><span class="text-xs-strong">Need help?</span>
    </a>
    <s-row v-if="!loading">
      <s-col>
        <div v-if="currentStep == 0">
          <div v-if="!data" class="pad-top-xl margin-top-xl">
            <img
              :src="`${$store.getters.assetsPath}/img/placeholder.png`"
              class="block margin-right-auto margin-left-auto margin-top-xl"
            />
            <p
              class="text-center margin-top-xs margin-bottom-xs text-md font-semibold"
            >
              You haven't created any classification schema yet!
            </p>
            <p class="text-center margin-top-xs jobs-intro">
              Get started by creating a new schema for your items.
            </p>
            <h3 class="margin-top-lg new-schema-button">
              <a @click="nextStep" class="inline">
                <button class="primary">
                  New schema
                </button>
              </a>
            </h3>
          </div>
          <div v-else>
            <h1 class="margin-bottom-none">Classification schema</h1>
            <s-row v-show="errorMessageTop !== ''">
              <s-col>
                <s-alert nodismiss class="margin-top-sm" status="error">
                  <div>
                    {{ errorMessageTop }}
                  </div>
                </s-alert>
              </s-col>
            </s-row>
            <s-row class="file-config step-details margin-top-md">
              <s-col class="pad-bottom-none" span="8">
                <div class="vertical-align-items-center">
                  <h3 class="inline margin-bottom-none margin-top-none">
                    File Configuration
                  </h3>
                </div>
                <div class="margin-top-sm pad-left-xs">
                  <b>File type</b>: {{ fileType }}
                </div>
                <div
                  v-if="fileType == 'Other'"
                  class="margin-top-sm pad-left-xs"
                >
                  <b>Separator</b>: {{ fileSeparator }}
                </div>
                <div class="margin-top-sm pad-left-xs">
                  <b>Has header</b>: {{ hasFileHeader ? "Yes" : "No" }}
                </div>
                <div class="margin-top-sm pad-left-xs">
                  <b>Fields</b>:
                  {{
                    data.schema.fields
                      .map((f) =>
                        f.autogenerate ? f.name + " (Autogenerated)" : f.name
                      )
                      .join(", ")
                  }}
                </div>
              </s-col>
            </s-row>

            <s-row
              v-if="fieldMapping"
              class="fields-config step-details margin-top-md"
            >
              <s-col class="pad-bottom-none" span="4">
                <div class="vertical-align-items-center">
                  <h3 class="inline margin-bottom-none margin-top-none">
                    Fields Configuration
                  </h3>
                </div>
                <div class="mappings-arrow margin-top-sm pad-left-xs">
                  <div
                    class="vertical-align-items-center"
                    v-for="k in fieldMappingKeys()"
                    :key="k"
                  >
                    <b>{{ k }}</b>
                    <s-icon
                      class="margin-left-xs margin-right-xs"
                      name="arrow-right"
                    ></s-icon>
                    {{ formatFieldMapping(fieldMapping[k]) }}
                  </div>
                </div>
              </s-col>
            </s-row>
            <s-row class="pad-top-xl">
              <s-col>
                <button @click="nextStep" class="primary icon-trailing">
                  Edit<s-icon name="arrow-right"></s-icon>
                </button>
              </s-col>
            </s-row>
          </div>
        </div>
        <BulkSchemaCreate
          v-else-if="currentStep == 1"
          v-on:step-done="nextStep"
          v-bind:accountId="accountId"
          v-bind:companyId="companyId"
          v-bind:data="data"
        />
        <BulkSchemaMapping
          v-else-if="currentStep == 2"
          v-on:step-done="nextStep"
          v-bind:accountId="accountId"
          v-bind:companyId="companyId"
        />
      </s-col>
    </s-row>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Provide, Watch } from "vue-property-decorator";
import BulkSchemaCreate from "@/components/BulkSchemaCreate.vue";
import BulkSchemaMapping from "@/components/BulkSchemaMapping.vue";
import CompaniesSwitcher from "@/components/CompaniesSwitcher.vue";
import errorsUtil from "../utils/errorMappings";
import _ from "lodash";
@Component({
  components: {
    BulkSchemaCreate,
    BulkSchemaMapping,
    CompaniesSwitcher,
  },
})
export default class BulkSchema extends Vue {
  @Provide("$api") $api: any;
  @Provide("$_") $_: any;

  @Prop() private companyId!: string;
  @Prop() private accountId!: string;

  private errorMessageTop = "";

  currentStep = 0;
  loading = true;
  data = null;
  fieldMapping: any = null;
  fileType = "";
  fileSeparator = "";
  hasFileHeader = true;

  mounted() {
    this.getSchemaAndMappings();
  }

  async nextStep() {
    this.currentStep = this.currentStep + 1;
  }

  async previousStep() {
    await this.getSchemaAndMappings();
    this.currentStep = this.currentStep - 1;
  }

  async gotoStep(step) {
    await this.getSchemaAndMappings();
    this.currentStep = step;
  }

  private setSchemaData(schemaData) {
    this.data = schemaData;
  }

  private displayError(error) {
    const errorCode = error.response.data.code;
    if (_.has(errorsUtil.errorMessages, errorCode)) {
      this.$store.commit("recordError", "");
      this.errorMessageTop = errorsUtil.errorMessages[errorCode];
    }
  }

  companySwitched(event) {
    const params = Object.assign({}, this.$route.params, {
      companyId: event.companyId,
    });
    this.$router.push({ name: "schema_list", params: params });
    location.reload();
  }

  getFileType(delimiter: string) {
    if (delimiter == ",") {
      return "CSV";
    } else if (delimiter == "|") {
      return "PSV";
    } else if (delimiter == "\t") {
      return "TSV";
    } else {
      return "Other";
    }
  }

  private fieldMappingKeys() {
    return this.fieldMapping ? Object.keys(this.fieldMapping) : [];
  }

  private formatFieldMapping(mapping) {
    if (mapping.fields.length == 1) {
      return mapping.fields[0];
    } else {
      return mapping.joinType == "concat"
        ? mapping.fields.join(mapping.delimiter) + " (concatenated)"
        : mapping.fields.join(" or ") + " (coalesced)";
    }
  }

  async getSchemaAndMappings() {
    this.$store.commit("startLoading");
    this.loading = true;
    try {
      const response = await this.$api.get(
        `item-classification-api/v1/company/${this.companyId}/defaultSchema`
      );
      const mappingResponse = await this.$api.get(
        `item-classification-api/v1/company/${this.companyId}/defaultMapping`
      );
      this.data = response.data;
      this.fieldMapping = mappingResponse.data;
      if (this.data) {
        this.fileType = this.getFileType(response.data.schema.delimiter);
        this.fileSeparator = response.data.schema.delimiter;
        this.hasFileHeader = response.data.schema.hasHeader;
      }
      this.$store.commit("stopLoading");
      this.loading = false;
    } catch (error) {
      if ((error as any).response.status === 404) {
        this.$store.commit("recordError", "");
      } else {
        this.displayError(error);
      }
      this.$store.commit("stopLoading");
      this.loading = false;
    }
  }
}
</script>

<style scoped lang="scss">
a:visited {
  color: var(--color-blue-dark);
}
.new-schema-button {
  margin-left: 46%;
}
.vertical-align-items-center {
  display: flex;
  align-items: center;
}
.step-details {
  s-icon {
    color: var(--color-green-dark);
  }
}
.mappings-arrow {
  s-icon {
    color: var(--color-gray-darkest);
  }
}
</style>
