import Vue from "vue";
import Vuex from "vuex";
import _ from "lodash";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    allCategories: [],
    backRoute: null,
    widget: false,
    loading: false,
    category: null,
    user: {
      isLoggedIn: false,
      customerAvataxAccountId: null,
      avataxUserId: null,
    },
    avataxRole: null,
    herculeFeatures: { bulk: null, taxability: null },
    herculeRole: null,
    error: "",
    success: "",
    toast: "",
    inputSchema: {},
    sessionExpiry: "",
    sessionRefreshing: false,
    sessionRefreshPromise: null,
    analyticsScript: "",
    taxcodeDetails: {},
    companies: [],
    currentCompanyId: null,
    bulkCustomerId: null,
    assetsPath: null,
    assetsPathGlobal: null,
    countryJurisdictions: {},
  },
  getters: {
    userID: (state) => {
      return state.user.avataxUserId;
    },
    avataxRole: (state) => {
      return state.avataxRole;
    },
    companyID: (state) => {
      return state.currentCompanyId;
    },
    companyName: (state) => (id) => {
      return (_.find(state.companies, (c: any) => c.id == id) || { name: "" })
        .name;
    },
    accountID: (state) => {
      return state.user.customerAvataxAccountId;
    },
    bulkCustomerID: (state) => {
      return state.bulkCustomerId;
    },
    analyticsEnabled: (state) => {
      return !_.isEmpty(state.analyticsScript);
    },
    taxcode: (state) => (code) => {
      return (
        state.taxcodeDetails[code] || {
          code: "",
          description: "",
          additionalDescription: "",
        }
      );
    },
    featureLevel: (state) => (feature) => {
      if (!(feature in state.herculeFeatures)) {
        return null;
      }
      return state.herculeFeatures[feature];
    },
    companies: (state) => {
      return state.companies;
    },
    assetsPath: (state) => {
      return state.assetsPath;
    },
    assetsPathGlobal: (state) => {
      return state.assetsPathGlobal;
    },
    countryJurisdictions: (state) => (countryCode) => {
      return state.countryJurisdictions[countryCode] || [];
    },
  },
  mutations: {
    startLoading(state) {
      state.loading = true;
    },
    stopLoading(state) {
      state.loading = false;
    },
    recordError(state, error) {
      state.error = error;
    },
    recordSuccess(state, success) {
      state.success = success;
    },
    recordToast(state, toast) {
      state.toast = toast;
    },
    recordSessionExpiry(state, expiry) {
      state.sessionExpiry = expiry;
    },
    sessionRefreshing(state, isSessionRefreshing) {
      state.sessionRefreshing = isSessionRefreshing;
    },
    sessionRefreshPromise(state, promise) {
      state.sessionRefreshPromise = promise;
    },
    recordHerculeFeatures(state, herculeFeatures) {
      for (const f in herculeFeatures) {
        Vue.set(state.herculeFeatures, f, herculeFeatures[f]);
      }
    },
    recordHerculeRole(state, herculeRole) {
      state.herculeRole = herculeRole;
    },
    recordAnalyticsScript(state, scriptUrl) {
      state.analyticsScript = scriptUrl;
    },
    setTaxcode(state, taxcode) {
      Vue.set(state.taxcodeDetails, taxcode.code, taxcode);
    },
    recordCompanies(state, companies) {
      state.companies = companies;
      const defaultCompany: any = _.find(
        state.companies,
        (o: any) => o.isDefault
      );

      if (_.isNil(defaultCompany) && companies.length > 0) {
        state.currentCompanyId = companies[0].id;
      } else {
        state.currentCompanyId = defaultCompany.id;
      }
    },
    recordCurrentCompany(state, currentId) {
      state.currentCompanyId = currentId;
    },
    recordBulkCustomerId(state, bulkCustomerId) {
      state.bulkCustomerId = bulkCustomerId;
    },
    recordAvataxRole(state, role) {
      state.avataxRole = role;
    },
    recordAssetsPath(state, assetsPath) {
      state.assetsPath = assetsPath;
    },
    recordAssetsPathGlobal(state, assetsPath) {
      state.assetsPathGlobal = assetsPath;
    },
    setCountryJurisdictions(state, countryJurisdictions) {
      Vue.set(
        state.countryJurisdictions,
        countryJurisdictions.countryCode,
        countryJurisdictions.jurisdictionNames
      );
    },
  },
  actions: {},
  modules: {},
});
