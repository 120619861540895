<template>
  <div class="margin-top-sm fs-exclude">
    <CompaniesSwitcher
      class="margin-bottom-xs"
      v-bind:currentId="companyId"
      v-on:company-switched="companySwitched"
      v-if="
        this.$store.getters.companies.length > 0 &&
          currentStep == 0 &&
          (!currentJobId || currentJobId === '')
      "
    />
    <a
      href="https://help.avalara.com/z_AvaWeb/Customer_Learning/UKL_Internal/Jaime's_Sandbox/Tax_category_classification"
      class="helper flex align-items-center right"
    >
      <s-icon name="info-circle" class="text-sm margin-right-xs"></s-icon
      ><span class="text-xs-strong">Need help?</span>
    </a>
    <div
      v-if="fromJobsList && currentStep === steps.length - 1"
      class="back-button"
    >
      <a v-on:click="backToAllJobs()">
        <s-icon class="align-self-center" name="arrow-left"></s-icon
        ><span>Back to all classifications</span>
      </a>
    </div>
    <s-row
      v-if="stepperVisible && showStepper() && steps.length != 0"
      class="stepper margin-top-xl"
    >
      <s-col span="8" center>
        <ul class="steps">
          <li
            :style="{ width: `${100 / steps.length}%` }"
            class="completed"
            v-for="s in steps.slice(0, currentStep)"
            v-bind:key="s"
          >
            {{ s }}
          </li>
          <li class="active" :style="{ width: `${100 / steps.length}%` }">
            {{ steps[currentStep] }}
          </li>
          <li
            class="pending"
            :style="{ width: `${100 / steps.length}%` }"
            v-for="s in steps.slice(currentStep + 1)"
            v-bind:key="s"
          >
            {{ s }}
          </li>
        </ul>
      </s-col>
    </s-row>
    <s-row>
      <s-col>
        <BulkUpload
          v-if="steps[currentStep] == 'Upload product catalog'"
          v-on:step-done="nextStep"
          :jobId="currentJobId"
          v-bind:accountId="accountId"
          v-bind:companyId="companyId"
        />
        <BulkQuestionnaire
          v-else-if="steps[currentStep] == 'Fill Questionnaire'"
          v-bind:jobId="currentJobId"
          v-on:step-done="nextStep"
          v-bind:accountId="accountId"
          v-bind:companyId="companyId"
        />
        <BulkCuratedUpload
          v-else-if="steps[currentStep] == 'Upload curated catalog'"
          v-bind:jobId="currentJobId"
          v-on:step-done="nextStep"
          v-bind:accountId="accountId"
          v-bind:companyId="companyId"
        />
        <BulkFieldMapping
          v-else-if="steps[currentStep] == 'Map columns'"
          v-bind:jobId="currentJobId"
          v-on:step-done="nextStep"
          v-bind:accountId="accountId"
          v-bind:companyId="companyId"
        />
        <BulkCategory
          v-else-if="steps[currentStep] == 'Select profile/categories'"
          v-bind:companyId="companyId"
          v-bind:accountId="accountId"
          v-bind:jobId="currentJobId"
          v-on:step-done="nextStep"
        />
        <BulkProgress
          v-else-if="steps[currentStep] == 'Review and create'"
          v-bind:companyId="companyId"
          v-bind:accountId="accountId"
          v-bind:jobId="currentJobId"
          v-on:job-start="toggleStepperOff"
        />
      </s-col>
    </s-row>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Provide, Watch } from "vue-property-decorator";
import BulkFieldMapping from "@/components/BulkFieldMapping.vue";
import BulkQuestionnaire from "@/components/BulkQuestionnaire.vue";
import BulkCuratedUpload from "@/components/BulkCuratedUpload.vue";
import BulkUpload from "@/components/BulkUpload.vue";
import BulkCategory from "@/components/BulkCategory.vue";
import BulkProgress from "@/components/BulkProgress.vue";
import CompaniesSwitcher from "@/components/CompaniesSwitcher.vue";
import { FeatureLevel } from "@/common/privilege";

@Component({
  components: {
    BulkUpload,
    BulkQuestionnaire,
    BulkCuratedUpload,
    BulkFieldMapping,
    BulkCategory,
    BulkProgress,
    CompaniesSwitcher,
  },
  beforeRouteEnter(to: any, from: any, next: any) {
    next((vm: any) => {
      if (from.name === "bulk_job_list") {
        vm.fromJobsList = true;
      }
    });
  },
})
export default class BulkJobCreate extends Vue {
  @Provide("$api") $api: any;
  @Provide("$_") $_: any;

  @Prop() private companyId!: string;
  @Prop() private accountId!: string;
  @Prop() private jobId!: string;
  @Prop() private step!: string;

  currentJobId = this.jobId || null;
  fromJobsList = false;
  stepperVisible = true;

  stepsByRoles: any = {
    customerOrRepWithSupport: [
      "Upload product catalog",
      "Fill Questionnaire",
      "Review and create",
    ],
    customerOrRepWithoutSupport: [
      "Upload product catalog",
      "Fill Questionnaire",
      "Map columns",
      "Select profile/categories",
      "Review and create",
    ],
    expert: [
      "Upload product catalog",
      "Fill Questionnaire",
      "Upload curated catalog",
      "Map columns",
      "Select profile/categories",
      "Review and create",
    ],
  };

  steps: string[] = [];

  currentStep = Number(this.step) || 0;

  FeatureLevel = FeatureLevel;

  async created() {
    this.steps = await this.getSteps();
  }

  private backToAllJobs() {
    this.$router.push({
      path: `/classification/${this.accountId}/${this.companyId}/jobs`,
    });
  }

  updateJobId(jobId) {
    this.currentJobId = jobId;
  }

  async getSteps() {
    if (this.$store.state.herculeRole === "ClassificationInternalExpert") {
      return this.stepsByRoles.expert;
    } else {
      const isFullClassificationAdmin =
        this.$store.state.herculeRole === "ClassificationAdmin" &&
        (this.$store.getters.featureLevel("item-classification") === FeatureLevel.MtccFull || 
        this.$store.getters.featureLevel("item-classification") === FeatureLevel.AtccFull ||
        this.$store.getters.featureLevel("item-classification") === FeatureLevel.AtccTrial);
      if (this.currentJobId && isFullClassificationAdmin) {
        this.$store.commit("startLoading");
        try {
          const response = await this.$api.get(
            `item-classification-api/v1/jobs/${this.companyId}/${this.currentJobId}`
          );
          const jobDetails = response.data;
          return jobDetails.metadata.support &&
            jobDetails.metadata.support == "true"
            ? this.stepsByRoles.customerOrRepWithSupport
            : this.stepsByRoles.customerOrRepWithoutSupport;
        } catch (error) {
          this.$store.commit("stopLoading");
          return this.stepsByRoles.customerOrRepWithoutSupport;
        }
      } else {
        return isFullClassificationAdmin
          ? this.stepsByRoles.customerOrRepWithoutSupport
          : this.stepsByRoles.customerOrRepWithSupport;
      }
    }
  }

  async nextStep() {
    this.steps = await this.getSteps();
    this.currentStep = this.currentStep + 1;
    this.fromJobsList = false;
    const query = Object.assign({}, this.$route.query);
    query.step = this.currentStep.toString();
    this.$router.push({ query });
  }

  async previousStep() {
    this.steps = await this.getSteps();
    this.currentStep = this.currentStep - 1;
    this.fromJobsList = false;
    const query = Object.assign({}, this.$route.query);
    query.step = this.currentStep.toString();
    this.$router.push({ query });
  }

  async gotoStep(step) {
    this.steps = await this.getSteps();
    this.currentStep = Number(step);
    this.fromJobsList = false;
    const query = Object.assign({}, this.$route.query);
    query.step = this.currentStep.toString();
    this.$router.push({ query });
  }

  companySwitched(event) {
    const params = Object.assign({}, this.$route.params, {
      companyId: event.companyId,
    });
    this.$router.push({ name: "bulk_job", params: params });
    location.reload();
  }

  showStepper() {
    //stepper to show only during job creation and not while visiting job summary
    return !(this.fromJobsList && this.currentStep === this.steps.length - 1);
  }

  toggleStepperOff() {
    this.stepperVisible = false;
  }
}
</script>

<style scoped lang="scss">
a:visited {
  color: var(--color-blue-dark);
}
.back-button {
  a:visited {
    color: var(--color-blue-dark);
  }
  span {
    line-height: 2;
  }
}
.steps li {
  list-style-type: none;
  float: left;
  font-size: 12px;
  position: relative;
  text-align: center;
}

.steps li::before {
  content: " ";
  line-height: 16px;
  border: 3px solid var(--color-gray-lighter);
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: block;
  text-align: center;
  margin: 0 auto 4px;
  background-color: var(--color-white);
}

.steps li:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: var(--color-gray-lighter);
  top: 7px;
  left: -50%;
  z-index: -1;
}

.steps li:first-child:after {
  content: none;
}

.steps li.active {
  color: var(--color-blue-dark);
  font-size: 14px;
  font-weight: 600;
}

.steps li.active:before {
  background-color: var(--color-white);
  border: 3px solid var(--color-blue-dark);
  width: 20px;
  height: 20px;
  line-height: 20px;
}

.steps li.active:after {
  background-color: var(--color-blue-dark);
}

.steps li.completed:before {
  background-color: var(--color-blue-dark);
  border: 3px solid var(--color-blue-dark);
}

.steps li.completed:after {
  background-color: var(--color-blue-dark);
}
</style>
