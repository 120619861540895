<template>
    <s-select
      async
      id="custom-select"
      :class="[isError ? 'error custom-select-box' : 'custom-select-box']"
      :inputid="name"
      :disabled="isInputDisabled"
      multiple="true"
      :optionsList="JSON.stringify(data)"
      :placeholder="placeholder"
      @s-select="onSelect"
      @s-deselect="onDeselect"
      @s-input="onInputChanged"
      :nosearch="!isSearcheable"
    >
      <div
        v-if="hasFooter && unselected.length == 0"
        slot="sticky-footer"
        class="pad-all-none"
      >
        <span class="inline-block margin-all-sm"
          ><button class="tertiary text-xs" @click="onUnselectAll">
            Unselect All
          </button></span
        >
      </div>
      <div v-else-if="hasFooter" slot="sticky-footer" class="pad-all-none">
        <span class="inline-block margin-all-sm"
          ><button class="tertiary text-xs" @click="onSelectAll">
            Select All
          </button></span
        >
      </div>
    </s-select>
</template>

<script lang="ts">
interface SelectOption {
  label: string;
  value: string;
  selected: boolean;
}

import { Component, Prop, Vue, Watch } from "vue-property-decorator";

type CallbackFunction = (e: unknown) => void;

@Component
export default class CustomSelectBox extends Vue {
  @Prop({ required: true }) private name!: string;
  @Prop({ default: () => [] }) private data!: SelectOption[];
  @Prop({ required: true }) private onSelect!: CallbackFunction;
  @Prop({ required: true }) private onDeselect!: CallbackFunction;
  @Prop() private onSelectAll!: CallbackFunction;
  @Prop() private onUnselectAll!: CallbackFunction;
  @Prop() private onInputChanged!: CallbackFunction;
  @Prop({ default: () => false }) private hasFooter!: boolean;
  @Prop({ default: () => "Search" }) private placeholder!: string;
  @Prop({ default: () => false }) private isError?: boolean;
  @Prop({ default: () => false }) private isInputDisabled?: boolean;
  @Prop({ default: () => true }) private isSearcheable?: boolean;

  unselected: SelectOption[] = this.data;

  @Watch("data", {
    immediate: true,
    deep: true,
  })
  dataChanged(newData: SelectOption[], oldData: SelectOption[]) {
    this.data = newData;
    this.unselected = this.data.filter((option) => !option.selected);
  }
}
</script>

<style lang="scss" scoped>
.custom-select-box div[ref="select-input-container"] {
  max-height: 6rem;
  overflow-y: scroll;
  min-width: unset;
  max-width: 30rem;
}

s-select div[ref="select-input-container"] input[ref="select-input"] {
  width: 100%;
}

.custom-select-box ul {
  max-width: 35rem;
  min-width: unset;
}
</style>
