import _ from "lodash";

class AnalyticsLayer {
  data = {};
  getUniquePageName({ name, category, subcategory }) {
    if (category) {
      return category + (subcategory ? " > " + subcategory : "");
    }
    return name;
  }
  isGlobalPageLoadTriggered() {
    return window.digitalData;
  }
  firePageLoad(props?: any) {
    if (this.isGlobalPageLoadTriggered()) {
      this.addToDataLayer(props);
      this.commitDataLayer();
    } else {
      this.addToDataLayer(props);
    }
  }
  commitDataLayer(event = "page_view") {
    window._satellite && window._satellite.track(event);
  }
  buildDataLayer() {
    window.digitalData = this.data;
  }
  generateBaseDataLayer({
    name,
    category,
    subcategory,
    accountID,
    isLoggedIn,
    isAvalaraUser,
  }) {
    const data = {
      page: {
        pageInfo: {
          pageName:
            "Hercule|" +
            this.getUniquePageName({ name, category, subcategory }),
          channel: "Hercule",
          category: category || "",
          subcategory: subcategory || "",
          treeName: "",
          language: "US_EN",
          platform: "DESKTOP",
        },
      },
      user: {
        profileInfo: {
          isLoggedIn,
          profileID: "",
          accountID,
          isAvalaraUser,
        },
      },
    };
    this.data = data;
  }
  addToDataLayer(props?: any) {
    this.data = _.merge({}, this.data, props || {});
    this.buildDataLayer();
  }
}
const analyticsLayerUtil = new AnalyticsLayer();
export default analyticsLayerUtil;
