var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.step.question)?_c('div',[_c('div',{staticClass:"card mx-auto",attrs:{"max-width":"100%","outlined":""}},[_c('div',{staticClass:"three-line"},[_c('div',{staticClass:"item-content"},[_c('div',{staticClass:"headline mb-1",staticStyle:{"white-space":"normal"}},[_vm._v(" "+_vm._s(_vm.step.question)+" ")])])]),_vm._l((_vm.step.parameters),function(parameter){return _c('div',{key:parameter.parameterName,staticClass:"actions"},[(_vm.buttonOrDropdown(parameter) == 'button')?_vm._l((parameter.possibleValues),function(answer,buttonIndex){return _c('button',{key:answer.value,class:_vm.chosenAnswers[parameter.parameterName] === answer.value
              ? 'primary--text'
              : 'tertiary',attrs:{"data-cy":'stepId-' + _vm.id + ' option-' + buttonIndex,"data-cy-opt":'stepId-' + _vm.id,"outlined":""},on:{"click":function($event){return _vm.response(
              parameter.parameterName,
              answer.value,
              _vm.step,
              _vm.id,
              $event,
              answer.displayName
            )}}},[_vm._v(" "+_vm._s(answer.displayName)+" ")])}):[_c('form',[_c('label',[_vm._v(" "+_vm._s(parameter.parameterName)+" "),_c('select',{on:{"change":function($event){return _vm.response(
                  parameter.parameterName,
                  $event.target.value,
                  _vm.step,
                  _vm.id,
                  $event
                )}}},[_c('option',{attrs:{"value":"","disabled":"","selected":""}},[_vm._v("Select an option")]),_vm._l((parameter.possibleValues),function(item){return _c('option',{key:item.value,domProps:{"value":item.value}},[_vm._v(_vm._s(item.displayName))])})],2)])])]],2)})],2)]):_c('div',[_c('div',{staticClass:"card",attrs:{"outlined":""}},[_c('div',{attrs:{"three-line":""}},[_c('div',[_c('div',{staticClass:"overline mb-4"},[_vm._v("RESULT")]),_c('div',{staticClass:"headline mb-1 decision-tree-final-result",attrs:{"data-cy":"decision-tree-final-result"}},[_vm._v(" "+_vm._s(_vm.step.name)+" ")]),(
            _vm.effectiveFrom(_vm.step.name) && _vm.isNewTaxcode(_vm.effectiveFrom(_vm.step.name))
          )?_c('div',{staticClass:"new-taxcode text-xs font-italic font-bold"},[_vm._v(" "+_vm._s(_vm.newTaxcodeText(_vm.effectiveFrom(_vm.step.name)))+" ")]):_vm._e(),_c('div',[_vm._v("Description: "+_vm._s(_vm.taxCodes[_vm.step.name]))])])]),_c('div',[_c('button',{staticClass:"secondary red--text",attrs:{"outlined":"","text":""},on:{"click":_vm.restart}},[_vm._v(" Restart ")]),(_vm.widget)?_c('button',{staticClass:"primary",attrs:{"title":"Use this taxcode"},on:{"click":function($event){$event.preventDefault();return _vm.onUseThis(_vm.step)}}},[_vm._v(" Use this ")]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }