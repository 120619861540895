import { FeatureLevel } from "@/common/privilege";

export default {
    async populateTrialLimitExceeded(api, accountId, companyId) {
        const trialLimit = await this.getTrialUsageFromAPI(api, accountId, companyId);
        return trialLimit;
    },

    async getTrialUsageFromAPI(api, accountId, companyId) {
        const trialLimit = await api.get(
            `item-classification-api/v1/trialUsage?companyId=${companyId}&accountId=${accountId}`
        );
        return trialLimit;
    }
}