<template>
  <div class="categories">
    <div v-for="category in categories()" :class="['category']" :key="category">
      <s-icon
        :id="`icon_${category}`"
        :name="isExpanded(category) ? 'chevron-down' : 'chevron-right'"
        class="margin-top-sm margin-right-xs"
        v-on:click="(e) => onCategoryClicked(e, category)"
      ></s-icon>
      {{ category }}
      <div
        :class="['subcategory', 'margin-left-md']"
        v-for="(subcategory, index) in subcategories(category)"
        :key="subcategory"
        v-show="isExpanded(category)"
      >
        <s-icon
          :id="`icon_${category}${index}`"
          :name="
            isExpanded(`${category} > ${subcategory}`)
              ? 'chevron-down'
              : 'chevron-right'
          "
          class="margin-top-sm margin-right-xs"
          v-on:click="(e) => onSubcategoryClicked(e, category, subcategory)"
        ></s-icon>
        {{ subcategory }}
        <div
          v-show="isExpanded(`${category} > ${subcategory}`)"
          class="leaf margin-left-xl  pad-left-sm margin-top-xs"
          v-for="leaf in leaves(category, subcategory)"
          :key="leaf"
        >
          {{ leaf }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { split } from "lodash";
import { Component, Prop, Vue, Provide, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class CategoryPathsViewer extends Vue {
  @Prop() private categoryPaths!: string[];
  @Provide("$_") $_: any;

  categoryTrees: object = {};
  expandedCategoryPaths: string[] = [];

  constructSubcategories(categoryPaths: string[]) {
    const paths = categoryPaths.filter((p) => p.split(" > ").length >= 2);
    const groupedBySubcategory = this.$_.groupBy(
      paths,
      (p) => p.split(" > ")[1]
    );
    return this.$_.mapValues(groupedBySubcategory, (paths) =>
      paths
        .filter((p) => p.split(" > ").length === 3)
        .map((p) => p.split(" > ")[2])
    );
  }

  categories() {
    return Object.keys(this.categoryTrees);
  }

  subcategories(category) {
    return Object.keys(this.categoryTrees[category]);
  }

  leaves(category, subcategory) {
    return this.categoryTrees[category][subcategory];
  }

  onCategoryClicked(e, category: string) {
    if (this.expandedCategoryPaths.indexOf(category) < 0)
      this.expandedCategoryPaths = [...this.expandedCategoryPaths, category];
    else {
      this.expandedCategoryPaths = this.expandedCategoryPaths.filter(
        (p) => !p.startsWith(category)
      );
    }
  }

  onSubcategoryClicked(e, category, subCategory) {
    const categoryPath = `${category} > ${subCategory}`;
    if (this.expandedCategoryPaths.indexOf(categoryPath) < 0)
      this.expandedCategoryPaths = [
        ...this.expandedCategoryPaths,
        categoryPath,
      ];
    else
      this.expandedCategoryPaths = this.expandedCategoryPaths.filter(
        (p) => !p.startsWith(categoryPath)
      );
  }

  isExpanded(categoryPath) {
    return this.expandedCategoryPaths.includes(categoryPath);
  }

  mounted() {
    const categoryToCategoryPaths = this.$_.groupBy(
      this.categoryPaths,
      (path) => path.split(" > ")[0]
    );
    this.categoryTrees = this.$_.mapValues(categoryToCategoryPaths, (paths) =>
      this.constructSubcategories(paths)
    );
  }
}
</script>

<style scoped lang="scss">
.category,
.subcategories {
  s-icon {
    cursor: pointer;
  }
}
</style>
