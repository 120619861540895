import _ from "lodash";

class Errors {
  errorMessages = {
    DefaultSchemaFileReadError:
      "The uploaded file does not satisfy the default schema. Please ensure file follows the schema.",
    DefaultSchemaNotFound:
      "No schema found. Please create a default schema or proceed without it.",
    PayloadError: "Incorrect JSON was found. Fix your payload and try again.",
    UserAlreadyExistsError:
      "This user already exists. Reenter your username and password or try again.",
    JobNotFoundError:
      "The job you’re requesting couldn’t be found. Choose a new job and try again.",
    FileNotFoundError:
      "The file was not uploaded. Upload your file and try again.",
    RunNotFoundError:
      "The job you’re requesting couldn’t be found. Choose a new job and try again.",
    MappingNotFoundError:
      "Your columns were not mapped. Map each column and try again.",
    CannotDeleteDefaultProfileError:
      "The default profile cannot be deleted. Change your default profile to a different profile and then try again.",
    DefaultProfileNotFoundError:
      "To have a default profile, you must have at least one profile created. ",
    ProfileNameAlreadyExistsError:
      "This profile name already exists. Create a unique profile name and try again.",
    CannotUnsetDefaultProfileError:
      "You must have a default profile. Because you only have one profile, this profile is automatically the default and cannot be changed.",
    ProfileNameError:
      "The profile name can contain only letters, numbers, and hyphens or underscores. It must be between 3-50 characters.",
    JobNameError:
      "The classification name can contain only letters, numbers, and hyphens or underscores. It must be between 3-50 characters.",
  };
  specialErrors = {
    FieldValidationError:
      "We could not validate your file. Check your file for errors and ensure all field mappings are correct. Then try again.",
    TitleContainsEmptyError:
      "The Title column you mapped to contains empty values. Make sure the field in your file that is mapped to the Title field does not contain any empty values and try again.",
    ItemCodeContainsDuplicatesError:
      "The field mapped for Item code contains duplicate values. Change your file to have no duplicates and upload your file again.",
    ItemCodeContainsEmptyError:
      "The field mapped to Item code contains empty values. Make sure the field in your file that is mapped to the Item code field does not contain any empty values and try again.",
    FileRecordLimitExceededError:
      "Your file can only have 30,000 rows of data. Update your file and upload it again.",
    FileTypeMismatchError:
      "Your file must be .xls or .xlxs format. Update your file and upload it again.",
    AVScanError:
      "Your file did not pass Avalara’s security check. Choose a different file and upload it again.",
    FileSizeLimitExceededError:
      "Your file can be up to 50 MB. Update your file and upload it again.",
    FileMimeTypeMismatchError:
      "The uploaded file has an extension does not match the file type. Check that your file is a .tsv or a .csv and upload your file again. ",
  };
  surfaceInternalErrors = { DefaultSchemaFileReadError : ""}
  errorMessage(code: string) {
    if (_.has(this.errorMessages, code)) {
      return this.errorMessages[code];
    } else if (_.has(this.specialErrors, code)) {
      return this.specialErrors[code];
    } else {
      return "";
    }
  } 
  getErrorMessage(error: any): string {
    const code = error.code
    let errorToDisplay = ""
    errorToDisplay = this.errorMessage(code);
    if (_.has(this.surfaceInternalErrors, code)) {
      errorToDisplay = errorToDisplay + "\n" + error.message
    } 
    return errorToDisplay
  }
}
const errorsUtil = new Errors();
export default errorsUtil;
