<template>
  <s-row>
    <s-col span="1">
      <div>
        <p class="result-text">Results</p>
      </div>
    </s-col>
    <s-col span="9">
      <div class="padding-top">
        <span class="taxability taxability-exempt border-dark"></span
        ><span class="text-center">Exempt</span
      ><span class="taxability taxability-special border-dark"></span>Special
        tax rates
        <span class="taxability taxability-unsupported border-dark"></span>Unsupported
      </div>
    </s-col>
    <s-col span="2">
      <h3>
        <a class="right">
          <button
              class="ghost icon-leading pad-top-xs text-xs"
              @click="onDownloadTaxability"
              title="download matrix"
              link
          >
            <s-icon name="download"></s-icon>
            Download this matrix
          </button>
        </a>
      </h3>
    </s-col>
    <s-col span="12">
      <div class="table-container">
        <table>
          <thead>
          <tr>
            <th v-if="regions.includes(Region.US) || regions.includes(Region.CA)" class="jurisdiction-header">JURISDICTIONS</th>
            <th v-if="!regions.includes(Region.US) && !regions.includes(Region.CA)" class="jurisdiction-header">COUNTRY</th>
            <th v-for="(taxCode, index) in taxcodes" :key="index">
              <div>{{ taxCode }}</div>
              <div
                  class="new-taxcode text-xs font-italic font-bold"
                  v-if="
                    taxcodeDetails[taxCode] &&
                      taxcodeDetails[taxCode].effectiveFrom &&
                      isNewTaxcode(taxcodeDetails[taxCode].effectiveFrom)
                  "
              >
                {{ newTaxcodeText(taxcodeDetails[taxCode].effectiveFrom) }}
              </div>
              <p
                  v-if="
                    !showFullDescription &&
                      taxcodeDetails[taxCode].description &&
                      taxcodeDetails[taxCode].description.length >
                        descriptionLengthLimit
                  "
                  class="taxcode-desc margin-top-none"
              >
                {{ getShortTaxcodeDescription(taxCode) }}
                <a @click="seeMore(taxCode)">...See more</a>
              </p>
              <p v-else class="taxcode-desc margin-top-none">
                {{ taxcodeDetails[taxCode].description || "" }}
              </p>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="countryName in getCountryNames()" :key="countryName">
            <td class="jurisdiction-cell">
              <s-box>{{ countryName }}</s-box>
            </td>
            <td v-for="(taxCode, index) in taxcodes" :key="index">
              <s-box :class="['big-cell', getTaxabilityClass(taxCode, countryName)]">
                <div>
                  <span class="">
                  {{ getTaxabilityByCodeAndJurisdiction(taxCode, [countryName]).taxability }}
                  </span>
                  <span class="special-tax">
                      {{
                      getTaxabilityByCodeAndJurisdiction(
                          taxCode,
                          [countryName]
                      ).specialTax
                    }}</span
                  >
                </div>
                <div class="additional-info">
                  <div>
                    {{
                      getTaxabilityByCodeAndJurisdiction(taxCode, [countryName])
                        .attributes.rate_type
                    }}
                  </div>
                  <div>
                    {{
                      getTaxabilityByCodeAndJurisdiction(taxCode, [countryName])
                        .additional_info
                    }}
                  </div>
                </div>
              </s-box>
            </td>
          </tr>
          <tr v-for="(jurisdiction, index) in jurisdictions" :key="index">
            <td class="jurisdiction-cell">
              <s-box>{{ getCountryNameWithPrefix(jurisdiction) }}</s-box>
            </td>
            <td v-for="(taxCode, index) in taxcodes" :key="index">
              <s-box
                  v-if="
                    getTaxabilityByCodeAndJurisdiction(taxCode, jurisdiction)
                      .country_code === Region.CA
                  "
                  :class="['small-cell', getTaxabilityClass(taxCode, 'Canada')]"
              ><span class="tax-type">Country Tax: </span
              >{{
                  getTaxabilityByCodeAndJurisdiction(taxCode, "Canada")
                      .taxability
                }}
              </s-box
              >
              <s-box
                  v-if="
                    getTaxabilityByCodeAndJurisdiction(taxCode, jurisdiction)
                      .country_code === Region.CA
                  "
                  :class="[
                    'small-cell',
                    getTaxabilityClass(taxCode, jurisdiction),
                  ]"
              >
                <div>
                  <span class="tax-type">State Tax: </span>
                  {{
                    getTaxabilityByCodeAndJurisdiction(taxCode, jurisdiction)
                        .taxability
                  }}
                  <div class="additional-info">
                    {{
                      getTaxabilityByCodeAndJurisdiction(taxCode, jurisdiction)
                          .additional_info
                    }}
                  </div>
                </div>
              </s-box>
              <s-box
                  v-else-if="regions.includes(Region.US)"
                  :class="[
                    'big-cell',
                    getTaxabilityClass(taxCode, jurisdiction),
                  ]"
              >
                <div>
                    <span>{{
                        getTaxabilityByCodeAndJurisdiction(taxCode, jurisdiction)
                            .taxability
                      }}</span>
                  <span class="special-tax">
                      {{
                      getTaxabilityByCodeAndJurisdiction(
                          taxCode,
                          jurisdiction
                      ).specialTax
                    }}</span
                  >
                </div>
                <div class="additional-info">
                  {{
                    getTaxabilityByCodeAndJurisdiction(taxCode, jurisdiction)
                        .additional_info
                  }}
                </div>
              </s-box>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </s-col>
  </s-row>
</template>

<script lang="ts">
import {Component, Vue, Prop, Watch, Provide} from "vue-property-decorator";
import dayjs from "dayjs";

enum TaxabilityType {
  EXEMPT = "Exempt",
  ZERO = "Zero",
  TAXABLE = "Taxable",
  NOMAD = "No State Sales Tax",
  ALBERTA = "No Provincial Sales Tax",
  CANADAEXEMPT = "No Territorial Sales Tax",
  UNSUPPORTED = "Unsupported"
}

interface TaxCode {
  code: string;
  description: string;
  additionalDescription: string;
  categories: string[];
}

enum Region {
  US = "US",
  CA = "CA",
}

interface TaxabilityRule {
  taxability: string;
  jurisdiction_name: string;
  jurisdiction_code: string;
  country_code: string;
  jurisdiction_type: string;
  additional_info: string;
}

@Component
export default class TaxabilityTable extends Vue {
  @Provide("$api") $api: any;
  @Prop({required: true}) private taxcodes!: string[];
  @Prop({required: true}) private jurisdictions!: string[];
  @Prop({required: true}) private regions!: string[];
  @Prop({required: true}) private codeToJurisdictionTaxability!: {
    [taxCode: string]: { [jurisdiction: string]: TaxabilityRule };
  };

  taxcodeDetails = {};
  descriptionLengthLimit = 80;
  showFullDescription = false;

  @Watch("taxcodes")
  public updateTaxcodes(newTaxcodes: string[]) {
    this.taxcodes = newTaxcodes;
    this.updateTaxcodeDetails();
  }

  @Watch("jurisdictions")
  public updateJurisdictions(newJurisdictions: string[]) {
    this.jurisdictions = newJurisdictions;
  }

  @Watch("region")
  public updateRegions(newRegions: string[]) {
    this.regions = newRegions;
  }

  @Watch("codeToJurisdictionTaxability")
  public updateCodeToJurisdictionTaxability(newMappings: {
    [taxCode: string]: { [jurisdiction: string]: TaxabilityRule };
  }) {
    this.codeToJurisdictionTaxability = newMappings;
  }

  getCountryNameWithPrefix(jurisdiction) {
    if (jurisdiction === "Canada") {
      return jurisdiction;
    }

    const firstTaxcode = Object.keys(this.codeToJurisdictionTaxability)[0];
    const countryCode =
      this.codeToJurisdictionTaxability[firstTaxcode][jurisdiction]
        .country_code;

    if (countryCode === Region.CA) {
      return "CAN - " + jurisdiction;
    } else {
      return "US - " + jurisdiction;
    }
  }

  getCountryNames() {
    const regions = this.regions.filter(
      (region) => region !== Region.US && region !== Region.CA
    );
    const ruleForCountry = this.codeToJurisdictionTaxability[this.taxcodes[0]];
    const countries = Object.keys(ruleForCountry).filter((countryName) =>
      regions.includes(ruleForCountry[countryName].country_code)
    );
    return countries;
  }

  Region = Region;

  getShortTaxcodeDescription(taxcode) {
    const description: string = this.taxcodeDetails[taxcode]?.description || "";
    return description.substr(0, this.descriptionLengthLimit - 15);
  }

  seeMore() {
    this.showFullDescription = true;
  }

  mounted() {
    this.updateTaxcodeDetails();
  }

  public updateTaxcodeDetails() {
    this.$api
        .post("/api/taxcodes/", this.taxcodes)
        .then((response) => {
          const taxcodesData: TaxCode[] = response.data;
          taxcodesData.forEach((taxcodeData) =>
              Vue.set(this.taxcodeDetails, taxcodeData.code, taxcodeData)
          );
          this.$store.commit("stopLoading");
        })
        .catch((error) => this.$store.commit("stopLoading"));
  }

  public onDownloadTaxability() {
    this.$api
        .post(
            "/api/taxability/",
            {
              taxcodes: this.taxcodes,
              country_codes: this.regions,
              state_codes: this.jurisdictions.map(
                  (jurisdiction) =>
                      this.codeToJurisdictionTaxability[this.taxcodes[0]][jurisdiction]
                          .jurisdiction_code
              ),
            },
            {
              responseType: "arraybuffer",
              headers: {
                Accept:
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              },
              params: {
                include_country: true
              },
            }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "taxability.xlsx");
          document.body.appendChild(link);
          link.click();
          this.captureDownloadTaxability();
        })
        .catch((error) => this.$store.commit("stopLoading"));
  }

  public captureDownloadTaxability() {
    if (this.$store.getters.analyticsEnabled) {
      window._satellite.track("taxability_build_download");
    }
  }

  public getTaxabilityClass(taxCode: string, jurisdiction: string): string {
    const taxabilityRule = this.getTaxabilityByCodeAndJurisdiction(
        taxCode,
        jurisdiction
    );
    if ([TaxabilityType.EXEMPT.toString(), TaxabilityType.NOMAD.toString(), TaxabilityType.CANADAEXEMPT.toString(), TaxabilityType.ALBERTA.toString()].indexOf(taxabilityRule.taxability) >= 0)
      return "taxability-exempt";
    if (taxabilityRule.taxability == TaxabilityType.UNSUPPORTED)
      return "taxability-unsupported"
    if (
        taxabilityRule.taxability == TaxabilityType.ZERO ||
        taxabilityRule.specialTax != "" ||
        taxabilityRule.additional_info != ""
    )
      return "taxability-special";
    return "";
  }

  private newTaxcodeText(date) {
    return date.endsWith("Z")
        ? "New tax code! Code will be active from " +
        dayjs(date.substr(0, date.length - 1)).format("D MMM, YYYY")
        : "";
  }

  private isNewTaxcode(date) {
    return dayjs(date).isAfter(dayjs(new Date()));
  }

  public getTaxabilityByCodeAndJurisdiction(
      taxCode: string,
      jurisdiction: string
  ) {
    if (
        this.codeToJurisdictionTaxability[taxCode] &&
        this.codeToJurisdictionTaxability[taxCode][jurisdiction] &&
        this.codeToJurisdictionTaxability[taxCode][jurisdiction].taxability != ""
    ) {
      const taxability = this.codeToJurisdictionTaxability[taxCode][
          jurisdiction
          ].taxability;
      if (taxability === TaxabilityType.EXEMPT)
        return {
          ...this.codeToJurisdictionTaxability[taxCode][jurisdiction],
          specialTax: "",
        };
      if (taxability === TaxabilityType.NOMAD)
        return {
          ...this.codeToJurisdictionTaxability[taxCode][jurisdiction],
          specialTax: "",
          additional_info: "Sales Tax not applicable for this state but other taxes may apply"
        };
      if (taxability === TaxabilityType.ALBERTA)
        return {
          ...this.codeToJurisdictionTaxability[taxCode][jurisdiction],
          specialTax: "",
          additional_info: "Sales Tax not applicable for this province but other taxes may apply"
        };
      if (taxability === TaxabilityType.CANADAEXEMPT)
        return {
          ...this.codeToJurisdictionTaxability[taxCode][jurisdiction],
          specialTax: "",
          additional_info: "Sales Tax not applicable for this territory but other taxes may apply"
        };
      if (
          taxability === TaxabilityType.TAXABLE ||
          taxability === TaxabilityType.ZERO ||
          taxability == TaxabilityType.UNSUPPORTED
      )
        return {
          ...this.codeToJurisdictionTaxability[taxCode][jurisdiction],
          specialTax: "",
        };
      return {
        ...this.codeToJurisdictionTaxability[taxCode][jurisdiction],
        taxability: TaxabilityType.TAXABLE,
        specialTax: taxability,
      };
    }
    return {
      taxability: "",
      specialTax: "",
      jurisdiction_name: "",
      jurisdiction_code: "",
      country_code: "",
      jurisdiction_type: "",
      additional_info: "",
    };
  }
}
</script>

<style scoped lang="scss">
.table-container {
  overflow-x: scroll;
  padding: 0px;
  border: 0.1rem solid var(--color-gray-medium);
  margin-bottom: 50px;
}

table {
  margin-top: 0px;
  table-layout: fixed;
  width: auto;
}

table td {
  width: 320px;
  font-weight: bold;
  font-size: 0.865rem;
  border-bottom: 5px solid var(--color-white);
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 5px;

  s-box.small-cell {
    min-height: 40px;
    width: 320px;
  }

  s-box.big-cell {
    min-height: 80px;
    width: 320px;
  }

  s-box {
    .tax-type {
      font-weight: normal;
    }

    .special-tax {
      color: var(--color-green-dark);
    }

    .additional-info {
      div {
        line-height: 0.7rem;
        font-weight: normal;
        font-size: 0.67rem;
      }
      line-height: 0.7rem;
      font-weight: normal;
      font-size: 0.67rem;
      height: 3rem;
    }
  }
}

thead {
  background-color: var(--color-gray-lightest);
  font-weight: bold;
  font-size: 0.865rem;
  border-bottom: 5px solid var(--color-white);
  border-top: none;

  th {
    .taxcode-desc {
      a {
        display: initial !important;
      }

      font-weight: 500;
      font-size: 0.78rem;
      white-space: initial;
    }

    padding-top: 12px;
    padding-bottom: 12px;
    vertical-align: top;
    border-top: none;
    width: 320px;
  }

  .jurisdiction-header {
    color: var(--color-gray-dark);
    font-size: 0.675rem;
    min-width: 250px;
  }
}

.jurisdiction-cell {
  background-color: var(--color-gray-lightest);

  s-box {
    background-color: var(--color-gray-lightest);
    border: none;
  }
}

s-col {
  padding-bottom: 0.1rem;
}

button.ghost {
  color: var(--color-blue-dark);
}

button.primary {
  font-size: 0.782rem;
  flex: auto;
}

.result-text {
  font-size: 1.4rem;
  font-weight: 500;
  margin-top: 20px;
}

.taxability {
  margin: 0px 5px 2px 20px;
  display: inline-block;
  border-radius: 5px;
  min-width: 20px;
  min-height: 20px;
  vertical-align: middle;
}

.taxability-exempt {
  background-color: var(--color-yellow-lightest);
  border: 1px solid var(--color-yellow-darker);
}

.taxability-special {
  background-color: var(--color-green-lightest);
  border: 1px solid var(--color-green-darker);
}

.taxability-unsupported {
  background-color: var(--color-red-lightest);
  border: 1px solid var(--color-red-darker);

  .additional-info {
    div {
      line-height: 0.7rem;
    }
    max-height: 3rem;
  }
}

.padding-top {
  padding-top: 20px;
}

.border-dark {
  border-width: 2px;
}

.new-taxcode {
  color: var(--color-blue-dark);
}
</style>
